import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Alert,
    Button,
    ButtonGroup,
    CardTitle,
    CardText,
    ListGroupItem, Badge, Modal, ModalHeader, ModalBody, Label, ModalFooter
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {AvForm, AvField, AvCheckbox} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    listTickets,
    addTicketPackage,
    listTicketPackage,
    deleteTicketPackage,
    editMicroArea, uploadImageArea, editTicketPackage, uploadTicketPackage
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {DataGrid} from "@material-ui/data-grid";

var moment = require('moment');

class TicketShopPackagePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "Nome ticket", field: "name", width: 180, valueGetter: (params) =>
                        (params.getValue(params.id, "is_disabled") ? "[DISABILITATO] " : "") + params.value
                },
                {headerName: "Ente", field: "society", width: 180, renderCell: (params) => params.formattedValue.name},
                {
                    headerName: "Area", field: "areaName", width: 140, valueGetter: (params) =>
                        (params.getValue(params.id, "area").name !== null ? params.getValue(params.id, "area").name : "") + " " +
                        (params.getValue(params.id, "micro_area") !== null ? params.getValue(params.id, "micro_area").name : "")
                },
                {headerName: "Prezzo", field: "price", width: 140},
                {headerName: "Inizio validità", field: "date_start_validity", width: 160},
                {headerName: "Fine validità", field: "date_end_validity", width: 160},
                {
                    headerName: "Tipologia", field: "typeName", width: 140, valueGetter: (params) => {
                        switch (params.getValue(params.id, "type")) {
                            case "DAILY":
                                return "Giornaliero";
                            case "TWODAYS":
                                return "Bigiornaliero";
                            case "THREEDAYS":
                                return "Tre giorni";
                            case "FOURDAYS":
                                return "Quattro giorni";
                            case "WEEKDAYS":
                                return "Cinque giorni";
                            case "WEEKLY":
                                return "Settimanale";
                            case "TWOWEEKS":
                                return "Bisettimanale";
                            case "MONTHLY":
                                return "Mensile";
                            case "TRIMESTER":
                                return "Trimestrale";
                            case "SEASONAL":
                                return "Stagionale";
                            case "SEMIANNUAL":
                                return "Semestrale";
                            case "YEARLY":
                                return "Annuale";
                            case "BIANNUAL":
                                return "Biennale";
                            case "TRIENNIAL":
                                return "Triennale";
                        }
                    }
                },
                {
                    headerName: "Tipo residenti", field: "number", width: 180, renderCell: (params) => {
                        if (params.getValue(params.id, "ticket_residence_type") === null)
                            return (<Badge color="alert">Sconosciuto</Badge>)
                        return (<Badge color="info">{params.getValue(params.id, "ticket_residence_type").name}</Badge>)
                    }
                },
            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            ticket: null,
            list_packages: [],
            package_edit: null
        };
    }

    async handleValidSubmit(event, values, edit = false) {
        if (this.state.ticket === null) {
            global.notify("Devi selezionare il ticket", "danger");
            return;
        }
        values.id_ticket = this.state.ticket.id;

        let formResult = await addTicketPackage(values);
        if (typeof formResult.shop_package !== 'undefined') {
            global.notify("Pacchetto aggiunto con successo", "success");
            this.form && this.form.reset();
            this.loadListPackages(this.state.ticket.id);
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con l'aggiunta: " + formResult.error, "danger");
            this.setState({error: formResult.error});
        } else {
            this.setState({error: 'Errore generico'});
        }
    }

    async handleValidSubmitEdit(event, values, shop_package) {
        values.id = shop_package.id;
        let res = await editTicketPackage(values);
        if (typeof res.error !== 'undefined')
            global.notify("Il pacchetto non è stato modificato: " + res.error, "danger");
        else
            global.notify("Pacchetto modificato con successo", "success");
        this.loadListPackages(this.state.ticket.id);
        this.handleClose();
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let package_edit = this.state.package_edit;
            var formData = new FormData();
            formData.append("image", event.target.files[0]);
            formData.append("id", package_edit.id);
            uploadTicketPackage(formData).then(result => {
                if (typeof result.error === "undefined") {
                    package_edit.image = result.package.image;
                    this.setState({package_edit: package_edit});

                    global.notify("L'immagine è caricata", "success");
                } else {
                    global.notify("L'immagine non è stata caricata", "danger");
                }
            });
        }
    };

    async componentDidMount() {
        this.loadList(0);
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listTickets(null, page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        });
    }

    handlePageChange = (params) => {
        this.loadList(params.page)
    };

    handleRowSelected = (rows) => {
        let row = [...rows.entries()][0][1];
        this.setState({ticket: row}, () => this.loadListPackages(row.id));
    }

    loadListPackages = (id_ticket) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listTicketPackage(id_ticket).then(result => {
            paginator.loading = false;
            this.setState({paginator: paginator});
            if (!result)
                this.setState({list_packages: []});
            else
                this.setState({list_packages: result.list});
        });
    }

    deletePackage = (id_package) => {
        deleteTicketPackage(id_package).then(result => {
            if (typeof result.error !== 'undefined')
                global.notify("Errore con l'eliminazione: " + result.error, "danger");
            else
                global.notify("Pacchetto eliminato con successo", "success");
            this.loadListPackages(this.state.ticket.id);
        });
    }

    renderFormAdd = () => {
        if (this.state.ticket !== null)
            return <AvForm className="form-horizontal" onValidSubmit={(e, v,) => {
                this.handleValidSubmit(e, v)
            }} ref={c => (this.form = c)}>
                <div className={"row"}>
                    <div className="col-md-12">
                        <h5>Aggiunta pacchetto</h5>
                    </div>
                    <div className="form-group col-md-12">
                        <AvField name="name" label="Nome"
                                 className="form-control" placeholder="Inserisci il nome"
                                 type="text" required/>
                    </div>
                    <div className="form-group col-md-12">
                        <AvField name="description" label="Descrizione"
                                 className="form-control" placeholder="Inserisci la descrizione del pacchetto"
                                 type="textarea"/>
                    </div>
                    <div className="form-group col-md-4">
                        <AvField name="price" label="Prezzo"
                                 className="form-control" placeholder="Inserisci il prezzo"
                                 type="text" required/>
                    </div>
                    <div className="form-group col-md-4">
                        <AvField name="discounted_price" label="Prezzo scontato"
                                 className="form-control" placeholder="Inserisci il prezzo scontato"
                                 type="text"/>
                    </div>
                    <div className="form-group col-md-4">
                        <AvField type="checkbox" className="form-check-input"
                                 id="is_price_for_all" name="is_price_for_all"
                                 label="Prezzo unificato?"/>
                        <small>(se abilitato, il prezzo non viene moltiplicato per il numero dei ticket)</small>
                    </div>
                    <div className="form-group col-md-6">
                        <AvField name="link" label="Link"
                                 className="form-control" placeholder="Inserisci il link del regolamento"
                                 type="text"/>
                    </div>
                    <div className="form-group col-md-6">
                        <AvField name="type" label="Tipologia"
                                 className="form-control" placeholder="Inserisci tipo"
                                 type="select" required>
                            <option value={"INSURANCE"}>Assicurazione</option>
                            <option value={"BOUGHT_TOGETHER"}>Comprato insieme</option>
                            <option value={"RECOMMEND"}>Raccomandato</option>
                            <option value={"PRODUCT"}>Prodotto</option>
                        </AvField>
                    </div>
                </div>
                <div className="text-center mt-4">
                    <Button type="submit" color="success">Crea</Button>
                </div>
            </AvForm>
    }

    renderListPackages = () => {
        if (this.state.ticket === null)
            return;
        let list = [];
        if (Object.keys(this.state.list_packages).length === 0) {
            list = <ListGroupItem>Non ci sono pacchetti da mostrare</ListGroupItem>
        } else {
            for (let i in this.state.list_packages) {
                let element = this.state.list_packages[i];
                let type = "Assicurazione";
                if (element.type === "PRODUCT")
                    type = "Prodotto";
                if (element.type === "BOUGHT_TOGETHER")
                    type = "Comprato insieme";
                if (element.type === "RECOMMEND")
                    type = "Raccomandato";
                list.push(<ListGroupItem>
                    <div className={"row"}>
                        <div className={"col-md-3"}>
                            Nome: {element.name}
                        </div>
                        <div className={"col-md-3"}>
                            Tipologia: {type}
                        </div>
                        <div className={"col-md-3"}>
                            Prezzo: €{element.price}
                        </div>
                        <div className={"col-md-3"} align={"right"}>
                            <ButtonGroup>
                                <Button color={"warning"} size="sm" onClick={() => this.setState({
                                    modalEdit: true,
                                    package_edit: element
                                })}>Modifica</Button>
                                <Button color={"danger"} size="sm"
                                        onClick={() => this.deletePackage(element.id)}>Elimina</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </ListGroupItem>)
            }
        }

        return <Card className={"mt-4"}>
            <CardBody>
                <h5>Lista pacchetti aggiuntivi</h5>
                {list}
            </CardBody>
        </Card>
    }

    handleClose = () => this.setState({modalEdit: false, package_edit: null});

    renderModal = () => {
        console.log(this.state.package_edit)
        if (this.state.modalEdit && this.state.package_edit !== null)
            return <Modal isOpen={this.state.modalEdit} backdrop="static" keyboard={false} size="xl">
                <AvForm onValidSubmit={(e, v) => {
                    this.handleValidSubmitEdit(e, v, this.state.package_edit)
                }} ref={c => (this.form_edit = c)}>
                    <ModalHeader>Modifica pacchetto: {this.state.package_edit.name}</ModalHeader>
                    <ModalBody>
                        <div className={"row"}>
                            <div className="form-group col-md-12">
                                <AvField name="name" label="Nome" value={this.state.package_edit.name}
                                         className="form-control" placeholder="Inserisci il nome"
                                         type="text" required/>
                            </div>
                            <div className="form-group col-md-12">
                                <AvField name="description" label="Descrizione"
                                         value={this.state.package_edit.description}
                                         className="form-control" placeholder="Inserisci la descrizione del pacchetto"
                                         type="textarea"/>
                            </div>
                            <div className="form-group col-md-4">
                                <AvField name="price" label="Prezzo" value={this.state.package_edit.price}
                                         className="form-control" placeholder="Inserisci il prezzo"
                                         type="text" required/>
                            </div>
                            <div className="form-group col-md-4">
                                <AvField name="discounted_price" label="Prezzo scontato"
                                         value={this.state.package_edit.discounted_price}
                                         className="form-control" placeholder="Inserisci il prezzo scontato"
                                         type="text"/>
                            </div>
                            <div className="form-group col-md-4">
                                <AvField type="checkbox" className="form-check-input"
                                         id="is_price_for_all" name="is_price_for_all"
                                         value={this.state.package_edit.is_price_for_all}
                                         label="Prezzo unificato?"/>
                                <small>(se abilitato, il prezzo non viene moltiplicato per il numero dei ticket)</small>
                            </div>
                            <div className="form-group col-md-6">
                                <AvField name="link" label="Link" value={this.state.package_edit.link}
                                         className="form-control" placeholder="Inserisci il link del regolamento"
                                         type="text"/>
                            </div>
                            <div className="form-group col-md-6">
                                <AvField name="type" label="Tipologia" value={this.state.package_edit.type}
                                         className="form-control" placeholder="Inserisci tipo"
                                         type="select" required>
                                    <option value={"INSURANCE"}>Assicurazione</option>
                                    <option value={"BOUGHT_TOGETHER"}>Comprato insieme</option>
                                    <option value={"RECOMMEND"}>Raccomandato</option>
                                    <option value={"PRODUCT"}>Prodotto</option>
                                </AvField>
                            </div>
                        </div>
                        <hr/>
                        <h6>Sfondo del pacchetto</h6>
                        <div align={"center"}>
                            <img
                                src={this.state.package_edit.image !== null ? global.baseUrlImg + '/' + this.state.package_edit.image : ""}
                                style={{width: "100%"}}/>
                        </div>
                        <div align={"center"}>
                            <input type="file" name="image" onChange={this.onImageChange}/>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.handleClose}>Chiudi</Button>
                        <Button type="submit" color="success">Salva</Button>
                    </ModalFooter>
                </AvForm>
            </Modal>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Aggiunta pacchetti aggiuntivi"/>
                        <Card>
                            <CardBody>
                                <h5>Scegli il ticket</h5>
                                <div style={{height: '40vh', width: '100%'}}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        pagination
                                        pageSize={this.state.paginator.limit}
                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                        rowCount={this.state.paginator.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageChange}
                                        loading={this.state.paginator.loading}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <br/>
                                {this.renderFormAdd()}
                            </CardBody>
                        </Card>
                        {this.renderListPackages()}
                        {this.renderModal()}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(TicketShopPackagePage));
