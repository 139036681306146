import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import GoogleMapReact from 'google-map-react';
import {DataGrid} from '@material-ui/data-grid';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {getListAreas, getMapAreas, fileRules, getListAreasShowcase} from '../../../store/actions';
import Breadcrumb from '../../../components/Common/Breadcrumb';

class ZoneShowcaseListPage extends Component {
    static defaultProps = {
        center: {
            lat: 42.45,
            lng: 12.96
        },
        zoom: 5.5
    };

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Nome area", field: "name", width: 180},
                {
                    headerName: "Categoria",
                    field: "category",
                    width: 140,
                    renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Provincia", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "Numero", field: "number", width: 140},
                {headerName: "Città", field: "city", width: 160},
                {headerName: "CAP", field: "cap", width: 140},
                {headerName: "Telefono", field: "phone", width: 140},
                {
                    headerName: " ",
                    width: 120,
                    field: 'id',
                    renderCell: (params) => (
                        <Button onClick={() => this.actionButton(params.id)} type="button"
                                color="primary">Modifica</Button>
                    )
                }
            ],
            rows: [],
            map: null,
            maps: null,
            paginator: {loading: true, countRows: 0, limit: 50},
            markers: [],
            image: null,
            modal: false,
            imageUpload: null,
            uploadId: null
        };
    }

    handleClose = () => this.setState({modal: false});

    async componentDidMount() {
        this.loadListAreas(0);
    }

    handleBoundChanged = () => {
        if (this.state.map !== null && this.state.maps !== null)
            this.handleApiLoaded(this.state.map, this.state.maps);
    }

    loadListAreas = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getListAreasShowcase(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    handlePageChange = (params) => {
        this.loadListAreas(params.page)
    };

    actionButton = (value) => {
        window.location.href = '/showcase/edit/' + value;
    }

    fileButton = (id) => {
        this.setState({modal: true, uploadId: id});
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista Zone"/>
                        <Card>
                            <CardBody>
                                <div className="form-group">
                                    <div style={{height: '100vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChange}
                                            loading={this.state.paginator.loading}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {getListAreas, getMapAreas})(ZoneShowcaseListPage));
