import {apiCall} from '../../../helpers/backend_helper';

export const getUserSos = async (page) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/user/society/list', data: {page: page}});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        return false;
    }
}

export const setUserSos = async (societyUser) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/society/user/association', data: societyUser});
        if (response) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        return false;
    }
}
export const deleteUserSos = async (society) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/society/user/association/delete', data: society});
        if (response) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}