import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal,
    ButtonGroup
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    apiError, getSubscriptionClients, suspendSubscription
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {AvField, AvForm} from "availity-reactstrap-validation";


class SubscriptionClientPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Email", field: "email", width: 180},
                {headerName: "Nome", field: "firstname", width: 160},
                {headerName: "Cognome", field: "lastname", width: 160},
                {
                    headerName: "Abbonamento", field: "premium", width: 180, renderCell: (params) => {
                        let premium = params.getValue(params.id, "premium");
                        if (premium !== null) {
                            if (premium.type_payment === "PAYMENT_APPLE")
                                return "Apple premium";
                            return "Premium";
                        }
                    }
                },
                {
                    headerName: "Tipologia", field: "premium1", width: 160, renderCell: (params) => {
                        let premium = params.getValue(params.id, "premium");
                        if (premium === null)
                            return "Deve pagare";
                        if (premium.type === "MONTHLY")
                            return "Mensile";
                        if (premium.type === "YEARLY")
                            return "Annuale";
                    }
                },
                {
                    headerName: "Data inizio", field: "premium2", width: 160, renderCell: (params) => {
                        let premium = params.getValue(params.id, "premium");
                        if (premium === null)
                            return "";
                        if (premium.date_start !== null)
                            return premium.date_start;
                    }
                },
                {
                    headerName: "Data fine trial", field: "premium3", width: 160, renderCell: (params) => {
                        let premium = params.getValue(params.id, "premium");
                        if (premium === null)
                            return "";
                        if (premium.date_end_trial !== null)
                            return premium.date_end_trial;
                        return "-";
                    }
                },
                {
                    headerName: "Data scadenza", field: "premium4", width: 160, renderCell: (params) => {
                        let premium = params.getValue(params.id, "premium");
                        if (premium === null)
                            return "";
                        if (premium.date_expire !== null)
                            return premium.date_expire;
                        return "-";
                    }
                },
                {
                    headerName: " ",
                    width: 240,
                    field: 'id',
                    renderCell: (params) => {
                        let is_disabled = false;
                        let premium = params.getValue(params.id, "premium");
                        if (premium === null || premium.status === "STATUS_SUSPENDED")
                            is_disabled = true;

                        return <ButtonGroup>
                            <Button onClick={() => window.location.href = "/client_info/" + params.row.id}
                                    type="button"
                                    color="primary">Scheda</Button>
                            <Button onClick={() => this.deleteClient(params.row)} type="button" disabled={is_disabled}
                                    color="danger">Sospendi</Button>
                        </ButtonGroup>
                    }
                }
            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            modal: false,
            user: null,
            reason: null,
            date: null,
            search: null
        };
    }

    async handleValidSubmit(event, values) {
        let search = null;
        if (values.search !== "" && values.search !== null)
            search = values.search;
        this.setState({search: search}, () => this.loadListClients(0));
    }

    deleteClient = (user) => {
        this.setState({modal: true, user: user});
    }

    loadListClients = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getSubscriptionClients(this.state.search, page + 1).then(result => {
            paginator.loading = false;

            if (!result) {
                paginator.page = 0;
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.page += 1;
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        });
    }

    handlePageChange = (params) => {
        this.loadListClients(params.page);
    };

    async componentDidMount() {
        this.loadListClients(0);
    }

    handleClose = () => this.setState({modal: false, user: null, link: null});

    suspendSubscription = () => {
        let data = {id: this.state.user.id};
        suspendSubscription(data).then((result) => {
            if (typeof result.error === 'undefined') {
                global.notify('Azione eseguita con successo', "success");
                this.setState({user: null, modal: false});
                this.loadListClients(0);
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }


    renderModal = () => {
        let user = this.state.user;
        if (user === null)
            return;
        return <Modal isOpen={this.state.modal} backdrop="static" keyboard={false}>
            <ModalHeader>Sospendi abbonamento dell'utente {user.firstname} {user.lastname}</ModalHeader>
            <ModalBody>
                <div align={"center"} className={"mt-2"}>
                    {user.premium.type_payment === "PAYMENT_APPLE" ? <><h5 style={{color: "#F44336"}}>Devi sospendere l'abbonamento dalla dashboard
                            della Apple</h5></> :
                        <Button type="button" color={"danger"} onClick={this.suspendSubscription}>Sospendi</Button>}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.handleClose}>Chiudi</Button>
            </ModalFooter>
        </Modal>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Abbonati"/>
                        <Card>
                            <CardBody>
                                <h5>Lista abbonati</h5>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v)
                                }} ref={c => (this.form = c)}>
                                    <div className={"row"}>
                                        <div className="form-group col-md-8">
                                            <AvField name="search" label="Cerca utente"
                                                     className="form-control"
                                                     placeholder="Cerca utente per nome, cognome o email"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-4" style={{display: "flex"}}>
                                            <div style={{marginTop: "auto"}}>
                                                <Button type="submit" color="primary">Cerca</Button>
                                            </div>
                                        </div>
                                    </div>
                                </AvForm>

                                <div style={{height: '80vh', width: '100%'}} className={'mt-2'}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        pagination
                                        pageSize={this.state.paginator.limit}
                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                        rowCount={this.state.paginator.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageChange}
                                        loading={this.state.paginator.loadingFishes}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                        {this.renderModal()}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {apiError})(SubscriptionClientPage));