import {put} from 'redux-saga/effects';
import {apiCall, UploadFile} from '../../../helpers/backend_helper';

export const getCategory = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/category/list'});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setCategory = async (category) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/category/add', data: category});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editCategory = async (category) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/category/edit', data: category});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const ImgCategory = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await UploadFile({method: 'POST', url: 'admin/category/upload', data: data});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}


export const uploadIconCategory = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await UploadFile({method: 'POST', url: 'admin/category/icon/upload', data: data});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const deleteCategory = async (idCat) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/category/delete', data: idCat});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getListServiceTag = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/service/tag/list'});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const addServiceTag = async (category) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/service/tag/add', data: category});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editServiceTag = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/service/tag/edit', data: data});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const imageServiceTag = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await UploadFile({method: 'POST', url: 'admin/service/tag/upload', data: data});
    } catch (error) {
        return false;
    }
}

export const iconServiceTag = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await UploadFile({method: 'POST', url: 'admin/service/tag/icon/upload', data: data});
    } catch (error) {
        return false;
    }
}

export const deleteServiceTag = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/service/tag/delete', data: {id: id}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}