import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    ButtonGroup,
    CardTitle,
    CardText,
    Badge,
    ListGroup, ListGroupItem, Label
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

import {AvForm, AvField, AvGroup} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import Chip from '@mui/material/Chip';

import {
    setRules,
    getListAreas,
    listAreaTickets,
    infoRules,
    deleteRules,
    getFishRegulation,
    getCategories,
    addFishingGroup,
    deleteFishingGroup, setFishingGroupRule, deleteFishingGroupRule, setMaximumSessions
} from '../../store/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';
import {DataGrid} from "@material-ui/data-grid";
import ClearIcon from "@mui/icons-material/Clear";

var moment = require('moment');

class RuleAddPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [{headerName: "Nome area", field: "name", width: 180},
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Province", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 140},
                {headerName: "CAP", field: "cap", width: 140},
                {headerName: "Telefono", field: "phone", width: 160}],
            columnsMicroArea: [{headerName: "Nome zona", field: "name", width: 240}],
            columnsTicket: [
                {
                    headerName: "Nome ticket", field: "name", width: 180, valueGetter: (params) =>
                        (params.getValue(params.id, "is_disabled") ? "[DISABILITATO] " : "") + params.value
                },
                {headerName: "Prezzo", field: "price", width: 160},
                {headerName: "Inizio validità", field: "date_start_validity", width: 160},
                {headerName: "Fine validità", field: "date_end_validity", width: 160},
                {
                    headerName: "Tipologia", field: "typeName", width: 160, valueGetter: (params) => {
                        switch (params.getValue(params.id, "type")) {
                            case "DAILY":
                                return "Giornaliero";
                            case "TWODAYS":
                                return "Bigiornaliero";
                            case "THREEDAYS":
                                return "Tre giorni";
                            case "FOURDAYS":
                                return "Quattro giorni";
                            case "WEEKDAYS":
                                return "Cinque giorni";
                            case "WEEKLY":
                                return "Settimanale";
                            case "TWOWEEKS":
                                return "Bisettimanale";
                            case "MONTHLY":
                                return "Mensile";
                            case "TRIMESTER":
                                return "Trimestrale";
                            case "SEASONAL":
                                return "Stagionale";
                            case "SEMIANNUAL":
                                return "Semestrale";
                            case "YEARLY":
                                return "Annuale";
                            case "BIANNUAL":
                                return "Biennale";
                            case "TRIENNIAL":
                                return "Triennale";
                        }
                    }
                },
                {
                    headerName: "Tipo residenti", field: "ticket_residence_type", width: 180, renderCell: (params) => {
                        if (params.value === null)
                            return (<Badge color="alert">Sconosciuto</Badge>)
                        return (<Badge color="info">{params.value.name}</Badge>)
                    }
                },
            ],
            columnsFish: [
                {headerName: "Nome Pesce", field: "name", flex: 1},
                {headerName: "Descrizione", field: "description", flex: 1},
            ],
            columnsSpec: [
                {
                    headerName: 'Lunghezza min',
                    field: 'min_length',
                    flex: 1
                },
                {
                    headerName: 'Lunghezza max',
                    field: 'max_length',
                    flex: 1
                },
                {
                    headerName: 'Larghezza min',
                    field: 'min_weight',
                    flex: 1
                },
                {
                    headerName: 'Larghezza max',
                    field: 'max_weight',
                    flex: 1
                },
            ],
            rows: [],
            rowsMicroArea: [],
            rowsTicket: [],
            rowsFish: [],
            rowsSpec: [],
            form_edit_session: {
                quantity: null,
                type: null,
            },
            form_edit: {
                id_area: null,
                id_micro_area: null,
                id_ticket: null,
                fishable_specs: null,
                max_quantity: null,
                date_capture: "DAILY",
                operating_dates: null,
                kill: true,
            },
            operating_dates: [],
            date_start: new Date(),
            date_end: new Date(),
            date_current: new Date(),
            flag_edit: false,
            showTable: 'AREA',
            paginator: {loading: true, countRows: 0, limit: 50},
            paginatorTicket: {loading: true, countRows: 0, limit: 50},
            paginatorFish: {loading: true, countRows: 0, limit: 50},
            area: null,
            micro_area: null,
            ticket: null,
            showFish: true,
            fish: null,
            specsList: [],
            rule: null,
            fishing_rule: null,
            fishing_rule_group: null
        };
    }

    async handleValidSubmit(event, values, edit = false) {
        if (this.state.fish === null) {
            global.notify("Devi selezionare un pesce", "danger");
            return;
        }

        if (this.state.area !== null)
            values.id_area = this.state.area.id;

        if (this.state.micro_area !== null)
            values.id_micro_area = this.state.micro_area.id;
        if (this.state.ticket !== null)
            values.id_ticket = this.state.ticket.id;
        values.id_fish = this.state.fish.id;
        if (this.state.specsList == []) {
            global.notify("Devi selezionare almeno una specifica", "danger");
            return;
        }
        values.fishable_specs = JSON.stringify(this.state.specsList);
        // if (this.state.flag_edit && this.state.fishing_rule !== null) {
        //     values.id = this.state.fishing_rule.id;
        //     let formResult = await editRules(values);
        //     if (typeof formResult.rules !== 'undefined') {
        //         global.notify("Regolamento modificato con successo", "success");
        //         this.form && this.form.reset();
        //     } else if (typeof formResult.error !== 'undefined') {
        //         global.notify("Errore con la modifica: " + formResult.error, "danger");
        //
        //         this.setState({error: formResult.error});
        //     } else {
        //         this.setState({error: 'Errore generico'});
        //     }
        //     return;
        // }
        console.log("values")
        console.log(values)
        let formResult = await setRules(values);

        if (typeof formResult.fishing_rule !== 'undefined') {
            global.notify("Regolamento aggiunto con successo", "success");
            this.getRules(formResult.fishing_regulation.id);

            this.form && this.form.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con l'aggiunta: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    async handleAddGroupSubmit(event, values) {
        values.id_fishing_regulation = this.state.fishing_regulation.id;
        let formResult = await addFishingGroup(values);

        if (this.state.fishing_rule !== null)
            this.getRules(this.state.fishing_rule.id);
        if (typeof formResult.fishing_rule_group !== 'undefined') {
            global.notify("Gruppo aggiunto con successo", "success");
            this.formGroup && this.formGroup.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con l'aggiunta: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    async handleSessionSettingsSubmit(event, values) {
        values.id_fishing_regulation = this.state.fishing_regulation.id;
        let formResult = await setMaximumSessions(values);

        if (this.state.fishing_rule !== null)
            this.getRules(this.state.fishing_rule.id);
        if (typeof formResult.fishing_regulation !== 'undefined') {
            global.notify("Sessioni impostate con successo", "success");
            this.formSession && this.formSession.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    loadListFish = () => {
        let paginatorFish = this.state.paginatorFish;
        paginatorFish.loading = true;
        this.setState({paginatorFish: paginatorFish});

        getFishRegulation().then(result => {
            paginatorFish.loading = false;
            if (!result) {
                this.setState({rowsFish: [], paginatorFish: paginatorFish});
            } else {
                this.setState({rowsFish: result, paginatorFish: paginatorFish});
            }
        })
    }

    handleRowFishSelected = (rows) => {
        let row = [...rows.entries()][0][1];
        if (this.state.showFish) {
            this.setState({
                fish: row,
                rowsSpec: typeof row.fish_specs !== "undefined" ? row.fish_specs : []
            });
        } else {
            let specsList = this.state.specsList;
            specsList.push(row.id);
            this.setState({
                specsList: specsList,
            });
            console.log(this.state.specsList);
        }
    }

    showFish = (state) => {
        if (!state && this.state.fish === null) {
            global.notify("Devi selezionare almeno un pesce", "warning");
            return;
        }
        if (!state && (typeof this.state.fish.fish_specs === "undefined" || Object.keys(this.state.fish.fish_specs).length === 0)) {
            global.notify("Non ci sono specifiche presenti per questo pesce", "warning");
            return;
        }

        this.setState({showFish: state});
        if (state) {
            if (Object.keys(this.state.rowsFish).length === 0)
                this.loadListFish(0);
        }
    }

    async componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined" && this.props.match.params.id !== null) {
            this.getRules(this.props.match.params.id, true);
        }
        this.loadListFish();
        this.showTable('AREA');
    }

    getRules = async (id, edit = false) => {
        let res = await infoRules(id);
        if (typeof res.error !== "undefined") {
            global.notify("Errore: " + res.error, "danger");
            return;
        }
        let fishing_rule = res.fishing_rule;
        if (edit) {
            let ticket = null;
            let area = null;
            let micro_area = null;
            if (typeof fishing_rule.area !== "undefined") {
                area = fishing_rule.area[0];
            }
            if (typeof fishing_rule.micro_area !== "undefined") {
                micro_area = fishing_rule.micro_area[0];
            }
            if (typeof fishing_rule.ticket !== "undefined") {
                ticket = fishing_rule.ticket[0];
                area = ticket.area;
                if (typeof ticket.micro_area !== "undefined")
                    micro_area = ticket.micro_area;
            }

            this.setState({
                fishing_rule: res.fishing_rule,
                ticket: ticket,
                area: area,
                micro_area: micro_area,
                flag_edit: true,
            });
        } else {
            this.setState({fishing_rule: res.fishing_rule, fishing_rule_group: res.fishing_rule_group});
        }
    }

    setDate = (date, type) => {
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start_validity = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end_validity = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    showTable = (state) => {
        console.log(state)
        if (state === 'MICRO' && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        if (state === 'MICRO' && (typeof this.state.area.micro_areas === "undefined" || Object.keys(this.state.area.micro_areas).length === 0)) {
            global.notify("Non ci sono micro aree presenti per quest'area", "warning");
            return;
        }
        if (state === 'TICKET' && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        if (state === 'TICKET' && (Object.keys(this.state.rowsTicket).length === 0)) {
            global.notify("Non ci sono ticket presenti per quest'area", "warning");
            return;
        }
        this.setState({showTable: state});
        if (state == 'AREA') {
            if (Object.keys(this.state.rows).length === 0)
                this.loadListAreas(0);
        }
    }

    loadListAreas = async (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});
        let categories = await getCategories();
        let id_category = null;
        if (categories !== null) {
            for (let i in categories) {
                if (categories[i].type === "TYPE_FISHING")
                    id_category = categories[i].id;
                break;
            }
        }
        getListAreas(page + 1, id_category).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, rowsArea: result.list, paginator: paginator});
            }
        })
    }

    loadListTicket = (page) => {
        let paginatorTicket = this.state.paginatorTicket;
        paginatorTicket.loading = true;
        this.setState({paginatorTicket: paginatorTicket});

        listAreaTickets(this.state.area.id, page + 1).then(result => {
            paginatorTicket.loading = false;
            if (!result) {
                paginatorTicket.countRows = 0;
                this.setState({rowsTicket: [], paginatorTicket: paginatorTicket});
            } else {
                paginatorTicket.countRows = result.paginator.count_all_entities;
                this.setState({rowsTicket: result.list, paginatorTicket: paginatorTicket});
            }
        });
    }

    handlePageChange = (params) => {
        this.loadListAreas(params.page)
    };

    handlePageTicketChange = (params) => {
        this.loadListTicket(params.page)
    };

    handleRowSelected = (rows) => {
        let row = [...rows.entries()][0][1];
        switch (this.state.showTable) {
            case "AREA":
                this.setState({
                    area: row,
                    rowsMicroArea: typeof row.micro_areas !== "undefined" ? row.micro_areas : []
                }, () => this.loadListTicket(0));
                break;
            case "MICRO":
                this.setState({micro_area: row});
                break;
            case "TICKET":
                this.setState({ticket: row});
                break;
        }
        console.log("row")
        console.log(row)
        this.setState({fishing_regulation: row.fishing_regulation, fishing_rule: null});

        if (typeof row.fishing_regulation !== "undefined" && row.fishing_regulation !== null) {
            this.getRules(row.fishing_regulation.id);

        }
    }

    deleteRule = (id) => {
        deleteRules(id).then(result => {
            if (typeof result.error !== 'undefined') {
                global.notify("Errore con l'eliminazione: " + result.error, "danger");
            } else {
                this.getRules(this.state.fishing_rule.id);
                global.notify("Regola eliminata con successo", "success");
            }
        });
    }

    deleteFishingRuleGroup = (id) => {
        deleteFishingGroup(id).then(result => {
            if (typeof result.error !== 'undefined') {
                global.notify("Errore con l'eliminazione: " + result.error, "danger");
            } else {
                this.getRules(this.state.fishing_rule.id);
                global.notify("Gruppo eliminato con successo", "success");
            }
        });
    }

    renderMaximumSession = () => {
        if (this.state.fishing_rule === null)
            return;

        return <Card>
            <CardBody>
                <h5>Limite avvio sessione del segnacatture</h5>
                <AvForm className="form-horizontal" ref={c => (this.formSession = c)} onValidSubmit={(e, v,) => {
                    this.handleSessionSettingsSubmit(e, v)
                }}>
                    <div className={"row"}>
                        <div className="form-group col-md-4">
                            <AvField name="quantity" label="Numero di sessioni"
                                     value={this.state.fishing_rule.maximum_session.quantity}
                                     className="form-control" placeholder="Nome gruppo"
                                     type="text"/>
                        </div>
                        <div className="form-group col-md-6">
                            <AvField name="type" label="Tipologia di limitazione"
                                     value={this.state.fishing_rule.maximum_session.type}
                                     className="form-control" placeholder="Tipologia"
                                     type="select" required>
                                <option value={""}>-</option>
                                <option value={"WEEKLY"}>Settimanale</option>
                                <option value={"TWOWEEKS"}>Bisettimanale</option>
                                <option value={"MONTHLY"}>Mensile</option>
                                <option value={"TRIMESTER"}>Trimestrale</option>
                                <option value={"SEMIANNUAL"}>Semestrale</option>
                                <option value={"YEARLY"}>Annuale</option>
                            </AvField>
                        </div>
                        <div className="form-group col-md-2" style={{display: "flex"}}>
                            <div style={{marginTop: "auto"}}>
                                <ButtonGroup>
                                    <Button type="submit" color="success">Imposta limite</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </AvForm>
            </CardBody>
        </Card>
    }


    renderFishingRuleGroup = () => {
        if (this.state.fishing_rule === null)
            return;

        let list = [];
        if (this.state.fishing_rule_group === null || Object.keys(this.state.fishing_rule_group).length === 0) {
            list = <ListGroupItem>
                <div className={"row"}>
                    <div className={"col-md-12"}>Non ci sono gruppi</div>
                </div>
            </ListGroupItem>
        } else {
            for (let j in this.state.fishing_rule_group) {
                let rule = this.state.fishing_rule_group[j];

                let quantity = "infinita";
                if (rule.max_quantity !== null)
                    quantity = rule.max_quantity;
                let date = "";
                switch (rule.date_capture) {
                    case "DAILY":
                        date = "Giornaliero";
                        break;
                    case "TWODAYS":
                        date = "Bigiornaliero";
                        break;
                    case "THREEDAYS":
                        date = "Tre giorni";
                        break;
                    case "FOURDAYS":
                        date = "Quattro giorni";
                        break;
                    case "WEEKDAYS":
                        date = "Cinque giorni";
                        break;
                    case "WEEKLY":
                        date = "Settimanale";
                        break;
                    case "TWOWEEKS":
                        date = "Bisettimanale";
                        break;
                    case "MONTHLY":
                        date = "Mensile";
                        break;
                    case "TRIMESTER":
                        date = "Trimestrale";
                        break;
                    case "SEASONAL":
                        date = "Stagionale";
                        break;
                    case "SEMIANNUAL":
                        date = "Semestrale";
                        break;
                    case "YEARLY":
                        date = "Annuale";
                        break;
                    case "BIANNUAL":
                        date = "Biennale";
                        break;
                    case "TRIENNIAL":
                        date = "Triennale";
                        break;
                }

                list.push(<ListGroupItem>
                    <div className={"row"}>
                        <div className={"col-md-10"}>{rule.name} - {date}<br/>Quantità massima: {quantity}</div>
                        <div className={"col-md-2"} align={"right"}><Button
                            onClick={() => this.deleteFishingRuleGroup(rule.id)}
                            color={"danger"}>Elimina</Button>
                        </div>
                    </div>
                </ListGroupItem>)
            }
        }
        return <Card>
            <CardBody>
                <h5>Lista limite gruppi</h5>
                <AvForm className="form-horizontal" ref={c => (this.formGroup = c)} onValidSubmit={(e, v,) => {
                    this.handleAddGroupSubmit(e, v)
                }}>
                    <div className={"row"}>
                        <div className="form-group col-md-4">
                            <AvField name="name" label="Nome gruppo"
                                     value={this.state.form_edit.max_quantity}
                                     className="form-control" placeholder="Nome gruppo"
                                     type="text"/>
                        </div>

                        <div className="form-group col-md-2">
                            <AvField name="max_quantity" label="Quantità massima pescabile"
                                     value={this.state.form_edit.max_quantity}
                                     className="form-control" placeholder="Quantità massima"
                                     type="text"/>
                        </div>

                        <div className="form-group col-md-4">
                            <AvField name="date_capture" label="Data di validità"
                                     value={this.state.form_edit.type}
                                     className="form-control" placeholder="Enter date capture"
                                     type="select" required>
                                <option value={"DAILY"}>Giornaliero</option>
                                <option value={"TWODAYS"}>Bigiornaliero</option>
                                <option value={"THREEDAYS"}>Tre giorni</option>
                                <option value={"FOURDAYS"}>Quattro giorni</option>
                                <option value={"WEEKDAYS"}>Cinque giorni</option>
                                <option value={"WEEKLY"}>Settimanale</option>
                                <option value={"TWOWEEKS"}>Bisettimanale</option>
                                <option value={"MONTHLY"}>Mensile</option>
                                <option value={"TRIMESTER"}>Trimestrale</option>
                                <option value={"SEASONAL"}>Stagionale</option>
                                <option value={"SEMIANNUAL"}>Semestrale</option>
                                <option value={"YEARLY"}>Annuale</option>
                            </AvField>
                        </div>
                        <div className="form-group col-md-2" style={{display: "flex"}}>
                            <div style={{marginTop: "auto"}}>
                                <ButtonGroup>
                                    <Button type="submit" color="success">Aggiungi gruppo</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    {list}
                </AvForm>
            </CardBody>
        </Card>
    }

    handleAddGroupForRule = (event, values, rule) => {
        if (values.id_group === null)
            return;

        setFishingGroupRule({id_fishing_rule: rule.id, id_fishing_rule_group: values.id_group}).then(result => {
            if (typeof result.error !== 'undefined') {
                global.notify("Errore con l'aggiunta: " + result.error, "danger");
            } else {
                this.getRules(this.state.fishing_rule.id);
                global.notify("Gruppo assegnato con successo", "success");
            }
        });
    }

    deleteGroupRule = (group, rule) => {
        deleteFishingGroupRule({id_fishing_rule: rule.id, id_fishing_rule_group: group.id}).then(result => {
            if (typeof result.error !== 'undefined') {
                global.notify("Errore con l'eliminazione: " + result.error, "danger");
            } else {
                this.getRules(this.state.fishing_rule.id);
                global.notify("Gruppo eliminato con successo", "success");
            }
        });
    }

    renderRegulation = () => {
        if (this.state.fishing_rule === null)
            return;
        let fishing_rule = this.state.fishing_rule;
        let res = [];

        let list_group = [<option value={null}>-</option>];
        for (let j in this.state.fishing_rule_group) {
            let rule = this.state.fishing_rule_group[j];
            list_group.push(<option value={rule.id}>{rule.name}</option>)
        }

        for (let j in fishing_rule.rules) {
            let rule = fishing_rule.rules[j];
            let fish = [];
            for (let i in rule.fishable_specs) {
                let spec = rule.fishable_specs[i];
                fish.push(<span>{spec.fish.name} - {rule.kill ? "KILL" : "NO KILL"}</span>);
                fish.push(<br/>);
                if (spec.min_length !== null)
                    fish.push("min. " + spec.min_length + " cm ");

                if (spec.max_length !== null)
                    fish.push("max. " + spec.max_length + " cm ");

                if (spec.min_weight !== null)
                    fish.push("min. " + spec.min_weight + " kg ");

                if (spec.max_weight !== null)
                    fish.push("max. " + spec.max_weight + " kg ");

            }
            let quantity = "infinita";
            if (rule.max_quantity !== null)
                quantity = rule.max_quantity;
            let date = "";
            switch (rule.date_capture) {
                case "DAILY":
                    date = "Giornaliero";
                    break;
                case "TWODAYS":
                    date = "Bigiornaliero";
                    break;
                case "THREEDAYS":
                    date = "Tre giorni";
                    break;
                case "FOURDAYS":
                    date = "Quattro giorni";
                    break;
                case "WEEKDAYS":
                    date = "Cinque giorni";
                    break;
                case "WEEKLY":
                    date = "Settimanale";
                    break;
                case "TWOWEEKS":
                    date = "Bisettimanale";
                    break;
                case "MONTHLY":
                    date = "Mensile";
                    break;
                case "TRIMESTER":
                    date = "Trimestrale";
                    break;
                case "SEASONAL":
                    date = "Stagionale";
                    break;
                case "SEMIANNUAL":
                    date = "Semestrale";
                    break;
                case "YEARLY":
                    date = "Annuale";
                    break;
            }

            let list_group_added = rule.fishing_rule_group.map((item, key) => {
                return <Chip className={"mb-2 mr-1"}
                             label={<strong>{item.name}</strong>}
                             onClick={() => this.deleteGroupRule(item, rule)}
                             icon={<ClearIcon/>}
                             variant={"outlined"}
                />
            });

            res.push(<ListGroupItem>
                <div className={"row"}>
                    <div className={"col-md-4"}>{date}<br/>Quantità massima: {quantity}</div>
                    <div className={"col-md-6"}>{fish}</div>
                    <div className={"col-md-2"} align={"right"}><Button onClick={() => this.deleteRule(rule.id)}
                                                                        color={"danger"}>Elimina</Button>
                    </div>
                </div>
                <hr/>
                <div className={"row"}>
                    <div className={"col-md-4"}>
                        <Label>Aggiungi limite gruppo</Label>
                        <AvForm className="form-inline" ref={c => (this.form = c)}
                                onValidSubmit={(e, v,) => {
                                    this.handleAddGroupForRule(e, v, rule)
                                }}>
                            <AvField name="id_group" type="select"
                                     style={{minWidth: "200px"}}>{list_group}</AvField>
                            <Button color={"success"} type={"submit"}>Aggiungi</Button>
                        </AvForm>
                    </div>
                    <div className={"col-md-8"}>
                        {list_group_added}
                    </div>
                </div>
            </ListGroupItem>)
        }
        return <Card><CardBody><h5>Lista regole</h5><ListGroup>{res}</ListGroup></CardBody></Card>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Aggiunta regolamento"/>
                        <Card>
                            <CardBody>
                                {this.state.flag_edit ? <></>
                                    : <div>
                                        <div className="text-center mb-4">
                                            <ButtonGroup>
                                                <Button
                                                    onClick={() => this.showTable('AREA')}
                                                    color={(this.state.showTable === 'AREA') ? "light" : "primary"}>Area</Button>
                                                <Button
                                                    onClick={() => this.showTable('MICRO')}
                                                    color={(this.state.showTable === 'MICRO') ? "light" : "primary"}>Zona</Button>
                                                <Button
                                                    onClick={() => this.showTable('TICKET')}
                                                    color={(this.state.showTable === 'TICKET') ? "light" : "primary"}>Ticket</Button>
                                            </ButtonGroup>
                                        </div>
                                        <div style={{height: '40vh', width: '100%'}}
                                             hidden={this.state.showTable !== 'AREA'}>
                                            <DataGrid
                                                rows={this.state.rows}
                                                columns={this.state.columns}
                                                pagination
                                                pageSize={this.state.paginator.limit}
                                                rowsPerPageOptions={[this.state.paginator.limit]}
                                                rowCount={this.state.paginator.countRows}
                                                paginationMode="server"
                                                onPageChange={this.handlePageChange}
                                                loading={this.state.paginator.loading}
                                                onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                            />
                                        </div>
                                        <div style={{height: '40vh', width: '100%'}}
                                             hidden={this.state.showTable !== 'MICRO'}>
                                            <DataGrid
                                                rows={this.state.rowsMicroArea}
                                                columns={this.state.columnsMicroArea}
                                                pageSize={Object.keys(this.state.rowsMicroArea).length}
                                                rowsPerPageOptions={[Object.keys(this.state.rowsMicroArea).length]}
                                                rowCount={Object.keys(this.state.rowsMicroArea).length}
                                                onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                            />
                                        </div>
                                        <div style={{height: '40vh', width: '100%'}}
                                             hidden={this.state.showTable !== 'TICKET'}>
                                            <DataGrid
                                                rows={this.state.rowsTicket}
                                                columns={this.state.columnsTicket}
                                                pagination
                                                pageSize={this.state.paginatorTicket.limit}
                                                rowsPerPageOptions={[this.state.paginatorTicket.limit]}
                                                rowCount={this.state.paginatorTicket.countRows}
                                                paginationMode="server"
                                                onPageChange={this.handlePageTicketChange}
                                                loading={this.state.paginatorTicket.loading}
                                                onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                            />
                                        </div>
                                    </div>}
                                <div className={"row"}>
                                    {this.state.area !== null ? <div className={"col-md-4"}>
                                            <Card body>
                                                <CardTitle tag="h5">Area</CardTitle>
                                                <CardText>Nome area: {this.state.area.name}<br/> {this.state.area.address},
                                                    n°{this.state.area.number} {this.state.area.city} {this.state.area.region}
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.micro_area !== null ? <div className={"col-md-4"}>
                                            <Card body>
                                                <CardTitle tag="h5">Zona</CardTitle>
                                                <CardText>Nome zona: {this.state.micro_area.name}</CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.ticket !== null ? <div className={"col-md-4"}>
                                            <Card body>
                                                <CardTitle tag="h5">Ticket</CardTitle>
                                                <CardText>Nome
                                                    ticket: {this.state.ticket.name}<br/> {this.state.ticket.price},
                                                    {this.state.ticket.date_start_validity} {this.state.ticket.date_end_validity} {this.state.ticket.type}
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                </div>
                                <br/>

                                <div className="text-center mb-4">
                                    <ButtonGroup>
                                        <Button
                                            onClick={() => this.showFish(true)}
                                            color={(this.state.showFish) ? "light" : "primary"}>Pesci</Button>
                                        <Button
                                            onClick={() => this.showFish(false)}
                                            color={(!this.state.showFish) ? "light" : "primary"}>Specifiche</Button>
                                    </ButtonGroup>
                                </div>
                                <div style={{height: '40vh', width: '100%'}} hidden={!this.state.showFish}>
                                    <DataGrid
                                        rows={this.state.rowsFish}
                                        columns={this.state.columnsFish}
                                        pageSize={Object.keys(this.state.rowsFish).length}
                                        rowsPerPageOptions={[Object.keys(this.state.rowsFish).length]}
                                        rowCount={Object.keys(this.state.rowsFish).length}
                                        loading={this.state.paginatorFish.loading}
                                        onRowSelected={(x) => this.handleRowFishSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div style={{height: '40vh', width: '100%'}} hidden={this.state.showFish}>
                                    <DataGrid
                                        rows={this.state.rowsSpec}
                                        columns={this.state.columnsSpec}
                                        pageSize={Object.keys(this.state.rowsSpec).length}
                                        rowsPerPageOptions={[Object.keys(this.state.rowsSpec).length]}
                                        rowCount={Object.keys(this.state.rowsSpec).length}
                                        onRowSelected={(x) => this.handleRowFishSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <br/>
                                <div className={"row"}>
                                    {this.state.specsList.map((specElem, i) => {
                                        return (
                                            <div className={"col-md-2"}>
                                                <Card body>
                                                    <CardTitle tag="h5">Specifica</CardTitle>
                                                    <CardText>Id: {specElem}<br/>
                                                    </CardText>
                                                </Card>
                                            </div>)
                                    })}
                                </div>
                                <br/>
                                <AvForm className="form-horizontal" ref={c => (this.form = c)}
                                        onValidSubmit={(e, v,) => {
                                            this.handleValidSubmit(e, v, this.state.flag_edit)
                                        }}>
                                    <div className={"row"}>
                                        <div className="form-group col-md-4">
                                            <AvField name="max_quantity" label="Quantità massima pescabile"
                                                     value={this.state.form_edit.max_quantity}
                                                     className="form-control" placeholder="Quantità massima"
                                                     type="text"/>
                                        </div>

                                        <div className="form-group col-md-4">
                                            <AvField name="date_capture" label="Data di validità"
                                                     value={this.state.form_edit.type}
                                                     className="form-control" placeholder="Enter date capture"
                                                     type="select" required>
                                                <option value={"DAILY"}>Giornaliero</option>
                                                <option value={"TWODAYS"}>Bigiornaliero</option>
                                                <option value={"THREEDAYS"}>Tre giorni</option>
                                                <option value={"FOURDAYS"}>Quattro giorni</option>
                                                <option value={"WEEKDAYS"}>Cinque giorni</option>
                                                <option value={"WEEKLY"}>Settimanale</option>
                                                <option value={"TWOWEEKS"}>Bisettimanale</option>
                                                <option value={"MONTHLY"}>Mensile</option>
                                                <option value={"TRIMESTER"}>Trimestrale</option>
                                                <option value={"SEASONAL"}>Stagionale</option>
                                                <option value={"SEMIANNUAL"}>Semestrale</option>
                                                <option value={"YEARLY"}>Annuale</option>
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="kill" label="Kill"
                                                     value={this.state.form_edit.kill}
                                                     className="form-control" placeholder="Enter yes o no" type="select"
                                            >
                                                <option value={true}>Kill</option>
                                                <option value={false}>No Kill</option>
                                            </AvField>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="submit" color="success">Aggiungi</Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                        {this.renderMaximumSession()}
                        {this.renderFishingRuleGroup()}
                        {this.renderRegulation()}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}


const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {setRules, getListAreas})(RuleAddPage));
