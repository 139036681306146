import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    Badge,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, ButtonGroup
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {fileRules, listTickets} from '../../../store/actions';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleMinus, faCoffee, faEyeSlash, faTrash} from '@fortawesome/free-solid-svg-icons'
import {AvField, AvForm} from "availity-reactstrap-validation";
import {listBlog} from "../../../store/manager/blog/actions";

class BlogListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "ID", field: "id", width: 60},
                {
                    headerName: "Immagine principale", field: "image", width: 240, renderCell: (params) => (
                        <img src={global.baseUrlImg + params.value} alt="" max-height="100" width="100"/>
                    )
                },
                {headerName: "Titolo", field: "title", width: 360},
                {headerName: " ", field: "body", width: 180, renderCell: (params)=> (
                            <Button color="warning" type="button"
                                    onClick={() => window.location.href = '/blog/edit/'+  params.id}>Modifica</Button>
                    )},

            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
        };
    }

    async componentDidMount() {
        this.loadList(0);
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listBlog(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        });
    }

    handlePageChange = (params) => {
        this.loadList(params.page)
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista post del Blog"/>
                        <Card>
                            <CardBody>
                                <div align={"right"}>
                                    <Button color="success" type="button"
                                            onClick={() => window.location.href = '/blog/add'}>Aggiungi post</Button>
                                </div>
                                <br/>
                                <div className="form-group">
                                    <div style={{height: '90vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChange}
                                            loading={this.state.paginator.loading}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {listTickets})(BlogListPage));
