import {put} from 'redux-saga/effects';
import {apiCall} from '../../../helpers/backend_helper';

export const getCaptureStats = async (page, date_start, date_end, id_area, id_ticket) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let data = {page: page, date_start: date_start, date_end: date_end};
        if (id_area) {
            data.id_area = id_area;
        }
        if (id_ticket) {
            data.id_ticket = id_ticket;
        }
        let response = await apiCall({method: 'POST', url: 'admin/stats/capture', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getCaptureTotalStats = async (page, date_start, date_end, id_area, id_ticket) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let data = {page: page, date_start: date_start, date_end: date_end};
        if (id_area) {
            data.id_area = id_area;
        }
        if (id_ticket) {
            data.id_ticket = id_ticket;
        }
        let response = await apiCall({method: 'POST', url: 'admin/stats/capture/total', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getSaleStats = async ( date_start, date_end) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let data = {date_start: date_start, date_end: date_end};
        let response = await apiCall({method: 'POST', url: 'admin/stats/sale', data: data});
        if (typeof response.error === 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}