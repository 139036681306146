import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Alert,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ButtonGroup
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

// actions
import {getFishes, setFish, editFish, ImgFish, apiError} from '../../store/actions';
import Breadcrumb from '../../components/Common/Breadcrumb';

class Fish extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "img",
                    width: 140,
                    field: 'image',
                    renderCell: (params) => (
                        <img src={global.baseUrlImg + params.value} alt="" max-height="100" width="100"/>
                    )

                },
                {headerName: "Nome Pesce", field: "name", flex: 0.5},
                {headerName: "Descrizione", field: "description", flex: 1},
                {
                    headerName: " ",
                    flex: 0.3,
                    field: 'id',
                    renderCell: (params) => (
                        <ButtonGroup>
                            <Button onClick={() => this.imgButton(params)} type="button" color="secondary">Img</Button>
                            <Button onClick={() => this.actionButton(params)} type="button"
                                    color="primary">Modifica</Button>
                        </ButtonGroup>
                    )
                }
            ],
            rows: [],
            form_edit: {
                id: null,
                name: null,
                description: null,
            },
            flag_edit: false,
            paginator: {loadingFishes: true, page: 0, countRows: 0, limit: 50},
            image: null,
            modal: false,
            imageUpload: null,
            uploadId: null
        };
        this.onImageChange = this.onImageChange.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    handleClose = () => this.setState({modal: false});

    uploadImage = () => {
        var formData = new FormData();
        formData.append("image", this.state.imageUpload);
        formData.append("id", this.state.uploadId);

        ImgFish(formData).then(result => {
            this.loadListFishes(0);
            this.setState({modal: false, imageUpload: null, uploadId: null, image: null});
        })
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                image: URL.createObjectURL(img),
                imageUpload: event.target.files[0]
            });
        }
    };

    async handleValidSubmit(event, values, edit = false) {
        let formResult = null;
        if (edit) {
            formResult = await editFish(values);
        } else {
            formResult = await setFish(values);
        }

        if (typeof formResult.fish !== 'undefined') {
            this.setState({error: false, success: 'Azione eseguita con successo'});
            this.loadListFishes();
        } else if (typeof formResult.error !== 'undefined') {
            this.setState({error: formResult.error});
        } else {
            this.setState({error: 'Errore generico'});
        }
        window.scrollTo(0, 0);
    }

    actionButton = (params) => {
        this.setState({form_edit: params.row, flag_edit: true});
    }

    imgButton = (params) => {
        this.setState({modal: true, uploadId: params.value});
    }

    async componentDidMount() {
        this.loadListFishes();
    }

    loadListFishes = () => {
        let paginator = this.state.paginator;
        paginator.loadingFishes = true;
        this.setState({paginator: paginator});

        getFishes().then(result => {
            paginator.loadingFishes = false;

            if (!result) {
                this.setState({rows: []});
            } else {
                this.setState({rows: result.list});
            }
        })
    }

    handlePageChange = (params) => {
        this.loadListSocieties(params.page);
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Fishes"/>
                        <Row>
                            <Col lg="12">
                                {this.state.error && this.state.error ?
                                    <Alert color="danger">{this.state.error}</Alert> : null}
                                {this.state.success && this.state.success ?
                                    <Alert color="success">{this.state.success}</Alert> : null}

                                <Card>
                                    <CardBody>
                                        <div style={{height: 400, width: '100%'}}>
                                            <DataGrid
                                                rows={this.state.rows}
                                                columns={this.state.columns}
                                                pagination
                                                pageSize={this.state.paginator.limit}
                                                rowsPerPageOptions={[this.state.paginator.limit]}
                                                rowCount={this.state.paginator.countRows}
                                                paginationMode="server"
                                                onPageChange={this.handlePageChange}
                                                loading={this.state.paginator.loadingFishes}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    <div className="row">
                                        {(this.state.flag_edit) ?
                                            <div className="form-group" hidden={true}>
                                                <AvField name="id" label="Id pesce" value={this.state.form_edit.id}
                                                         className="form-control" placeholder="inserisci Id pesce"
                                                         type="text" required/>
                                            </div> : ""
                                        }
                                        <div className="form-group col-md-4">
                                            <AvField name="name" label="Nome pesce" value={this.state.form_edit.name}
                                                     className="form-control" placeholder="inserisci nome pesce"
                                                     type="text" required/>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <AvField name="description" label="Descrizione pesce"
                                                     value={this.state.form_edit.description} className="form-control"
                                                     placeholder="inserisci descrizione pesce" type="text"/>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="submit"
                                                color="danger">{(this.state.flag_edit) ? "Modifica Pesce" : "Crea Pesce"}</Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                        <Modal
                            isOpen={this.state.modal}
                            // onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}>
                            <ModalHeader>Immagine Pesce</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg="12">
                                        <img src={this.state.image}/>
                                        <h1>Scegli immagine</h1>
                                        <input type="file" name="myImage" onChange={this.onImageChange}/>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.uploadImage}>Carica</Button>{' '}
                                <Button color="secondary" onClick={this.handleClose}>Annulla</Button>
                            </ModalFooter>
                        </Modal>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(
    connect(
        mapStatetoProps, {getFishes, setFish, editFish, apiError}
    )
    (Fish));