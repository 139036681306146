import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../assets/images/logo.png";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-sm-light.png";
import logoDark from "../../assets/images/logo-dark.png";

//i18n
import {withTranslation} from 'react-i18next';


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
    }

    /**
     * Toggle sidebar
     */
    toggleMenu() {
        this.props.toggleMenuCallback();
    }


    toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm"><img src={logoLightSvg} alt="" height="22"/></span>
                                    <span className="logo-lg"><img src={logoLightPng} alt="" height="19"/></span>
                                </Link>
                            </div>

                            <button type="button" onClick={this.toggleMenu}
                                    className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                                    id="vertical-menu-btn">
                                <i className="fa fa-fw fa-bars"></i>
                            </button>

                            {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input type="text" className="form-control" placeholder={this.props.t('Search') + "..."} />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form> */}
                        </div>
                        <div className="d-flex">
                            {/*<div className="dropdown d-inline-block d-lg-none ml-2">
                <button onClick={() => { this.setState({ isSearch: !this.state.isSearch }); }} type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown">
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div className={this.state.isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                  aria-labelledby="page-header-search-dropdown">

                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>*/}

                            <LanguageDropdown/>
                            <div className="dropdown d-none d-lg-inline-block ml-1">
                                <button type="button" onClick={this.toggleFullscreen}
                                        className="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
                                    <i className="bx bx-fullscreen"></i>
                                </button>
                            </div>
                            {/*<NotificationDropdown />*/}
                            <ProfileMenu/>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {layoutType} = state.Layout;
    return {layoutType};
};

export default connect(mapStatetoProps)(withTranslation()(Header));
