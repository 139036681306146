import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    Badge,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {addTicket, getListAreas, listNotifications, listTickets, sendNotify} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {DataGrid} from "@material-ui/data-grid";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

class Notify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [{headerName: "Nome area", field: "name", width: 180},
                {
                    headerName: "Categoria",
                    field: "category",
                    width: 140,
                    renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Province", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 160},
                {headerName: "CAP", field: "cap", width: 140},
                {headerName: "Telefono", field: "phone", width: 140}
            ],
            rows: [],
            columnsTicket: [
                {
                    headerName: "Nome ticket", field: "name", width: 180, valueGetter: (params) =>
                        (params.getValue(params.id, "is_disabled") ? "[DISABILITATO] " : "") + params.value
                },
                {headerName: "Ente", field: "society", width: 180, renderCell: (params) => params.formattedValue.name},
                {
                    headerName: "Area", field: "areaName", width: 140, valueGetter: (params) =>
                        (params.getValue(params.id, "area").name !== null ? params.getValue(params.id, "area").name : "") + " " +
                        (params.getValue(params.id, "micro_area") !== null ? params.getValue(params.id, "micro_area").name : "")
                },
                {headerName: "Prezzo", field: "price", width: 140},
                {headerName: "Inizio validità", field: "date_start_validity", width: 160},
                {headerName: "Fine validità", field: "date_end_validity", width: 160},
                {
                    headerName: "Tipologia", field: "typeName", width: 140, valueGetter: (params) => {
                        switch (params.getValue(params.id, "type")) {
                            case "DAILY":
                                return "Giornaliero";
                            case "TWODAYS":
                                return "Bigiornaliero";
                            case "THREEDAYS":
                                return "Tre giorni";
                            case "FOURDAYS":
                                return "Quattro giorni";
                            case "WEEKDAYS":
                                return "Cinque giorni";
                            case "WEEKLY":
                                return "Settimanale";
                            case "TWOWEEKS":
                                return "Bisettimanale";
                            case "MONTHLY":
                                return "Mensile";
                            case "TRIMESTER":
                                return "Trimestrale";
                            case "SEASONAL":
                                return "Stagionale";
                            case "SEMIANNUAL":
                                return "Semestrale";
                            case "YEARLY":
                                return "Annuale";
                            case "BIANNUAL":
                                return "Biennale";
                            case "TRIENNIAL":
                                return "Triennale";
                        }
                    }
                },
                {
                    headerName: "Tipo residenti", field: "number", width: 180, renderCell: (params) => {
                        if (params.getValue(params.id, "ticket_residence_type") === null)
                            return (<Badge color="alert">Sconosciuto</Badge>)
                        return (<Badge color="info">{params.getValue(params.id, "ticket_residence_type").name}</Badge>)
                    }
                },
            ],
            rowsTicket: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            paginatorTicket: {loading: true, countRows: 0, limit: 50},
            columnsNotify: [
                {headerName: "Data invio", field: "date_send", width: 160},
                {
                    headerName: "Stato", field: "status", width: 120, renderCell: (params) => {
                        switch (params.value) {
                            case 'COMPLETED':
                                return <Badge color="success">COMPLETATO</Badge>;
                            case 'TO_DO':
                                return <Badge color="info">DA INVIARE</Badge>;
                            case 'IN_PROGRESS':
                                return <Badge color="warning">IN ELABORAZIONE</Badge>;
                        }
                    }
                },
                {headerName: "Titolo", field: "title", width: 240},
                {headerName: "Messaggio", field: "message", width: 460},
                {headerName: "Dispositivi", field: "total_device", width: 180},
                {headerName: "Ricevuti", field: "total_receive", width: 180},
                {headerName: "Aperti", field: "total_open", width: 180},

            ],
            rowsNotify: [],
            paginatorNotify: {loading: true, countRows: 0, limit: 50},

            form: {
                date_send: moment.parseZone(new Date()).local().format("DD/MM/YYYY HH:mm"),
            },
            date_send: new Date(),
            area: null,
            ticket: null,
            type: null,
            update_mushroom: {send_to: "ALL"},
            info_area: {send_to: "ALL", id_area: null},
            promotion: {send_to: "ALL", id_ticket: null},
            isLoading: false
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async handleValidSubmit(event, values) {
        console.log("values")
        console.log(values)
        let data = {title: values.title, description: values.description, type: this.state.type, date: values.date_send};
        switch (this.state.type) {
            case "PROMOTION":
                if (this.state.promotion.id_ticket === null) {
                    global.notify("Devi selezionare un ticket per inviare la notifica", "warning");
                    return;
                }
                data.id_ticket = this.state.promotion.id_ticket;
                data.send_to = this.state.promotion.send_to;
                break;
            case "UPDATE_MUSHROOM":
                data.send_to = this.state.update_mushroom.send_to;
                break;
            case "INFO_AREA":
                if (this.state.info_area.id_area === null) {
                    global.notify("Devi selezionare un'area per inviare la notifica", "warning");
                    return;
                }
                data.id_area = this.state.info_area.id_area;
                data.send_to = this.state.info_area.send_to;
                break;
        }
        this.setState({isLoading: true});
        sendNotify(data).then((result) => {
            if (typeof result.notify !== 'undefined') {
                global.notify("Notifica aggiunta nei processi", "success");
                this.form && this.form.reset();
                this.loadListNotify(0);
            } else if (typeof result.error !== 'undefined')
                global.notify("Errore: " + result.error, "danger");
            else
                global.notify("Errore generico", "danger");
            this.setState({isLoading: false});
        });
    }

    async componentDidMount() {
        this.loadListAreas(0);
        this.loadListTickets(0);
        this.loadListNotify(0);
    }

    loadListNotify = (page) => {
        let paginator = this.state.paginatorNotify;
        paginator.loading = true;
        this.setState({paginatorTicket: paginator});

        listNotifications(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rowsNotify: [], paginatorTicket: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rowsNotify: result.list, paginatorTicket: paginator});
            }
        });
    }

    loadListTickets = (page) => {
        let paginator = this.state.paginatorTicket;
        paginator.loading = true;
        this.setState({paginatorTicket: paginator});

        listTickets(null, page + 1, "true").then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rowsTicket: [], paginatorTicket: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rowsTicket: result.list, paginatorTicket: paginator});
            }
        });
    }

    loadListAreas = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getListAreas(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, rowsArea: result.list, paginator: paginator});
            }
        })
    }

    handlePageChange = (params) => {
        this.loadListAreas(params.page)
    };

    handlePageChangeTicket = (params) => {
        this.loadListTickets(params.page)
    };

    handlePageChangeNotify = (params) => {
        this.loadListNotify(params.page)
    };

    handleRowSelected = (type, rows) => {
        let row = [...rows.entries()][0][1];
        switch (type) {
            case "area":
                let info_area = this.state.info_area;
                info_area.id_area = row.id;
                this.setState({area: row, info_area: info_area});
                break;
            case "ticket":
                let promotion = this.state.promotion;
                promotion.id_ticket = row.id;
                this.setState({ticket: row, promotion: promotion});
                break;
        }
    }

    setDate = (date) => {
        let form = this.state.form;
        form.date_send = moment.parseZone(date).local().format("DD/MM/YYYY HH:mm");

        this.setState({date_send: date, form: form});
    }

    renderStep = () => {
        if (this.state.type === null)
            return;

        let form = <AvForm className="form-horizontal" ref={c => (this.form = c)} onValidSubmit={(e, v,) => {
            this.handleValidSubmit(e, v)
        }}>
            <div className={"row"}>
                <div className="form-group col-md-6">
                    <AvField name="title" label="Titolo della notifica" className="form-control"
                             placeholder="Titolo della notifica" type="text" required/>
                </div>
                <div className="form-group col-md-6">
                    <AvField name="description" label="Descrizione della notifica" className="form-control"
                             placeholder="Descrizione della notifica" type="text" required/>
                </div>
                <div className="form-group col-4">
                    <AvField name="date_send" hidden
                             label="Data invio"
                             value={this.state.form.date_send}
                             className="form-control"
                             placeholder="Inserisci data" type="text"
                             required/>
                    <DatePicker selected={this.state.date_send}
                                className="form-control"
                                onChange={(date) => this.setDate(date)}
                                minDate={new Date()}
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={30}
                    />
                </div>
            </div>
            <div className="text-center mt-4">
                <Button type="submit" color="success" disabled={this.state.isLoading}>Invia</Button>
            </div>
        </AvForm>;

        if (this.state.type === "COMMUNICATION")
            return <Card><CardBody>{form}</CardBody></Card>

        if (this.state.type === "UPDATE_MUSHROOM") {
            return <Card>
                <CardBody>
                    <label>Invia la notifica a:</label>
                    <select name="type_update_mushroom" className="form-control"
                            placeholder="Scegli a chi inviare la notifica"
                            type="select"
                            onChange={(event) => this.setState({update_mushroom: {send_to: event.target.value}})}>
                        <option value={"ALL"}>Tutti</option>
                        <option value={"RESTRICTED"}>Chi ha acquistato un ticket per la categoria funghi</option>
                    </select>
                    {form}
                </CardBody>
            </Card>
        }
        if (this.state.type === "INFO_AREA") {
            let info_area = this.state.info_area;
            return <Card>
                <CardBody>
                    <label>Seleziona l'area</label>
                    <div style={{height: '40vh', width: '100%', marginBottom: 16}}>
                        <DataGrid
                            rows={this.state.rows}
                            columns={this.state.columns}
                            pagination
                            pageSize={this.state.paginator.limit}
                            rowsPerPageOptions={[this.state.paginator.limit]}
                            rowCount={this.state.paginator.countRows}
                            paginationMode="server"
                            onPageChange={this.handlePageChange}
                            loading={this.state.paginator.loading}
                            onRowSelected={(x) => this.handleRowSelected("area", x.api.current.getSelectedRows())}
                        />
                    </div>
                    <label>Invia la notifica a:</label>
                    <select name="type_info_area" className="form-control"
                            placeholder="Scegli a chi inviare la notifica"
                            type="select"
                            onChange={(event) => {
                                info_area.send_to = event.target.value;
                                this.setState({info_area: info_area})
                            }}>
                        <option value={"ALL"}>Tutti</option>
                        <option value={"RESTRICTED"}>Chi ha acquistato un ticket per questa area</option>
                    </select>
                    {form}
                </CardBody>
            </Card>
        }

        if (this.state.type === "PROMOTION") {
            let info_area = this.state.promotion;
            return <Card>
                <CardBody>
                    <label>Seleziona il ticket</label>
                    <div style={{height: '40vh', width: '100%', marginBottom: 16}}>
                        <DataGrid
                            rows={this.state.rowsTicket}
                            columns={this.state.columnsTicket}
                            pagination
                            pageSize={this.state.paginatorTicket.limit}
                            rowsPerPageOptions={[this.state.paginatorTicket.limit]}
                            rowCount={this.state.paginatorTicket.countRows}
                            paginationMode="server"
                            onPageChange={this.handlePageChangeTicket}
                            loading={this.state.paginatorTicket.loading}
                            onRowSelected={(x) => this.handleRowSelected("ticket", x.api.current.getSelectedRows())}
                        />
                    </div>
                    <label>Invia la notifica a:</label>
                    <select name="type_promotion" className="form-control"
                            placeholder="Scegli a chi inviare la notifica"
                            type="select"
                            onChange={(event) => {
                                info_area.send_to = event.target.value;
                                this.setState({promotion: info_area})
                            }}>
                        <option value={"ALL"}>Tutti</option>
                        <option value={"RESTRICTED"}>Chi ha acquistato questo ticket</option>
                    </select>
                    {form}
                </CardBody>
            </Card>
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem={"Invio notifiche"}/>
                        <Card>
                            <CardBody>
                                <label>Tipologia di notifica</label>
                                <select name="type" label="Tipologia notifica"
                                        className="form-control" placeholder="Scegli la tipologia di notifica"
                                        type="select" required
                                        onChange={(event) => this.setState({type: event.target.value})}>
                                    <option value={null}>Scegli la notifica</option>
                                    <option value={"COMMUNICATION"}>Comunicazione generale</option>
                                    <option value={"PROMOTION"}>Promozione ticket</option>
                                    <option value={"UPDATE_MUSHROOM"}>Aggiornamento tabella crescita</option>
                                    <option value={"INFO_AREA"}>Informazione dell'area</option>
                                </select>
                            </CardBody>
                        </Card>
                        {this.renderStep()}
                        <Card>
                            <CardBody>
                                <label>Lista notifiche</label>
                                <div style={{height: '40vh', width: '100%', marginBottom: 16}}>
                                    <DataGrid
                                        rows={this.state.rowsNotify}
                                        columns={this.state.columnsNotify}
                                        pagination
                                        pageSize={this.state.paginatorNotify.limit}
                                        rowsPerPageOptions={[this.state.paginatorNotify.limit]}
                                        rowCount={this.state.paginatorNotify.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageChangeNotify}
                                        loading={this.state.paginatorNotify.loading}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {addTicket, getListAreas})(Notify));
