import React, {Component} from "react";
import {Card, CardBody, Container, Button, Modal, ModalHeader, ModalBody, Row, Col, ModalFooter} from "reactstrap";

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    getUserSos,
    setUserSosAdd,
    setFishingguardSosAdd,
    apiError,
    getSocieties,
    setUserSos
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {DataGrid} from "@material-ui/data-grid";
import moment from "moment";

class UserSociety extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Email", field: "email", width: 240},
                {headerName: "Nome", field: "firstname", width: 160},
                {headerName: "Cognome", field: "lastname", width: 160},
                {
                    headerName: "Tipologia", field: "role", width: 160,
                    renderCell: (params) => {
                        switch (params.value) {
                            case "ROLE_USER_SOCIETY":
                                return "Gestore";
                            case "ROLE_USER_FISHING_GUARD":
                                return "Guardiapesca";
                        }
                    }
                },
                {
                    headerName: "Ente",
                    width: 180,
                    field: 'society',
                    renderCell: (params) => {
                        return params.getValue(params.id, "society") === null ? <span
                            style={{color: "red"}}>Nessun ente</span> : params.getValue(params.id, "society").name.trim()
                    }
                },
                {
                    headerName: "Ultimo accesso",
                    width: 180,
                    field: 'last_login',
                    renderCell: (params) => {
                        return params.getValue(params.id, "last_login") === null ?
                            <span>Nessun accesso</span> : moment(params.getValue(params.id, "last_login"), "DD/MM/YYYY hh:mm").format("DD/MM/YYYY hh:mm")
                    }
                },
                {
                    headerName: "Modifica",
                    width: 140,
                    field: 'id',
                    renderCell: (params) => {
                        return <Button
                            onClick={() => this.chooseSociety(params.row, params.getValue(params.id, "society"))}
                            type="button"
                            color="primary">Modifica</Button>
                    }
                }
            ],
            columnsSociety: [
                {headerName: "Nome ente", field: "name", flex: 1},
                {headerName: "Regione", field: "region", flex: 1},
                {headerName: "Provincia", field: "province", flex: 1},
                {
                    headerName: " ",
                    width: 100,
                    field: 'id',
                    renderCell: (params) => (
                        <Button onClick={() => {
                            let form = this.state.form_edit;
                            form.society = params.row;
                            this.setState({form_edit: form});
                        }} type="button" color="primary">Seleziona</Button>)
                }
            ],
            rows: [],
            rowsSociety: [],
            paginator: {loadingSociety: true, countRows: 0, limit: 50},
            paginatorSociety: {loadingSociety: true, countRows: 0, limit: 50},
            societySelected: null,
            form_edit: {
                user: null,
                society: null,
                modal: false,
            },
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async handleValidSubmit(event, values) {
        let formResult = null;
        if (this.state.societySelected !== null)
            values.id_society = this.state.societySelected.id;

        if (values.role === "FISHING_GUARD") {
            delete values.role;
            formResult = await setFishingguardSosAdd(values);
        } else {
            delete values.role;
            formResult = await setUserSosAdd(values);
        }
        if (typeof formResult.error === 'undefined') {
            global.notify("Utente aggiunto con successo", "success");
            this.loadListUsers();
            this.setState({societySelected: null});
            this.form && this.form.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }

        window.scrollTo(0, 0);
    }

    actionButton = (params) => {
        this.setState({form_edit: params.data});
    }

    chooseSociety = (user = null, society = null) => {
        this.setState({form_edit: {user: user, society: society, modal: true}});
    };

    async componentDidMount() {
        this.loadListSocieties(0);
        this.loadListUsers(0);
    }

    loadListUsers = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getUserSos(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.page = 0;
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.page += 1;
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        });
    }

    loadListSocieties = (page) => {
        let paginator = this.state.paginatorSociety;
        paginator.loadingSociety = true;
        this.setState({paginatorSociety: paginator});

        getSocieties(page + 1).then(result => {
            paginator.loadingSociety = false;

            if (!result) {
                paginator.countRows = 0;
                this.setState({rowsSociety: [], paginatorSociety: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rowsSociety: result.list, paginatorSociety: paginator});

            }
        })
    }

    handlePageChange = (params) => {
        this.loadListUsers(params.page);
    };

    handlePageChangeSociety = (params) => {
        this.loadListSocieties(params.page);
    };

    saveModal = () => {
        let form_edit = this.state.form_edit;
        if (form_edit.society === null) {
            global.notify("Errore: Devi impostare l'ente", "danger");
            return;
        }
        if (form_edit.user !== null) {
            setUserSos({id_society: form_edit.society.id, id_user: form_edit.user.id}).then((result) => {
                if (typeof result.error === "undefined") {
                    global.notify("Modifica salvata con successo", "success");
                    this.loadListUsers(this.state.paginator.page - 1);
                } else {
                    global.notify("Errore: " + result.error, "danger");
                }
            });
        } else {
            this.setState({societySelected: form_edit.society});
        }
        this.setState({form_edit: {user: null, society: null, modal: false}});

    }

    renderModal = () => {
        let form_edit = this.state.form_edit;
        let card_society = null;
        if (form_edit.society !== null) {
            card_society = <Col lg={"6"}>
                <Card>
                    <CardBody>
                        <b>Ente selezionato:</b><br/>
                        <span>Nome: {form_edit.society.name}</span><br/>
                        <span>Regione: {form_edit.society.region}</span><br/>
                        <span>Provincia: {form_edit.society.province}</span><br/>
                    </CardBody>
                </Card>
            </Col>
        }
        let card_user = null;
        if (form_edit.user !== null) {
            card_user = <Col lg={"6"}>
                <Card>
                    <CardBody>
                        <b>Utente:</b><br/>
                        <span>Email: {form_edit.user.email}</span><br/>
                        <span>Nome e cognome: {form_edit.user.firstname} {form_edit.user.lastname}</span><br/>
                    </CardBody>
                </Card>
            </Col>
        }
        return <Modal isOpen={this.state.form_edit.modal} className={"modal-xl"} backdrop="static"
                      keyboard={false}>
            <ModalHeader>Impostazione ente</ModalHeader>
            <ModalBody>
                <Row>
                    {card_user}
                    {card_society}
                    <Col lg="12">
                        <div style={{height: 400, width: '100%'}}>
                            <DataGrid
                                rows={this.state.rowsSociety}
                                columns={this.state.columnsSociety}
                                pagination
                                pageSize={this.state.paginatorSociety.limit}
                                rowsPerPageOptions={[this.state.paginatorSociety.limit]}
                                rowCount={this.state.paginatorSociety.countRows}
                                paginationMode="server"
                                onPageChange={this.handlePageChangeSociety}
                                loading={this.state.paginatorSociety.loadingSociety}
                                onRowSelected={(x) => {
                                }}

                            />
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => {
                    let form = this.state.form_edit;
                    form.modal = false;
                    form.society = null;
                    this.setState({form_edit: form});
                }}>Annulla</Button>{' '}
                <Button color="success" onClick={() => this.saveModal()}>Salva</Button>
            </ModalFooter>
        </Modal>
    }

    renderCardSociety = () => {
        return <Card>
            <CardBody>
                <b>Ente assegnato:</b><br/>
                {this.state.societySelected === null ? "Nessun ente selezionato" :
                    <div><span>Nome: {this.state.societySelected.name}</span><br/>
                        <span>Regione: {this.state.societySelected.region}</span><br/>
                        <span>Provincia: {this.state.societySelected.province}</span><br/></div>}
                <div className="text-center col-12 mt-4">
                    <Button type="button" onClick={() => this.chooseSociety()} color="info">Imposta
                        ente</Button>
                </div>
            </CardBody>
        </Card>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Utenti ente"/>
                        <Card>
                            <CardBody>
                                <h5>Lista utenti ente</h5>
                                <div style={{height: '70vh', width: '100%'}}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        pagination
                                        pageSize={this.state.paginator.limit}
                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                        rowCount={this.state.paginator.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageChange}
                                        loading={this.state.paginator.loading}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h5>Aggiungi utente</h5>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v)
                                }} ref={c => (this.form = c)}>
                                    <div className={"row"}>
                                        <div className="form-group col-12">
                                            <AvField name="email" label="Email"
                                                     className="form-control" placeholder="Email" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-6 col-sm-6">
                                            <AvField name="firstname" label="Nome"
                                                     className="form-control" placeholder="Nome" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-6 col-sm-6">
                                            <AvField name="lastname" label="Cognome"
                                                     className="form-control" placeholder="Cognome" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-6 col-sm-6">
                                            <AvField type="select" name="role" label="Tipologia utente"
                                                     placeholder="Imposta il ruolo" required>
                                                <option value="USER_SOCIETY">Gestore ente</option>
                                                <option value="FISHING_GUARD">Guardia pesca</option>
                                            </AvField>
                                        </div>
                                        <div className={"col-md-6 col-sm-6"}>
                                            {this.renderCardSociety()}
                                        </div>
                                        <hr/>
                                        <div className="text-center col-12 mt-4">
                                            <Button type="submit" color="success">Aggiungi utente</Button>
                                        </div>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Container>
                    {this.renderModal()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(
    connect(
        mapStatetoProps, {getUserSos, setUserSosAdd, setFishingguardSosAdd, apiError}
    )
    (UserSociety));