import React, {Component} from "react";
import {Card, CardBody, Container, Button, Input, InputGroup} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {listShopPackage, setAvailabilityPackage} from '../../../store/actions';
import Breadcrumb from '../../../components/Common/Breadcrumb';

class ShopPackageListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Nome pacchetto", field: "name", width: 240},
                {
                    headerName: "Tipologia",
                    field: "type",
                    width: 180,
                    renderCell: (params) => this.convertType(params.value)
                },
                {
                    headerName: "Prezzo",
                    field: "price",
                    width: 180,
                    renderCell: (params) => "€ " + params.value.toFixed(2)
                },
                {
                    headerName: "Prezzo scontato",
                    field: "discounted_price",
                    width: 180,
                    renderCell: (params) => {
                        if (params.value !== null)
                            return "€ " + params.value.toFixed(2);
                        return "-";
                    }
                },
                {
                    headerName: "Disponibilità rimanente",
                    field: "availability",
                    width: 220,
                    renderCell: (params) => {
                        if (params.value !== null)
                            return params.value;
                        return "∞";
                    }
                },
                {
                    headerName: "Disponibilità rimanente",
                    field: "id",
                    width: 220,
                    renderCell: (params) => {
                        return <InputGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <Input type="text" onChange={(e) => {
                                let inputs = this.state.tmp_input;
                                inputs[params.value] = e.target.value;
                                this.setState({tmp_input: inputs});
                            }}
                                   placeholder={"Quantità"}/>
                            <Button className={"btn-success"} onClick={() => {
                                this.setAvailability(params.value, this.state.tmp_input[params.value])
                            }}>Salva</Button>
                        </InputGroup>
                    }
                },
                {
                    headerName: " ",
                    field: "id_",
                    width: 220,
                    renderCell: (params) => (<Button color={"warning"} onClick={() => {window.location.href = '/package/edit/' + params.id}}>Modifica</Button>
                    )
                },
            ],
            rows: [],
            tmp_input: {},
            paginator: {loading: true, countRows: 0, limit: 50, page: 1},
        };
    }

    async componentDidMount() {
        this.loadList(0);
    }

    setAvailability = (id, quantity) => {
        if (typeof quantity === "undefined" || quantity === null)
            quantity = -1;
        else if (parseInt(quantity) < 0) {
            global.notify("Non puoi impostare questo valore", "danger");
            return;
        }
        setAvailabilityPackage({id: id, availability: quantity}).then((result) => {
            if (typeof result.shop_package !== 'undefined') {
                global.notify("Disponibilità salvata", "success");
                this.loadList(this.state.paginator.page - 1);
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listShopPackage(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator, page: page});
            } else {
                paginator.page = result.paginator.page;
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator, page: page});
            }
        });
    }

    convertType = (type) => {
        switch (type) {
            case "PREMIUM":
                return "Premium";
            case "INSURANCE":
                return "Assicurazione";
            case "RECOMMEND":
                return "Raccomandati";
            case "BOUGHT_TOGETHER":
                return "Comprato insieme";
            case "PRODUCT":
                return "Prodotto";
        }
    }

    handlePageChange = (params) => {
        this.loadList(params.page)
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista pacchetti"/>
                        <Card>
                            <CardBody>
                                <div className="form-group">
                                    <div style={{height: '90vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChange}
                                            loading={this.state.paginator.loading}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(ShopPackageListPage));
