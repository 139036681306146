import {put} from 'redux-saga/effects';
import {apiCall} from '../../../helpers/backend_helper';

export const getTag = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/category/tag/list'});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setTag = async (category) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/category/tag/add', data: category});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editTag = async (category) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/category/tag/edit', data: category});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}


export const deleteTag = async (idCat) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/category/tag/delete', data: idCat});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setAreaTag = async (id, tags) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({
            method: 'POST',
            url: 'admin/area/tag/set',
            data: {id_area: id, category_tags: JSON.stringify(tags)}
        });
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setMicroAreaTag = async (id, tags) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({
            method: 'POST',
            url: 'admin/microarea/tag/set',
            data: {id_micro_area: id, category_tags: JSON.stringify(tags)}
        });
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}