import React, {Component} from "react";
import {Row, Col, Card, CardTitle, CardText, CardBody, Container, Button, Badge} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import GoogleMapReact from 'google-map-react';

import {
    listSocClientTickets,
    infoSocClientTicket,
    refundClientTicket,
    checkSession,
    getSocControl, getSocControlSessions
} from '../../store/actions';
import {geoCenter} from "../../components/Common/GeoCenter";

import Breadcrumb from '../../components/Common/Breadcrumb';
import "./society.scss";
import {AvField, AvForm} from "availity-reactstrap-validation";
import spid_logo from "../../assets/images/spid-level2-logo-lb.png";

var moment = require('moment');

class SocControlPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "[Ticket] Utente", field: "client", width: 340, renderCell: (params) => (
                        <Row>
                            <Col>[{params.id}] {params.value.email}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Nome cognome", field: "client1", width: 340, renderCell: (params) => (
                        <Row>
                            <Col>{params.getValue(params.id, "client").firstname} {params.getValue(params.id, "client").lastname}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Ticket", field: "ticket", width: 160, renderCell: (params) => (
                        <Row>
                            <Col>{params.value.name}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Data Acquisto", field: "date_purchase", width: 160
                },
                {
                    headerName: "Sessione", field: "score_capture_sessions", width: 180, renderCell: (params) => {
                        if (typeof params.value === 'undefined' || params.value === null || Object.keys(params.value).length === 0)
                            return <Badge color="danger">Nessuna</Badge>;
                        let is_opened = false;
                        for (let i in params.value) {
                            if (params.value[i].date_end === null)
                                is_opened = true;
                        }
                        if (is_opened)
                            return <Badge color="success">Aperta</Badge>;
                        return <Badge color="warning">Chiusa</Badge>;
                    }
                },
                {
                    headerName: "Utilizzato", field: "is_used", width: 160, renderCell: (params) =>
                        ((params.value) ? <Badge color="success">SI</Badge> :
                                <Badge color="danger">NO</Badge>
                        )
                },
                {
                    headerName: " ",
                    width: 120,
                    field: 'id',
                    renderCell: (params) => (
                        <Button onClick={() => this.infoButton(params.id)} type="button" color="info">Info</Button>)
                }
            ],
            rows: [],
            map: null,
            maps: null,
            paginator: {loading: true, countRows: 0, limit: 50},
            markers: [],
            form_edit: {
                date_start: moment().subtract('1', "month").format("DD/MM/YYYY"),
                date_end: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
                date_session: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
            },
            date_start: (moment().subtract('1', "month").toDate()),
            date_end: new Date(),
            date_current: new Date(),
            date_session: new Date(),
            ticket: null,
            sessions: [],
            search: null,
            session_actived: true,
            enabled_sessions: [],
            center: {
                lat: geoCenter.lat,
                lng: geoCenter.lng
            },
            zoom: 5.5
        };
    }

    async handleValidSubmit(event, values, edit = false) {
        let search = null;
        if (values.search !== "" && values.search !== null)
            search = values.search;
        this.setState({search: search}, () => this.loadList());
    }

    setDate = (date, type) => {
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");
        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    setDateSession = (date) => {
        let date_start = date;
        let form_edit = this.state.form_edit;
        form_edit.date_session = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        this.setState({date_session: date_start, form_edit: form_edit});
    }

    async componentDidMount() {
        if (typeof this.props.match.params.type !== "undefined" && this.props.match.params.type !== null) {
            let date_start = this.state.date_start;
            let date_end = this.state.date_end;
            switch (this.props.match.params.type) {
                case 'DAILY':
                    date_start = moment.parseZone(new Date());
                    date_end = moment.parseZone(new Date());
                    break;
                case 'TWODAYS':
                    date_start = moment.parseZone(new Date()).subtract(1, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'THREEDAYS':
                    date_start = moment.parseZone(new Date()).subtract(2, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'FOURDAYS':
                    date_start = moment.parseZone(new Date()).subtract(3, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'WEEKDAYS':
                    date_start = moment.parseZone(new Date()).subtract(4, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'WEEKLY':
                    date_start = moment.parseZone(new Date()).subtract(6, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'TWOWEEKS':
                    date_start = moment.parseZone(new Date()).subtract(13, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'MONTHLY':
                    date_start = moment.parseZone(new Date()).subtract(26, 'days');
                    date_end = moment.parseZone(new Date()).add(4, 'days');
                    break;
                case 'TRIMESTER':
                    date_start = moment.parseZone(new Date()).subtract(26, 'days');
                    date_end = moment.parseZone(new Date()).add(45, 'days');
                    break;
                case "SEASONAL":
                case 'SEMIANNUAL':
                    date_start = moment.parseZone(new Date()).subtract(100, 'days');
                    date_end = moment.parseZone(new Date()).add(100, 'days');
                    break;
                case 'YEARLY':
                    date_start = moment.parseZone(new Date()).subtract(176, 'days');
                    date_end = moment.parseZone(new Date()).add(189, 'days');
                    break;
            }
            let form_edit = {
                date_start: date_start.local().format("DD/MM/YYYY"),
                date_end: date_end.local().format("DD/MM/YYYY"),
            }
            this.setState({
                date_start: new Date(date_start),
                date_end: new Date(date_end),
                form_edit: form_edit
            }, () => this.loadListSessions());
        }
    }

    loadList = () => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});
        let data = {
            date_start: this.state.form_edit.date_start,
            date_end: this.state.form_edit.date_end
        }
        if (this.state.search !== null)
            data.search = this.state.search;
        if (this.state.session_actived)
            data.session_actived = "true";

        getSocControl(data).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});

                if (this.state.map !== null && this.state.maps !== null) {
                    this.state.markers.map((marker, i) => {
                        marker.setMap(null);
                    });
                    let markers = [];
                    let lat = null;
                    let lon = null;

                    result.list.map((value, i) => {
                        value.score_capture_sessions.map((session, i) => {
                            if (lat === null) {
                                lat = session.lat;
                                lon = session.lon;
                            }
                            let url = "http://maps.google.com/mapfiles/ms/icons/orange-dot.png";
                            if (session.date_end === null)
                                url = "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
                            let marker = new this.state.maps.Marker({
                                map: this.state.map,
                                position: new this.state.maps.LatLng(session.lat, session.lon),
                                icon: {
                                    url: url,
                                    scaledSize: new this.state.maps.Size(42, 42)
                                },
                            });
                            markers.push(marker);

                            this.state.maps.event.addListener(marker, 'click', () => {
                                this.infoButton(value.id)
                            });
                        });
                    })
                    this.setState({markers: markers, center: {lat: lat, lng: lon}, zoom: 12});
                }

            }
        })
    }

    loadListSessions = () => {
        let data = {date: this.state.form_edit.date_session}
        getSocControlSessions(data).then(result => {
            if (!result) {
                this.setState({enabled_sessions: []});
            } else {

                let sessions = [];
                for (let i in result.list) {
                    let session = result.list[i];
                    sessions.push({
                        id: session.id,
                        client: session.ticket_client.client,
                        id_ticket: session.ticket_client.id,
                        name: "Sessione " + session.id,
                        data: session.date_start,
                        date_start: session.date_start,
                        date_end: session.date_end,
                        date_check: session.date_check,
                        user_check: session.checked_by,
                        area: session.ticket_client.ticket.area.name,
                        micro_area: (session.micro_area != undefined) ? session.micro_area.name : '',
                        count: session.captures.length,
                        fishes: session.captures
                    });
                }
                this.setState({enabled_sessions: sessions});
            }
        })
    }

    async refundButton(params) {
        let res = await refundClientTicket(params);
        if (res) {
            global.notify("Rimborso effettuato con successo", "success");
            this.loadList();
        } else {
            global.notify("Errore con il rimborso", "danger");
            this.loadList()
        }
    }

    async infoButton(params) {
        let res = await infoSocClientTicket(params);
        if (Object.keys(res.sessions).length === 0) {
            this.setState({ticket: res.ticket, sessions: []});
        }
        let lat = null;
        let lon = null;
        let sessions = [];
        for (let i in res.sessions) {
            let session = res.sessions[i];
            if (lat === null) {
                lat = session.lat;
                lon = session.lon;
            }
            let captures = [];
            for (let c in res.captures) {
                let capture = res.captures[c];
                if (typeof capture.score_capture_session !== "undefined" && capture.score_capture_session !== null) {
                    if (capture.score_capture_session.id === session.id)
                        captures.push(capture);
                }
            }
            sessions.push({
                id: session.id,
                id_ticket: res.ticket.id,
                name: "Sessione " + session.id,
                data: res.ticket.date_purchase,
                date_start: session.date_start,
                date_end: session.date_end,
                date_check: session.date_check,
                user_check: session.checked_by,
                area: res.ticket.ticket.area.name,
                micro_area: (res.ticket.ticket.micro_area != undefined) ? res.ticket.ticket.micro_area.name : '',
                count: captures.length,
                fishes: captures
            });
        }
        this.setState({ticket: res.ticket, sessions: sessions, center: {lat: lat, lng: lon}, zoom: 12});
    }

    renderMap() {
        return <div style={{height: '60vh', width: '100%'}}>
            <GoogleMapReact
                bootstrapURLKeys={{key: "AIzaSyCV53jtplvMZi17CqUW7w8Q5w9uat1lgqw"}}
                center={this.state.center}
                zoom={this.state.zoom}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({map, maps}) => {
                    this.setState({map: map, maps: maps}, () => this.loadList())
                }}
            />
        </div>
    }

    checkSession = async (id_ticket, id_session) => {
        let res = await checkSession(id_ticket, id_session);
        if (typeof res.error === "undefined") {
            let sessions = this.state.sessions;
            for (let i in sessions) {
                if (sessions[i].id === id_session) {
                    sessions[i].date_check = res.session.date_check;
                    sessions[i].user_check = res.session.checked_by;
                }
            }
            this.setState({sessions: sessions});
            global.notify("Sessione controllata con successo", "success");

        } else {
            global.notify("Errore durante la verifica della sessione: " + res.error, "error");
        }
    }

    renderListSessions = () => {
        return this.state.enabled_sessions.map((session, i) => {
            let spid = null;
            let data_client = [];
            if (session.client !== null) {
                data_client.push("Utente: " + session.client.firstname + " " + session.client.lastname);
                data_client.push(<br/>)
                data_client.push("Email: " + session.client.email);
                data_client.push(<br/>)
            }

            if (typeof session.client_spid !== "undefined" && session.client_spid !== null) {
                let data = session.client_spid.data;
                let content = [];
                if (typeof data.name !== "undefined") {
                    content.push("Nome: " + data.name[0]);
                    content.push(<br/>);
                }
                if (typeof data.familyName !== "undefined") {
                    content.push("Cognome: " + data.familyName[0]);
                    content.push(<br/>);
                }
                if (typeof data.email !== "undefined") {
                    content.push("Email: " + data.email[0]);
                    content.push(<br/>);
                }
                if (typeof data.address !== "undefined") {
                    content.push("Indirizzo: " + data.address[0]);
                    content.push(<br/>);
                }
                if (typeof data.fiscalNumber !== "undefined") {
                    content.push("Codice fiscale: " + data.fiscalNumber[0]);
                    content.push(<br/>);
                }
                if (typeof data.idCard !== "undefined") {
                    content.push("Documento: " + data.idCard[0]);
                    content.push(<br/>);
                }
                spid = <>
                    <div className={'col-md-12'}>
                        <hr/>
                    </div>
                    <div className={'col-md-6'}>
                        <CardText>
                            <h5 style={{color: "#01579b"}}>Sessionte autenticata con SPID</h5>
                            {content}
                        </CardText>
                    </div>
                    <div className={'col-md-6'} align={"center"}>
                        <img src={spid_logo} style={{width: "80%", maxWidth: 350, opacity: 0.5}}/>
                    </div>
                </>;
            }
            return (
                <Card className={"col-md-12 col-sm-12"} body>
                    <CardTitle tag="h5">{session.name} - {session.date_start}</CardTitle>
                    <div className={"row"}>
                        <div className={'col-md-6'}>
                            <CardText>
                                {data_client}
                                Data avvio: {session.date_start}<br/>
                                Data chiusura: {session.date_end}<br/>
                                Area: {session.area}<br/>
                                MicroArea: {session.micro_area}<br/>
                                Catture: {session.count}<br/>
                            </CardText>
                        </div>
                        <div className={'col-md-6'}>
                            <CardText>Ultima verifica: {(session.date_check === null) ? "-" : session.date_check}
                                <Button
                                    onClick={() => this.checkSession(session.id_ticket, session.id)} type="button"
                                    size={"sm"}
                                    color="success">Verifica ora</Button><br/>
                                Controllato
                                da: {(session.user_check === null || typeof session.user_check === "undefined") ? "Sconosciuto" : (session.user_check.firstname + " " + session.user_check.lastname + " " + session.user_check.email + "(" + session.user_check.id + ")")}
                            </CardText>
                        </div>
                        {spid}
                    </div>
                    <Row>
                        {session.fishes.map((fish, iFish) => {
                            return (
                                <div className={"col-md-2 col-sm-12"}>
                                    <Card body>
                                        <CardTitle
                                            tag="h5">{fish.fish.name}</CardTitle>
                                        <CardText>
                                            Data: <b>{fish.date_capture}</b><br/>
                                            {(fish.killed) ?
                                                <Badge color="info">KILL</Badge> :
                                                <Badge color="warning">NO KILL</Badge>}<br/>
                                            Lunghezza: {fish.length}<br/>
                                            Peso: {fish.weight}<br/>
                                        </CardText>
                                    </Card>
                                </div>
                            )
                        })}
                    </Row>
                </Card>
            )
        })
    }


    renderSessions = () => {
        return this.state.sessions.map((session, i) => {
            let spid = null;
            if (typeof session.client_spid !== "undefined" && session.client_spid !== null) {
                let data = session.client_spid.data;
                let content = [];
                if (typeof data.name !== "undefined") {
                    content.push("Nome: " + data.name[0]);
                    content.push(<br/>);
                }
                if (typeof data.familyName !== "undefined") {
                    content.push("Cognome: " + data.familyName[0]);
                    content.push(<br/>);
                }
                if (typeof data.email !== "undefined") {
                    content.push("Email: " + data.email[0]);
                    content.push(<br/>);
                }
                if (typeof data.address !== "undefined") {
                    content.push("Indirizzo: " + data.address[0]);
                    content.push(<br/>);
                }
                if (typeof data.fiscalNumber !== "undefined") {
                    content.push("Codice fiscale: " + data.fiscalNumber[0]);
                    content.push(<br/>);
                }
                if (typeof data.idCard !== "undefined") {
                    content.push("Documento: " + data.idCard[0]);
                    content.push(<br/>);
                }
                spid = <>
                    <div className={'col-md-12'}>
                        <hr/>
                    </div>
                    <div className={'col-md-6'}>
                        <CardText>
                            <h5 style={{color: "#01579b"}}>Sessionte autenticata con SPID</h5>
                            {content}
                        </CardText>
                    </div>
                    <div className={'col-md-6'} align={"center"}>
                        <img src={spid_logo} style={{width: "80%", maxWidth: 350, opacity: 0.5}}/>
                    </div>
                </>;
            }
            return (
                <Card className={"col-md-12 col-sm-12"} body>
                    <CardTitle tag="h5">{session.name} - {session.date_start}</CardTitle>
                    <div className={"row"}>
                        <div className={'col-md-6'}>
                            <CardText>
                                Data avvio: {session.date_start}<br/>
                                Data chiusura: {session.date_end}<br/>
                                Area: {session.area}<br/>
                                MicroArea: {session.micro_area}<br/>
                                Catture: {session.count}<br/>
                            </CardText>
                        </div>
                        <div className={'col-md-6'}>
                            <CardText>Ultima verifica: {(session.date_check === null) ? "-" : session.date_check}
                                <Button
                                    onClick={() => this.checkSession(session.id_ticket, session.id)} type="button"
                                    size={"sm"}
                                    color="success">Verifica ora</Button><br/>
                                Controllato
                                da: {(session.user_check === null || typeof session.user_check === "undefined") ? "Sconosciuto" : (session.user_check.firstname + " " + session.user_check.lastname + " " + session.user_check.email + "(" + session.user_check.id + ")")}
                            </CardText>
                        </div>
                        {spid}
                    </div>
                    <Row>
                        {session.fishes.map((fish, iFish) => {
                            return (
                                <div className={"col-md-2 col-sm-12"}>
                                    <Card body>
                                        <CardTitle
                                            tag="h5">{fish.fish.name}</CardTitle>
                                        <CardText>
                                            Data: <b>{fish.date_capture}</b><br/>
                                            {(fish.killed) ?
                                                <Badge color="info">KILL</Badge> :
                                                <Badge color="warning">NO KILL</Badge>}<br/>
                                            Lunghezza: {fish.length}<br/>
                                            Peso: {fish.weight}<br/>
                                        </CardText>
                                    </Card>
                                </div>
                            )
                        })}
                    </Row>
                </Card>
            )
        })
    }

    renderInfoTicket = () => {
        if (this.state.ticket === null)
            return;
        let ticket = this.state.ticket;
        let extra_data = [];
        if (Object.keys(ticket.extra_data).length > 0)
            extra_data.push(<hr/>);
        for (let i in ticket.extra_data) {
            let data = ticket.extra_data[i];
            extra_data.push(<b>{data.name}:</b>);
            extra_data.push(<span> {data.value}</span>);
            extra_data.push(<br/>);
        }
        return <div className={"row"}>
            <div className={"col-md-12"}>
                <Card body>
                    <CardTitle tag="h5">Ticket #{ticket.id}</CardTitle>
                    <CardText>
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                Nome ticket: {ticket.ticket.name}<br/>
                                Data acquisto: {ticket.date_purchase}<br/>
                                Data Utilizzo: {ticket.date_start_use} - {ticket.date_end_use}<br/>
                                {this.state.ticket.client !== null ? "Utente: " + this.state.ticket.client.firstname + " " + this.state.ticket.client.lastname : ""}<br/>
                                {ticket.nickname !== null ? "Nominativo: " + ticket.nickname : <></>} <br/>
                                {extra_data}
                                <hr/>
                                Area: {ticket.ticket.area.name} <br/>
                                {ticket.ticket.micro_area !== null ? "Zona: " + ticket.ticket.micro_area.name : <></>}<br/>
                            </div>
                        </div>
                    </CardText>
                </Card>
            </div>
        </div>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Controllo ticket"/>
                        <Card>
                            <CardBody>
                                <div className={"row"}>
                                    <div className="form-group col-md-4">
                                        <label>Data inizio</label>
                                        <DatePicker selected={this.state.date_start}
                                                    startDate={this.state.date_current} className="form-control"
                                                    onChange={(date) => this.setDate(date, "start")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Data fine</label>
                                        <DatePicker selected={this.state.date_end}
                                                    minDate={this.state.date_start} className="form-control"
                                                    onChange={(date) => this.setDate(date, "end")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4" style={{display: "flex"}}>
                                        <div style={{marginTop: "auto"}}>
                                            <Button onClick={() => this.loadList()}
                                                    color={"success"}>Aggiorna</Button>
                                        </div>
                                    </div>
                                    <div className="form-check col-md-12 pl-5">
                                        <input type="checkbox" className="form-check-input"
                                               id="filter_session" name="session" checked={this.state.session_actived}
                                               onChange={(value) => (this.setState({session_actived: value.target.checked}))}/>
                                        <label className="form-check-label" for="filter_session">Mostra SOLO le sessioni
                                            attive</label>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <div>
                            {this.renderMap()}
                        </div>
                        <Card className={"mt-4"}>
                            <CardBody>
                                <h5>Lista sessioni attive</h5>
                                <div className={"row"}>
                                    <div className="form-group col-md-6">
                                        <label>Data</label>
                                        <DatePicker selected={this.state.date_session}
                                                    startDate={this.state.date_session} className="form-control"
                                                    onChange={(date) => this.setDateSession(date)}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-6" style={{display: "flex"}}>
                                        <div style={{marginTop: "auto"}}>
                                            <Button onClick={() => this.loadListSessions()}
                                                    color={"success"}>Aggiorna</Button>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                {this.renderListSessions()}
                            </CardBody>
                        </Card>
                        <Card className={"mt-4"}>
                            <CardBody>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    <div className={"row"}>
                                        <div className="form-group col-md-8">
                                            <AvField name="search" label="Cerca utente"
                                                     className="form-control"
                                                     placeholder="Cerca ticket per nome, cognome o email dei clienti"
                                                     type="text"/>

                                        </div>
                                        <div className="form-group col-md-4" style={{display: "flex"}}>
                                            <div style={{marginTop: "auto"}}>
                                                <Button type="submit" color="primary">Cerca</Button>
                                            </div>
                                        </div>
                                    </div>
                                </AvForm>
                                <div style={{height: '30vh', width: '100%'}}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        pagination
                                        pageSize={this.state.paginator.limit}
                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                        rowCount={this.state.paginator.countRows}
                                        paginationMode="server"
                                        loading={this.state.paginator.loading}
                                    />
                                </div>
                                {this.renderInfoTicket()}
                                {this.renderSessions()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {listSocClientTickets})(SocControlPage));
