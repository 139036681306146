import {put} from 'redux-saga/effects';
import {apiCall, UploadFile} from '../../../helpers/backend_helper';

export const infoRules = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/rules/info', data: {id: id}});
    } catch (error) {
        return false;
    }
}

export const listRules = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/rules/list'});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setRules = async (rule) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/rules/add', data: rule});
    } catch (error) {
        return false;
    }
}

export const editRules = async (rule) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/rules/edit', data: rule});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}
export const setOperatingDates = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/operating/dates', data: data});
    } catch (error) {
        return false;
    }
}

export const setOperatingDatesSlot = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/operating/dates/slot', data: data});
    } catch (error) {
        return false;
    }
}

export const extraOperatingDateSet = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/operating/dates/extra', data: data});
    } catch (error) {
        return false;
    }
}

export const deleteRules = async (idRule) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/rules/delete', data: {id: idRule}});
    } catch (error) {
        return false;
    }
}

export const fileRules = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await UploadFile({method: 'POST', url: 'admin/rules/upload', data: data});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setMaximumSessions = async (rule) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/fishing/maximum/sessions', data: rule});
    } catch (error) {
        return false;
    }
}

export const addFishingGroup = async (rule) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/fishing/rule/group/add', data: rule});
    } catch (error) {
        return false;
    }
}

export const deleteFishingGroup = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/fishing/rule/group/delete', data: {id: id}});
    } catch (error) {
        return false;
    }
}

export const setFishingGroupRule = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/fishing/rule/group/set', data: data});
    } catch (error) {
        return false;
    }
}
export const deleteFishingGroupRule = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/fishing/rule/group/delete/set', data: data});
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}