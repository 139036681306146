import React, {Component} from "react";
import {Card, CardBody, Container, Button, Input, InputGroup, Badge} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {listTickets, setAvailabilityTicket} from '../../../store/actions';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleMinus, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

class TicketListAvailabilityPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "Nome ticket", field: "name", width: 180, renderCell: (params) => {
                        let name = [];
                        if (params.getValue(params.id, "is_hidden"))
                            name.push(<FontAwesomeIcon icon={faEyeSlash} color={"#212121"}/>);
                        if (params.getValue(params.id, "is_disabled"))
                            name.push(<FontAwesomeIcon icon={faCircleMinus} color={"#212121"}/>);
                        name.push(<span>{params.value}</span>);
                        return name;
                    }
                },
                {headerName: "Ente", field: "society", width: 180, renderCell: (params) => params.formattedValue.name},
                {
                    headerName: "Area", field: "areaName", width: 140, valueGetter: (params) =>
                        (params.getValue(params.id, "area").name !== null ? params.getValue(params.id, "area").name : "") + " " +
                        (params.getValue(params.id, "micro_area") !== null ? params.getValue(params.id, "micro_area").name : "")
                },
                {headerName: "Prezzo", field: "price", width: 140},
                {headerName: "Inizio validità", field: "date_start_validity", width: 160},
                {headerName: "Fine validità", field: "date_end_validity", width: 160},
                {
                    headerName: "Tipologia", field: "typeName", width: 140, valueGetter: (params) => {
                        switch (params.getValue(params.id, "type")) {
                            case "DAILY":
                                return "Giornaliero";
                            case "TWODAYS":
                                return "Bigiornaliero";
                            case "THREEDAYS":
                                return "Tre giorni";
                            case "FOURDAYS":
                                return "Quattro giorni";
                            case "WEEKDAYS":
                                return "Cinque giorni";
                            case "WEEKLY":
                                return "Settimanale";
                            case "TWOWEEKS":
                                return "Bisettimanale";
                            case "MONTHLY":
                                return "Mensile";
                            case "TRIMESTER":
                                return "Trimestrale";
                            case "SEASONAL":
                                return "Stagionale";
                            case "SEMIANNUAL":
                                return "Semestrale";
                            case "YEARLY":
                                return "Annuale";
                            case "BIANNUAL":
                                return "Biennale";
                            case "TRIENNIAL":
                                return "Triennale";
                        }
                    }
                },
                {
                    headerName: "Tipo residenti", field: "number", width: 180, renderCell: (params) => {
                        if (params.getValue(params.id, "ticket_residence_type") === null)
                            return (<Badge color="alert">Sconosciuto</Badge>)
                        return (<Badge color="info">{params.getValue(params.id, "ticket_residence_type").name}</Badge>)
                    }
                },
                {
                    headerName: "Disponibilità rimanente",
                    field: "availability",
                    width: 220,
                    renderCell: (params) => {
                        if (params.value !== null)
                            return params.value;
                        return "∞";
                    }
                },
                {
                    headerName: "Disponibilità rimanente",
                    field: "id",
                    width: 220,
                    renderCell: (params) => {
                        return <InputGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <Input type="text" onChange={(e) => {
                                let inputs = this.state.tmp_input;
                                inputs[params.value] = e.target.value;
                                this.setState({tmp_input: inputs});
                            }}
                                   placeholder={"Quantità"}/>
                            <Button className={"btn-success"} onClick={() => {
                                this.setAvailability(params.value, this.state.tmp_input[params.value])
                            }}>Salva</Button>
                        </InputGroup>
                    }
                },
            ],
            rows: [],
            tmp_input: {},
            paginator: {loading: true, countRows: 0, limit: 50, page: 1},
        };
    }

    async componentDidMount() {
        this.loadList(0);
    }

    setAvailability = (id, quantity) => {
        if (typeof quantity === "undefined" || quantity === null)
            quantity = -1;
        else if (parseInt(quantity) < 0) {
            global.notify("Non puoi impostare questo valore", "danger");
            return;
        }

        setAvailabilityTicket({id: id, availability: quantity}).then((result) => {
            if (typeof result.ticket !== 'undefined') {
                global.notify("Disponibilità salvata", "success");
                this.loadList(this.state.paginator.page - 1);
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listTickets(null, page + 1, true).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator, page: page});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                paginator.page = result.paginator.page;
                this.setState({rows: result.list, paginator: paginator, page: page});
            }
        });
    }

    handlePageChange = (params) => {
        this.loadList(params.page)
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Disponibilità ticket"/>
                        <Card>
                            <CardBody>
                                <div className="form-group">
                                    <div style={{height: '90vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChange}
                                            loading={this.state.paginator.loading}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(TicketListAvailabilityPage));
