import {apiCall, UploadFile} from '../../../helpers/backend_helper';

export const getInfoArea = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/area/info', data: {id: id}});
        if (typeof response.area !== 'undefined') {
            return response.area;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const uploadImageArea = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await UploadFile({method: 'POST', url: 'admin/area/upload', data: data});
    } catch (error) {
        return false;
    }
}

export const uploadImageGalleryArea = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await UploadFile({method: 'POST', url: 'admin/area/gallery/upload', data: data});
    } catch (error) {
        return false;
    }
}

export const removeImageGalleryArea = async (id_area, index) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/area/gallery/remove', data: {id: id_area, index: index}});
    } catch (error) {
        return false;
    }
}

export const uploadLogoArea = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await UploadFile({method: 'POST', url: 'admin/area/logo/upload', data: data});
    } catch (error) {
        return false;
    }
}

export const addMicroArea = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/microarea/add', data: data});
    } catch (error) {
        return false;
    }
}

export const editMicroArea = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/microarea/edit', data: data});
    } catch (error) {
        return false;
    }
}

export const deleteMicroArea = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/microarea/delete', data: {id: id}});
        if (typeof response.error === 'undefined') {
            return [];
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getCategories = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/category/list'});
        if (typeof response.list !== 'undefined') {
            return response.list;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getSocieties = async (page) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/society/list', data: {page: page}});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}
export const getListAreas = async (page, id_category = null, id_society = null) => {
    try {
        let data = {page: page};
        if (id_category !== null)
            data.id_category = id_category;
        if (id_society !== null)
            data.id_society = id_society;

        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/area/list', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getListAreasShowcase = async (page, id_category = null) => {
    try {
        let data = {page: page};
        if (id_category !== null)
            data.id_category = id_category;

        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/area/showcase/list', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getMapAreas = async (category, lat_a, lon_a, lat_b, lon_b) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({
            method: 'POST',
            url: 'admin/area/map',
            data: {id_category: category, lat_a: lat_a, lon_a: lon_a, lat_b: lat_b, lon_b: lon_b}
        });
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setZone = async (area) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/area/add', data: area});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const addZoneShowcase = async (area) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/area/showcase/add', data: area});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editZoneShowcase = async (area) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/area/showcase/edit', data: area});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}


export const editZone = async (area) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/area/edit', data: area});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const deleteArea = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/area/delete', data: {id: id}});
    } catch (error) {
        return false;
    }
}

export const listGroup = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/microarea/group/list', data: data});
    } catch (error) {
        return false;
    }
}

export const addGroup = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/microarea/group/add', data: data});
    } catch (error) {
        return false;
    }
}

export const deleteGroup = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/microarea/group/delete', data: data});
    } catch (error) {
        return false;
    }
}

export const listAdsArea = async (id_area) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/area/ads/list', data: {id: id_area}});
    } catch (error) {
        return false;
    }
}

export const addAdsArea = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/area/ads/upload', data: data});
    } catch (error) {
        return false;
    }
}

export const deleteAdsArea = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/area/ads/delete', data: {id: id}});
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}