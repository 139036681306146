import {marker} from 'leaflet';
import {apiCall, UploadFile} from '../../helpers/backend_helper';

export const getSocietyListAreas = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'society/area/list'});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const infoArea = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'society/area/info', data: {id: id}});
        if (typeof response.area !== 'undefined')
            return response.area;
        return false;
    } catch (error) {
        return false;
    }
}

export const editArea = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'society/area/edit', data: data});
    } catch (error) {
        return false;
    }
}

export const getSocietyClients = async (page) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'society/client/list', data: {page: page}});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const banSocietyClients = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'society/client/unban', data: data});
    } catch (error) {
        return false;
    }
}

export const unbanSocietyClients = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'society/client/unban', data: data});
    } catch (error) {
        return false;
    }
}

export const setSocietyClient = async (client) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'society/user/client/add', data: client});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const infoSocClientTicket = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'ticket/client/info', data: {id: id}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const socListClientTicketsCalendar = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'ticket/client/calendar', data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const socRefundClientTicket = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'ticket/client/refund', data: {id: id}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const socTicketClientChangeDate = async (id, date_start, date_end) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({
            method: 'POST',
            url: 'ticket/client/change/date',
            data: {id_ticket: id, date_start: date_start, date_end: date_end}
        });
    } catch (error) {
        return false;
    }
}


export const checkSession = async (id_ticket, id_session) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({
            method: 'POST',
            url: 'session/check',
            data: {id_ticket: id_ticket, id_session: id_session}
        });
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const refundSocClientTicket = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'ticket/client/refund', data: {id: id}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getStatsDashboard = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'stats/dashboard'});
    } catch (error) {
        return false;
    }
}

export const getSocControl = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let rows = [];
        let response = await apiCall({method: 'POST', url: 'ticket/client/control', data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}


export const getSocControlSessions = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let rows = [];
        let response = await apiCall({method: 'POST', url: 'sessions/control', data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getSocCaptureStats = async (page, date_start, date_end, id_area, id_ticket) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let data = {page: page, date_start: date_start, date_end: date_end};
        if (id_area) {
            data.id_area = id_area;
        }
        if (id_ticket) {
            data.id_ticket = id_ticket;
        }
        let response = await apiCall({method: 'POST', url: 'stats/capture', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getSocCaptureTotalStats = async (page, date_start, date_end, id_area, id_ticket) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let data = {page: page, date_start: date_start, date_end: date_end};
        if (id_area) {
            data.id_area = id_area;
        }
        if (id_ticket) {
            data.id_ticket = id_ticket;
        }
        let response = await apiCall({method: 'POST', url: 'stats/capture/total', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getSocietyInfo = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'society/info'});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listSocTickets = async (page, area = null) => {
    try {
        let data = {page: page};
        if (area !== null)
            data.id = area;
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'ticket/list/society', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listSocClientTickets = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'ticket/client/list', data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listSocAreaTickets = async (area, page) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'ticket/list/area', data: {id: area, page: page}});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const addSocTicket = async (ticket) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'ticket/add', data: ticket});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const shareSocTicket = async (ticket) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'ticket/share', data: ticket});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editSocTicket = async (ticket) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'ticket/edit', data: ticket});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const infoSocTicket = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'ticket/info', data: {id: id}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const deleteSocProduct = async (idProd) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'DELETE', url: 'ticket/delete', data: idProd});
        console.log(response);
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listUsers = async (page) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'society/user/list', data: {page: page}});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        return false;
    }
}

export const addFishingGuard = async (societyUser) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'society/user/fishingguard/add', data: societyUser});
    } catch (error) {
        return false;
    }
}

export const deleteFishingGuard = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'society/user/fishingguard/delete', data: {id: id}});
    } catch (error) {
        return false;
    }
}

export const getListRegion = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'region/list'});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const addMicroArea = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'society/microarea/add', data: data});
    } catch (error) {
        return false;
    }
}

export const editMicroArea = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'society/microarea/edit', data: data});
    } catch (error) {
        return false;
    }
}

export const deleteMicroArea = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'society/microarea/delete', data: {id: id}});
        if (typeof response.error === 'undefined') {
            return [];
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editZone = async (area) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'society/area/edit', data: area});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editSocSociety = async (society) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'society/edit', data: society});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setAreaTag = async (id, tags) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({
            method: 'POST',
            url: 'society/area/tag/set',
            data: {id_area: id, category_tags: JSON.stringify(tags)}
        });
        if (response)
            return response;
        return false;
    } catch (error) {
        return false;
    }
}

export const setMicroAreaTag = async (id, tags) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({
            method: 'POST',
            url: 'society/microarea/tag/set',
            data: {id_micro_area: id, category_tags: JSON.stringify(tags)}
        });
        if (response)
            return response;
        return false;
    } catch (error) {
        return false;
    }
}

export const uploadImageArea = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await UploadFile({method: 'POST', url: 'society/area/upload', data: data});
    } catch (error) {
        return false;
    }
}

export const uploadLogoArea = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await UploadFile({method: 'POST', url: 'society/area/logo/upload', data: data});
    } catch (error) {
        return false;
    }
}

export const regulationFileUpload = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        return await UploadFile({method: 'POST', url: 'rules/upload', data: data});

    } catch (error) {
        return false;
    }
}

export const getListFish = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'fish/list/regulation'});
        if (typeof response.list !== 'undefined') {
            return response.list;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const socSetOperatingDates = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'operating/dates', data: data});
    } catch (error) {
        return false;
    }
}

export const socSetOperatingDatesSlot = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'operating/dates/slot', data: data});
    } catch (error) {
        return false;
    }
}

export const socExtraOperatingDateSet = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'operating/dates/extra', data: data});
    } catch (error) {
        return false;
    }
}

export const addSocCapture = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'fishing/capture/add', data: data});
    } catch (error) {
        return false;
    }
}