import React, {Component} from "react";
import {Card, CardBody, Container, Button, CardTitle, CardText} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {addTicket, getListAreas, listAdsArea, addAdsArea, deleteAdsArea} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {DataGrid} from "@material-ui/data-grid";
import ImageUploader from "react-images-upload";

class ZoneAdsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [{headerName: "Nome area", field: "name", width: 180},
                {
                    headerName: "Categoria",
                    field: "category",
                    width: 140,
                    renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Province", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 160},
                {headerName: "CAP", field: "cap", width: 140},
                {headerName: "Telefono", field: "phone", width: 140},
            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            area: null,
            ticket: null,
            defaultImages: [],
            imagesToUpload: []
        };
        this.fixOnChange = 0;
    }

    async componentDidMount() {
        this.loadListAreas(0);
    }

    loadListAreas = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getListAreas(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    handlePageChange = (params) => {
        this.loadListAreas(params.page);
    };

    handleRowSelected = (rows) => {
        let row = [...rows.entries()][0][1];

        this.setState({area: row, defaultImages: [], imagesToUpload: []}, () => {
            listAdsArea(row.id).then(result => {
                if (typeof result.list !== "undefined") {
                    let list = [];
                    for (let i in result.list)
                        list.push(global.baseUrlImg + result.list[i].image);
                    this.setState({defaultImages: list});
                }
            });
        });
    }

    handleChangeImage = async (file, list) => {
        let list_default = this.state.defaultImages;

        for (let i in list) {
            if (list_default.includes(list[i]))
                list_default.splice(list_default.indexOf(list[i]), 1);
        }
        if (Object.keys(list_default).length > 0) {
            if (this.fixOnChange > 0) {
                this.fixOnChange = 0;
                return;
            }
            this.fixOnChange += 1;

            await listAdsArea(this.state.area.id).then(async (result) => {
                if (typeof result.list !== "undefined") {
                    let list_res = [];
                    for (let i in result.list) {
                        if (list_default[0] === global.baseUrlImg + result.list[i].image) {
                            await deleteAdsArea(result.list[i].id).then(resultDelete => {
                                if (typeof resultDelete.error === "undefined") {
                                    global.notify("Immagine eliminata con successo", "success");
                                }
                            });
                        } else {
                            list_res.push(global.baseUrlImg + result.list[i].image);
                        }
                    }
                    this.setState({defaultImages: list_res});
                }
            });
        }
        await this.setState({imagesToUpload: file});
    };

    uploadImages = async () => {
        if (Object.keys(this.state.imagesToUpload).length === 0) {
            global.notify("Seleziona un'immagine per procedere", "danger");
            return;
        }
        for (let i in this.state.imagesToUpload) {
            let image = this.state.imagesToUpload[i];
            await addAdsArea({id: this.state.area.id, image: image}).then(result => {
                if (typeof result.error !== 'undefined') {
                    global.notify("Errore con il caricamento: " + result.error, "danger");
                }
            })
        }
        let area = this.state.area;

        this.setState({imagesToUpload: [], area: null}, () => {
            listAdsArea(area.id).then(result => {
                if (typeof result.list !== "undefined") {
                    let list = [];
                    for (let i in result.list)
                        list.push(global.baseUrlImg + result.list[i].image);
                    this.setState({defaultImages: list, area: area});
                }
            });
        });
    }

    renderImages = () => {
        if (this.state.area !== null)
            return <div>
                <ImageUploader
                    key={this.state.area.id}
                    withIcon={false}
                    withLabel={false}
                    withPreview={true}
                    dataURLKey="data_url"
                    buttonText={"Aggiungi foto"}
                    fileSizeError={"Il file è troppo pesante!"}
                    fileTypeError={"Estensione non supportata!"}
                    onChange={this.handleChangeImage}
                    imgExtension={[".jpg", ".png", ".jpeg"]}
                    maxFileSize={5242880}
                    defaultImages={this.state.defaultImages}
                />
                <div className="text-center mt-4"><Button color="success" onClick={() => this.uploadImages()}>Carica
                    elementi</Button></div>
            </div>;
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Impostazione pubblicità"/>
                        <Card>
                            <CardBody>
                                <h5>Seleziona l'area su cui caricare le pubblicità</h5>
                                <div style={{height: '40vh', width: '100%'}}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        pagination
                                        pageSize={this.state.paginator.limit}
                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                        rowCount={this.state.paginator.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageChange}
                                        loading={this.state.paginator.loading}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div className={"row"}>
                                    {this.state.area !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Area</CardTitle>
                                                <CardText>Nome area: {this.state.area.name}<br/> {this.state.area.address},
                                                    n°{this.state.area.number} {this.state.area.city} {this.state.area.region}
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                </div>
                                <br/>
                                {this.renderImages()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {addTicket, getListAreas})(ZoneAdsPage));
