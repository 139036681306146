import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    ButtonGroup,
    CardTitle,
    CardText,
    Badge,
    Modal, ModalHeader, ModalBody, ModalFooter, Input,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

// actions
import {
    extraOperatingDateSet,
    getListAreas,
    listAreaTickets,
    setOperatingDates, setOperatingDatesSlot,
} from '../../store/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';
import {DataGrid} from "@material-ui/data-grid";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import InputMask from "react-input-mask";
import {AvForm, AvField} from "availity-reactstrap-validation";

var moment = require('moment');

class OperatingDatesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [{headerName: "Nome area", field: "name", width: 180},
                {
                    headerName: "Categoria",
                    field: "category",
                    width: 160, renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Province", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 140},
                {headerName: "CAP", field: "cap", width: 140},
                {headerName: "Telefono", field: "phone", width: 160}],
            columnsMicroArea: [{headerName: "Nome micro area", field: "name", width: 240}],
            columnsTicket: [
                {
                    headerName: "Nome ticket", field: "name", width: 180, valueGetter: (params) =>
                        (params.getValue(params.id, "is_disabled") ? "[DISABILITATO] " : "") + params.value
                },
                {headerName: "Prezzo", field: "price", width: 160},
                {headerName: "Inizio validità", field: "date_start_validity", width: 160},
                {headerName: "Fine validità", field: "date_end_validity", width: 160},
                {
                    headerName: "Tipologia", field: "typeName", width: 160, valueGetter: (params) => {
                        switch (params.getValue(params.id, "type")) {
                            case "DAILY":
                                return "Giornaliero";
                            case "TWODAYS":
                                return "Bigiornaliero";
                            case "THREEDAYS":
                                return "Tre giorni";
                            case "FOURDAYS":
                                return "Quattro giorni";
                            case "WEEKDAYS":
                                return "Cinque giorni";
                            case "WEEKLY":
                                return "Settimanale";
                            case "TWOWEEKS":
                                return "Bisettimanale";
                            case "MONTHLY":
                                return "Mensile";
                            case "TRIMESTER":
                                return "Trimestrale";
                            case "SEASONAL":
                                return "Stagionale";
                            case "SEMIANNUAL":
                                return "Semestrale";
                            case "YEARLY":
                                return "Annuale";
                            case "BIANNUAL":
                                return "Biennale";
                            case "TRIENNIAL":
                                return "Triennale";
                        }
                    }
                },
                {
                    headerName: "Tipo residenti", field: "ticket_residence_type", width: 180, renderCell: (params) => {
                        if (params.value === null)
                            return (<Badge color="alert">Sconosciuto</Badge>)
                        return (<Badge color="info">{params.value.name}</Badge>)
                    }
                },
            ],
            rows: [],
            rowsMicroArea: [],
            rowsTicket: [],
            form_edit: {
                slot_value: null,
                maximum_booking_slot: null,
            },
            dates_week: {},
            operating_dates: null,
            showTable: 'AREA',
            paginator: {loading: true, countRows: 0, limit: 50},
            paginatorTicket: {loading: true, countRows: 0, limit: 50},
            area: null,
            micro_area: null,
            ticket: null,
            disabledDates: [],
            eventsCalendar: [],
            editSaved: true,
            modalDateClose: false,
            modalDateWeek: [],
            modalDateOpen: [],
            form_modal_date: {
                open: "00:00",
                close: "23:59"
            }
        };
    }

    async componentDidMount() {
        this.showTable('AREA');
    }

    async submitSlot() {
        let values = [];
        if (this.state.area !== null)
            values.id_area = this.state.area.id;
        if (this.state.micro_area !== null)
            values.id_micro_area = this.state.micro_area.id;
        if (this.state.ticket !== null)
            values.id_ticket = this.state.ticket.id;
        if (this.state.area === null && this.state.micro_area === null && this.state.ticket === null) {
            global.notify("Devi selezionare una zona o ticket per procedere", "danger");
            return;
        }

        values.slot_value = this.state.form_edit.slot_value;
        values.maximum_booking_slot = this.state.form_edit.maximum_booking_slot;
        let formResult = await setOperatingDatesSlot(values);

        if (typeof formResult.error === 'undefined') {
            global.notify("Modifiche salvate con successo", "success");
            this.setState({editSaved: true});
            this.loadListAreas(0);
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con la modifica: " + formResult.error, "danger");
            this.setState({error: formResult.error});
        } else
            this.setState({error: 'Errore generico'});
    }

    async handleValidSubmit() {
        let values = [];
        if (this.state.area !== null)
            values.id_area = this.state.area.id;
        if (this.state.micro_area !== null)
            values.id_micro_area = this.state.micro_area.id;
        if (this.state.ticket !== null)
            values.id_ticket = this.state.ticket.id;
        if (this.state.area === null && this.state.micro_area === null && this.state.ticket === null) {
            global.notify("Devi selezionare una zona o ticket per procedere", "danger");
            return;
        }

        for (let i in this.state.dates_week) {
            let arr = this.state.dates_week[i];
            for (let c in arr) {
                let open = arr[c].open.split(":");
                let close = arr[c].close.split(":");
                for (let z in arr) {
                    if (c <= z)
                        continue;
                    let open_ = arr[z].open.split(":");
                    let close_ = arr[z].close.split(":");
                    if (parseInt(open[0]) >= parseInt(open_[0]) && parseInt(open[0]) <= parseInt(close_[0])) {
                        global.notify("Conflitto apertura nel giorno " + this.convertDateLang(i), "danger");
                        return;
                    }
                    if (parseInt(close[0]) >= parseInt(open_[0]) && parseInt(close[0]) <= parseInt(close_[0])) {
                        global.notify("Conflitto chiusura nel giorno " + this.convertDateLang(i), "danger");
                        return;
                    }
                }
            }
        }
        values.operating_dates = JSON.stringify(this.state.dates_week);
        let formResult = await setOperatingDates(values);

        if (typeof formResult.error === 'undefined') {
            global.notify("Giorni di lavoro impostati con successo", "success");
            this.setState({editSaved: true});
            // this.setState({operation_dates: []})
            this.form && this.form.reset();
            this.loadListAreas(0);
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con la modifica: " + formResult.error, "danger");
            this.setState({error: formResult.error});
        } else
            this.setState({error: 'Errore generico'});
    }

    saveModal = async () => {
        let values = [];
        if (this.state.area !== null)
            values.id_area = this.state.area.id;
        if (this.state.micro_area !== null)
            values.id_micro_area = this.state.micro_area.id;
        if (this.state.ticket !== null)
            values.id_ticket = this.state.ticket.id;
        if (this.state.area === null && this.state.micro_area === null && this.state.ticket === null) {
            global.notify("Devi selezionare una zona o ticket per procedere", "danger");
            return;
        }
        values.date = moment(this.state.modalDate).format("DD/MM/YYYY HH:mm");
        let list = [];

        for (let i in this.state.modalDateWeek) {
            let date_week = this.state.modalDateWeek[i];
            if (date_week.is_closed)
                list.push({
                    open: date_week.open,
                    close: date_week.close,
                    status: "close"
                    // status: typeof date_week.is_closed === "undefined" || !date_week.is_closed ? "open" : "close"
                });
        }

        for (let i in this.state.modalDateOpen) {
            let date_week = this.state.modalDateOpen[i];
            list.push({
                open: date_week.open,
                close: date_week.close,
                status: "open"
            });
        }
        values.extra_operating_dates = JSON.stringify(list);
        let formResult = await extraOperatingDateSet(values);

        if (typeof formResult.error === 'undefined') {
            global.notify("Giorni di lavoro impostati con successo", "success");
            this.reloadDates(formResult.operating_dates);
            this.setState({
                modalDateClose: false,
                modalDateWeek: [],
                modalDateOpen: [],
                form_modal_date: {
                    open: "00:00",
                    close: "23:59"
                }
            })
            this.loadListAreas(0);
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con la modifica: " + formResult.error, "danger");
            this.setState({error: formResult.error});
        } else
            this.setState({error: 'Errore generico'});
    }

    showTable = (state) => {
        if (state === 'MICRO' && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        if (state === 'MICRO' && (typeof this.state.area.micro_areas === "undefined" || Object.keys(this.state.area.micro_areas).length === 0)) {
            global.notify("Non ci sono micro aree presenti per quest'area", "warning");
            return;
        }
        if (state === 'TICKET' && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        this.setState({showTable: state});
        if (state === 'AREA') {
            if (Object.keys(this.state.rows).length === 0)
                this.loadListAreas(0);
        }
    }

    setOperatingDate = (day) => {
        if (this.state.area === null) {
            global.notify("Devi selezionare un'area", "warning");
            return;
        }
        let days = this.state.dates_week;
        if (typeof days[day] !== "undefined") {
            delete days[day];
            this.setState({dates_week: days});
        } else
            this.addDates(day);
        this.setState({editSaved: false});
        this.reloadDates(this.state.operating_dates);
    }

    loadListAreas = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getListAreas(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, rowsArea: result.list, paginator: paginator});
            }
        })
    }

    loadListTicket = (page) => {
        let paginatorTicket = this.state.paginatorTicket;
        paginatorTicket.loading = true;
        this.setState({paginatorTicket: paginatorTicket});

        listAreaTickets(this.state.area.id, page + 1).then(result => {
            paginatorTicket.loading = false;
            if (!result) {
                paginatorTicket.countRows = 0;
                this.setState({rowsTicket: [], paginatorTicket: paginatorTicket});
            } else {
                paginatorTicket.countRows = result.paginator.count_all_entities;
                this.setState({rowsTicket: result.list, paginatorTicket: paginatorTicket});
            }
        })
    }

    handlePageChange = (params) => {
        this.loadListAreas(params.page)
    };

    handlePageTicketChange = (params) => {
        this.loadListTicket(params.page)
    };

    reloadDates = (operating_dates = null) => {
        let dates_week = [];
        let events = [];

        if (typeof operating_dates !== "undefined" && operating_dates !== null && operating_dates.dates_week !== null) {
            dates_week = operating_dates.dates_week;
        } else {
            dates_week = {
                "monday": [{"open": "00:00", "close": "23:59"}],
                "tuesday": [{"open": "00:00", "close": "23:59"}],
                "wednesday": [{"open": "00:00", "close": "23:59"}],
                "thursday": [{"open": "00:00", "close": "23:59"}],
                "friday": [{"open": "00:00", "close": "23:59"}],
                "saturday": [{"open": "00:00", "close": "23:59"}],
                "sunday": [{"open": "00:00", "close": "23:59"}]
            };
        }
        this.setState({dates_week: dates_week, operating_dates: operating_dates});

        let date_start = moment();
        let date_end = moment().add(1, "y");
        while (date_start < date_end) {
            if (!dates_week[date_start.format('dddd').toLowerCase()]) {
                let is_open = false;
                if (typeof operating_dates.extra_operating_dates !== "undefined") {
                    let find = operating_dates.extra_operating_dates.find((item) => {
                        if (item.status === "open" && moment(item.open, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY") === date_start.format("DD/MM/YYYY"))
                            return item;
                    });
                    if (typeof find !== "undefined")
                        is_open = true;
                }

                if (!is_open)
                    events.push({
                        title: 'chiuso tutto il giorno',
                        date: date_start.format("YYYY-MM-DD"),
                        backgroundColor: "grey"
                    });
            }
            date_start.add(1, "d");
        }
        if (typeof operating_dates !== "undefined" && operating_dates !== null && operating_dates.extra_operating_dates !== null) {
            for (let i in operating_dates.extra_operating_dates) {
                let date = operating_dates.extra_operating_dates[i];
                let open = moment(date.open, "DD/MM/YYYY HH:mm");
                let close = moment(date.close, "DD/MM/YYYY HH:mm");
                if (date.status === "close")
                    events.push({
                        title: "chiuso " + open.format("HH:mm") + '-' + close.format("HH:mm"),
                        date: open.format("YYYY-MM-DD"),
                        backgroundColor: "red"
                    });
                else
                    events.push({
                        title: "aperto " + open.format("HH:mm") + '-' + close.format("HH:mm"),
                        date: open.format("YYYY-MM-DD"),
                        backgroundColor: "green"
                    });
            }
        }
        this.setState({
            eventsCalendar: events,
            form_edit: {
                slot_value: operating_dates.slot_value,
                maximum_booking_slot: operating_dates.maximum_booking_slot
            }
        });
    }

    handleRowSelected = (rows) => {
        let row = [...rows.entries()][0][1];
        this.reloadDates(row.operating_dates)
        switch (this.state.showTable) {
            case "AREA":
                this.setState({
                    area: row,
                    rowsMicroArea: typeof row.micro_areas !== "undefined" ? row.micro_areas : []
                }, () => this.loadListTicket(0));
                break;
            case "MICRO":
                this.setState({micro_area: row});
                break;
            case "TICKET":
                this.setState({ticket: row});
                break;
        }
    }

    renderCalendar = () => {
        if (this.state.area === null)
            return <></>;
        let date_end = moment().add(1, "y");
        return <div>
            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                aspectRatio={2}
                locale={itLocale}
                initialView="dayGridMonth"
                validRange={{start: new Date(), end: date_end.toDate()}}
                events={this.state.eventsCalendar}
                eventClick={(info) => {
                    if (!this.state.editSaved) {
                        global.notify("Devi salvare le modifiche per proseguire", "warning");
                        return;
                    }
                    let date_edit = moment(info.event.start);
                    let dates_week = this.state.dates_week[date_edit.format("dddd").toLowerCase()];
                    this.setState({modalDateClose: true, modalDate: info.event.start, modalDateWeek: dates_week});
                }}
                dateClick={(info) => {
                    if (!this.state.editSaved) {
                        global.notify("Devi salvare le modifiche per proseguire", "warning");
                        return;
                    }
                    let date_edit = moment(info.date);
                    let dates_week = this.state.dates_week[date_edit.format("dddd").toLowerCase()];
                    this.setState({modalDateClose: true, modalDate: info.date, modalDateWeek: dates_week});
                }}
            /></div>;
    }

    convertDateLang = (date) => {
        switch (date) {
            case "monday":
                return "Lunedì";
            case "tuesday":
                return "Martedì";
            case "wednesday":
                return "Mercoledì";
            case "thursday":
                return "Giovedì";
            case "friday":
                return "Venerdi";
            case "saturday":
                return "Sabato";
            case "sunday":
                return "Domenica";
        }
    }

    renderDates = () => {
        const days_of_week = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
        let list = [];
        for (let i in days_of_week) {
            let dates_week = this.state.dates_week[days_of_week[i]];
            if (typeof dates_week === "undefined")
                continue;

            let row = [];
            row.push();
            for (let r in dates_week) {
                let date = dates_week[r];
                row.push(
                    <Card className={"col-md-4"} style={{borderWidth: 1}}>
                        <CardBody>
                            <div className={"row align-items-center"}>
                                <div className={"form-group col-md-5"}>
                                    <label htmlFor={i + "open"}>Apertura</label>
                                    <InputMask name={i + "open"} className="form-control"
                                               mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                                               value={date.open}
                                               onChange={(e) => {
                                                   let value = e.target.value;
                                                   value = value.replace("_", "0")
                                                   let hours = value.split(":");
                                                   if (parseInt(hours[0]) >= 24)
                                                       value = "23:" + hours[1];
                                                   this.changeDates(days_of_week[i], r, "open", value);
                                               }}
                                               placeholder={"Apertura"}/>
                                </div>
                                <div className={"form-group col-md-5"}>
                                    <label htmlFor={i + "close"}>Chiusura</label>
                                    <InputMask name={i + "close"} className="form-control"
                                               mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                                               value={date.close}
                                               onChange={(e) => {
                                                   let value = e.target.value;
                                                   value = value.replace("_", "0")
                                                   let hours = value.split(":");
                                                   if (parseInt(hours[0]) >= 24)
                                                       value = "23:" + hours[1];
                                                   this.changeDates(days_of_week[i], r, "close", value);
                                               }}
                                               placeholder={"Chiusura"}/>
                                </div>
                                <div className={"col-md-2"}>
                                    <div style={{marginTop: "auto"}}>
                                        <Button onClick={() => this.removeDates(days_of_week[i], r)}
                                                color={"danger"}>-</Button>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                );
            }

            list.push(<Card>
                <CardBody><span><h5>{this.convertDateLang(days_of_week[i])}</h5></span>
                    <div className={"row"}>{row}
                        <div className={"col-md-3"} style={{display: "flex"}}>
                            <div style={{marginTop: "auto", marginBottom: "auto"}}>
                                <Button onClick={() => this.addDates(days_of_week[i])} color={"success"}>+ aggiungi
                                    orario</Button></div>
                        </div>
                    </div>
                </CardBody></Card>);
        }
        return list;
    }

    addDates = (day) => {
        let dates_week = this.state.dates_week;
        if (typeof dates_week[day] === "undefined")
            dates_week[day] = [];
        dates_week[day].push({open: "00:00", close: "23:59"});
        this.setState({dates_week: dates_week, editSaved: false});
    }

    removeDates = (day, index) => {
        let dates_week = this.state.dates_week;
        if (typeof dates_week[day] !== "undefined") {
            if (typeof dates_week[day][index] !== "undefined")
                delete dates_week[day][index];
            if (Object.keys(dates_week[day]).length === 0)
                delete dates_week[day];
        }
        this.setState({dates_week: dates_week, editSaved: false});
    }

    changeDates = (day, index, key, value) => {
        let dates_week = this.state.dates_week;
        if (typeof dates_week[day] !== "undefined" && typeof dates_week[day][index] !== "undefined") {
            if (typeof dates_week[day][index][key] !== "undefined") {
                dates_week[day][index][key] = value;
            }
        }
        this.setState({dates_week: dates_week, editSaved: false});
    }

    renderModalDateClose = () => {
        if (this.state.modalDateClose) {
            let operating_dates = this.state.operating_dates;
            let date_edit = moment(this.state.modalDate);
            let dates_week = this.state.modalDateWeek;
            let listWorking = [];
            if (typeof dates_week !== "undefined")
                for (let r in dates_week) {
                    let date = dates_week[r];
                    let find = operating_dates.extra_operating_dates.find((item) => {
                        if (item !== undefined) {
                            let start = moment(item.open, "DD/MM/YYYY HH:mm");
                            let end = moment(item.close, "DD/MM/YYYY HH:mm");
                            return item.status === "close" && start.format("DD/MM/YYYY") === date_edit.format("DD/MM/YYYY") && start.format("HH:mm") === date.open && end.format("HH:mm") === date.close
                        }
                    });
                    if (typeof find !== "undefined")
                        dates_week[r].is_closed = true;
                    listWorking.push(
                        <div className={"row align-items-center"}>
                            <div className={"form-group col-md-5"}>
                                <label>Apertura</label>
                                <p>{date.open}</p>
                            </div>
                            <div className={"form-group col-md-5"}>
                                <label>Chiusura</label>
                                <p>{date.close}</p>
                            </div>
                            <div className={" col-md-2"}>
                                <div style={{marginTop: "auto"}}>
                                    {typeof date.is_closed !== "undefined" && date.is_closed ?
                                        <Button color={"success"} onClick={() => {
                                            dates_week[r].is_closed = false;
                                            this.setState({modalDateWeek: dates_week});
                                        }}>Apri</Button> : <Button color={"danger"} onClick={() => {
                                            dates_week[r].is_closed = true;
                                            this.setState({modalDateWeek: dates_week});
                                        }}>Chiudi</Button>}
                                </div>
                            </div>
                        </div>
                    );
                }

            let listOpening = [];
            let modalDateOpening = this.state.modalDateOpen;
            operating_dates.extra_operating_dates.map((item) => {
                let start = moment(item.open, "DD/MM/YYYY HH:mm");
                let end = moment(item.close, "DD/MM/YYYY HH:mm");

                if (item.status === "open" && start.format("DD/MM/YYYY") === date_edit.format("DD/MM/YYYY")) {
                    modalDateOpening.push({open: start.format("HH:mm"), close: end.format("HH:mm")});
                }
            });
            for (let i in modalDateOpening) {
                let date = modalDateOpening[i];
                if (date === undefined)
                    continue;
                listOpening.push(<div className={"row"}>
                    <div className={"form-group col-md-4"}>
                        <label>Apertura</label>
                        <p>{date.open}</p>
                    </div>
                    <div className={"form-group col-md-4"}>
                        <label>Chiusura</label>
                        <p>{date.close}</p>
                    </div>
                    <div className={"col-md-4"}>
                        <div style={{marginTop: "auto"}}>
                            <Button color={"danger"} onClick={() => {
                                for (let index in operating_dates.extra_operating_dates) {
                                    let item = operating_dates.extra_operating_dates[index];
                                    let start = moment(item.open, "DD/MM/YYYY HH:mm");
                                    let close = moment(item.close, "DD/MM/YYYY HH:mm");

                                    if (item.status === "open" && start.format("DD/MM/YYYY") === date_edit.format("DD/MM/YYYY")) {
                                        if (start.format("HH:mm") === date.open && close.format("HH:mm") === date.close) {
                                            delete operating_dates.extra_operating_dates[index];
                                        }
                                    }
                                }
                                delete modalDateOpening[i];
                                this.setState({modalDateOpen: modalDateOpening, operating_dates: operating_dates});
                            }}>Rimuovi</Button>
                        </div>
                    </div>
                </div>);
            }

            return <Modal
                isOpen={this.state.modalDateClose}
                backdrop="static"
                keyboard={false}>
                <ModalHeader>Modifica giorno {date_edit.format("DD/MM/YYYY")}</ModalHeader>
                <ModalBody>
                    <p>Orari di lavoro</p>
                    {listWorking}
                    <hr/>
                    <p>Orari di apertura</p>
                    {listOpening}
                    <div className={"row"}>
                        <div className={"form-group col-md-4"}>
                            <label>Apertura</label>
                            <InputMask className="form-control"
                                       mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                                       value={this.state.form_modal_date.open}
                                       onChange={(e) => {
                                           let value = e.target.value;
                                           value = value.replace("_", "0")
                                           let hours = value.split(":");
                                           if (parseInt(hours[0]) >= 24)
                                               value = "23:" + hours[1];
                                           let form = this.state.form_modal_date;
                                           form.open = value;
                                           this.setState({form_modal_date: form});
                                       }}
                                       placeholder={"Apertura"}/>
                        </div>
                        <div className={"form-group col-md-4"}>
                            <label>Chiusura</label>
                            <InputMask className="form-control"
                                       mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                                       value={this.state.form_modal_date.close}
                                       onChange={(e) => {
                                           let value = e.target.value;
                                           value = value.replace("_", "0")
                                           let hours = value.split(":");
                                           if (parseInt(hours[0]) >= 24)
                                               value = "23:" + hours[1];
                                           let form = this.state.form_modal_date;
                                           form.close = value;
                                           this.setState({form_modal_date: form});
                                       }}
                                       placeholder={"Chiusura"}/>
                        </div>
                        <div className={"col-md-4"}>
                            <div style={{marginTop: "auto"}}>
                                <Button color={"success"} onClick={() => {
                                    let form = this.state.form_modal_date;
                                    modalDateOpening.push({open: form.open, close: form.close});
                                    this.setState({modalDateOpen: modalDateOpening});
                                }}>Aggiungi</Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                            onClick={() => this.setState({modalDateClose: false})}>Annulla</Button>{' '}
                    <Button color="primary" onClick={this.saveModal}>Salva</Button>
                </ModalFooter>
            </Modal>
        }
    }

    convertSlotValue = (value) => {
        if (value === null)
            return "00:00";
        let minutes = 0, hours = 0;
        if (value / 60 > 0) {
            minutes = parseInt(value / 60, 10);
        }
        if (minutes / 60 > 0) {
            hours = parseInt(minutes / 60, 10);
            minutes = minutes % 60;
        }
        return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Giorni di lavoro"/>
                        <Card>
                            <CardBody>
                                <div className="text-center mb-4">
                                    <ButtonGroup>
                                        <Button
                                            onClick={() => this.showTable('AREA')}
                                            color={(this.state.showTable === 'AREA') ? "light" : "primary"}>Area</Button>
                                        <Button
                                            onClick={() => this.showTable('MICRO')}
                                            color={(this.state.showTable === 'MICRO') ? "light" : "primary"}>MicroArea</Button>
                                        <Button
                                            onClick={() => this.showTable('TICKET')}
                                            color={(this.state.showTable === 'TICKET') ? "light" : "primary"}>Ticket</Button>
                                    </ButtonGroup>
                                </div>
                                <div style={{height: '40vh', width: '100%'}} hidden={this.state.showTable !== 'AREA'}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        pagination
                                        pageSize={this.state.paginator.limit}
                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                        rowCount={this.state.paginator.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageChange}
                                        loading={this.state.paginator.loading}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div style={{height: '40vh', width: '100%'}} hidden={this.state.showTable !== 'MICRO'}>
                                    <DataGrid
                                        rows={this.state.rowsMicroArea}
                                        columns={this.state.columnsMicroArea}
                                        pageSize={Object.keys(this.state.rowsMicroArea).length}
                                        rowsPerPageOptions={[Object.keys(this.state.rowsMicroArea).length]}
                                        rowCount={Object.keys(this.state.rowsMicroArea).length}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div style={{height: '40vh', width: '100%'}} hidden={this.state.showTable !== 'TICKET'}>
                                    <DataGrid
                                        rows={this.state.rowsTicket}
                                        columns={this.state.columnsTicket}
                                        pagination
                                        pageSize={this.state.paginatorTicket.limit}
                                        rowsPerPageOptions={[this.state.paginatorTicket.limit]}
                                        rowCount={this.state.paginatorTicket.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageTicketChange}
                                        loading={this.state.paginatorTicket.loading}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div className={"row"}>
                                    {this.state.area !== null ? <div className={"col-md-4"}>
                                            <Card body>
                                                <CardTitle tag="h5">Area</CardTitle>
                                                <CardText>Nome area: {this.state.area.name}<br/> {this.state.area.address},
                                                    n°{this.state.area.number} {this.state.area.city} {this.state.area.region}
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.micro_area !== null ? <div className={"col-md-4"}>
                                            <Card body>
                                                <CardTitle tag="h5">Micro Area</CardTitle>
                                                <CardText>Nome micro area: {this.state.micro_area.name}</CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.ticket !== null ? <div className={"col-md-4"}>
                                            <Card body>
                                                <CardTitle tag="h5">Ticket</CardTitle>
                                                <CardText>Nome
                                                    ticket: {this.state.ticket.name}<br/> {this.state.ticket.price},
                                                    {this.state.ticket.date_start_validity} {this.state.ticket.date_end_validity} {this.state.ticket.type}
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h4>Giorni di lavoro</h4>
                                <div className={"row"}>
                                    <div className="form-group col-md-12" align={"center"}>
                                        <ButtonGroup>
                                            <Button
                                                className={typeof this.state.dates_week["monday"] !== "undefined" ? "btn-success" : "btn-primary"}
                                                onClick={() => this.setOperatingDate("monday")}>Lun</Button>
                                            <Button
                                                className={typeof this.state.dates_week["tuesday"] !== "undefined" ? "btn-success" : "btn-primary"}
                                                onClick={() => this.setOperatingDate("tuesday")}>Mar</Button>
                                            <Button
                                                className={typeof this.state.dates_week["wednesday"] !== "undefined" ? "btn-success" : "btn-primary"}
                                                onClick={() => this.setOperatingDate("wednesday")}>Mer</Button>
                                            <Button
                                                className={typeof this.state.dates_week["thursday"] !== "undefined" ? "btn-success" : "btn-primary"}
                                                onClick={() => this.setOperatingDate("thursday")}>Gio</Button>
                                            <Button
                                                className={typeof this.state.dates_week["friday"] !== "undefined" ? "btn-success" : "btn-primary"}
                                                onClick={() => this.setOperatingDate("friday")}>Ven</Button>
                                            <Button
                                                className={typeof this.state.dates_week["saturday"] !== "undefined" ? "btn-success" : "btn-primary"}
                                                onClick={() => this.setOperatingDate("saturday")}>Sab</Button>
                                            <Button
                                                className={typeof this.state.dates_week["sunday"] !== "undefined" ? "btn-success" : "btn-primary"}
                                                onClick={() => this.setOperatingDate("sunday")}>Dom</Button>
                                        </ButtonGroup>
                                    </div>
                                    <div className="col-md-12">
                                        <hr/>
                                    </div>
                                    <div className={"col-md-12"}>
                                        {this.renderDates()}
                                    </div>
                                </div>
                                <div className="text-center mt-4">
                                    <Button type="submit" color="success"
                                            onClick={() => this.handleValidSubmit()}>Modifica</Button>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>Valore di uno slot</label>
                                        <InputMask className="form-control"
                                                   mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                                                   value={this.convertSlotValue(this.state.form_edit.slot_value)}
                                                   onChange={(e) => {
                                                       let value = e.target.value;
                                                       value = value.replace("_", "0")
                                                       let hours = value.split(":");
                                                       if (parseInt(hours[0]) >= 24)
                                                           value = "23:" + hours[1];

                                                       hours = value.split(":");
                                                       let time = parseInt(hours[0]) * 60 * 60 + parseInt(hours[1]) * 60;
                                                       let form = this.state.form_edit;
                                                       form.slot_value = time;
                                                       this.setState({form_edit: form});
                                                   }}
                                                   placeholder={"Valore di uno slot"}/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Numero massimo di prenotazioni contemporanee</label>
                                        <Input name="maximum_booking_slot"
                                               value={this.state.form_edit.maximum_booking_slot}
                                               className="form-control"
                                               placeholder="Numero massimo di prenotazioni contemporanee"
                                               type="number" required
                                               onChange={(e) => {
                                                   let form = this.state.form_edit;
                                                   form.maximum_booking_slot = e.target.value;
                                                   this.setState({form_edit: form});
                                               }}/>
                                    </div>
                                </div>
                                <div className="text-center mt-4">
                                    <Button type="submit" color="success" onClick={() => {
                                        this.submitSlot()
                                    }}>Salva</Button>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h4>Calendario chiusure e aperture</h4>
                                {this.renderCalendar()}
                            </CardBody>
                        </Card>
                    </Container>
                    {this.renderModalDateClose()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(OperatingDatesPage));
