import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    ButtonGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, ListGroupItem
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {withTranslation} from 'react-i18next';

import {
    getAdmin,
    setAdmin,
    editAdmin,
    deleteAdmin,
    apiError,
    adminAssociationSocietyDelete, getSocieties, listAdsArea, adminAssociationSocietyAdd
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Nome", field: "firstname", width: 240},
                {headerName: "Cognome", field: "lastname", width: 240},
                {headerName: "Email", field: "email", width: 240},
                {
                    headerName: "Ruolo",
                    field: "role",
                    width: 200,
                    renderCell: (params) => {
                        switch (params.row.role) {
                            case "ROLE_ADMIN":
                                return <span>Admin</span>;
                            case "ROLE_SUPERUSER":
                                return <span>Super Utente</span>;
                            case "ROLE_MANAGER_SOCIETY":
                                return <span>Gestore enti</span>;
                        }
                    }
                },
                {
                    headerName: " ",
                    width: 160,
                    field: 'id',
                    renderCell: (params) => (
                        <ButtonGroup>
                            <Button onClick={() => this.actionButton(params)} type="button"
                                    color="warning">Modifica</Button>
                            <Button onClick={() => this.deleteAdmin({id: params.id})} type="button"
                                    color="danger">Elimina</Button>
                        </ButtonGroup>
                    )
                },
                {
                    headerName: " ",
                    width: 140,
                    field: 'id1',
                    renderCell: (params) => {
                        let list_societies = params.getValue(params.id, "list_manage_societies");
                        if (typeof list_societies !== "undefined")
                            return <ButtonGroup>
                                <Button onClick={() => this.showModalSocieties(params.row, list_societies)}
                                        type="button"
                                        color="primary">Società gestite</Button>
                            </ButtonGroup>
                    }
                }
            ],
            columnsSociety: [
                {headerName: "Nome ente", field: "name", width: 180},
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Provincia", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 160},
                {headerName: "CAP", field: "cap", width: 120},
            ],
            rows: [],
            rowsSociety: [],
            form_edit: {
                id: null,
                firstname: null,
                lastname: null,
                email: null,
                role: null,
            },
            paginator: {loading: true, countRows: 0, limit: 50, page: 0},
            paginatorSociety: {loading: true, countRows: 0, limit: 50, page: 0},
            flag_edit: false,
            modal: false,
            modalSocieties: false,
            dataModalSocieties: {user: null, list_societies: []}

        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    showModalSocieties = (user, list_societies) => {
        this.setState({modal: true, dataModalSocieties: {user: user, list_societies: list_societies}});
    }

    deleteAdmin = (id) => {
        deleteAdmin(id).then(result => {
            if (!result) {
                global.notify("Errore con l'eliminazione", "danger");
            } else {
                global.notify("Utente eliminato con successo", "success");
                this.loadListAdmins(this.state.paginator.page - 1);
            }
        });
    }

    handlePageChangeSociety = (params) => {
        this.loadListSocieties(params.page);
    };

    loadListSocieties = (page) => {
        let paginator = this.state.paginatorSociety;
        paginator.loadingSociety = true;
        this.setState({paginatorSociety: paginator});

        getSocieties(page + 1).then(result => {
            paginator.loadingSociety = false;
            if (!result) {
                paginator.page = 0;
                paginator.countRows = 0;
                this.setState({rowsSociety: [], paginatorSociety: paginator});
            } else {
                paginator.page += 1;
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rowsSociety: result.list, paginatorSociety: paginator});
            }
        })
    }

    loadListAdmins = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        paginator.page = page;
        this.setState({paginator: paginator});

        getAdmin(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    async handleValidSubmit(event, values, edit = false) {
        let formResult = edit ? await editAdmin(values) : await setAdmin(values);
        if (typeof formResult.user !== "undefined") {
            global.notify("Azione eseguita con successo", "success");
            this.loadListAdmins(this.state.paginator.page - 1);
            this.form && this.form.reset();
            this.setState({flag_edit: false});
        } else if (typeof formResult.error !== 'undefined')
            global.notify("Errore: " + formResult.error, "danger");
        else
            global.notify("Errore generico", "danger");
        window.scrollTo(0, 0);
    }

    actionButton = (params) => {
        this.setState({form_edit: params.row, flag_edit: true});
    }

    handlePageChange = (params) => {
        this.loadListAdmins(params.page)
    };

    async componentDidMount() {
        this.loadListAdmins(0);
        this.loadListSocieties(0);
    }

    deleteAssociation = async (id_admin, id_society) => {
        let formResult = await adminAssociationSocietyDelete({id_user: id_admin, id_society: id_society});
        if (typeof formResult.user !== 'undefined') {
            global.notify("Società rimossa con successo", "success");
            this.setState({
                dataModalSocieties: {
                    user: formResult.user,
                    list_societies: formResult.user.list_manage_societies
                }
            })
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con la rimozione: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    handleRowSelected = async (rows) => {
        let row = [...rows.entries()][0][1];
        let formResult = await adminAssociationSocietyAdd({
            id_user: this.state.dataModalSocieties.user.id,
            id_society: row.id
        });
        if (typeof formResult.user !== 'undefined') {
            global.notify("Società aggiunta con successo", "success");
            this.setState({
                modalSocieties: false,
                dataModalSocieties: {
                    user: formResult.user,
                    list_societies: formResult.user.list_manage_societies
                }
            })
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con la rimozione: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    renderModalSocieties = () => {
        return <Modal
            isOpen={this.state.modalSocieties}
            backdrop="static"
            keyboard={false}>
            <ModalHeader>Seleziona l'ente da associare</ModalHeader>
            <ModalBody>
                <div style={{height: 400, width: '100%'}}>
                    <DataGrid
                        rows={this.state.rowsSociety}
                        columns={this.state.columnsSociety}
                        pagination
                        pageSize={this.state.paginatorSociety.limit}
                        rowsPerPageOptions={[this.state.paginatorSociety.limit]}
                        rowCount={this.state.paginatorSociety.countRows}
                        paginationMode="server"
                        onPageChange={this.handlePageChangeSociety}
                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                        loading={this.state.paginatorSociety.loadingSociety}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary"
                        onClick={() => this.setState({modalSocieties: false}, () => this.loadListSocieties(0))}>Chiudi</Button>
            </ModalFooter>
        </Modal>
    }

    renderModal = () => {
        if (!this.state.modal)
            return;
        let user = this.state.dataModalSocieties.user;
        let list_societies = this.state.dataModalSocieties.list_societies;
        let list = [];
        for (let i in list_societies) {
            let society = list_societies[i].society;
            list.push(<ListGroupItem>
                {society.name}
                <div style={{float: "right"}}>
                    <Button color="danger" size="sm"
                            onClick={() => this.deleteAssociation(user.id, society.id)}>Elimina</Button>
                </div>
            </ListGroupItem>);
        }
        if (Object.keys(list_societies).length === 0)
            list = <ListGroupItem>Non ci sono enti associati all'utente</ListGroupItem>
        return <Modal
            isOpen={this.state.modal}
            backdrop="static"
            keyboard={false}>
            <ModalHeader>Enti gestiti dall'utente {user.email}</ModalHeader>
            <ModalBody>
                <div align={"center"}>
                    <Button color="success" onClick={() => this.setState({modalSocieties: true})}>Aggiungi
                        ente</Button>
                </div>
                <hr/>
                <p>Lista società gestite</p>
                {list}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary"
                        onClick={() => this.setState({modal: false}, () => this.loadListAdmins(this.state.paginator.page - 1))}>Chiudi</Button>
            </ModalFooter>
        </Modal>
    }

    render() {
        return (<React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Gestione utenti amministratori"/>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <h5>Lista amministratori</h5>
                                        <div style={{height: 400, width: '100%'}}>
                                            <DataGrid
                                                rows={this.state.rows}
                                                columns={this.state.columns}
                                                pagination
                                                pageSize={this.state.paginator.limit}
                                                rowsPerPageOptions={[this.state.paginator.limit]}
                                                rowCount={this.state.paginator.countRows}
                                                paginationMode="server"
                                                onPageChange={this.handlePageChange}
                                                loading={this.state.paginator.loadingFishes}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <h5>{(this.state.flag_edit) ? "Modifica amministratore" : "Aggiunta amministratore"}</h5>
                                <hr/>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    <div className={"row"}>
                                        {(this.state.flag_edit) ?
                                            <div className="form-group" hidden>
                                                <AvField name="id" label="Admin id" value={this.state.form_edit.id}
                                                         className="form-control" placeholder="Admin id" type="text"
                                                         required/>
                                            </div> : ""
                                        }
                                        <div className="form-group col-md-12">
                                            <AvField name="email" label="Email" value={this.state.form_edit.email}
                                                     className="form-control" placeholder="Email" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="firstname" label="Nome"
                                                     value={this.state.form_edit.firstname} className="form-control"
                                                     placeholder="Nome" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="lastname" label="Cognome"
                                                     value={this.state.form_edit.lastname} className="form-control"
                                                     placeholder="Cognome" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <AvField type="select" name="role" label="Ruolo"
                                                     value={this.state.form_edit.role} required>
                                                <option disabled selected>Ruolo</option>
                                                <option value={"ROLE_ADMIN"}>Admin</option>
                                                <option value={"ROLE_SUPERUSER"}>Super Utente</option>
                                                <option value={"ROLE_MANAGER_SOCIETY"}>Gestore enti</option>
                                            </AvField>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="submit"
                                                color={(this.state.flag_edit) ? "danger" : "success"}>{(this.state.flag_edit) ? "Modifica" : "Crea Admin"}</Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Container>
                    {this.renderModal()}
                    {this.renderModalSocieties()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(
    connect(
        mapStatetoProps, {getAdmin, setAdmin, editAdmin, deleteAdmin, apiError}
    )
    (Admin));