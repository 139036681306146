import {put} from 'redux-saga/effects';
import {apiCall} from '../../../helpers/backend_helper';

export const getClients = async (search = null, page = 1) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let data = {page: page};
        if (search !== null)
            data.search = search;
        let response = await apiCall({method: 'POST', url: 'admin/client/list', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getSubscriptionClients = async (search = null, page = 1) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let data = {page: page};
        if (search !== null)
            data.search = search;
        let response = await apiCall({method: 'POST', url: 'admin/client/subscription/list', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setClient = async (admin) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'client/register', data: admin});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const clientSubscriptionSuspend = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/client/subscription/suspend', data: {id: id}});
    } catch (error) {
        return false;
    }
}
export const infoClient = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/user/client/info', data: {id: id}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editClient = async (client) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'client/edit', data: client});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const banClient = async (client) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/client/ban', data: client});
    } catch (error) {
        return false;
    }
}

export const suspendSubscription = async (client) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/client/subscription/suspend', data: client});
    } catch (error) {
        return false;
    }
}

export const generateLinkPassword = async (client) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/client/password/recovery', data: client});
    } catch (error) {
        return false;
    }
}

export const unbanClient = async (client) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/client/unban', data: client});
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}