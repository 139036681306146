import {takeEvery, fork, put, all, call} from 'redux-saga/effects';

// Login Redux States
import {LOGIN_USER, LOGOUT_USER} from './actionTypes';
import {loginSuccess, logoutUserSuccess, apiError} from './actions';

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from '../../../helpers/firebase_helper';

import {apiCall} from '../../../helpers/backend_helper';
import {Redirect} from "react-router-dom";
import React from "react";

function* loginUser({payload: {user, history}}) {
    try {
        const response = yield call(apiCall, {
            method: 'POST',
            url: 'user/login',
            data: {email: user.email, password: user.password}
        });
        if (typeof response.user !== 'undefined') {
            localStorage.setItem("authUser", JSON.stringify(response));
            yield put(loginSuccess(response));
            window.location.href = '/dashboard';
        } else {
            yield put(apiError(response.error));
        }
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({payload: {history}}) {
    try {
        const response = yield call(apiCall, {method: 'POST', url: 'user/logout'});
        localStorage.removeItem("authUser");
        window.location.href = '/login';
    } catch (error) {
        yield put(apiError(error));
    }
}


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;