import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Modal,
    ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {getSocietyClients, apiError, banSocietyClients, unbanSocietyClients} from '../../store/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';
import "./society.scss";
import DatePicker from "react-datepicker";

var moment = require('moment');

class SocClientPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "Email",
                    field: "email",
                    width: 180
                },
                {
                    headerName: "Nome",
                    field: "firstname",
                    width: 160
                },
                {
                    headerName: "Cognome",
                    field: "lastname",
                    width: 160
                },
                {
                    headerName: "C.F.",
                    field: "fiscal_code",
                    width: 180,
                },
                {
                    headerName: "Telefono",
                    field: "phone",
                    width: 160
                },
                {
                    headerName: "Indirizzo",
                    field: "address",
                    width: 160
                },
                {
                    headerName: "N°",
                    field: "number",
                    width: 120
                },
                {
                    headerName: "Città",
                    field: "city",
                    width: 140
                },
                {
                    headerName: "CAP",
                    field: "cap",
                    width: 140
                },
                {
                    headerName: " ",
                    width: 140,
                    field: 'id',
                    renderCell: (params) => {
                        if (params.getValue(params.id, "is_banned"))
                            return <Button onClick={() => this.deleteClient(params.row)} type="button"
                                           color="primary">Riabilita</Button>
                        else
                            return <Button onClick={() => this.deleteClient(params.row)} type="button"
                                           color="danger">Sospendi</Button>
                    }
                }
            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            modal: false,
            user: null,
            reason: null,
            date: null,
        };
    }

    deleteClient = (user) => {
        console.log("user")
        console.log(user)
        this.setState({modal: true, user: user});
    }

    loadListClients = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getSocietyClients(page + 1).then(result => {
            paginator.loading = false;

            if (!result) {
                paginator.page = 0;
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.page += 1;
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        });
    }

    handlePageChange = (params) => {
        this.loadListClients(params.page);
    };

    async componentDidMount() {
        this.loadListClients(0);
    }

    handleClose = () => this.setState({modal: false});

    ban = () => {
        let data = {id: this.state.user.id};
        if (this.state.reason !== null)
            data.reason = this.state.reason;
        if (this.state.date !== null)
            data.date = moment.parseZone(this.state.date).local().format("DD/MM/YYYY")

        banSocietyClients(data).then((result) => {
            if (typeof result.error === 'undefined') {
                global.notify('Azione eseguita con successo', "success");
                this.setState({user: null, reason: null, date: null, modal: false});
                this.loadListClients(0);
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    unban = () => {
        unbanSocietyClients({id: this.state.user.id}).then((result) => {
            if (typeof result.error === 'undefined') {
                global.notify('Azione eseguita con successo', "success");
                this.setState({user: null, reason: null, date: null, modal: false});
                this.loadListClients(0);
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    renderModal = () => {
        let content = null;
        let user = this.state.user;
        if (user === null)
            return;
        if (!user.is_banned)
            content = <Form>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="reason" className="mr-sm-2">Motivazione</Label>
                    <Input type="text" name="reason" id="reason" placeholder={"Motivazione della sospensione"}
                           value={this.state.reason} onChange={(e) => this.setState({reason: e.target.value})}/>
                </FormGroup>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="reason" className="mr-sm-2">Data fine sospensione (lasciare il campo vuoto se si vuole
                        bloccare l'utente per sempre)</Label>
                    <DatePicker selected={this.state.date}
                                placeholder={"Data fine sospensione"}
                                startDate={this.state.date}
                                minDate={new Date()}
                                className="form-control"
                                onChange={(date) => this.setState({date: date})}
                                dateFormat="dd/MM/yyyy"
                    />
                </FormGroup>
                <div align={"center"} className={"mt-2"}>
                    <Button type="button" color={"danger"} onClick={this.ban}>Sospendi</Button>
                </div>
            </Form>
        else {
            content = <div><p><b>Motivazione:</b> {user.reason_ban === null ? "Nessuna" : user.reason_ban}</p><p><b>Data
                scadenza
                sospensione:</b> {user.date_to_unban === null ? "Per sempre" : user.date_to_unban}</p>
                <div align={"center"} className={"mt-2"}>
                    <Button type="button" color={"danger"} onClick={this.unban}>Riattiva</Button>
                </div>
            </div>
        }

        return <Modal isOpen={this.state.modal} backdrop="static" keyboard={false}>
            <ModalHeader>Sospendi utente {user.firstname} {user.lastname}</ModalHeader>
            <ModalBody>
                {content}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.handleClose}>Chiudi</Button>
            </ModalFooter>
        </Modal>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Clienti"/>
                        <Card>
                            <CardBody>
                                <div style={{height: '80vh', width: '100%'}}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        pagination
                                        pageSize={this.state.paginator.limit}
                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                        rowCount={this.state.paginator.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageChange}
                                        loading={this.state.paginator.loadingFishes}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                        {this.renderModal()}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(
    connect(
        mapStatetoProps, {getSocietyClients, apiError}
    )
    (SocClientPage));