import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import accessToken from "../jwt-token-access/accessToken";

let users = [
    {
        id: 3,
        login: "admin",
        firstName: "Administrator",
        lastName: "Administrator",
        password: 'ciccificci',
        email: 'geoticket@geo.it',
        imageUrl: "",
        activated: true,
        langKey: "en",
        createdBy: "system",
        createdDate: null,
        lastModifiedBy: "system",
        lastModifiedDate: null,
        authorities: [
            "ROLE_USER",
            "ROLE_ADMIN"
        ],
        token: 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTYwODExMTM0Nn0.tVgXR384htTLE3uU_uQMw4knE59FQ_6nfdQJ0T6cSY8vkcuxifISdbtOATD5cpn0jQdDy3k7xRTYk0sXFoHPsg'
    }
];

let campaings = [
    {
        id: 1,
        name: "Campagna di prova",
        description: "Campagna di prova descrizione",
        segments: []
    },
    {
        id: 2,
        name: "Campagna di altro",
        description: "Campagna di altro descrizione",
        segments: []
    }
];

const fakeBackend = () => {
    // This sets the mock adapter on the default instance
    var mock = new MockAdapter(axios);

    mock.onPost('/post-fake-register').reply(function (config) {

        const user = JSON.parse(config['data']);
        users.push(user);

        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                resolve([200, user]);
            });
        });
    });

    mock.onPost('/authenticate').reply(function (config) {

        const user = JSON.parse(config['data']);
        const validUser = users.filter(usr => usr.email === user.email && usr.password === user.password);

        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                if (validUser['length'] === 1) {
                    var token = accessToken;
                    const tokenObj = {idToken: token};
                    resolve([200, tokenObj]);
                } else {
                    reject([400, "Username and password are invalid. Please enter correct username and password"]);
                }
            });
        });
    });

    mock.onPost('/account').reply(function (config) {

        const idToken = config['data'];
        const validUser = users.filter(usr => usr.token === idToken);

        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                if (validUser['length'] === 1) {
                    resolve([200, validUser[0]]);
                } else {
                    reject([400, "Username and password are invalid. Please enter correct username and password"]);
                }
            });
        });
    });

    mock.onPost('/campaigns').reply(function (config) {

        const campaing = JSON.parse(config['data']);
        const last = campaings[campaings.length - 1];
        campaing.id = last.id + 1;
        campaings.push(campaing);

        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                resolve([200, campaing]);
            });
        });
    });

    mock.onGet('/campaigns').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                resolve([200, campaings]);
            });
        });
    });


    mock.onPost('/fake-forget-pwd').reply(function (config) {
        // User needs to check that user is eXist or not and send mail for Reset New password

        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                resolve([200, "Check you mail and reset your password."]);
            });
        });

    });


    mock.onPost('/post-jwt-register').reply(function (config) {

        const user = JSON.parse(config['data']);
        users.push(user);

        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                resolve([200, user]);
            });
        });
    });

    mock.onPost('/post-jwt-login').reply(function (config) {

        const user = JSON.parse(config['data']);
        const validUser = users.filter(usr => usr.email === user.email && usr.password === user.password);

        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                if (validUser['length'] === 1) {

                    // You have to generate AccessToken by jwt. but this is fakeBackend so, right now its dummy
                    var token = accessToken;

                    // JWT AccessToken
                    const tokenObj = {accessToken: token};    // Token Obj
                    const validUserObj = {...validUser[0], ...tokenObj};    // validUser Obj

                    resolve([200, validUserObj]);
                } else {
                    reject([400, "Username and password are invalid. Please enter correct username and password"]);
                }
            });
        });
    });


    mock.onPost('/post-jwt-profile').reply(function (config) {

        const user = JSON.parse(config['data']);

        const one = config.headers;

        let finalToken = one.Authorization;

        const validUser = users.filter(usr => usr.id === user.idx);

        return new Promise(function (resolve, reject) {

            setTimeout(function () {

                // Verify Jwt token from header.Authorization
                if (finalToken === accessToken) {
                    if (validUser['length'] === 1) {

                        let objIndex;

                        //Find index of specific object using findIndex method.
                        objIndex = users.findIndex((obj => obj.id === user.idx));

                        //Update object's name property.
                        users[objIndex].firstName = user.firstName;

                        // Assign a value to locastorage
                        localStorage.removeItem("authUser");
                        localStorage.setItem("authUser", JSON.stringify(users[objIndex]));

                        resolve([200, "Profile Editted successfully"]);

                    } else {
                        reject([400, "Something wrong for edit profile"]);
                    }
                } else {
                    reject([400, "Invalid Token !!"]);
                }
            });
        });
    });


    mock.onPost('/post-fake-profile').reply(function (config) {

        const user = JSON.parse(config['data']);

        const validUser = users.filter(usr => usr.id === user.idx);

        return new Promise(function (resolve, reject) {
            setTimeout(function () {

                if (validUser['length'] === 1) {


                    let objIndex;

                    //Find index of specific object using findIndex method.
                    objIndex = users.findIndex((obj => obj.id === user.idx));

                    //Update object's name property.
                    users[objIndex].firstName = user.firstName;

                    // Assign a value to locastorage
                    localStorage.removeItem("authUser");
                    localStorage.setItem("authUser", JSON.stringify(users[objIndex]));

                    console.log(localStorage.getItem("authUser"));

                    resolve([200, "Profile Editted successfully"]);

                } else {
                    reject([400, "Something wrong for edit profile"]);
                }

            });
        });
    });


    mock.onPost('/jwt-forget-pwd').reply(function (config) {
        // User needs to check that user is eXist or not and send mail for Reset New password

        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                resolve([200, "Check you mail and reset your password."]);
            });
        });

    });

}

export default fakeBackend;