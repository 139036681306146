import React, {Component} from "react";
import {Card, CardBody, Container, Button, CardTitle, CardText} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {infoTicket, shareTicket} from "../../store/manager_society/actions";

import Breadcrumb from '../../components/Common/Breadcrumb';

var moment = require('moment');

class TicketSharePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            rowsMicroArea: [],
            form_edit: {
                date_start_use: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
                date_end_use: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
                email: null,
                nickname: null,
            },
            date_start: new Date(),
            date_end: new Date(),
            date_current: new Date(),
            flag_edit: false,
            area: null,
            micro_area: null,
            ticket: null,
            extra_data: {}
            // ticket: {
            //     id_area: null,
            //     id_micro_area: null,
            //     id_ticket: null,
            //     name: null,
            //     price: null,
            //     date_start_validity: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
            //     date_end_validity: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
            //     type: "DAILY",
            // }
        };
    }

    async handleValidSubmit(event, values, edit = false) {
        console.log("values")
        console.log(values)
        console.log(this.state.extra_data)
        values.id_ticket = this.state.ticket.id;
        values.date_start_use = this.state.form_edit.date_start_use;
        values.date_end_use = this.state.form_edit.date_end_use;
        let arr = [];
        this.state.extra_data.map(data => {
            if (data.value !== null)
                arr.push( {id: data.id, value: data.value});
        })
        values.arr_extra_data = JSON.stringify(arr);
        let formResult = await shareTicket(values);

        if (typeof formResult.ticket !== 'undefined') {
            global.notify("Ticket condiviso con successo", "success");
            this.form && this.form.reset();
            this.form1 && this.form1.reset();
            let extra_data = [];
            this.state.ticket.extra_data.map((item) => {
                extra_data.push({id: item.id, value: null, name: item.name, type: item.type, options: item.options});
            });
            this.setState({
                extra_data: extra_data,
                form_edit: {
                    date_start_use: moment.parseZone(this.state.date_start).local().format("DD/MM/YYYY"),
                    date_end_use: moment.parseZone(this.state.date_end).local().format("DD/MM/YYYY"),
                    email: null,
                    nickname: null,
                }
            });
            console.log({ form_edit: {
                    date_start_use: moment.parseZone(this.state.date_start).local().format("DD/MM/YYYY"),
                    date_end_use: moment.parseZone(this.state.date_end).local().format("DD/MM/YYYY"),
                    email: null,
                    nickname: null,
                }})
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con l'aggiunta: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico ", "danger");
        }
    }

    async componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined" && this.props.match.params.id !== null) {
            let res = await infoTicket(this.props.match.params.id);
            if (typeof res.error !== "undefined") {
                global.notify("Errore: " + res.error, "danger");
                return;
            }
            let ticket = res.ticket;
            let extra_data = [];
            ticket.extra_data.map((item) => {
                extra_data.push({id: item.id, value: null, name: item.name, type: item.type, options: item.options});
            });
            console.log(extra_data)
            this.setState({
                area: ticket.area,
                extra_data: extra_data,
                micro_area: typeof ticket.micro_area !== "undefined" ? ticket.micro_area : null,
                ticket: ticket,
            });
        }
    }

    setDate = (date, type) => {
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start_use = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end_use = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    setExtraData = (id, value) => {
        let arr = this.state.extra_data;
        for (let i in arr) {
            if (arr[i].id === id)
                arr[i].value = value
        }

        this.setState({extra_data: arr});
    }

    renderExtraData = () => {
        if (this.state.ticket === null)
            return;
        let extra_data = this.state.extra_data;
        if (extra_data === null || Object.keys(extra_data).length === 0)
            return;

        let list = [];
        extra_data.map((data, i) => {
            switch (data.type) {
                case "STRING":
                    list.push(<div className="form-group col-md-6">
                        <label htmlFor={i}>{data.name}</label>
                        <input name={i} value={data.value}
                               className="form-control"
                               onChange={(e) => {
                                   this.setExtraData(data.id, e.target.value);
                               }}
                               placeholder={data.name} type="text"/>
                    </div>);
                    break;
                case "CHECKBOX":
                    list.push(<div className="form-group col-md-6">
                        <input type="checkbox" className="form-check-input"
                               id={i} name={data.id} checked={data.value}
                               onChange={(e) => {
                                   this.setExtraData(data.id, !data.value);
                               }}/>
                        <label className="form-check-label" htmlFor={i}>{data.name}</label>
                    </div>);
                    break;
                case "SELECT":
                    list.push(<div className="form-group col-md-6">
                        <label htmlFor={i}>{data.name}</label>
                        <select name={i} onChange={(e) => {
                            this.setExtraData(data.id, e.target.value);
                        }}
                                className="form-control" placeholder={data.name} value={data.value === null ? "" : data.value }
                                type="select">
                            <option value="" disabled={true} selected={true}>-</option>
                            {data.options.map((option) => <option value={option}>{option}</option>)}
                        </select>
                    </div>)
                    break;
                case "DATE":
                    list.push(<div className="form-group col-md-6">
                        <label htmlFor={data.id}>{data.name}</label>
                        <input name={i} hidden value={data.value}
                               className="form-control" placeholder={data.name} type="text"/>
                        <DatePicker className="form-control"
                                    placeholder={data.name}
                                    selected={data.value !== null ? moment(data.value, "DD/MM/YYYY").toDate() : null}
                                    onChange={(date) => this.setExtraData(data.id, moment.parseZone(date).local().format("DD/MM/YYYY"))}
                                    dateFormat="dd/MM/yyyy"
                        />
                    </div>);
                    break;
            }
        })

        return <>
            <hr/>
            <h5>Dati opzionali</h5>
            <form className={"row"} ref={c => (this.form1 = c)}>
                {list}</form>
        </>;

    }

    render() {
        if (this.state.ticket === null)
            return <></>;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Vendita permesso"/>
                        <Card>
                            <CardBody>
                                <div className={"row"}>
                                    <Card body>
                                        <CardTitle tag="h5">{this.state.ticket.name}</CardTitle>
                                        <CardText>Prezzo: €{this.state.ticket.price}<br/> {this.state.ticket.type}<br/>
                                            Valido
                                            dal {this.state.ticket.date_start_validity} al {this.state.ticket.date_end_validity}
                                        </CardText>
                                    </Card>
                                    {this.state.area !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Area</CardTitle>
                                                <CardText>Nome area: {this.state.area.name}<br/> {this.state.area.address},
                                                    n°{this.state.area.number} {this.state.area.city} {this.state.area.region}
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.micro_area !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Micro Area</CardTitle>
                                                <CardText>Nome micro area: {this.state.micro_area.name}</CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                </div>
                                <br/>
                                <AvForm className="form-horizontal" ref={c => (this.form = c)}
                                        onValidSubmit={(e, v,) => {
                                            this.handleValidSubmit(e, v, this.state.flag_edit)
                                        }}>
                                    <div className={"row"}>
                                        <div className="form-group col-md-4">
                                            <AvField name="date_start_use" hidden
                                                     label="Data inizio"
                                                     value={this.state.form_edit.date_start_use}
                                                     className="form-control"
                                                     placeholder="Enter start date" type="text"
                                            />
                                            <DatePicker selected={this.state.date_start}
                                                        minDate={moment(this.state.ticket.date_start_validity, "DD-MM-YYYY").toDate()}
                                                        startDate={this.state.date_current}
                                                        className="form-control"
                                                        onChange={(date) => this.setDate(date, "start")}
                                                        dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="date_end_use" hidden label="Data fine"
                                                     value={this.state.form_edit.date_end_use}
                                                     className="form-control"
                                                     placeholder="Enter end date" type="text"
                                            />
                                            <DatePicker selected={this.state.date_end}
                                                        maxDate={moment(this.state.ticket.date_end_validity, "DD-MM-YYYY").toDate()}
                                                        minDate={this.state.date_start} className="form-control"
                                                        onChange={(date) => this.setDate(date, "end")}
                                                        dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="nickname" label="Nominativo"
                                                     value={this.state.form_edit.nickname}
                                                     className="form-control" placeholder="Inserisci nominativo"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="email" label="Email" value={this.state.form_edit.email}
                                                     className="form-control" placeholder="Inserisci l'email"
                                                     type="text"/>
                                        </div>
                                    </div>
                                    {this.renderExtraData()}
                                    <div className="text-center mt-4">
                                        <Button type="submit" color="success">Invia</Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {shareTicket})(TicketSharePage));
