import {apiCall} from '../helpers/backend_helper';

export const login = async (data) => {
    try {
        return await apiCall({method: 'POST', url: 'user/login', data: data});
    } catch (error) {
        return false;
    }
}

export const passwordRecovery = async (data) => {
    try {
        return await apiCall({method: 'POST', url: 'user/password/recovery', data: data});
    } catch (error) {
        return false;
    }
}

export const permission = async () => {
    try {
        return await apiCall({method: 'POST', url: 'permission'});
    } catch (error) {
        return false;
    }
}

export const editProfile = async (data) => {
    try {
        return await apiCall({method: 'POST', url: 'user/profile', data: data});
    } catch (error) {
        return false;
    }
}

export const updatePassword = async (data) => {
    try {
        return await apiCall({method: 'POST', url: 'user/password/update', data: data});
    } catch (error) {
        return false;
    }
}