import React, {Component} from 'react';
import {Button} from "reactstrap";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {AvForm, AvField} from 'availity-reactstrap-validation';

import {loginUser, userForgetPassword, apiError} from '../../store/actions';

import background from '../../assets/images/background.jpg';
import {login, passwordRecovery, permission} from "../../store/account";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form_forgot_password: false
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    handleValidSubmit(event, values) {
        if (this.state.form_forgot_password)
            passwordRecovery({email: values.email}).then((result) => {
                if (typeof result.error !== "undefined")
                    global.notify("Errore: " + result.error, "danger");
                else {
                    global.notify("Controlla l'email per completare la procedura", "success");
                    this.form && this.form.reset();
                }
            });
        else {
            login({email: values.email, password: values.password}).then((result) => {
                if (typeof result.user !== "undefined") {
                    localStorage.setItem("authUser", JSON.stringify(result));
                    window.location.href = '/dashboard';
                    return;
                }
                if (typeof result.error !== "undefined")
                    global.notify("Errore: " + result.error, "danger");
                else
                    global.notify("Errore generico", "danger");
            });
        }
    }

    componentDidMount() {
        permission().then((result) => {
            if (typeof result.user !== "undefined") {
                localStorage.setItem("authUser", JSON.stringify(result));
                window.location.href = '/dashboard';
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className={"row"}>
                    <div className={"col-md-6 d-none d-sm-none  d-md-block "}>
                        <img src={background} style={{height: "100vh", width: "100%", opacity: 0.7}}/>
                    </div>
                    <div className={"col-md-6"}>
                        <div className={"row"} style={{height: "100vh"}}>
                            <div className={"col-md-8 col-sm-12"} style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                {this.state.form_forgot_password ?
                                    <div style={{flex: 1, padding: 16}}>
                                        <h2>Benvenuto nella dashboard di <b>Geoticket!</b></h2>
                                        <br/>
                                        <h5>Recupera la password dell'account</h5>
                                        <br/>
                                        <br/>
                                        <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}
                                                ref={c => (this.form = c)}>
                                            <div className="form-group">
                                                <AvField name="email" label="Email" className="form-control"
                                                         placeholder="Inserisci email"
                                                         type="email" required/>
                                            </div>
                                            <div className="mt-3">
                                                <button className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit">Reimposta password
                                                </button>
                                            </div>
                                        </AvForm>
                                        <div className="mt-4 text-center">
                                            <Button color="link"
                                                    onClick={() => this.setState({form_forgot_password: false})}>Torna
                                                al login</Button>
                                        </div>
                                    </div>
                                    :
                                    <div style={{flex: 1, padding: 16}}>
                                        <h2>Benvenuto nella dashboard di <b>Geoticket!</b></h2>
                                        <br/>
                                        <h5>Effettua il login per proseguire</h5>
                                        <br/>
                                        <br/>
                                        <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                                            <div className="form-group">
                                                <AvField name="email" label="Email" value=""
                                                         className="form-control" placeholder="Inserisci email"
                                                         type="email" required/>
                                            </div>

                                            <div className="form-group">
                                                <AvField name="password" label="Password" value=""
                                                         type="password"
                                                         required placeholder="Inserisci Password"/>
                                            </div>
                                            <div className="mt-3">
                                                <button
                                                    className="btn btn-primary btn-block waves-effect waves-light"
                                                    type="submit">Entra
                                                </button>
                                            </div>
                                        </AvForm>
                                        <div className="mt-4 text-center">
                                            <Button color="link"
                                                    onClick={() => this.setState({form_forgot_password: true})}><i
                                                className="mdi mdi-lock mr-1"></i> Password
                                                dimenticata?</Button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {error} = state.Login;
    return {error};
}

export default withRouter(connect(mapStatetoProps,
    {
        loginUser, userForgetPassword, apiError
    }
)(Login));

