import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal,
    FormGroup, Label, Input, Form, ButtonGroup
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {getClients, apiError, banClient, unbanClient, generateLinkPassword} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import DatePicker from "react-datepicker";
import {windowOpen} from "echarts/src/util/format";
import {AvField, AvForm} from "availity-reactstrap-validation";

var moment = require('moment');

class Client extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Email", field: "email", width: 180},
                {headerName: "Nome", field: "firstname", width: 160},
                {headerName: "Cognome", field: "lastname", width: 160},
                {headerName: "Abbonamento", field: "premium", width: 180, renderCell: (params)=>{
                    if(params.value !== null)
                        return "Premium"
                    }},
                {headerName: "C.F.", field: "fiscal_code", width: 180},
                {headerName: "Telefono", field: "phone", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 140},
                {headerName: "CAP", field: "cap", width: 140},
                {
                    headerName: " ",
                    width: 340,
                    field: 'id',
                    renderCell: (params) =>
                        <ButtonGroup>
                            <Button onClick={() => window.location.href = "/client_info/" + params.row.id}
                                    type="button"
                                    color="primary">Scheda</Button>
                            <Button onClick={() => this.setState({user: params.row, modalPassword: true})} type="button"
                                    color="info">Password</Button>
                            {(params.getValue(params.id, "is_banned")) ?
                                <Button onClick={() => this.deleteClient(params.row)} type="button"
                                        color="primary">Riabilita</Button> :
                                <Button onClick={() => this.deleteClient(params.row)} type="button"
                                        color="danger">Sospendi</Button>}
                        </ButtonGroup>
                }
            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            modal: false,
            modalPassword: false,
            user: null,
            reason: null,
            date: null,
            search: null
        };
    }

    async handleValidSubmit(event, values) {
        let search = null;
        if (values.search !== "" && values.search !== null)
            search = values.search;
        this.setState({search: search}, () => this.loadListClients(0));
    }

    deleteClient = (user) => {
        this.setState({modal: true, user: user});
    }

    loadListClients = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getClients(this.state.search, page + 1).then(result => {
            paginator.loading = false;

            if (!result) {
                paginator.page = 0;
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.page += 1;
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        });
    }

    handlePageChange = (params) => {
        this.loadListClients(params.page);
    };

    async componentDidMount() {
        this.loadListClients(0);
    }

    handleClose = () => this.setState({modal: false, modalPassword: false, user: null, link: null});

    generateLinkPassword = () => {
        let data = {id: this.state.user.id};

        generateLinkPassword(data).then((result) => {
            if (typeof result.error === 'undefined') {
                global.notify('Azione eseguita con successo', "success");
                this.setState({link: result.link});
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    ban = () => {
        let data = {id: this.state.user.id};
        if (this.state.reason !== null)
            data.reason = this.state.reason;
        if (this.state.date !== null)
            data.date = moment.parseZone(this.state.date).local().format("DD/MM/YYYY")

        banClient(data).then((result) => {
            if (typeof result.error === 'undefined') {
                global.notify('Azione eseguita con successo', "success");
                this.setState({user: null, reason: null, date: null, modal: false});
                this.loadListClients(0);
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    unban = () => {
        unbanClient({id: this.state.user.id}).then((result) => {
            if (typeof result.error === 'undefined') {
                global.notify('Azione eseguita con successo', "success");
                this.setState({user: null, reason: null, date: null, modal: false});
                this.loadListClients(0);
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    renderModal = () => {
        let content = null;
        let user = this.state.user;
        if (user === null)
            return;
        if (!user.is_banned)
            content = <Form>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="reason" className="mr-sm-2">Motivazione</Label>
                    <Input type="text" name="reason" id="reason" placeholder={"Motivazione della sospensione"}
                           value={this.state.reason} onChange={(e) => this.setState({reason: e.target.value})}/>
                </FormGroup>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="reason" className="mr-sm-2">Data fine sospensione (lasciare il campo vuoto se si vuole
                        bloccare l'utente per sempre)</Label>
                    <DatePicker selected={this.state.date}
                                placeholder={"Data fine sospensione"}
                                startDate={this.state.date}
                                minDate={new Date()}
                                className="form-control"
                                onChange={(date) => this.setState({date: date})}
                                dateFormat="dd/MM/yyyy"
                    />
                </FormGroup>
                <div align={"center"} className={"mt-2"}>
                    <Button type="button" color={"danger"} onClick={this.ban}>Sospendi</Button>
                </div>
            </Form>
        else {
            content = <div><p><b>Motivazione:</b> {user.reason_ban === null ? "Nessuna" : user.reason_ban}</p><p><b>Data
                scadenza
                sospensione:</b> {user.date_to_unban === null ? "Per sempre" : user.date_to_unban}</p>
                <div align={"center"} className={"mt-2"}>
                    <Button type="button" color={"danger"} onClick={this.unban}>Riattiva</Button>
                </div>
            </div>
        }

        return <Modal isOpen={this.state.modal} backdrop="static" keyboard={false}>
            <ModalHeader>Sospendi utente {user.firstname} {user.lastname}</ModalHeader>
            <ModalBody>
                {content}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.handleClose}>Chiudi</Button>
            </ModalFooter>
        </Modal>
    }

    renderModalPassword = () => {
        let user = this.state.user;
        if (user === null)
            return;

        return <Modal isOpen={this.state.modalPassword} backdrop="static" keyboard={false}>
            <ModalHeader>Recupero password utente {user.firstname} {user.lastname}</ModalHeader>
            <ModalBody>
                <div align={"center"}>
                    <Button type="button" color={"danger"} onClick={this.generateLinkPassword}>Genera link
                        recupero</Button>
                </div>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="reason" className="mr-sm-2">Link</Label>
                    <Input type="text" name="link_password" id="link_password"
                           placeholder={"Genera link di recupero password"} value={this.state.link}/>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.handleClose}>Chiudi</Button>
            </ModalFooter>
        </Modal>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Clienti"/>
                        <Card>
                            <CardBody>
                                <h5>Lista clienti</h5>
                                <Button type="button" onClick={() => {
                                    windowOpen(global.baseUrl + 'admin/user/download/', "blank")
                                }} color="info">Scarica tutti utenti in .csv</Button> 
                                <Button type="button" onClick={() => {
                                    windowOpen(global.baseUrl + 'admin/user/newsletter/download/', "blank")
                                }} color="primary">Scarica utenti newsletter in .csv</Button>
                                <hr/>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v)
                                }} ref={c => (this.form = c)}>
                                    <div className={"row"}>
                                        <div className="form-group col-md-8">
                                            <AvField name="search" label="Cerca utente"
                                                     className="form-control"
                                                     placeholder="Cerca utente per nome, cognome o email"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-4" style={{display: "flex"}}>
                                            <div style={{marginTop: "auto"}}>
                                                <Button type="submit" color="primary">Cerca</Button>
                                            </div>
                                        </div>
                                    </div>
                                </AvForm>

                                <div style={{height: '80vh', width: '100%'}} className={'mt-2'}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        pagination
                                        pageSize={this.state.paginator.limit}
                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                        rowCount={this.state.paginator.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageChange}
                                        loading={this.state.paginator.loadingFishes}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                        {this.renderModal()}
                        {this.renderModalPassword()}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(
    connect(
        mapStatetoProps, {apiError}
    )
    (Client));