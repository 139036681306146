import React, {Component} from "react";
import {Card, CardBody, Container, Button, Badge} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';


import Breadcrumb from '../../components/Common/Breadcrumb';
import {listTicketSociety, societyInfo} from "../../store/manager_society/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleMinus, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

class SocietyTicketListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "Nome ticket", field: "name", width: 180, renderCell: (params) => {
                        let name = [];
                        if (params.getValue(params.id, "is_hidden"))
                            name.push(<FontAwesomeIcon icon={faEyeSlash} color={"#212121"}/>);
                        if (params.getValue(params.id, "is_disabled"))
                            name.push(<FontAwesomeIcon icon={faCircleMinus} color={"#212121"}/>);
                        name.push(<span>{params.value}</span>);
                        return name;
                    }
                },
                {
                    headerName: "Area", field: "areaName", width: 140, valueGetter: (params) =>
                        (params.getValue(params.id, "area").name !== null ? params.getValue(params.id, "area").name : "") + " " +
                        (params.getValue(params.id, "micro_area") !== null ? params.getValue(params.id, "micro_area").name : "")
                },
                {headerName: "Prezzo", field: "price", width: 140},
                {headerName: "Inizio validità", field: "date_start_validity", width: 160},
                {headerName: "Fine validità", field: "date_end_validity", width: 160},
                {
                    headerName: "Tipologia", field: "typeName", width: 140, valueGetter: (params) => {
                        switch (params.getValue(params.id, "type")) {
                            case "DAILY":
                                return "Giornaliero";
                            case "TWODAYS":
                                return "Bigiornaliero";
                            case "THREEDAYS":
                                return "Tre giorni";
                            case "FOURDAYS":
                                return "Quattro giorni";
                            case "WEEKDAYS":
                                return "Cinque giorni";
                            case "WEEKLY":
                                return "Settimanale";
                            case "TWOWEEKS":
                                return "Bisettimanale";
                            case "MONTHLY":
                                return "Mensile";
                            case "TRIMESTER":
                                return "Trimestrale";
                            case "SEASONAL":
                                return "Stagionale";
                            case "SEMIANNUAL":
                                return "Semestrale";
                            case "YEARLY":
                                return "Annuale";
                            case "BIANNUAL":
                                return "Biennale";
                            case "TRIENNIAL":
                                return "Triennale";
                        }
                    }
                },
                {
                    headerName: "Tipo residenti", field: "number", width: 180, renderCell: (params) => {
                        if (params.getValue(params.id, "ticket_residence_type") === null)
                            return (<Badge color="alert">Sconosciuto</Badge>)
                        return (<Badge color="info">{params.getValue(params.id, "ticket_residence_type").name}</Badge>)
                    }
                },
                {
                    headerName: " ",
                    width: 120,
                    field: 'id',
                    renderCell: (params) => (<Button onClick={() => this.shareButton(params.id)} type="button"
                                                     color="info">Assegna</Button>
                    )
                },

            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            society: null
        };
    }

    async componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined" && this.props.match.params.id !== null) {
            societyInfo(this.props.match.params.id).then((result) => {
                if (!result) {
                    global.notify("Errore", "danger");
                    return;
                }
                this.setState({society: result.society}, () => this.loadList(0))
            });
        }
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listTicketSociety(page + 1, this.state.society.id).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        });
    }

    handlePageChange = (params) => {
        this.loadList(params.page)
    };

    shareButton = (id) => {
        window.location.href = '/ticket_share/' + id
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista ticket dell'ente"/>
                        {this.state.society === null ? <div></div> : <Card>
                            <CardBody>
                                <div className="form-group">
                                    <div style={{height: '90vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChange}
                                            loading={this.state.paginator.loading}
                                        />
                                    </div>
                                </div>
                                <FontAwesomeIcon icon={faEyeSlash} color={"#212121"}/> Ticket nascosto in app<br/>
                                <FontAwesomeIcon icon={faCircleMinus} color={"#212121"}/> Ticket disabilitato del
                                tutto<br/>
                            </CardBody>
                        </Card>}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(SocietyTicketListPage));
