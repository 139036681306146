import React, {Component} from "react";
import {Card, CardBody, Container, Button,} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    addShopPackage,
    editShopPackage,
    infoShopPackage,
    deleteShopPackage,
    uploadImagePackage
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';

var moment = require('moment');

class ShopPackagePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form_edit: [],
            edit: false,
            shop_package: null,
        };
    }

    async componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined" && this.props.match.params.id !== null) {
            infoShopPackage(this.props.match.params.id).then((res) => {
                if (typeof res.error !== "undefined") {
                    global.notify("Errore: " + res.error, "danger");
                    return;
                }
                let shop_package = res.shop_package;
                this.setState({form_edit: shop_package, edit: true, shop_package: shop_package});
            });
        }
    }

    async handleValidSubmit(event, values, edit = false) {
        let formResult = null;
        if (edit) {
            values.id = this.state.shop_package.id;
            formResult = await editShopPackage(values);
            if (typeof formResult.shop_package !== 'undefined')
                global.notify("Pacchetto modificato con successo", "success");
            else if (typeof formResult.error !== 'undefined')
                global.notify("Il pacchetto non è stato modificato: " + formResult.error, "danger");
            else
                global.notify("Errore generico", "danger");
        } else {
            formResult = await addShopPackage(values);
            if (typeof formResult.shop_package !== 'undefined') {
                global.notify("Pacchetto aggiunto con successo", "success");
                this.setState({form_edit: formResult.shop_package, edit: true, shop_package: formResult.shop_package});
            } else if (typeof formResult.error !== 'undefined') {
                global.notify("Errore con l'aggiunta: " + formResult.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        }
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let package_edit = this.state.shop_package;
            var formData = new FormData();
            formData.append("image", event.target.files[0]);
            formData.append("id", package_edit.id);
            uploadImagePackage(formData).then(result => {
                if (typeof result.error === "undefined") {
                    package_edit.image = result.package.image;
                    this.setState({shop_package: package_edit});
                    global.notify("L'immagine è caricata", "success");
                } else {
                    global.notify("L'immagine non è stata caricata", "danger");
                }
            });
        }
    };

    deletePackage = () => {
        deleteShopPackage(this.state.shop_package.id).then(result => {
            if (typeof result.error !== 'undefined')
                global.notify("Errore con l'eliminazione: " + result.error, "danger");
            else
                global.notify("Pacchetto eliminato con successo", "success");
            this.form && this.form.reset();
            this.setState({edit: false, form_edit: null, shop_package: null});
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Aggiunta dei pacchetti"/>
                        <Card>
                            <CardBody>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v,) => {
                                    this.handleValidSubmit(e, v, this.state.edit)
                                }} ref={c => (this.form = c)}>
                                    <div className={"row"}>
                                        <div className="col-md-12">
                                            <h5>{this.state.edit ? "Modifica" : "Aggiunta"} pacchetto</h5>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <AvField name="name" label="Nome"
                                                     className="form-control" placeholder="Inserisci il nome"
                                                     type="text" required value={this.state.form_edit.name}/>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <AvField name="description" label="Descrizione"
                                                     className="form-control"
                                                     placeholder="Inserisci la descrizione del pacchetto"
                                                     type="textarea" value={this.state.form_edit.description}/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="price" label="Prezzo"
                                                     className="form-control" placeholder="Inserisci il prezzo"
                                                     type="number" required value={this.state.form_edit.price}/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="discounted_price" label="Prezzo scontato"
                                                     className="form-control" placeholder="Inserisci il prezzo scontato"
                                                     type="number" value={this.state.form_edit.discounted_price}/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="availability" label="Disponibilità"
                                                     className="form-control" placeholder="La disponibilità"
                                                     type="number" value={this.state.form_edit.availability}/>
                                            <small>-1 o campo vuoto per impostare la quantità infinita</small>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField type="checkbox" className="form-check-input" id="is_price_for_all"
                                                     name="is_price_for_all" label="Prezzo unificato?"
                                                     checked={this.state.form_edit.is_price_for_all}/>
                                            <small>(se abilitato, il prezzo non viene moltiplicato per il numero dei
                                                ticket)</small>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="link" label="Link" className="form-control"
                                                     placeholder="Inserisci il link del documento"
                                                     type="text" value={this.state.form_edit.link}/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="type" label="Tipologia" className="form-control"
                                                     placeholder="Inserisci tipo"
                                                     type="select" required value={this.state.form_edit.type}>
                                                <option value={"INSURANCE"}>Assicurazione</option>
                                                <option value={"BOUGHT_TOGETHER"}>Comprato insieme</option>
                                                <option value={"RECOMMEND"}>Raccomandato</option>
                                                <option value={"PRODUCT"}>Prodotto</option>
                                                <option value={"PREMIUM"}>PREMIUM</option>
                                            </AvField>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        {this.state.edit ? <Button type="submit" color="warning">Modifica</Button> :
                                            <Button type="submit" color="success">Crea</Button>}
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                        {this.state.edit ? <Card>
                            <CardBody>
                                <h5>Sfondo del pacchetto</h5>
                                <div className={"row"}>
                                    <div className="col-md-12">
                                        <div align={"center"}>
                                            <img
                                                src={this.state.form_edit.image !== null ? global.baseUrlImg + '/' + this.state.form_edit.image : ""}
                                                style={{width: "100%"}}/>
                                        </div>
                                        <div align={"center"}>
                                            <input type="file" name="image" onChange={this.onImageChange}/>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card> : <></>}
                        {this.state.edit ?
                            <div align={"center"}>
                                <Button color={"danger"} onClick={() => this.deletePackage()}>Elimina pacchetto</Button>
                            </div>
                            : <></>}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(ShopPackagePage));
