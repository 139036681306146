import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {listRules, fileRules} from '../../store/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';

class RuleListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "Area", field: "area", width: 280,
                    renderCell: (params) => {
                        let area = null;
                        if (typeof params.getValue(params.id, "area") !== "undefined") {
                            area = params.getValue(params.id, "area")[0].name;
                        } else {
                            if (typeof params.getValue(params.id, "ticket") !== "undefined") {
                                area = params.getValue(params.id, "ticket")[0].area.name;
                            }
                        }
                        return area;
                    }
                },
                {
                    headerName: "Impostato su", field: "id", width: 320,
                    renderCell: (params) => {
                        let str = "";
                        if (typeof params.getValue(params.id, "ticket") !== "undefined")
                            str = "Ticket: " + params.getValue(params.id, "ticket")[0].area.name;
                        else if (typeof params.getValue(params.id, "micro_area") !== "undefined")
                            str = "Zona: " + params.getValue(params.id, "micro_area")[0].name;
                        else if (typeof params.getValue(params.id, "area") !== "undefined")
                            str = "Area: " + params.getValue(params.id, "area")[0].name;
                        return str;
                    }
                },
                {
                    headerName: "Spec.", field: "rules", width: 360,
                    renderCell: (params) => {
                        let cellView = "";
                        if (typeof params.formattedValue !== 'undefined') {
                            console.log(params.formattedValue);
                            params.formattedValue.map((rule, iRule) => {
                                console.log(rule);
                                cellView += rule.date_capture + ': ' + rule.max_quantity + ' - ';
                                rule.fishable_specs.map((specRule, iRule) => {
                                    cellView += specRule.fish.name + ' '
                                })
                            })
                        }
                        return cellView
                    }
                },
                {
                    headerName: "file",
                    width: 200,
                    field: 'document',
                    renderCell: (params) => {
                        return <Row>
                            <Col>
                                <Button onClick={() => this.fileButton(params.getValue(params.id, "id"))} type="button"
                                        color="secondary">File</Button>
                            </Col>
                            <Col>
                                {(params.formattedValue !== null) ? ((params.getValue(params.id, "document") !== null) ?
                                    <a href={global.baseUrlImg + params.formattedValue.document} alt="" target="_blank"
                                       max-height="100" width="100">Regolamento</a> : 'Nessun file') : 'Nessun file'}
                            </Col>
                        </Row>
                    }
                },
                {
                    headerName: " ",
                    width: 140,
                    field: 'id_1',
                    renderCell: (params) => {
                        return <Button onClick={() => {
                            window.location.href = '/rule/edit/' + params.id
                        }} type="button" color="warning">Modifica</Button>
                    }
                }
            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            image: null,
            modal: false,
            imageUpload: null,
            uploadId: null
        };
        this.onImageChange = this.onImageChange.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose = () => this.setState({modal: false});

    uploadImage = () => {
        var formData = new FormData();
        formData.append("file", this.state.imageUpload);
        formData.append("id_fishing_regulation", this.state.uploadId);

        fileRules(formData).then(result => {
            this.loadList(0);
            this.setState({modal: false, imageUpload: null, uploadId: null, image: null});
        })
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                image: URL.createObjectURL(img),
                imageUpload: event.target.files[0]
            });
        }
    };

    async componentDidMount() {
        this.loadList(0);
    }

    fileButton = (params) => {
        this.setState({modal: true, uploadId: params.value});
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listRules().then(result => {
            paginator.loading = false;
            console.log(result)
            if (!result) {
                this.setState({rows: [], paginator: paginator});
            } else {
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    handlePageChange = (params) => {
        this.loadListAreas(params.page)
    };


    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista regolamenti"/>
                        <Card>
                            <CardBody>
                                <div className="form-group">
                                    <div style={{height: '90vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChange}
                                            loading={this.state.paginator.loading}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Modal
                            isOpen={this.state.modal}
                            // onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}>
                            <ModalHeader>Carica file</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg="12">
                                        <img src={this.state.image}/>
                                        <h1>Scegli file</h1>
                                        <input type="file" name="file" onChange={this.onImageChange}/>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.uploadImage}>Carica</Button>{' '}
                                <Button color="secondary" onClick={this.handleClose}>Annulla</Button>
                            </ModalFooter>
                        </Modal>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {listRules})(RuleListPage));
