import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    Label,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {AvForm, AvInput, AvCheckboxGroup, AvCheckbox} from 'availity-reactstrap-validation';

import {getInfoArea, addGroup, listGroup, deleteGroup} from '../../store/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';

class GroupAreaPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            microarea: [],
            list_group: [],
            area: null,
            modalEdit: false,
            modalGroup: false,
            modalGroupData: {micro_areas: null, id_group: null}
        };
    }

    async componentDidMount() {
        let area = await getInfoArea(this.props.match.params.id);
        this.setState({microarea: area.micro_areas, area: area}, () => this.getListGroups());
    }

    async handleValidSubmit(event, values) {
        if (Object.keys(values.micro_areas).length === 0) {
            window.scrollTo(0, 0);
            global.notify("Devi selezionare almeno una zona", "danger");
            return;
        }
        let data = {id_area: this.state.area.id, name: values.name};
        data.id_micro_areas = JSON.stringify(values.micro_areas.map((micro_area) => {
            return micro_area;
        }));
        let formResult = await addGroup(data);
        if (typeof formResult.error === 'undefined') {
            global.notify("Modifica eseguita con successo", "success");
            this.form.reset();
        } else
            global.notify(formResult.error, "danger");
        await this.getListGroups();
    }

    async handleValidSubmitModal(event, values) {
        if (Object.keys(values.micro_areas).length === 0) {
            this.handleClose();
            return;
        }
        let data = {id_area: this.state.area.id, id_group: this.state.modalGroupData.id_group};
        data.id_micro_areas = JSON.stringify(values.micro_areas.map((micro_area) => {
            return micro_area;
        }));

        let formResult = await addGroup(data);
        if (typeof formResult.error === 'undefined') {
            global.notify("Modifica eseguita con successo", "success");
            this.form.reset();
        } else
            global.notify(formResult.error, "danger");
        await this.getListGroups();
        this.handleClose();
    }

    getListGroups = async () => {
        let list = await listGroup({id: this.state.area.id});
        this.setState({list_group: list.list});
    }

    handleBoundChanged = () => {
        if (this.state.map !== null && this.state.maps !== null)
            this.loadMarkers(this.state.map, this.state.maps);
    }

    listMicroAreas = () => {
        let list;
        if (Object.keys(this.state.microarea).length === 0)
            list = <ListGroupItem>Non ci sono microaree impostate</ListGroupItem>
        else {
            list = this.state.microarea.map((item) => {
                return <ListGroupItem className="justify-content-between">
                    <AvCheckbox label={item.name} value={item.id}/>
                </ListGroupItem>
            });
        }

        return <ListGroup>
            <AvCheckboxGroup name="micro_areas">{list}</AvCheckboxGroup>
        </ListGroup>
    }

    handleClose = () => this.setState({modalGroup: false});

    deleteGroup = async (id_group, id_micro_area) => {
        let result = await deleteGroup({id_group: id_group, id_micro_area: id_micro_area});
        if (typeof result.error === 'undefined') {
            global.notify("Zona rimossa con successo", "success");
            await this.getListGroups();
        } else
            global.notify(result.error, "danger");
    }

    renderListGroup = () => {
        let list = [];
        if (Object.keys(this.state.list_group).length === 0)
            list = <ListGroupItem>Non ci sono gruppi impostati</ListGroupItem>
        else {
            for (let i in this.state.list_group) {
                let list_group = this.state.list_group[i];
                let micro_areas = [];
                let list_ = list_group.micro_areas.map((micro_area) => {
                    micro_areas.push(micro_area);
                    return <ListGroupItem>
                        <div style={{display: "flex"}}>
                            <div style={{flex: 1}}>{micro_area.name}</div>
                            <Button color={"danger"} size={"sm"}
                                    onClick={() => this.deleteGroup(list_group.id, micro_area.id)}>Elimina</Button>
                        </div>
                    </ListGroupItem>;
                })
                list.push(<ListGroupItem className="justify-content-between">
                    <Label>{list_group.name}</Label>
                    <ListGroup>
                        {list_}
                    </ListGroup>
                    <div align={"center"} className={"mt-2"}>
                        <Button color={"success"} size={"sm"} onClick={() => {
                            this.setState({
                                modalGroup: true,
                                modalGroupData: {micro_areas: micro_areas, id_group: list_group.id}
                            })
                        }}>Aggiungi zona</Button>
                    </div>
                </ListGroupItem>);
            }
        }
        return <ListGroup>{list}</ListGroup>;
    }

    renderModalGroup = () => {
        let listMicroArea;
        if (Object.keys(this.state.microarea).length === 0)
            listMicroArea = <ListGroupItem>Non ci sono microaree da aggiungere</ListGroupItem>
        else {
            let micro_areas_added = this.state.modalGroupData.micro_areas;
            listMicroArea = this.state.microarea.map((item) => {
                if (micro_areas_added === null || !micro_areas_added.find(element => element.id === item.id))
                    return <ListGroupItem className="justify-content-between">
                        <AvCheckbox label={item.name} value={item.id}/>
                    </ListGroupItem>
            });
        }

        return <Modal isOpen={this.state.modalGroup} backdrop="static" keyboard={false}>
            <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                this.handleValidSubmitModal(e, v)
            }} ref={c => (this.form = c)}>
                <ModalHeader>Aggiunta zone al gruppo </ModalHeader>
                <ModalBody>
                    <h6>Lista zone</h6>
                    <AvCheckboxGroup name="micro_areas">{listMicroArea}</AvCheckboxGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.handleClose}>Chiudi</Button>
                    <Button type="submit" color="success">Aggiungi</Button>
                </ModalFooter>
            </AvForm>

        </Modal>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Gestione gruppi zone"/>
                        <Card>
                            <CardBody>
                                <h5>Aggiungi gruppo</h5>
                                <hr/>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v)
                                }} ref={c => (this.form = c)}>
                                    <Label>Lista zone</Label>
                                    {this.listMicroAreas()}
                                    <div className="row">
                                        <div className="form-group col-sm-10">
                                            <Label for="name">Nome gruppo</Label>
                                            <AvInput name="name" id="name" required placeholder={"Nome gruppo"}/>
                                        </div>
                                        <div className="form-group col-sm-2" style={{display: "flex"}}>
                                            <div style={{marginTop: "auto"}}>
                                                <Button color={"success"} type={"submit"}>Aggiungi gruppo</Button>
                                            </div>
                                        </div>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h5>Lista gruppi</h5>
                                <hr/>
                                {this.renderListGroup()}
                            </CardBody>
                        </Card>
                        {this.renderModalGroup()}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = () => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps)(GroupAreaPage));