import {apiCall} from '../../helpers/backend_helper';

export const listSocieties = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'manager/society/list'});
    } catch (error) {
        return false;
    }
}

export const societyInfo = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'manager/society/info', data: {id: id}});
    } catch (error) {
        return false;
    }
}

export const societyStatsSummary = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'manager/society/stats/summary', data: {id: id}});
    } catch (error) {
        return false;
    }
}

export const societyStats = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'manager/society/stats/total', data: {id: id}});
    } catch (error) {
        return false;
    }
}

export const ticketClientList = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'manager/ticket/client/list', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const ticketInfo = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'manager/ticket/client/info', data: {id: id}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listUsersSociety = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'manager/user/society/list', data: data});
        if (typeof response.list !== 'undefined')
            return response;
        else
            return response;
    } catch (error) {
        return false;
    }
}

export const listUsers = async (page) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'manager/user/list', data: {page: page}});
        if (typeof response.list !== 'undefined')
            return response;
        else
            return response;
    } catch (error) {
        return false;
    }
}

export const listAreaSociety = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'manager/society/area/list', data: {id: id}});
        if (typeof response.list !== 'undefined')
            return response;
        else
            return response;
    } catch (error) {
        return false;
    }
}

export const listClientSociety = async (id_society, page) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({
            method: 'POST',
            url: 'manager/society/client/list',
            data: {id_society: id_society, page: page}
        });
        if (typeof response.list !== 'undefined')
            return response;
        else
            return response;
    } catch (error) {
        return false;
    }
}

export const statsDashboard = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'manager/stats/total'});
    } catch (error) {
        return false;
    }
}

export const listClientTickets = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'manager/ticket/client/list', data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listAllClientTickets = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'manager/ticket/client/list/all', data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}


export const captureTotalStats = async (page, date_start, date_end, id_society, id_area, id_ticket) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let data = {id_society: id_society, page: page, date_start: date_start, date_end: date_end};
        if (id_area) {
            data.id_area = id_area;
        }
        if (id_ticket) {
            data.id_ticket = id_ticket;
        }
        let response = await apiCall({method: 'POST', url: 'manager/stats/capture/total', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const captureStats = async (page, date_start, date_end, id_society, id_area, id_ticket) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let data = {id_society: id_society, page: page, date_start: date_start, date_end: date_end};
        if (id_area) {
            data.id_area = id_area;
        }
        if (id_ticket) {
            data.id_ticket = id_ticket;
        }
        let response = await apiCall({method: 'POST', url: 'manager/stats/capture', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listTicketSociety = async (page, society = null) => {
    try {
        let data = {page: page};
        if (society !== null)
            data.id_society = society;
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'manger/ticket/list/society', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const shareTicket = async (ticket) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'manager/ticket/share', data: ticket});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const infoTicket = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'manager/ticket/info', data: {id: id}});
        if (response)
            return response;
        return false;
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}