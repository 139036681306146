import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ButtonGroup
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// actions
import {apiError} from '../../../store/actions';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import {addMushroom, editMushroom, listMushrooms, uploadImageMushroom} from "../../../store/manager/actions";

class Mushroom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "Immagine",
                    width: 140,
                    field: 'image',
                    renderCell: (params) => (
                        <img src={global.baseUrlImg + params.value} alt="" max-height="100" width="100"/>
                    )

                },
                {headerName: "Nome fungo", field: "name", flex: 0.5},
                {headerName: "Descrizione", field: "description", flex: 1},
                {
                    headerName: " ",
                    flex: 0.3,
                    field: 'id',
                    renderCell: (params) => (
                        <ButtonGroup>
                            <Button onClick={() => this.imgButton(params)} type="button" color="secondary">Cambia
                                immagine</Button>
                            <Button onClick={() => this.actionButton(params)} type="button"
                                    color="primary">Modifica</Button>
                        </ButtonGroup>
                    )
                }
            ],
            rows: [],
            form_edit: {
                id: null,
                name: null,
                description: null,
            },
            flag_edit: false,
            paginator: {loading: true, page: 0, countRows: 0, limit: 50},
            image: null,
            modal: false,
            imageUpload: null,
            uploadId: null
        };
    }

    async componentDidMount() {
        this.loadList();
    }

    handleClose = () => this.setState({modal: false});

    uploadImage = () => {
        var formData = new FormData();
        formData.append("image", this.state.imageUpload);
        formData.append("id", this.state.uploadId);

        uploadImageMushroom(formData).then(result => {
            this.loadList();
            this.setState({modal: false, imageUpload: null, uploadId: null, image: null});
        })
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                image: URL.createObjectURL(img),
                imageUpload: event.target.files[0]
            });
        }
    };

    async handleValidSubmit(event, values, edit = false) {
        let formResult = null;
        if (edit) {
            formResult = await editMushroom(values);
        } else {
            formResult = await addMushroom(values);
        }

        if (typeof formResult.mushroom !== 'undefined') {
            global.notify("Azione eseguita con successo!", "success");
            this.loadList();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore: " + formResult.error, "danger");

        } else {
            global.notify("Errore generico", "danger");
        }
        window.scrollTo(0, 0);
    }

    actionButton = (params) => {
        this.setState({form_edit: params.row, flag_edit: true});
    }

    imgButton = (params) => {
        this.setState({modal: true, uploadId: params.value});
    }

    loadList = () => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listMushrooms().then(result => {
            paginator.loading = false;
            if (!result) {
                this.setState({rows: []});
            } else {
                this.setState({rows: result.list});
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista funghi"/>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div style={{height: 400, width: '100%'}}>
                                            <DataGrid
                                                rows={this.state.rows}
                                                columns={this.state.columns}
                                                pagination
                                                pageSize={this.state.paginator.limit}
                                                rowsPerPageOptions={[this.state.paginator.limit]}
                                                rowCount={this.state.paginator.countRows}
                                                loading={this.state.paginator.loading}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    <div className="row">
                                        {(this.state.flag_edit) ?
                                            <div className="form-group" hidden={true}>
                                                <AvField name="id" label="Id fungo" value={this.state.form_edit.id}
                                                         className="form-control" type="text" required/>
                                            </div> : ""
                                        }
                                        <div className="form-group col-md-4">
                                            <AvField name="name" label="Nome del fungo"
                                                     value={this.state.form_edit.name}
                                                     className="form-control" placeholder="Inserisci nome del fungo"
                                                     type="text" required/>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <AvField name="description" label="Descrizione del fungo"
                                                     value={this.state.form_edit.description} className="form-control"
                                                     placeholder="Inserisci descrizione del fungo" type="text"/>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="submit"
                                                color={(this.state.flag_edit) ? "warning" : "success"}>{(this.state.flag_edit) ? "Modifica Fungo" : "Crea Fungo"}</Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                        <Modal
                            isOpen={this.state.modal}
                            // onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}>
                            <ModalHeader>Immagine Fungo</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg="12">
                                        <img src={this.state.image}/>
                                        <h1>Scegli immagine</h1>
                                        <input type="file" name="myImage" onChange={this.onImageChange}/>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.uploadImage}>Carica</Button>{' '}
                                <Button color="secondary" onClick={this.handleClose}>Annulla</Button>
                            </ModalFooter>
                        </Modal>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {apiError})(Mushroom));