import React, {Component} from "react";
import {Container, Card, CardBody, Button, ButtonGroup, CardTitle, CardText, Row, Badge, Col, Label} from "reactstrap";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import {withTranslation} from 'react-i18next';

import {societyStatsSummary, ticketClientList, ticketInfo} from "../../store/manager_society/actions";
import DatePicker from "react-datepicker";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {DataGrid} from "@material-ui/data-grid";
import {windowOpen} from "echarts/src/util/format";
import spid_logo from "../../assets/images/spid-level2-logo-lb.png";

var moment = require('moment');
moment.locale('it');

class SocietyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "[Ticket] Utente", field: "client", width: 320, renderCell: (params) => (
                        <Row>
                            <Col>[{params.id}] {params.value !== null ? params.value.email : "Utente anonimo"}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Nome e cognome",
                    field: "client1",
                    width: 320,
                    renderCell: (params) => params.getValue(params.id, "client") !== null ? (
                        <Row>
                            <Col>{params.getValue(params.id, "client").firstname} {params.getValue(params.id, "client").lastname}</Col>
                        </Row>
                    ) : <></>
                },
                {headerName: "Ticket", field: "ticket", width: 160, renderCell: (params) => params.value.name},
                {headerName: "Data Acquisto", field: "date_purchase", width: 180},
                {
                    headerName: "Stato Pagamento", field: "status_payment", width: 200, renderCell: (params) => {
                        switch (params.value) {
                            case 'STATUS_PAYED':
                                return <Badge color="success">PAGATO</Badge>;
                            case 'STATUS_TO_PAY':
                                return <Badge color="info">DA PAGARE</Badge>;
                            case 'STATUS_CANCELLED':
                                return <Badge color="danger">CANCELLATO</Badge>;
                            case 'STATUS_REFUNDED':
                                return <Badge color="warning">RIMBORSATO</Badge>;
                        }
                    }
                },
                {
                    headerName: "Stato", field: "is_used", width: 120, renderCell: (params) =>
                        ((params.value) ? <Badge color="success">USATO</Badge> :
                                <Badge color="danger">NON USATO</Badge>
                        )
                },
                {
                    headerName: " ",
                    width: 80,
                    field: 'id',
                    renderCell: (params) => (
                        <Button onClick={() => this.infoButton(params.id)} type="button" color="info">Info</Button>)
                }
            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            form_edit: {
                date_start: moment().subtract('1', "month").format("DD/MM/YYYY"),
                date_end: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
            },
            search: null,
            date_start: (moment().subtract('1', "month").toDate()),
            date_end: new Date(),
            date_current: new Date(),
            ticket: null,
            sessions: [],
            society: null,
            total: 0,
            total_price: 0
        };
    }

    componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined" && this.props.match.params.id !== null) {
            societyStatsSummary(this.props.match.params.id).then((result) => {
                if (!result) {
                    global.notify("Errore", "danger");
                    return;
                }
                this.setState({
                    society: result.society,
                    total: result.total,
                    total_price: parseFloat(result.total_price).toFixed(2)
                }, () => this.loadList(0))
            })
        }
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});
        let data = {
            id_society: this.state.society.id,
            page: page + 1,
            date_start: this.state.form_edit.date_start,
            date_end: this.state.form_edit.date_end
        }
        if (this.state.search !== null)
            data.search = this.state.search;
        ticketClientList(data).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    async handleValidSubmit(event, values, edit = false) {
        let search = null;
        if (values.search !== "" && values.search !== null)
            search = values.search;
        this.setState({search: search}, () => this.loadList(0));
    }

    setDate = (date, type) => {
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;
        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    async infoButton(params) {
        let res = await ticketInfo(params);
        if (Object.keys(res.sessions).length === 0)
            this.setState({ticket: res.ticket, sessions: []});
        let sessions = [];
        for (let i in res.sessions) {
            let session = res.sessions[i];

            let captures = [];
            for (let c in res.captures) {
                let capture = res.captures[c];
                if (typeof capture.score_capture_session !== "undefined" && capture.score_capture_session !== null) {
                    if (capture.score_capture_session.id === session.id)
                        captures.push(capture);
                }
            }
            sessions.push({
                id: session.id,
                id_ticket: res.ticket.id,
                name: "Sessione " + session.id,
                data: res.ticket.date_purchase,
                date_start: session.date_start,
                date_end: session.date_end,
                date_check: session.date_check,
                user_check: session.checked_by,
                area: res.ticket.ticket.area.name,
                micro_area: (res.ticket.ticket.micro_area != undefined) ? res.ticket.ticket.micro_area.name : '',
                count: captures.length,
                fishes: captures
            });
        }
        this.setState({ticket: res.ticket, sessions: sessions});
    }

    renderSessions = () => {
        return this.state.sessions.map((session, i) => {
            let spid = null;
            if (typeof session.client_spid !== "undefined" && session.client_spid !== null) {
                let data = session.client_spid.data;
                let content = [];
                if (typeof data.name !== "undefined") {
                    content.push("Nome: " + data.name[0]);
                    content.push(<br/>);
                }
                if (typeof data.familyName !== "undefined") {
                    content.push("Cognome: " + data.familyName[0]);
                    content.push(<br/>);
                }
                if (typeof data.email !== "undefined") {
                    content.push("Email: " + data.email[0]);
                    content.push(<br/>);
                }
                if (typeof data.address !== "undefined") {
                    content.push("Indirizzo: " + data.address[0]);
                    content.push(<br/>);
                }
                if (typeof data.fiscalNumber !== "undefined") {
                    content.push("Codice fiscale: " + data.fiscalNumber[0]);
                    content.push(<br/>);
                }
                if (typeof data.idCard !== "undefined") {
                    content.push("Documento: " + data.idCard[0]);
                    content.push(<br/>);
                }
                spid = <>
                    <div className={'col-md-12'}>
                        <hr/>
                    </div>
                    <div className={'col-md-6'}>
                        <CardText>
                            <h5 style={{color: "#01579b"}}>Sessionte autenticata con SPID</h5>
                            {content}
                        </CardText>
                    </div>
                    <div className={'col-md-6'} align={"center"}>
                        <img src={spid_logo} style={{width: "80%", maxWidth: 350, opacity: 0.5}}/>
                    </div>
                </>;
            }
            return (
                <div className={"col-md-12 col-sm-12"}>
                    <Card body>
                        <CardTitle tag="h5">{session.name} - {session.date_start}</CardTitle>
                        <div className={"row"}>
                            <div className={'col-md-6'}>
                                <CardText>
                                    Data avvio: {session.date_start}<br/>
                                    Data chiusura: {session.date_end}<br/>
                                    Area: {session.area}<br/>
                                    MicroArea: {session.micro_area}<br/>
                                    Catture: {session.count}<br/>
                                </CardText>
                            </div>
                            <div className={'col-md-6'}>
                                <CardText>Ultima verifica: {(session.date_check === null) ? "-" : session.date_check}
                                    <br/>
                                    Controllato
                                    da: {(session.user_check === null || typeof session.user_check === "undefined") ? "Sconosciuto" : (session.user_check.firstname + " " + session.user_check.lastname + " " + session.user_check.email + "(" + session.user_check.id + ")")}
                                </CardText>
                            </div>
                            {spid}
                        </div>
                        <Row>
                            {session.fishes.map((fish, iFish) => {
                                return (
                                    <div className={"col-md-2 col-sm-12"}>
                                        <Card body>
                                            <CardTitle
                                                tag="h5">{fish.fish.name}</CardTitle>
                                            <CardText>
                                                Data: <b>{fish.date_capture}</b><br/>
                                                {(fish.killed) ?
                                                    <Badge color="info">KILL</Badge> :
                                                    <Badge color="warning">NO KILL</Badge>}<br/>
                                                Lunghezza: {fish.length}<br/>
                                                Peso: {fish.weight}<br/>
                                            </CardText>
                                        </Card>
                                    </div>
                                )
                            })}
                        </Row>
                    </Card>
                </div>
            )
        })
    }

    getAddressClient = (client) => {
        let address = null;
        if (client.address !== null)
            address += "via " + client.address + " ";
        if (client.number !== null)
            address += "n° " + client.number + " ";
        if (client.city !== null)
            address += " " + client.city + " ";
        if (client.cap !== null)
            address += " (" + client.cap + ") ";
        return address;
    }

    downloadTickets = () => {
        let date_start = moment(this.state.date_start).unix();
        let date_end = moment(this.state.date_end).unix();

        global.notify("Dati scaricati", "success");
        windowOpen(global.baseUrl + 'admin/stats/ticket/download/' + this.state.society.id + "/" + date_start + "/" + date_end, "blank")
    }

    handlePageChange = (params) => {
        this.loadList(params.page)
    };

    renderSociety() {
        if (this.state.society === null)
            return;
        let society = this.state.society;
        let text = "";
        if (society.address !== null)
            text += " " + society.address;
        if (society.number !== null)
            text += ", n°" + society.number;
        if (society.province !== null)
            text += ", " + society.province;
        if (society.region !== null)
            text += " (" + society.region + ")";
        return <CardText>{text}</CardText>
    }

    downloadTicket = (id) => {
        global.notify("Ticket scaricato", "success");
        windowOpen(global.baseUrl + 'admin/ticket/download/' + id, "blank")
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={"Homepage"} breadcrumbItem={"Scheda ente"}/>
                        {this.state.society === null ? <div></div> : <div className={"row"}>
                            <div className={"col-md-6"}>
                                <Card>
                                    <CardBody>
                                        <div className="form-group">
                                            <Label for="society"><b>{this.state.society.name}</b></Label>
                                            {this.renderSociety()}
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className={"col-md-6"}>
                                <Card>
                                    <CardBody>
                                        <ButtonGroup>
                                            <Button color="primary"
                                                    onClick={() => window.location.href = "/user_society/" + this.state.society.id}>
                                                Mostra gli utenti dell'ente
                                            </Button>
                                            <Button color="primary"
                                                    onClick={() => window.location.href = "/society_zone/" + this.state.society.id}>Visualizza
                                                le aree e zone gestite</Button>
                                            <Button color="primary"
                                                    onClick={() => window.location.href = "/society_client/" + this.state.society.id}>Visualizza
                                                i clienti dell'ente</Button>
                                            <Button color="primary"
                                                    onClick={() => window.location.href = "/ticket_list/" + this.state.society.id}>Visualizza
                                                i ticket dell'ente</Button>
                                        </ButtonGroup>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className={"col-md-6"}>
                                <Card>
                                    <CardBody>
                                        <h6>Ticket venduti [<small>{moment().format("MMMM YY")}</small>]</h6>
                                        <h4>{this.state.total}</h4>
                                        <a href={"/society_stats/" + this.state.society.id}>
                                            <Button color="link">Visualizza tutte le statistiche</Button>
                                        </a>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className={"col-md-6"}>
                                <Card>
                                    <CardBody>
                                        <h6>Totale ricavi [<small>{moment().format("MMMM YY")}</small>]</h6>
                                        <h4>€ {this.state.total_price}</h4>
                                        <a href={"/society_stats/" + this.state.society.id}>
                                            <Button color="link">Visualizza tutte le statistiche</Button>
                                        </a>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className={"col-md-12"}>
                                <Card>
                                    <CardBody>
                                        <h5>Scegli le date in cui vuoi visualizzare i ticket</h5>
                                        <div className={"row"}>
                                            <div className="form-group col-md-4">
                                                <DatePicker selected={this.state.date_start}
                                                            startDate={this.state.date_current} className="form-control"
                                                            onChange={(date) => this.setDate(date, "start")}
                                                            dateFormat="dd/MM/yyyy"
                                                />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <DatePicker selected={this.state.date_end}
                                                            minDate={this.state.date_start} className="form-control"
                                                            onChange={(date) => this.setDate(date, "end")}
                                                            dateFormat="dd/MM/yyyy"
                                                />
                                            </div>
                                            <div className="form-group col-md-4" style={{display: "flex"}}>
                                                <div style={{marginTop: "auto"}}>
                                                    <ButtonGroup>
                                                        <Button onClick={() => this.loadList(0)}
                                                                color={"success"}>Aggiorna</Button>
                                                        <Button onClick={() => this.downloadTickets()}
                                                                color={"primary"}>Scarica dati</Button>
                                                    </ButtonGroup>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                            this.handleValidSubmit(e, v, this.state.flag_edit)
                                        }} ref={c => (this.form = c)}>
                                            <div className={"row"}>
                                                <div className="form-group col-md-8">
                                                    <AvField name="search" label="Cerca ticket"
                                                             className="form-control"
                                                             placeholder="Cerca ticket per nome, cognome o email dei clienti"
                                                             type="text"/>
                                                </div>
                                                <div className="form-group col-md-4" style={{display: "flex"}}>
                                                    <div style={{marginTop: "auto"}}>
                                                        <Button type="submit" color="primary">Cerca</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </AvForm>

                                        <div className="form-group">
                                            <div style={{height: '50vh', width: '100%'}}>
                                                <DataGrid
                                                    rows={this.state.rows}
                                                    columns={this.state.columns}
                                                    pagination
                                                    pageSize={this.state.paginator.limit}
                                                    rowsPerPageOptions={[this.state.paginator.limit]}
                                                    rowCount={this.state.paginator.countRows}
                                                    paginationMode="server"
                                                    onPageChange={this.handlePageChange}
                                                    loading={this.state.paginator.loading}
                                                />
                                            </div>
                                        </div>
                                        <div className={"row"}>

                                            {this.state.ticket !== null ? <>
                                                    <div className={"col-md-6"}>
                                                        <Card body>
                                                            <CardTitle tag="h5">Ticket</CardTitle>
                                                            <CardText>
                                                                Nome: {this.state.ticket.ticket.name}<br/>
                                                                Data
                                                                acquisto: {this.state.ticket.date_purchase}<br/>
                                                                Data
                                                                Utilizzo: {this.state.ticket.date_start_use}<br/>
                                                                <br/>
                                                                <Button
                                                                    onClick={() => this.downloadTicket(this.state.ticket.id)}
                                                                    color={"danger"}>Scarica pdf</Button>
                                                            </CardText>
                                                        </Card>
                                                    </div>
                                                    <div className={"col-md-6"}>
                                                        <Card body>
                                                            <CardTitle tag="h5">Dati utente</CardTitle>
                                                            <CardText>
                                                                <div className={"row"}>
                                                                    <div className={"col-md-6"}>
                                                                        Email: {this.state.ticket.client.email}<br/>
                                                                        Nome
                                                                        cognome: {this.state.ticket.client.firstname} {this.state.ticket.client.lastname}<br/>
                                                                        Nominativo ticket: {this.state.ticket.nickname}<br/>
                                                                        Telefono: {this.state.ticket.client.phone !== null ? this.state.ticket.client.phone : "non impostato"}<br/>
                                                                        Codice
                                                                        fiscale: {this.state.ticket.client.fiscal_code !== null ? this.state.ticket.client.fiscal_code : "non impostato"}<br/>
                                                                        Indirizzo: {this.getAddressClient(this.state.ticket.client) !== null ? this.getAddressClient(this.state.ticket.client.address) : "non impostato"}<br/>
                                                                    </div>
                                                                </div>
                                                            </CardText>
                                                        </Card>
                                                    </div>
                                                </>
                                                : ""}
                                            {this.renderSessions()}
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                        </div>
                        }
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(SocietyPage);
