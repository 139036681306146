import {apiCall} from '../../../helpers/backend_helper';

export const getAdmin = async (page) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/list', data: {page: page}});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const checkUser = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'permission'});
        if (typeof response.user !== 'undefined') {
            if (typeof response.society_category !== "undefined")
                localStorage.setItem("society_category", JSON.stringify(response.society_category));
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setAdmin = async (admin) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/add', data: admin});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editAdmin = async (category) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/edit', data: category});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const deleteAdmin = async (idAdm) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/delete', data: idAdm});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}
export const adminAssociationSocietyAdd = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/society/user/admin/association', data: data});
    } catch (error) {
        return false;
    }
}

export const adminAssociationSocietyDelete = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/society/user/admin/association/delete', data: data});
    } catch (error) {
        return false;
    }
}

export const getAdminStatsDashboard = async (date_start, date_end) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({
            method: 'POST',
            url: 'admin/stats/dashboard',
            data: {date_start: date_start, date_end: date_end}
        });
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}