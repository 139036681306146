import React, {Component} from "react";
import {Row, Col, Card, CardBody, Container, Button} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {apiError} from '../../store/actions';
import {listSocieties} from "../../store/manager_society/actions";

import Breadcrumb from '../../components/Common/Breadcrumb';

class ListSocietyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Nome ente", field: "name", width: 180},
                {headerName: "Numero tel", field: "phone", width: 160},
                {headerName: "Partita Iva", field: "piva", width: 160},
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Provincia", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 160},
                {headerName: "CAP", field: "cap", width: 120},
                {
                    headerName: " ",
                    width: 120,
                    field: 'id',
                    renderCell: (params) => (<Button onClick={() => this.summarySociety(params)} type="button"
                                                     color="success">Scheda</Button>)
                }
            ],
            rows: [],
            paginator: {loadingSociety: true, limit: 50},
        };
    }

    summarySociety = (params) => {
        window.location.href = '/society/' + params.id
    }

    async componentDidMount() {
        this.loadListSocieties();
    }

    loadListSocieties = () => {
        let paginator = this.state.paginator;
        paginator.loadingSociety = true;
        this.setState({paginator: paginator});

        listSocieties().then(result => {
            paginator.loadingSociety = false;
            if (!result) {
                this.setState({rows: [], paginator: paginator});
            } else {
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista enti"/>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div style={{height: '80vh', width: '100%'}}>
                                            <DataGrid
                                                rows={this.state.rows}
                                                columns={this.state.columns}
                                                pagination
                                                pageSize={this.state.paginator.limit}
                                                rowsPerPageOptions={[this.state.paginator.limit]}
                                                loading={this.state.paginator.loadingSociety}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {apiError})(ListSocietyPage));