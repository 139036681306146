import {put} from 'redux-saga/effects';
import {apiCall} from '../../../helpers/backend_helper';

export const getFish = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/fish/list'});
        if (typeof response.list !== 'undefined') {
            return response.list;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}
export const getFishRegulation = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/fish/list/regulation'});
        if (typeof response.list !== 'undefined') {
            return response.list;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setSpecs = async (specs) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/fish/specs/add', data: specs});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editSpecs = async (specs) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/fish/specs/edit', data: specs});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const deleteSpecs = async (idSpecs) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/fish/specs/delete', data: idSpecs});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}