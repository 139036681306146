import React, {Component} from "react";
import {Row, Col, Card, CardBody, Container, Label, CardText, Button} from "reactstrap";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    getSociety,
    setSociety,
    editSociety,
    apiError,
    infoSociety,
    uploadImageTicket,
    editSocietyEmail, editSocietyTicketSettings, editSocietyEmailTemplate, uploadSocietyImageTicket
} from '../../store/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';
import {AvField, AvForm} from "availity-reactstrap-validation";

class SocietySettingsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            society: null,
            form_edit: {
                email_send_order: null
            },
        };
    }

    async componentDidMount() {
        infoSociety(this.props.match.params.id).then((result) => {
            if (typeof result.error === 'undefined') {
                console.log(" result.society.email_send_order")
                console.log(result.society.email_send_order)
                this.setState({
                    society: result.society,
                    form_edit: {
                        email_send_order: result.society.email_send_order
                    }
                });
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    async handleValidSubmit(event, values) {
        values.id = this.state.society.id;
        let formResult = await editSocietyEmail(values);

        if (typeof formResult.society !== 'undefined') {
            this.setState({society: formResult.society, email_send_order: formResult.society.email_send_order});
            global.notify('Azione eseguita con successo', "success");
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
        window.scrollTo(0, 0);
    }


    async handleValidSubmitTemplate(event, values) {
        values.id = this.state.society.id;
        let formResult = await editSocietyEmailTemplate(values);

        if (typeof formResult.society !== 'undefined') {
            this.setState({society: formResult.society});
            global.notify('Azione eseguita con successo', "success");
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
        window.scrollTo(0, 0);
    }

    async handleValidSubmitTicket(event, values) {
        values.id = this.state.society.id;
        let formResult = await editSocietyTicketSettings(values);

        if (typeof formResult.society !== 'undefined') {
            this.setState({society: formResult.society});
            global.notify('Azione eseguita con successo', "success");
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
        window.scrollTo(0, 0);
    }

    renderSociety() {
        if (this.state.society === null)
            return;
        let society = this.state.society;
        let text = society.name;
        if (society.address !== null)
            text += " " + society.address;
        if (society.number !== null)
            text += ", n°" + society.number;
        if (society.province !== null)
            text += ", " + society.province;
        if (society.region !== null)
            text += " (" + society.region + ")";
        return <CardText>{text}</CardText>
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            var formData = new FormData();
            formData.append("image", event.target.files[0]);
            formData.append("id", this.state.society.id);
            uploadSocietyImageTicket(formData).then(result => {
                if (typeof result.error === "undefined") {
                    let society = this.state.society;
                    society.image_ticket = result.society.image_ticket;
                    this.setState({society: society});
                    global.notify("L'immagine è stata caricata", "success");
                } else {
                    global.notify("L'immagine non è non è stata caricata", "danger");
                }
            });
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Scheda ente"/>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="form-group">
                                            <Label for="society">Ente</Label>
                                            {this.renderSociety()}
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <h6>Carica immagine del logo del ticket</h6>
                                        <div align={"center"}>
                                            <img
                                                src={(this.state.society !== null && this.state.society.image_ticket !== null) ? global.baseUrlImg + '/' + this.state.society.image_ticket : ""}
                                                style={{maxHeight: "35vh"}}/>
                                        </div>
                                        <hr/>
                                        <div align={"center"}>
                                            <input type="file" name="logo" onChange={this.onImageChange}/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6">
                                <Card>
                                    <CardBody>
                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                            this.handleValidSubmit(e, v)
                                        }} ref={c => (this.form = c)}>
                                            <div className="row">
                                                <div className="form-group col-md-10 ">
                                                    <AvField name="email_send_order"
                                                             label="Email di ricevuta degli ordini effettuati"
                                                             value={this.state.form_edit.email_send_order}
                                                             className="form-control" placeholder="Inserisci email"
                                                             type="email" required/>
                                                </div>
                                                <div className="form-group col-md-2" style={{display: "flex"}}>
                                                    <div style={{marginTop: "auto"}}>
                                                        <Button type="submit" color={"success"}>Salva email</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            {(this.state.society !== null) ? <Col lg="6">
                                <Card>
                                    <CardBody>
                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                            this.handleValidSubmitTemplate(e, v)
                                        }} ref={c => (this.form = c)}>
                                            <div className="row">
                                                <div className="form-group col-md-10 ">
                                                    <AvField type="select" name="template"
                                                             label="Template email degli ordini"
                                                             value={this.state.society.mail_template}>
                                                        <option value={""}>Standard</option>
                                                        <option value={"MADONNA_DI_CAMPIGLIO"}>Madonna di Campiglio
                                                        </option>
                                                    </AvField>
                                                </div>
                                                <div className="form-group col-md-2" style={{display: "flex"}}>
                                                    <div style={{marginTop: "auto"}}>
                                                        <Button type="submit" color={"success"}>Salva</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col> : <></>}
                            {(this.state.society !== null) ? <Col lg="6">
                                <Card>
                                    <CardBody>
                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                            this.handleValidSubmitTicket(e, v)
                                        }} ref={c => (this.form = c)}>
                                            <div className="row">
                                                <div className="form-group col-md-12">
                                                    <AvField type="checkbox" className="form-check-input"
                                                             defaultValue={this.state.society.is_disabled_ticket_address}
                                                             id="disabled_ticket_address" name="disabled_ticket_address"
                                                             label="Disabilita indirizzo del cliente nel ticket"/>
                                                    <AvField type="checkbox" className="form-check-input"
                                                             defaultValue={this.state.society.is_disabled_ticket_fiscal_code}
                                                             id="disabled_ticket_fiscal_code"
                                                             name="disabled_ticket_fiscal_code"
                                                             label="Disabilita codice fiscale nel ticket"/>
                                                </div>
                                                <div className="form-group col-md-12" align={"center"}>
                                                    <Button type="submit" color={"success"}>Salva</Button>
                                                </div>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col> : <></>}
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(
    connect(
        mapStatetoProps, {getSociety, setSociety, editSociety, apiError}
    )
    (SocietySettingsPage));