import React, {Component} from "react";

import {Card, CardBody, Container, Button, Label, CardTitle} from "reactstrap";
import GoogleMapReact from 'google-map-react';
import {DataGrid} from '@material-ui/data-grid';

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {getCategories, getSocieties, getMapAreas, setZone, getListRegion} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import GooglePlacesAutocomplete, {geocodeByPlaceId} from "react-google-places-autocomplete";
import ReactQuill from "react-quill";

class Zone extends Component {
    static defaultProps = {
        center: {
            lat: 42.45,
            lng: 12.96
        },
        zoom: 5.5
    };

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Nome ente", field: "name", width: 180},
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Provincia", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "Numero", field: "number", width: 140},
                {headerName: "Città", field: "city", width: 160},
                {headerName: "CAP", field: "cap", width: 150},
                {headerName: "Telefono", field: "phone", width: 140}
            ],
            rows: [],
            categories: [],
            lat: null,
            lon: null,
            polygons: [],
            map: null,
            maps: null,
            polygonObj: null,
            id_society: null,
            id_category: 0,
            category: null,
            paginator: {loadingSociety: true, countRows: 0, limit: 50},
            markers: [],
            markersObj: [],
            polygonsObj: [],
            regionList: [],
            description: null
        };
        this.quill = null;
    }

    async handleValidSubmit(event, values) {
        if (this.state.id_society === null) {
            global.notify("Devi scegliere l'ente", "danger");
            return;
        }

        values.id_society = this.state.id_society;
        if (this.state.lat === null || this.state.lon === null) {
            global.notify("Devi impostare un marker nella mappa", "danger");
            return;
        }
        values.lat = this.state.lat;
        values.lon = this.state.lon;
        if (this.state.description !== null && this.state.description.trim() !== "" && this.state.description !== "<p><br></p>")
            values.full_description = this.state.description;

        if (Object.keys(this.state.polygons).length !== 0) {
            values.area_cords = JSON.stringify(this.state.polygons.map((cords) => {
                return {lat: cords.lat, lon: cords.lng};
            }));
        }

        let formResult = await setZone(values);
        if (typeof formResult.error === 'undefined')
            global.notify("Area aggiunta con successo", "success");
        else
            global.notify(formResult.error, "danger");
        window.scrollTo(0, 0);
    }

    async componentDidMount() {
        let categories = await getCategories();
        this.setState({categories: categories});
        if (Object.keys(categories).length > 0)
            this.setState({category: categories[0]});
        this.loadRegions();
        this.loadListSocieties(0);
    }

    loadRegions = () => {
        getListRegion().then(result => {
            if (!result) {
                this.setState({regionList: []});
            } else {
                let regions = [];
                result.list.map((value, i) => {
                    if (typeof regions[value.country] === "undefined")
                        regions[value.country] = [];
                    regions[value.country].push(value.region);
                })
                this.setState({regionList: regions});
            }
        })
    }

    handleBoundChanged = () => {
        if (this.state.map !== null && this.state.maps !== null)
            this.handleApiLoaded(this.state.map, this.state.maps);
    }

    handleApiLoaded = (map, maps) => {
        let bounds = map.getBounds();
        getMapAreas(this.state.category.id, bounds.getNorthEast().lat(), bounds.getNorthEast().lng(), bounds.getSouthWest().lat(), bounds.getSouthWest().lng()).then((result) => {
            let arr_markers = [];
            for (let i in result.list) {
                let area = result.list[i];
                if (typeof this.state.markers.find(m => {
                    return m.id === area.id
                }) !== "undefined")
                    continue;

                arr_markers.push({id: area.id});
                let marker = new maps.Marker({
                    map: map,
                    position: new maps.LatLng(area.lat, area.lon),
                });

                let markers = this.state.markersObj;
                markers.push(marker);
                this.setState({markersObj: markers});

                if (typeof area.area_cords !== "undefined" && area.area_cords !== null && Object.keys(area.area_cords).length >= 3) {
                    let polygon = [];
                    for (let i in area.area_cords)
                        polygon.push({lat: area.area_cords[i].lat, lng: area.area_cords[i].lon});

                    let polygonObj = new maps.Polygon({
                        paths: polygon,
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.35
                    });
                    polygonObj.setMap(map);
                    let polygons = this.state.polygonsObj;
                    polygons.push(polygonObj);
                    this.setState({polygonsObj: polygons});
                }
            }
            this.setState({markers: [...arr_markers, ...this.state.markers]})
        });
    }

    renderMap() {
        let mapObj = this.state.map;
        let mapsObj = this.state.maps;

        let markerDragged = (e) => {
            this.setState({lat: e.latLng.lat(), lon: e.latLng.lng()})
        }

        let polygonEdited = (e) => {
            const nextPath = this.state.polygonObj.getPath().getArray().map(latLng => {
                return {lat: latLng.lat(), lng: latLng.lng()};
            });
            this.setState({polygons: nextPath})
        }
        let removeBorders = () => {
            if (this.state.polygonObj !== null)
                this.state.polygonObj.setMap(null);
            this.setState({polygons: []})
        }
        let addBorders = () => {
            let add_poly = this.state.polygons;
            if (this.state.lat === null || this.state.lon === null) {
                global.notify("Devi prima aggiungere il marker", "warning");
                return;
            }
            if (Object.keys(this.state.polygons).length === 0) {
                add_poly.push({lat: this.state.lat + 0.01, lng: this.state.lon + 0.02});
                add_poly.push({lat: this.state.lat - 0.02, lng: this.state.lon - 0.01});
                add_poly.push({lat: this.state.lat + 0.01, lng: this.state.lon - 0.02});
            }

            if (this.state.polygonObj !== null)
                this.state.polygonObj.setMap(null);

            let polygonObj = new mapsObj.Polygon({
                paths: add_poly,
                strokeColor: "#0088ff",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#144356",
                fillOpacity: 0.35,
                editable: true,
                draggable: true
            });
            mapsObj.event.addListener(polygonObj.getPath(), 'set_at', position => polygonEdited(position));
            mapsObj.event.addListener(polygonObj.getPath(), 'insert_at', position => polygonEdited(position));
            polygonObj.setMap(mapObj);
            this.setState({polygons: add_poly, polygonObj: polygonObj});
        }

        const setMarker = () => {
            let marker = new mapsObj.Marker({
                map: mapObj,
                animation: mapsObj.Animation.DROP,
                icon: {
                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                    scaledSize: new mapsObj.Size(42, 42)
                },
                position: new mapsObj.LatLng({lat: mapObj.center.lat(), lng: mapObj.center.lng()}),
                draggable: true
            });
            mapsObj.event.addListener(marker, 'dragend', markerDragged);
            this.setState({lat: mapObj.center.lat(), lon: mapObj.center.lng()})
        }

        return <Card body inverse style={{backgroundColor: '#eeeeee', borderColor: '#eeeeee'}}>
            {this.state.map !== null ?
                <GooglePlacesAutocomplete
                    // apiKey={"AIzaSyCV53jtplvMZi17CqUW7w8Q5w9uat1lgqw"}
                    autocompletionRequest={{language: 'it', types: ["address"]}}
                    selectProps={{
                        onChange: (data) => {
                            geocodeByPlaceId(data.value.place_id).then(results => {
                                this.setState({
                                    lat: results[0].geometry.location.lat(),
                                    lon: results[0].geometry.location.lng()
                                });
                                this.state.map.setCenter({
                                    lat: results[0].geometry.location.lat(),
                                    lng: results[0].geometry.location.lng()
                                });
                                this.state.map.setZoom(12);
                                setMarker();
                            }).catch(error => console.error(error));
                        }
                        ,
                        styles: {
                            input: (provided) => ({
                                ...provided,
                                color: 'black',
                            }),
                            option: (provided) => ({
                                ...provided,
                                color: 'black',
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                color: 'blue',
                            }),
                        },
                    }}
                /> : <></>}
            <div className="text-center mb-4 mt-4">
                <div className="btn-group">
                    <Button type="button" color="success"
                            disabled={this.state.lat !== null && this.state.lon !== null} onClick={setMarker}>Aggiungi
                        Marker</Button>
                    <Button type="button" color="warning" onClick={addBorders}
                            disabled={Object.keys(this.state.polygons).length > 0}>Aggiungi Bordi</Button>
                    <Button type="button" color="danger" onClick={removeBorders}>Rimuovi Bordi</Button>
                </div>
            </div>

            <div style={{height: '50vh', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyCV53jtplvMZi17CqUW7w8Q5w9uat1lgqw", libraries: ["places"]}}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    yesIWantToUseGoogleMapApiInternals={false}
                    onGoogleApiLoaded={({map, maps}) => {
                        this.setState({map: map, maps: maps})
                        this.handleApiLoaded(map, maps)
                    }}
                    onBoundsChange={this.handleBoundChanged}
                >
                </GoogleMapReact>
            </div>
        </Card>
    }

    listCategories() {
        if (Object.keys(this.state.categories).length === 0)
            return;
        return this.state.categories.map(function (category, i) {
            return <option value={category.id} key={i}>{category.name}</option>
        });
    }

    loadListSocieties = (page) => {
        let paginator = this.state.paginator;
        paginator.loadingSociety = true;
        this.setState({paginator: paginator});

        getSocieties(page + 1).then(result => {
            paginator.loadingSociety = false;

            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    handlePageChange = (params) => {
        this.loadListSocieties(params.page)
    };

    handleRowSelected = (rows) => {
        let row = [...rows.entries()][0][1];
        this.setState({id_society: row.id});
    }

    renderListRegion = (regionSelected = null) => {
        let ret = [];
        for (let country in this.state.regionList) {
            ret.push(<option disabled>{country}</option>)
            for (let region in this.state.regionList[country]) {
                let selected = null;
                if (regionSelected !== null && this.state.regionList[country][region] === regionSelected)
                    selected = "selected";
                ret.push(<option
                    value={this.state.regionList[country][region]}
                    selected={selected}>{this.state.regionList[country][region]}</option>)
            }
        }
        return ret;
    }

    changeCategory = (id_category) => {
        let category = null;
        for (let i in this.state.categories) {
            if (this.state.categories[i].id === parseInt(id_category))
                category = this.state.categories[i];
        }
        for (let i in this.state.markersObj)
            this.state.markersObj[i].setMap(null);
        for (let i in this.state.polygonsObj)
            this.state.polygonsObj[i].setMap(null);

        this.setState({category: category, markers: [], markersObj: [], polygonsObj: []}, () => {
                this.handleApiLoaded(this.state.map, this.state.maps)
            }
        )
    }

    renderDetails = () => {
        if (this.state.category === null)
            return;
        switch (this.state.category.type) {
            case "TYPE_FISHING":
                return (<div className="form-check col-md-12 mb-6">
                    <AvField type="checkbox" className="form-check-input"
                             id="is_check_fipsas_enabled" name="is_check_fipsas_enabled" label="Area controllo FIPSAS"/>
                    <AvField type="checkbox" className="form-check-input"
                             id="is_check_coupon_enabled" name="is_check_coupon_enabled"
                             label="Area controllo FIPSAS con codice non attivato"/>
                    <AvField type="checkbox" className="form-check-input"
                             id="is_score_capture_unlimited" name="is_score_capture_unlimited"
                             label="Segna catture con tempo sessione illimitato"/>
                    {/*<AvField type="checkbox" className="form-check-input"*/}
                    {/*         id="is_check_spid_enabled" name="is_check_spid_enabled" label="Area pesca SPID"/>*/}
                </div>);
            case "TYPE_MUSHROOM":
                return (<div className="form-group col-md-6">
                    <AvField type="select" name="area_level" label="Livello popolamento" value={0}
                             placeholder="Livello crescita">
                        <option selected disabled>Scegli livello popolamento</option>
                        <option value={0} key={0}>0 - Assente</option>
                        <option value={1} key={1}>1 - Insufficiente</option>
                        <option value={2} key={2}>2 - Scarsa</option>
                        <option value={3} key={3}>3 - Buona</option>
                        <option value={4} key={4}>4 - Abbondante</option>
                        <option value={5} key={5}>5 - Miracolosa</option>
                    </AvField>
                </div>);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Aggiunta zona"/>
                        <Card>
                            <CardBody>
                                <CardTitle tag="h5">Aggiungi zona</CardTitle>
                                <div className="form-group">
                                    <Label for="society">Scegli la società</Label>
                                    <div style={{height: 400, width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChange}
                                            loading={this.state.paginator.loadingSociety}
                                            onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                        />
                                    </div>
                                </div>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v)
                                }} ref={c => (this.form = c)}>
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <AvField type="select" name="id_category" label="Categoria"
                                                     value={Object.keys(this.state.categories).length > 0 ? this.state.categories[0].id : null}
                                                     placeholder="Inserisci Categoria" required
                                                     onChange={(event) => this.changeCategory(event.target.value)}>
                                                {this.listCategories()}
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <AvField name="name" label="Nome" className="form-control"
                                                     placeholder="Inserisci Nome" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="region" label="Regione" className="form-control"
                                                     placeholder="Inserisci Regione" type="select" required>
                                                {this.renderListRegion()}
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="province" label="Provincia" className="form-control"
                                                     placeholder="Inserisci Provincia" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="address" label="Indirizzo" className="form-control"
                                                     placeholder="Inserisci Indirizzo" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <AvField name="number" label="Numero" className="form-control"
                                                     placeholder="Inserisci Numero" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="city" label="Città" className="form-control"
                                                     placeholder="Inserisci Città" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <AvField name="cap" label="CAP" className="form-control"
                                                     placeholder="Inserisci CAP" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="tickets_available_daily"
                                                     label="Numero di posti disponibili al giorno"
                                                     className="form-control" placeholder="Inserisci numero"
                                                     type="text"/>
                                            <small>Attenzione! Le date d'utilizzo per i ticket di questa area saranno
                                                obbligatorie (lasciare il campo vuoto se non ci sono limiti)</small>
                                        </div>
                                        {this.renderDetails()}
                                    </div>
                                    <hr/>
                                    <p><b>Descrizione zona</b></p>
                                    <ReactQuill theme="snow"
                                                name="full_description"
                                                value={this.state.description}
                                                ref={(ref) => this.quill = ref}
                                                modules={{
                                                    toolbar: {
                                                        container: [
                                                            [
                                                                {header: [1, 2, 3, 4, 5, 6, false]},
                                                            ],
                                                            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'line'],
                                                            [
                                                                {list: 'ordered'},
                                                                {list: 'bullet'},
                                                                {align: []},
                                                            ],
                                                            [{color: []}],
                                                            ['link'],
                                                            ['clean'],
                                                        ],
                                                    },
                                                    clipboard: {
                                                        matchVisual: false,
                                                    },
                                                }}
                                                formats={[
                                                    'header',
                                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                    'list', 'bullet', 'indent',
                                                    'link',
                                                    'color',
                                                    'formats/style', 'style', 'line', 'align', 'clean', 'hr'
                                                ]}
                                                onChange={(value) => {
                                                    this.setState({description: value});
                                                }}/>
                                    <hr/>
                                    {this.renderMap()}
                                    <div className="text-center mt-4">
                                        <Button type="submit" color="success">Salva</Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {getCategories, getSocieties, getMapAreas, setZone})(Zone));
