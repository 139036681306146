import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    Label,
    CardText,
} from "reactstrap";

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ImageUploading from 'react-images-uploading';

import {
    getInfoArea,
    uploadImageArea,
    uploadLogoArea,
    uploadImageGalleryArea, removeImageGalleryArea
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';

class ZoneImagePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            area: null,
            gallery: []
        };
    }

    async componentDidMount() {
        let area = await getInfoArea(this.props.match.params.id);
        let gallery = [];
        for(let i in area.gallery)
            gallery.push(area.gallery[i])

        this.setState({area: area, gallery: gallery});
    }

    renderSociety() {
        if (this.state.area === null)
            return;
        let society = this.state.area.society;
        let text = society.name;
        if (society.address !== null)
            text += " " + society.address;
        if (society.number !== null)
            text += ", n°" + society.number;
        if (society.province !== null)
            text += ", " + society.province;
        if (society.region !== null)
            text += " (" + society.region + ")";
        return <CardText>{text}</CardText>
    }

    renderArea() {
        if (this.state.area === null)
            return;
        let area = this.state.area;
        let text = area.name;
        return <CardText>{text}</CardText>
    }

    uploadImageGallery = (e) => {
        let file = e.find((item) => typeof item.file !== "undefined");
        var formData = new FormData();
        formData.append("image", file.file);
        formData.append("id", this.state.area.id);

        uploadImageGalleryArea(formData).then(result => {
            if (typeof result.error === "undefined") {
                let gallery = [];
                for(let i in result.area.gallery)
                    gallery.push(result.area.gallery[i])
                this.setState({gallery: gallery});
                global.notify("L'immagine è stata caricata", "success");
            } else {
                global.notify("L'immagine non è stata caricata", "danger");
            }
        })
    }

    removeImageGallery = (index) => {
        removeImageGalleryArea(this.state.area.id, index).then(result => {
            if (typeof result.error === "undefined") {
                let gallery = [];
                for(let i in result.area.gallery)
                    gallery.push(result.area.gallery[i])
                this.setState({gallery: gallery});
                global.notify("L'immagine è stata rimossa", "success");
            } else {
                global.notify("L'immagine non è stata rimossa", "danger");
            }
        })
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            var formData = new FormData();
            formData.append("image", event.target.files[0]);
            formData.append("id", this.state.area.id);
            uploadImageArea(formData).then(result => {
                if (typeof result.error === "undefined") {
                    let area = this.state.area;
                    area.image = result.area.image;
                    this.setState({area: area});
                    global.notify("L'immagine è caricata", "success");
                } else {
                    global.notify("L'immagine non è stata caricata", "danger");
                }
            });
        }
    };

    onLogoChange = event => {
        if (event.target.files && event.target.files[0]) {
            var formData = new FormData();
            formData.append("image", event.target.files[0]);
            formData.append("id", this.state.area.id);
            uploadLogoArea(formData).then(result => {
                if (typeof result.error === "undefined") {
                    let area = this.state.area;
                    area.logo = result.area.logo;
                    this.setState({area: area});
                    global.notify("Il logo è caricato", "success");
                } else {
                    global.notify("Il logo non è stato caricato", "danger");
                }
            });
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Galleria Immagini Zona"/>
                        <Card>
                            <CardBody>
                                <div className={"row"}>
                                    <div className="form-group col-md-6">
                                        <Label for="society">Ente</Label>
                                        {this.renderSociety()}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <Label for="area">Area</Label>
                                        {this.renderArea()}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h5>Caricamento immagini principali</h5>
                                <div className="row">
                                    <Card className={'col-md-6'}>
                                        <CardBody>
                                            <h6>Scegli sfondo</h6>
                                            <div align={"center"}>
                                                <img
                                                    src={(this.state.area !== null && this.state.area.image !== null) ? global.baseUrlImg + '/' + this.state.area.image : ""}
                                                    style={{width: "100%"}}/>
                                            </div>
                                            <hr/>
                                            <div align={"center"}>
                                                <input type="file" name="image" onChange={this.onImageChange}/>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className={'col-md-6'}>
                                        <CardBody>
                                            <h6>Scegli logo</h6>
                                            <div align={"center"}>
                                                <img
                                                    src={(this.state.area !== null && this.state.area.logo !== null) ? global.baseUrlImg + '/' + this.state.area.logo : ""}
                                                    style={{maxHeight: "35vh"}}/>
                                            </div>
                                            <hr/>
                                            <div align={"center"}>
                                                <input type="file" name="logo" onChange={this.onLogoChange}/>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h5>Galleria immagini</h5>
                                <small>(massimo 10 immagini)</small>
                                <ImageUploading
                                    multiple
                                    value={this.state.gallery}
                                    onChange={this.uploadImageGallery}
                                    maxNumber={10}
                                    dataURLKey="image"
                                >
                                    {({
                                          imageList,
                                          onImageUpload,
                                      }) => (
                                        <div className="upload__image-wrapper">
                                            <div align={"center"} className={"mb-2"}>
                                                <Button type="button" color="info" onClick={onImageUpload}>Carica
                                                    immagine</Button>
                                            </div>
                                            <div className={"row"}>
                                                {imageList.map((image, index) => (
                                                    <div key={index} className="col-md-2">
                                                        <img src={global.baseUrlImg + '/' + image['image']} alt=""
                                                             style={{width: "100%"}}/>
                                                        <div className="image-item__btn-wrapper" align={"center"}
                                                             style={{marginTop: 4}}>
                                                            <Button type="button" color="danger"
                                                                    onClick={() => this.removeImageGallery(image.index)}>Rimuovi</Button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </ImageUploading>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
}

export default withRouter(connect(mapStatetoProps, {})(ZoneImagePage));