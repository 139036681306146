import React, {Component} from "react";
import {Row, Col, Card, CardBody, Container, Alert, Button, ButtonGroup, CardTitle, CardText} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    addSocTicket,
    editSocTicket,
    infoSocTicket,
    getSocietyListAreas,
    ticketResidenceList
} from '../../store/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';
import "./society.scss";
import {DataGrid} from "@material-ui/data-grid";

var moment = require('moment');

class SocTicketAddPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [{headerName: "Nome area", field: "name", flex: 1},
                {
                    headerName: "Categoria",
                    field: "category",
                    flex: 1,
                    renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", flex: 1},
                {headerName: "Province", field: "province", flex: 1},
                {headerName: "Indirizzo", field: "address", flex: 1},
                {headerName: "Numero", field: "number", width: 150},
                {headerName: "Città", field: "city", flex: 1},
                {headerName: "CAP", field: "cap", width: 150},
                {headerName: "Telefono", field: "phone", flex: 1},
                {
                    headerName: "MicroArea",
                    width: 150,
                    field: 'id',
                    // renderCell: (params) => (Object.keys(params.getValue(params.id, "micro_areas")).length > 0 ?
                    //     <Button type="button" color="primary">></Button> : " ")
                }],
            columnsMicroArea: [{headerName: "Nome micro area", field: "name", flex: 1}],
            rows: [],
            rowsMicroArea: [],
            form_edit: {
                id_area: null,
                id_micro_area: null,
                id_ticket: null,
                name: null,
                price: null,
                date_start_validity: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
                date_end_validity: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
                type: "DAILY",
                id_residence_type: null,
                maximum_use: null,
                is_hidden: null
            },
            date_start: new Date(),
            date_end: new Date(),
            date_current: new Date(),
            //blackout_dates: [],
            flag_edit: false,
            showArea: true,
            paginator: {loading: true, countRows: 0, limit: 50},
            area: null,
            micro_area: null,
            ticket: null,
            residenceTypes: []

        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async handleValidSubmit(event, values, edit = false) {
        if (this.state.area === null) {
            global.notify("Devi selezionare l'area a cui associare il ticket", "danger");
            return;
        }
        values.id_area = this.state.area.id;
        if (this.state.micro_area !== null)
            values.id_micro_area = this.state.micro_area.id;

        if (this.state.flag_edit) {
            values.id = this.state.ticket.id;
            let formResult = await editSocTicket(values);

            if (typeof formResult.ticket !== 'undefined') {
                global.notify("Ticket modificato con successo", "success");
                this.form && this.form.reset();
            } else if (typeof formResult.error !== 'undefined') {
                global.notify("Errore con la modifica: " + formResult.error, "danger");

                this.setState({error: formResult.error});
            } else {
                this.setState({error: 'Errore generico'});
            }
            return;
        }
        let formResult = await addSocTicket(values);

        if (typeof formResult.ticket !== 'undefined') {
            global.notify("Ticket aggiunto con successo", "success");
            this.form && this.form.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con l'aggiunta: " + formResult.error, "danger");

            this.setState({error: formResult.error});
        } else {
            this.setState({error: 'Errore generico'});
        }
    }

    actionButton = (params) => {
        this.setState({form_edit: params.data, flag_edit: true});
    }

    async componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined" && this.props.match.params.id !== null) {
            let res = await infoSocTicket(this.props.match.params.id);
            if (typeof res.error !== "undefined") {
                global.notify("Errore: " + res.error, "danger");
                return;
            }
            let ticket = res.ticket;
            let form_edit = {
                name: ticket.name,
                price: ticket.price,
                date_start_validity: ticket.date_start_validity,
                date_end_validity: ticket.date_end_validity,
                type: ticket.type,
                id_residence_type: (ticket.ticket_residence_type !== null) ? ticket.ticket_residence_type.id : null,
                maximum_use: (ticket.maximum_usage !== null) ? ticket.maximum_usage : null,
                is_hidden: ticket.is_hidden
            };
            this.setState({
                area: ticket.area,
                micro_area: typeof ticket.micro_area !== "undefined" ? ticket.micro_area : null,
                flag_edit: true,
                form_edit: form_edit,
                ticket: ticket,
                date_start: moment(ticket.date_start_validity, "DD/MM/YYYY").toDate(),
                date_end: moment(ticket.date_end_validity, "DD/MM/YYYY").toDate()
            })
        }
        let residenceTypes = await ticketResidenceList();
        if (typeof residenceTypes.error === "undefined")
            this.setState({residenceTypes: residenceTypes.list})

        this.showArea(true);
    }

    setDate = (date, type) => {
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start_validity = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end_validity = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    /*setBlackoutDate = (day) => {
        let days = this.state.blackout_dates;
        if (typeof days[day] !== "undefined")
            delete days[day];
        else
            days[day] = true;
        this.setState({blackout_dates: days});
    }*/

    showArea = (state) => {
        if (!state && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        if (!state && (typeof this.state.area.micro_areas === "undefined" || Object.keys(this.state.area.micro_areas).length === 0)) {
            global.notify("Non ci sono micro aree presenti per quest'area", "warning");
            return;
        }

        this.setState({showArea: state});
        if (state) {
            if (Object.keys(this.state.rows).length === 0)
                this.loadListAreas(0);
        }
    }

    loadListAreas = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getSocietyListAreas().then(result => {
            paginator.loading = false;
            if (!result) {
                this.setState({rows: [], paginator: paginator});
            } else {
                this.setState({rows: result.list, rowsArea: result.list, paginator: paginator});
            }
        })
    }

    handlePageChange = (params) => {
        this.loadListAreas(params.page)
    };

    handleRowSelected = (rows) => {
        let row = [...rows.entries()][0][1];
        if (this.state.showArea)
            this.setState({
                area: row,
                rowsMicroArea: typeof row.micro_areas !== "undefined" ? row.micro_areas : []
            }, () => this.showArea(false));
        else
            this.setState({micro_area: row}, () => this.showArea(false));
    }


    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumb title="Geoticket" breadcrumbItem="Ticket"/>
                        <Row>
                            <Col lg="12">
                                {this.props.error && this.props.error ?
                                    <Alert color="danger">{this.props.error}</Alert> : null}
                                {this.props.success && this.props.success ?
                                    <Alert color="success">{this.props.success}</Alert> : null}
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>

                                <div className="text-center mb-4">
                                    <ButtonGroup>
                                        <Button
                                            onClick={() => this.showArea(true)}
                                            color={(!this.state.showArea) ? "light" : "primary"}>Area</Button>
                                        <Button
                                            onClick={() => this.showArea(false)}
                                            color={(this.state.showArea) ? "light" : "primary"}>MicroArea</Button>
                                    </ButtonGroup>
                                </div>
                                <div style={{height: '40vh', width: '100%'}} hidden={!this.state.showArea}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        pagination
                                        pageSize={this.state.paginator.limit}
                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                        rowCount={this.state.paginator.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageChange}
                                        loading={this.state.paginator.loading}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div style={{height: '40vh', width: '100%'}} hidden={this.state.showArea}>
                                    <DataGrid
                                        rows={this.state.rowsMicroArea}
                                        columns={this.state.columnsMicroArea}
                                        pageSize={Object.keys(this.state.rowsMicroArea).length}
                                        rowsPerPageOptions={[Object.keys(this.state.rowsMicroArea).length]}
                                        rowCount={Object.keys(this.state.rowsMicroArea).length}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div className={"row"}>
                                    {this.state.area !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Area</CardTitle>
                                                <CardText>Nome area: {this.state.area.name}<br/> {this.state.area.address},
                                                    n°{this.state.area.number} {this.state.area.city} {this.state.area.region}
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.micro_area !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Micro Area</CardTitle>
                                                <CardText>Nome micro area: {this.state.micro_area.name}</CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                </div>
                                <br/>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v,) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }}>
                                    <div className={"row"}>
                                        <div className="form-group col-md-6">
                                            <AvField name="name" label="Nome" value={this.state.form_edit.name}
                                                     className="form-control" placeholder="Enter product name"
                                                     type="text" required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="price" label="Prezzo" value={this.state.form_edit.price}
                                                     className="form-control" placeholder="Enter product price"
                                                     type="text" required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="date_start_validity" hidden
                                                     label="Data inizio"
                                                     value={this.state.form_edit.date_start_validity}
                                                     className="form-control"
                                                     placeholder="Enter start date" type="text"
                                                     required/>
                                            <DatePicker selected={this.state.date_start}
                                                        startDate={this.state.date_current} className="form-control"
                                                        onChange={(date) => this.setDate(date, "start")}
                                                        dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="date_end_validity" hidden label="Data fine"
                                                     value={this.state.form_edit.date_end_validity}
                                                     className="form-control"
                                                     placeholder="Enter end date" type="text"
                                                     required/>
                                            <DatePicker selected={this.state.date_end}
                                                        minDate={this.state.date_start} className="form-control"
                                                        onChange={(date) => this.setDate(date, "end")}
                                                        dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="type" label="Tipo" value={this.state.form_edit.type}
                                                     className="form-control" placeholder="Enter product type"
                                                     type="select" required>
                                                <option value={"DAILY"}>Giornaliero</option>
                                                <option value={"TWODAYS"}>Bigiornaliero</option>
                                                <option value={"THREEDAYS"}>Tre giorni</option>
                                                <option value={"FOURDAYS"}>Quattro giorni</option>
                                                <option value={"WEEKDAYS"}>Cinque giorni</option>
                                                <option value={"WEEKLY"}>Settimanale</option>
                                                <option value={"TWOWEEKS"}>Bisettimanale</option>
                                                <option value={"MONTHLY"}>Mensile</option>
                                                <option value={"TRIMESTER"}>Trimestrale</option>
                                                <option value={"SEASONAL"}>Stagionale</option>
                                                <option value={"SEMIANNUAL"}>Semestrale</option>
                                                <option value={"YEARLY"}>Annuale</option>
                                                <option value={"BIANNUAL"}>Biennale</option>
                                                <option value={"TRIENNIAL"}>Triennale</option>
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="id_residence_type" label="Tipologia residenza"
                                                     value={this.state.form_edit.id_residence_type}
                                                     className="form-control" placeholder="Inserisci la residenza"
                                                     type="select" required>
                                                {this.state.residenceTypes.map((data, i) => {
                                                    return <option value={data.id}>{data.name}</option>
                                                })}
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="maximum_use" label="Numero massimo di utilizzi del ticket"
                                                     value={this.state.form_edit.maximum_use}
                                                     className="form-control" placeholder="Inserisci numero"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField type="checkbox" className="form-check-input"
                                                     id="is_hidden" name="is_hidden"
                                                     checked={this.state.form_edit.is_hidden}
                                                     label="Ticket nascoto in APP?"/>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="submit"
                                                color="danger">{(this.state.flag_edit) ? "Modifica" : "Crea"}</Button>
                                    </div>
                                </AvForm>

                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

/*
                                        <div className="form-group col-4">
                                            <AvField name="blackout_dates" label="Giorni di lavoro"
                                                     value={this.state.form_edit.blackout_dates}
                                                     className="form-control" placeholder="Enter blockout date"
                                                     type="text" hidden/>

                                            <ButtonGroup>
                                                <Button
                                                    onClick={() => this.setBlackoutDate("monday")}
                                                    active={typeof this.state.blackout_dates["monday"] !== "undefined"}>Lun</Button>
                                                <Button
                                                    onClick={() => this.setBlackoutDate("tuesday")}
                                                    active={typeof this.state.blackout_dates["tuesday"] !== "undefined"}>Mar</Button>
                                                <Button
                                                    onClick={() => this.setBlackoutDate("wednesday")}
                                                    active={typeof this.state.blackout_dates["wednesday"] !== "undefined"}>Mer</Button>
                                                <Button
                                                    onClick={() => this.setBlackoutDate("thursday")}
                                                    active={typeof this.state.blackout_dates["thursday"] !== "undefined"}>Gio</Button>
                                                <Button
                                                    onClick={() => this.setBlackoutDate("friday")}
                                                    active={typeof this.state.blackout_dates["friday"] !== "undefined"}>Ven</Button>
                                                <Button
                                                    onClick={() => this.setBlackoutDate("saturday")}
                                                    active={typeof this.state.blackout_dates["saturday"] !== "undefined"}>Sab</Button>
                                                <Button
                                                    onClick={() => this.setBlackoutDate("sunday")}
                                                    active={typeof this.state.blackout_dates["sunday"] !== "undefined"}>Dom</Button>
                                            </ButtonGroup>
                                        </div>
 */

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {addSocTicket, getSocietyListAreas})(SocTicketAddPage));
