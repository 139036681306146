import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Alert,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ButtonGroup
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {
    getCategory,
    setCategory,
    editCategory,
    ImgCategory,
    deleteCategory,
    apiError,
    uploadIconCategory
} from '../../../store/actions';
import Breadcrumb from '../../../components/Common/Breadcrumb';

class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "Immagine",
                    width: 140,
                    field: 'image',
                    renderCell: (params) => (
                        <img src={global.baseUrlImg + params.value} alt="" max-height="100" width="100"/>
                    )

                },
                {
                    headerName: "Icona",
                    width: 140,
                    field: 'icon',
                    renderCell: (params) => (
                        <img src={global.baseUrlImg + params.value} alt="" max-height="100" width="100"/>
                    )

                },
                {
                    headerName: 'Nome',
                    field: 'name',
                    flex: 1
                },
                {
                    headerName: " ",
                    width: 160,
                    field: 'id',
                    renderCell: (params) => (
                        <ButtonGroup>
                            <Button onClick={() => this.imgButton(params)} type="button" color="secondary">Img</Button>
                            <Button onClick={() => this.iconButton(params)} type="button" color="info">Icona</Button>
                            <Button onClick={() => this.actionButton(params)} type="button"
                                    color="primary">Modifica</Button>
                        </ButtonGroup>
                    )
                }
            ],
            rows: [],
            form_edit: {
                id: null,
                name: null,
            },
            paginator: {loading: true, page: 0, countRows: 0, limit: 50},
            flag_edit: false,
            image: null,
            modal: false,
            modalIcon: false,
            imageUpload: null,
            uploadId: null,
            icon: null,
            iconUpload: null
        };
    }

    handleClose = () => this.setState({modal: false, modalIcon: false, imageUpload: null, uploadId: null, image: null,  iconUpload: null,  icon: null});

    uploadImage = () => {
        var formData = new FormData();
        formData.append("image", this.state.imageUpload);
        formData.append("id", this.state.uploadId);

        ImgCategory(formData).then(result => {
            this.loadListCategories();
            this.setState({modal: false, imageUpload: null, uploadId: null, image: null});
        })
    }

    uploadIcon = () => {
        var formData = new FormData();
        formData.append("image", this.state.iconUpload);
        formData.append("id", this.state.uploadId);

        uploadIconCategory(formData).then(result => {
            this.loadListCategories();
            this.setState({modalIcon: false, iconUpload: null, uploadId: null, icon: null});
        })
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                image: URL.createObjectURL(img),
                imageUpload: event.target.files[0]
            });
        }
    };

    onIconChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                icon: URL.createObjectURL(img),
                iconUpload: event.target.files[0]
            });
        }
    };

    async deleteCategory(id) {
        let formResult = null;
        formResult = await deleteCategory(id);

        if (formResult) {
            this.setState({error: false, success: 'Azione eseguita con successo'});
            this.loadListCategories();
        } else {
            this.setState({error: 'Errore generico'});
        }
    }

    async handleValidSubmit(event, values, edit = false) {
        let formResult = null;
        if (edit) {
            formResult = await editCategory(values);
        } else {
            formResult = await setCategory(values);
        }
        console.log(formResult)
        if (typeof formResult.category !== 'undefined') {
            this.setState({error: false, success: 'Azione eseguita con successo'}, function () {
            });
            this.loadListCategories();
        } else if (typeof formResult.error !== 'undefined') {
            this.setState({error: formResult.error}, function () {
            });
        } else {
            this.setState({error: 'Errore generico'}, function () {
            });
        }
        this.form && this.form.reset();
        window.scrollTo(0, 0);
    }

    actionButton = (params) => {
        this.setState({form_edit: params.row, flag_edit: true});
    }

    imgButton = (params) => {
        this.setState({modal: true, uploadId: params.value});
    }

    iconButton = (params) => {
        this.setState({modalIcon: true, uploadId: params.value});
    }

    async componentDidMount() {
        this.loadListCategories();
    }

    loadListCategories = () => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getCategory().then(result => {
            paginator.loading = false;

            if (!result) {
                this.setState({rows: []});
            } else {
                this.setState({rows: result.list});
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Category"/>
                        <Row>
                            <Col lg="12">
                                {this.state.error && this.state.error ?
                                    <Alert color="danger">{this.state.error}</Alert> : null}
                                {this.state.success && this.state.success ?
                                    <Alert color="success">{this.state.success}</Alert> : null}

                                <Card>
                                    <CardBody>
                                        <div style={{height: 400, width: '100%'}}>
                                            <DataGrid
                                                rows={this.state.rows}
                                                columns={this.state.columns}
                                                pagination
                                                pageSize={this.state.paginator.limit}
                                                rowsPerPageOptions={[this.state.paginator.limit]}
                                                rowCount={this.state.paginator.countRows}
                                                paginationMode="server"
                                                onPageChange={this.handlePageChange}
                                                loading={this.state.paginator.loading}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Card>
                            <CardBody>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    {(this.state.flag_edit) ?
                                        <div className="form-group">
                                            <AvField name="id" label="Id categoria" value={this.state.form_edit.id}
                                                     className="form-control" placeholder="Inserisci id categoria"
                                                     type="text" required/>
                                        </div> : ""
                                    }
                                    <div className="form-group">
                                        <AvField name="name" label="Nome categoria" value={this.state.form_edit.name}
                                                 className="form-control" placeholder="Inserisci nome categoria"
                                                 type="text" required/>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="submit"
                                                color="danger">{(this.state.flag_edit) ? "Modifica Categoria" : "Crea Categoria"}</Button>
                                    </div>
                                </AvForm>

                            </CardBody>
                        </Card>
                        <Modal
                            isOpen={this.state.modal}
                            backdrop="static"
                            keyboard={false}>
                            <ModalHeader>Immagine categoria</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg="12">
                                        <img src={this.state.image}/>
                                        <h1>Scegli immagine</h1>
                                        <input type="file" name="myImage" onChange={this.onImageChange}/>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.uploadImage}>Carica</Button>{' '}
                                <Button color="secondary" onClick={this.handleClose}>Annulla</Button>
                            </ModalFooter>
                        </Modal>
                        <Modal
                            isOpen={this.state.modalIcon}
                            backdrop="static"
                            keyboard={false}>
                            <ModalHeader>Icona categoria</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg="12">
                                        <img src={this.state.icon}/>
                                        <h1>Scegli immagine</h1>
                                        <input type="file" name="myImage" onChange={this.onIconChange}/>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.uploadIcon}>Carica</Button>{' '}
                                <Button color="secondary" onClick={this.handleClose}>Annulla</Button>
                            </ModalFooter>
                        </Modal>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(
    connect(
        mapStatetoProps, {getCategory, setCategory, editCategory, deleteCategory, apiError}
    )
    (Category));