if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
    // var baseUrl = 'https://newdevapi.geoticket.it/api/';
    var baseUrl = 'https://api.geoticket.it/api/';
    // global.baseUrlImg = 'https://newdevapi.geoticket.it';
    global.baseUrlImg = 'https://api.geoticket.it';
    global.baseUrl = baseUrl;
}

const getLoggedInUser = () => {
    const user = localStorage.getItem('authUser');
    if (user)
        return JSON.parse(user);
    return null;
}

const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

const apiCall = async (params) => {
    let body = (params['data']) ? global.getFormData(params['data']) : null;
    let result = await fetch(baseUrl + params['url'], {
        method: params['method'],
        credentials: 'include',
        body: body
    })
        .then((response) => {
            if (response.status === 401) {
                // localStorage.removeItem("authUser");
                // window.location.href = '/login';
                return false;
            }
            return (response.status !== 500) ? response.json() : false
        })
        .then((responseJson) => {
            return responseJson;
        })
        .catch((err) => {
            return err;
        });
    return result;
}

const UploadFile = async (params) => {
    let result = await fetch(baseUrl + params['url'], {
        method: params['method'],
        credentials: 'include',
        body: params['data']
    })
        .then((response) => {
            if (response.status === 401) {
                // localStorage.removeItem("authUser");
                // window.location.href = '/login';
                return false;
            }
            return (response.status !== 500) ? response.json() : false
        })
        .then((responseJson) => {
            return responseJson;
        })
        .catch((err) => {
            return err;
        });
    return result;
}

const postAccount = async (data) => {
    let result = fetch(baseUrl + 'permission', {
        method: "POST",
        credentials: 'include',
    })
        .then((response) => (response.status !== 500) ? response.json() : false)
        .then((responseJson) => {
            if (typeof responseJson.user !== 'undefined') {
                return responseJson.user;
            } else if (typeof responseJson.error !== 'undefined') {
                return responseJson.error;
            }
        })
        .catch((err) => {
            return err;
        });
    return result;
}

export {
    getLoggedInUser,
    isUserAuthenticated,
    apiCall,
    UploadFile,
    postAccount,
}