import React, {Component} from 'react';
import {Container, Row, Col, Card, Alert, CardBody, Media, Button} from "reactstrap";

// availity-reactstrap-validation
import {AvForm, AvField} from 'availity-reactstrap-validation';

// Redux
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';

// actions
import {editProfile, login, permission, updatePassword} from "../../store/account";

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {user: null, email: "", firstname: "", lastname: "", idx: 1}
    }

    handleValidSubmit(event, values) {
        editProfile({email: values.email, firstname: values.firstname, lastname: values.lastname}).then((result) => {
            if (typeof result.user !== "undefined") {
                localStorage.setItem("authUser", JSON.stringify(result));
                global.notify("Dati modificati con successo", "success");
                this.setState({user: result.user});
                return;
            }
            if (typeof result.error !== "undefined")
                global.notify("Errore: " + result.error, "danger");
            else
                global.notify("Errore generico", "danger");
        });
    }

    handleValidSubmitPassword(event, values) {
        updatePassword({old_password: values.old_password, password: values.password}).then((result) => {
            if (typeof result.user !== "undefined") {
                localStorage.setItem("authUser", JSON.stringify(result));
                global.notify("Password modificata con successo", "success");
                return;
            }
            if (typeof result.error !== "undefined")
                global.notify("Errore: " + result.error, "danger");
            else
                global.notify("Errore generico", "danger");
        });
    }

    componentDidMount() {
        permission().then((result) => {
            if (typeof result.user !== "undefined")
                this.setState({user: result.user});
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Profilo"/>
                        {this.state.user === null ? <></> :
                            <>
                                <Card>
                                    <CardBody>
                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                            this.handleValidSubmit(e, v)
                                        }} ref={c => (this.form = c)}>
                                            <h5>Modifica dati</h5>
                                            <hr/>
                                            <div className="row">
                                                <div className="form-group col-md-12">
                                                    <AvField name="email" label="Email"
                                                             value={this.state.user.email}
                                                             className="form-control"
                                                             placeholder="Email"
                                                             type="email"
                                                             required/>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <AvField name="name" label="Nome"
                                                             value={this.state.user.firstname}
                                                             className="form-control"
                                                             placeholder="Nome"
                                                             type="text"
                                                             required/>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <AvField name="lastname" label="Cognome"
                                                             value={this.state.user.lastname}
                                                             className="form-control"
                                                             placeholder="Cognome"
                                                             type="text"
                                                             required
                                                    />
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <div className="text-center">
                                                        <Button type="submit" color={"success"}>Modifica</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                            this.handleValidSubmitPassword(e, v)
                                        }} ref={c => (this.form = c)}>
                                            <h5>Modifica Password</h5>
                                            <hr/>
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <AvField name="old_password" label="Vecchia password"
                                                             className="form-control"
                                                             placeholder="Vecchia password"
                                                             type="password" required/>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <AvField name="password" label="Nuova password"
                                                             className="form-control"
                                                             placeholder="Nuova password"
                                                             type="password" required/>
                                                </div>
                                                <div className="form-group col-md-12">
                                                    <div className="text-center">
                                                        <Button type="submit" color={"danger"}>Modifica</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </>
                        }


                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {error, success} = state.Profile;
    return {error, success};
}

export default withRouter(connect(mapStatetoProps, {editProfile})(UserProfile));

