import React from 'react';
import {Row, Col, Card, CardBody, Container, Alert, Button} from "reactstrap";


export default (props) => {
    console.log(props);
    const data = props.data
    const actionButton = (data) => {
        // console.log(this)
        // this.setState({ form_edit: data, flag_edit: true }, function () { });
    };
    return (
        <span>
                <Button color="primary" onClick={() => actionButton(data)}>Edit</Button>
            </span>
    );

}