import {apiCall, UploadFile} from '../../../helpers/backend_helper';

export const getListRegion = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/region/list'});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setRegion = async (region) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/region/add', data: region});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}
export const editRegion = async (region) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/region/edit', data: region});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const ImgRegion = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await UploadFile({method: 'POST', url: 'admin/region/upload', data: data});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}