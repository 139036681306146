import React, {Component} from "react";
import {Row, Col, Card, CardTitle, CardText, CardBody, Container, Button, Badge, ButtonGroup, Input} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Redux
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
//i18n
import {withTranslation} from 'react-i18next';
import {AvForm, AvField} from 'availity-reactstrap-validation';

import {infoClientTicket, listClientTickets, listTicketClientPackage, refundClientTicket} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {windowOpen} from "echarts/src/util/format";

var moment = require('moment');

class TicketClientPackageListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "Nome pacchetto", field: "shop_package", width: 320, renderCell: (params) => (
                        <Row>
                            <Col>{params.value.name}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Tipologia",
                    field: "shop_package1",
                    width: 160,
                    renderCell: (params) => {
                        if (params.getValue(params.id, "shop_package") !== null) {
                            return <Row><Col>{this.convertType(params.getValue(params.id, "shop_package").type)}</Col></Row>;
                        }
                        return <></>;
                    }
                },
                {
                    headerName: "Data acquisto", field: "ticket_client", width: 160, renderCell: (params) => (
                        <Row>
                            <Col>{params.value.date_purchase}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Email", field: "ticket_client1", width: 240, renderCell: (params) => (
                        <Row>
                            <Col>{params.getValue(params.id, "ticket_client").client.email}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Nominativo", field: "ticket_client2", width: 240, renderCell: (params) => (
                        <Row>
                            <Col>{params.getValue(params.id, "ticket_client").nickname}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Prezzo", field: "price", width: 160, renderCell: (params) => (
                        <Row>
                            <Col>€ {params.value.toFixed(2)}</Col>
                        </Row>)
                },
                {
                    headerName: " ",
                    width: 100,
                    field: 'id',
                    renderCell: (params) => (
                        <Button
                            onClick={() => this.infoButton(params.row, params.getValue(params.id, "ticket_client").id)}
                            type="button" color="info">Info</Button>
                    )
                }
            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            form_edit: {
                date_start: moment().subtract('1', "month").format("DD/MM/YYYY"),
                date_end: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
            },
            date_start: (moment().subtract('1', "month").toDate()),
            date_end: new Date(),
            date_current: new Date(),
            ticket: null,
            package: null,
            type: null,
            sessions: []
        };
    }

    setDate = (date, type) => {
        console.log(date);
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    async componentDidMount() {
        this.loadList(0);
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});
        let data = {
            page: page + 1,
            date_start: this.state.form_edit.date_start,
            date_end: this.state.form_edit.date_end
        }
        if (this.state.type !== null)
            data.type = this.state.type;
        listTicketClientPackage(data).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    async infoButton(pack, params) {
        console.log("pack")
        console.log(pack)
        let res = await infoClientTicket(params);

        if (Object.keys(res.sessions).length === 0) {
            this.setState({ticket: res.ticket, sessions: []});
        }
        let sessions = [];
        for (let i in res.sessions) {
            let session = res.sessions[i];
            let captures = [];
            for (let c in res.captures) {
                let capture = res.captures[c];
                if (typeof capture.score_capture_session !== "undefined" && capture.score_capture_session !== null) {
                    if (capture.score_capture_session.id === session.id)
                        captures.push(capture);
                }
            }
            sessions.push({
                id: session.id,
                id_ticket: res.ticket.id,
                name: "Sessione " + session.id,
                data: res.ticket.date_purchase,
                date_start: session.date_start,
                date_end: session.date_end,
                date_check: session.date_check,
                user_check: session.checked_by,
                area: res.ticket.ticket.area.name,
                micro_area: (res.ticket.ticket.micro_area != undefined) ? res.ticket.ticket.micro_area.name : '',
                count: captures.length,
                fishes: captures
            });
        }
        this.setState({ticket: res.ticket, sessions: sessions, package: pack});
    }

    changeType = (type) => {
        this.setState({type: type});
    }

    getAddressClient = (client) => {
        let address = null;
        if (client.address !== null)
            address += "via " + client.address + " ";
        if (client.number !== null)
            address += "n° " + client.number + " ";
        if (client.city !== null)
            address += " " + client.city + " ";
        if (client.cap !== null)
            address += " (" + client.cap + ") ";
        return address;
    }

    convertType = (type) => {
        switch (type) {
            case "INSURANCE":
                return "Assicurazione";
            case "RECOMMEND":
                return "Raccomandati";
            case "BOUGHT_TOGETHER":
                return "Comprato insieme";
            case "PRODUCT":
                return "Prodotto";
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista Pacchetti"/>
                        <Card>
                            <CardBody>
                                <h5>Scegli le date in cui vuoi visualizzare i pacchetti</h5>
                                <div className={"row"}>
                                    <div className="form-group col-md-3">
                                        <DatePicker selected={this.state.date_start}
                                                    startDate={this.state.date_current} className="form-control"
                                                    onChange={(date) => this.setDate(date, "start")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <DatePicker selected={this.state.date_end}
                                                    minDate={this.state.date_start} className="form-control"
                                                    onChange={(date) => this.setDate(date, "end")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <Input type="select"
                                               onChange={(event) => this.changeType(event.target.value)}>
                                            <option value={null}>Tipologia</option>
                                            <option value={null}>Tutti</option>
                                            <option value={"PRODUCT"}>Prodotti</option>
                                            <option value={"INSURANCE"}>Assicurazione</option>
                                            <option value={"RECOMMEND"}>Raccomandati</option>
                                            <option value={"BOUGHT_TOGETHER"}>Comprati insieme</option>
                                        </Input>
                                    </div>
                                    <div className="form-group col-md-3" style={{display: "flex"}}>
                                        <div style={{marginTop: "auto"}}>
                                            <ButtonGroup>
                                                <Button onClick={() => this.loadList(0)}
                                                        color={"success"}>Aggiorna</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="form-group">
                                    <div style={{height: '50vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            loading={this.state.paginator.loading}
                                        />
                                    </div>
                                </div>
                                <div className={"row"}>
                                    {this.state.ticket !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Ticket</CardTitle>
                                                <CardText>
                                                    <div className={"row"}>
                                                        <div className={"col-md-6"}>
                                                            Nome: {this.state.ticket.ticket.name}<br/>
                                                            Data acquisto: {this.state.ticket.date_purchase}<br/>
                                                            Data Utilizzo: {this.state.ticket.date_start_use}<br/>
                                                        </div>
                                                    </div>
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.package !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Pacchetto</CardTitle>
                                                <CardText>
                                                    <div className={"row"}>
                                                        <div className={"col-md-6"}>
                                                            Nome: {this.state.package.shop_package.name}<br/>
                                                            Tipologia: {this.convertType(this.state.package.shop_package.type)}<br/>
                                                            Prezzo: € {this.state.package.price}<br/>
                                                        </div>
                                                    </div>
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.ticket !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Dati utente</CardTitle>
                                                <CardText>
                                                    <div className={"row"}>
                                                        <div className={"col-md-6"}>
                                                            Email: {this.state.ticket.client.email}<br/>
                                                            Nome
                                                            cognome: {this.state.ticket.client.firstname} {this.state.ticket.client.lastname}<br/>
                                                            Nominativo ticket: {this.state.ticket.nickname}<br/>
                                                            Telefono: {this.state.ticket.client.phone !== null ? this.state.ticket.client.phone : "non impostato"}<br/>
                                                            Codice
                                                            fiscale: {this.state.ticket.client.fiscal_code !== null ? this.state.ticket.client.fiscal_code : "non impostato"}<br/>
                                                            Indirizzo: {this.getAddressClient(this.state.ticket.client) !== null ? this.getAddressClient(this.state.ticket.client.address) : "non impostato"}<br/>
                                                        </div>
                                                    </div>
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(TicketClientPackageListPage));
