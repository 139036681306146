import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button, ButtonGroup,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {
    getInfoSettings,
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import ReactQuill from "react-quill";
import {addBlog, uploadImage} from "../../../store/manager/blog/actions";

import './react.quill.css';
import {AvField} from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

class BlogAddPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            description: null,
            mainImage: null,
            languages: ['it'],
            languageSelected: 'it',
            titleList: {},
            descriptionList: {},
            url: null,
            buttonUrl: null,
            date_availability: null
        };
        this.quill = null;
    }

    async componentDidMount() {
        let settings = await getInfoSettings();
        if (typeof settings.error === "undefined") {
            this.setState({languages: settings.settings.languages});
        }
    }

    async handleValidSubmit() {
        if (this.state.mainImage === null) {
            global.notify("Immagine mancante", "danger");
            return;
        }
        if (this.state.titleList["it"] === "undefined" || this.state.titleList["it"] === "") {
            global.notify("Titolo mancante", "danger");
            return;
        }
        if (this.state.descriptionList["it"] === "undefined" || this.state.descriptionList["it"] === "") {
            global.notify("Descrizione mancante", "danger");
            return;
        }


        let data = {
            title: this.state.titleList["it"],
            body: this.state.descriptionList["it"],
            image: this.state.mainImage,
            titleList: JSON.stringify(this.state.titleList),
            bodyList: JSON.stringify(this.state.descriptionList),
            sku: this.state.url
        };
        if (this.state.date_availability !== null)
            data.date_availability = moment.parseZone(this.state.date_availability).local().format("DD/MM/YYYY HH:mm");
        if (this.state.buttonUrl !== null) {
            try {
                let url = new URL(this.state.buttonUrl);
                data.buttonUrl = this.state.buttonUrl;
            } catch (e) {
                global.notify("Url del buttone non valido", "danger");
                return false;
            }
        }

        let formResult = await addBlog(data);
        if (typeof formResult.blog !== 'undefined') {
            global.notify("Post aggiunto con successo", "success");
            window.location.href = '/blog/edit/' + formResult.blog.id
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con l'aggiunta: " + formResult.error, "danger");

            this.setState({error: formResult.error});
        } else {
            this.setState({error: 'Errore generico'});
        }
    }

    onMainImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            var formData = new FormData();
            formData.append("image", event.target.files[0]);
            uploadImage(formData).then((result) => {
                if (typeof result.error === "undefined") {
                    this.setState({mainImage: result.url});
                    global.notify("L'immagine è caricata", "success");
                } else {
                    global.notify("L'immagine non è stata caricata", "danger");
                }
            });
        }
    };

    imageHandler() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            var file = input.files[0];
            var formData = new FormData();

            formData.append('image', file);
            uploadImage(formData).then((res) => {
                if (typeof res.url !== "undefined") {
                    const range = this.quill.getSelection();
                    this.quill.insertEmbed(range.index, 'image', global.baseUrlImg + res.url);
                }
            });
        };
    }

    changeLanguage = (lng) => {
        let description = "";
        if (typeof this.state.descriptionList[lng] !== "undefined")
            description = this.state.descriptionList[lng];
        let title = "";
        if (typeof this.state.titleList[lng] !== "undefined")
            title = this.state.titleList[lng];
        this.setState({languageSelected: lng, description: description, title: title});

    }

    renderLanguages = () => {
        let res = [];
        for (let i in this.state.languages) {
            res.push(<Button
                onClick={() => this.changeLanguage(this.state.languages[i])}
                color={(this.state.languages[i] === this.state.languageSelected) ? "light" : "primary"}>{this.state.languages[i]}</Button>);
        }
        return res;
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem={"Aggiunta blog"}/>
                        <Card>
                            <CardBody>
                                <Card>
                                    <CardBody>
                                        <h6>Scegli immagine copertina</h6>
                                        <div align={"center"}>
                                            <img
                                                src={(this.state.mainImage !== null) ? global.baseUrlImg + '/' + this.state.mainImage : ""}
                                                style={{maxHeight: "35vh"}}/>
                                        </div>
                                        <hr/>
                                        <div align={"center"}>
                                            <input type="file" name="logo" onChange={this.onMainImageChange}/>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="text-center mb-4">
                                    <ButtonGroup>
                                        {this.renderLanguages()}
                                    </ButtonGroup>
                                </div>
                                <div className="form-horizontal">
                                    <div className={"row"}>
                                        <div className="form-group col-md-12">
                                            <label>Titolo dell'articolo</label>
                                            <input name="title" label="Titolo dell'articolo" value={this.state.title}
                                                   className="form-control" placeholder="Titolo dell'articolo"
                                                   type="text" required onChange={(e) => {
                                                let titleList = this.state.titleList;
                                                titleList[this.state.languageSelected] = e.target.value;
                                                let url = e.target.value.toLowerCase().replace(/[^\w\s]/g, '-').replace(/\s+/g, '-').replace(/-{2,}/g, '-')
                                                this.setState({title: e.target.value, titleList: titleList, url: url});
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="form-group col-md-12">
                                            <label>Url dell'articolo</label>
                                            <input name="url" label="Indirizzo dell'articolo" value={this.state.url}
                                                   maxlength={128}
                                                   className="form-control" placeholder="Titolo dell'articolo"
                                                   type="text" required onChange={(e) => {
                                                let value = e.target.value.toLowerCase().replace(/[^\w\s]/g, '-').replace(/\s+/g, '-').replace(/-{2,}/g, '-')
                                                this.setState({url: value});
                                            }}/>
                                            <small><a href={"https://geoticket.it/blog/" + this.state.url}
                                                      target={"_blank"}>https://geoticket.it/blog/{this.state.url}</a></small>
                                        </div>
                                    </div>
                                    <hr/>
                                    <p><b>Descrizione</b></p>
                                    <ReactQuill theme="snow"
                                                value={this.state.description}
                                                ref={(ref) => this.quill = ref}
                                                modules={{
                                                    toolbar: {
                                                        container: [
                                                            [
                                                                {header: [1, 2, 3, 4, 5, 6, false]},
                                                            ],
                                                            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'line'],
                                                            [
                                                                {list: 'ordered'},
                                                                {list: 'bullet'},
                                                                {align: []},
                                                            ],
                                                            [{color: []}],
                                                            ['link', 'image'],
                                                            ['clean'],
                                                        ],
                                                        handlers: {
                                                            image: this.imageHandler
                                                        }
                                                    },
                                                    clipboard: {
                                                        matchVisual: false,
                                                    },
                                                }}
                                                formats={[
                                                    'header',
                                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                    'list', 'bullet', 'indent',
                                                    'link',
                                                    'color',
                                                    'image',
                                                    'formats/style', 'style', 'line', 'align', 'clean', 'hr'
                                                ]}
                                                onChange={(value) => {
                                                    let descriptionList = this.state.descriptionList;
                                                    descriptionList[this.state.languageSelected] = value;
                                                    this.setState({
                                                        description: value,
                                                        descriptionList: descriptionList
                                                    });
                                                }}/>
                                    <hr/>
                                    <div className={"row"}>
                                        <div className="form-group col-md-12">
                                            <label>Url del buttone</label>
                                            <input name="url" label="Indirizzo del buttone" value={this.state.buttonUrl}
                                                   maxLength={128}
                                                   className="form-control" placeholder="Indirizzo dell buttone"
                                                   type="text" required onChange={(e) => {
                                                this.setState({buttonUrl: e.target.value});
                                            }}/>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Data pubblicazione del post</label>
                                            <DatePicker selected={this.state.date_availability}
                                                        minDate={new Date()} className="form-control"
                                                        onChange={(date) => this.setState({date_availability: date})}
                                                        dateFormat="dd/MM/yyyy HH:mm"
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="button" color="success" onClick={() => {
                                            this.handleValidSubmit();
                                        }}>Aggiungi articolo</Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps,
    {}
)(BlogAddPage));
