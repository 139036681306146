import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    Badge,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, ButtonGroup
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {fileRules, listTickets} from '../../../store/actions';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleMinus, faCoffee, faEyeSlash, faTrash} from '@fortawesome/free-solid-svg-icons'
import {AvField, AvForm} from "availity-reactstrap-validation";

class TicketListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "Nome ticket", field: "name", width: 180, renderCell: (params) => {
                        let name = [];
                        if (params.getValue(params.id, "is_hidden"))
                            name.push(<FontAwesomeIcon icon={faEyeSlash} color={"#212121"}/>);
                        if (params.getValue(params.id, "is_disabled"))
                            name.push(<FontAwesomeIcon icon={faCircleMinus} color={"#212121"}/>);
                        name.push(<span>{params.value}</span>);
                        return name;
                    }
                },
                {headerName: "Ente", field: "society", width: 180, renderCell: (params) => params.formattedValue.name},
                {
                    headerName: "Area", field: "areaName", width: 140, valueGetter: (params) =>
                        (params.getValue(params.id, "area").name !== null ? params.getValue(params.id, "area").name : "") + " " +
                        (params.getValue(params.id, "micro_area") !== null ? params.getValue(params.id, "micro_area").name : "")
                },
                {headerName: "Prezzo", field: "price", width: 140},
                {headerName: "Inizio validità", field: "date_start_validity", width: 160},
                {headerName: "Fine validità", field: "date_end_validity", width: 160},
                {
                    headerName: "Tipologia", field: "typeName", width: 140, valueGetter: (params) => {
                        switch (params.getValue(params.id, "type")) {
                            case "DAILY":
                                return "Giornaliero";
                            case "TWODAYS":
                                return "Bigiornaliero";
                            case "THREEDAYS":
                                return "Tre giorni";
                            case "FOURDAYS":
                                return "Quattro giorni";
                            case "WEEKDAYS":
                                return "Cinque giorni";
                            case "WEEKLY":
                                return "Settimanale";
                            case "TWOWEEKS":
                                return "Bisettimanale";
                            case "MONTHLY":
                                return "Mensile";
                            case "TRIMESTER":
                                return "Trimestrale";
                            case "SEASONAL":
                                return "Stagionale";
                            case "SEMIANNUAL":
                                return "Semestrale";
                            case "YEARLY":
                                return "Annuale";
                            case "BIANNUAL":
                                return "Biennale";
                            case "TRIENNIAL":
                                return "Triennale";
                        }
                    }
                },
                {
                    headerName: "Tipo residenti", field: "number", width: 180, renderCell: (params) => {
                        if (params.getValue(params.id, "ticket_residence_type") === null)
                            return (<Badge color="alert">Sconosciuto</Badge>)
                        return (<Badge color="info">{params.getValue(params.id, "ticket_residence_type").name}</Badge>)
                    }
                },
                {
                    headerName: "Regolamento",
                    width: 200,
                    field: 'fishing_regulation',
                    renderCell: (params) => {
                        return <Row>
                            <Col>
                                <Button onClick={() => this.fileButton(params.getValue(params.id, "id"))} type="button"
                                        color="secondary">File</Button>
                            </Col>
                            <Col>
                                {(params.formattedValue !== null) ? ((params.formattedValue.document !== null) ?
                                    <a href={global.baseUrlImg + params.formattedValue.document} alt="" target="_blank"
                                       max-height="100" width="100">Regolamento</a> : 'Nessun file') : 'Nessun file'}
                            </Col>
                        </Row>
                    }
                },
                {
                    headerName: " ",
                    width: 200,
                    field: 'id',
                    renderCell: (params) => <ButtonGroup>
                        <Button type="button" onClick={() => window.location.href = '/ticket/edit/' + params.id}
                                color="primary">Modifica</Button>
                        <Button type="button" onClick={() => window.location.href = '/ticket/share/' + params.id}
                                color="warning">Assegna</Button>
                    </ButtonGroup>
                }
            ],
            rows: [],
            map: null,
            maps: null,
            paginator: {loading: true, countRows: 0, limit: 50},
            markers: [],
            modal: false,
            imageUpload: null,
            uploadId: null,
            search: null
        };
    }

    async componentDidMount() {
        this.loadList(0);
    }

    async handleValidSubmit(event, values) {
        let search = null;
        if (values.search !== "" && values.search !== null)
            search = values.search;
        this.setState({search: search}, () => this.loadList(0));
    }

    fileButton = (id) => {
        this.setState({modal: true, uploadId: id});
    }

    uploadImage = async () => {
        let formData = new FormData();
        formData.append("file", this.state.imageUpload);
        formData.append("id_ticket", this.state.uploadId);

        let result = await fileRules(formData);
        if (typeof result.error === "undefined") {
            this.setState({modal: false, imageUpload: null, uploadId: null, image: null}, () => {
                global.notify("Immagine caricata con successo", "success");
                this.loadList(0);

            });
        } else {
            global.notify("Errore durante il caricamento" + result.error, "error");
        }
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                image: URL.createObjectURL(img),
                imageUpload: event.target.files[0]
            });
        }
    };

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listTickets(null, page + 1, false, this.state.search).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        });
    }

    handlePageChange = (params) => {
        this.loadList(params.page)
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista Ticket"/>
                        <Card>
                            <CardBody>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v)
                                }} ref={c => (this.form = c)}>
                                    <div className={"row"}>
                                        <div className="form-group col-md-8">
                                            <AvField name="search" label="Cerca ticket"
                                                     className="form-control"
                                                     placeholder="Cerca ticket per nome del ticket, nome dell'ente o nome dell'area"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-4" style={{display: "flex"}}>
                                            <div style={{marginTop: "auto"}}>
                                                <Button type="submit" color="primary">Cerca</Button>
                                            </div>
                                        </div>
                                    </div>
                                </AvForm>

                                <div className="form-group">
                                    <div style={{height: '90vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChange}
                                            loading={this.state.paginator.loading}
                                        />
                                    </div>
                                </div>
                                <FontAwesomeIcon icon={faEyeSlash} color={"#212121"}/> Ticket nascosto in app<br/>
                                <FontAwesomeIcon icon={faCircleMinus} color={"#212121"}/> Ticket disabilitato del
                                tutto<br/>
                            </CardBody>
                        </Card>
                    </Container>
                    <Modal
                        isOpen={this.state.modal}
                        backdrop="static"
                        keyboard={false}>
                        <ModalHeader>Carica file</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col lg="12">
                                    <img src={this.state.image}/>
                                    <h1>Scegli file</h1>
                                    <input type="file" name="file" onChange={this.onImageChange}/>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary"
                                    onClick={() => this.setState({modal: false})}>Annulla</Button>{' '}
                            <Button color="primary" onClick={this.uploadImage}>Carica</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {listTickets})(TicketListPage));
