import React, {Component} from "react";
import {Row, Col, Card, CardBody, Container, Button, ButtonGroup} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    apiError, listTicketResidenceType, deleteTicketResidenceType, addTicketResidenceType, editTicketResidenceType
} from '../../../store/actions';
import Breadcrumb from '../../../components/Common/Breadcrumb';

class TicketResidenceTypePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: 'Tipologia',
                    field: 'name',
                    flex: 1
                },
                {
                    headerName: " ",
                    width: 180,
                    field: 'id',
                    renderCell: (params) => (
                        <ButtonGroup>
                            <Button onClick={() => this.actionButton(params)} type="button"
                                    color="primary">Modifica</Button>
                            <Button onClick={() => this.deleteType({id: params.id})} type="button"
                                    color="danger">Elimina</Button>
                        </ButtonGroup>
                    )
                }
            ],
            rows: [],
            form_edit: {
                id: null,
                name: null,
            },
            paginator: {loading: true, page: 0, countRows: 0, limit: 50},
            flag_edit: false,
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async deleteType(id) {
        let formResult = await deleteTicketResidenceType(id);
        if (formResult) {
            global.notify("Tipologia eliminata con successo", "success");
            this.loadListTypes();
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    async handleValidSubmit(event, values, edit = false) {
        let formResult = null;
        if (edit) {
            formResult = await editTicketResidenceType(values);
        } else {
            formResult = await addTicketResidenceType(values);
        }
        if (typeof formResult.error === 'undefined') {
            global.notify('Azione eseguita con successo', "success");
            this.setState({flag_edit: false});
            this.loadListTypes();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
        this.form && this.form.reset();
        window.scrollTo(0, 0);
    }

    actionButton = (params) => {
        this.setState({form_edit: params.row, flag_edit: true});
    }

    async componentDidMount() {
        this.loadListTypes();
    }

    loadListTypes = () => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listTicketResidenceType().then(result => {
            paginator.loading = false;

            if (!result) {
                this.setState({rows: []});
            } else {
                this.setState({rows: result.list});
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Tipologia residenza ticket"/>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div style={{height: 400, width: '100%'}}>
                                            <DataGrid
                                                rows={this.state.rows}
                                                columns={this.state.columns}
                                                pagination
                                                pageSize={this.state.paginator.limit}
                                                rowsPerPageOptions={[this.state.paginator.limit]}
                                                loading={this.state.paginator.loading}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Card>
                            <CardBody>
                                <h5>{(!this.state.flag_edit) ? "Aggiunta tipologia" : "Modifica tipologia"}</h5>
                                <hr/>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    {(this.state.flag_edit) ?
                                        <div className="form-group" hidden>
                                            <AvField name="id" label="Id tag" value={this.state.form_edit.id}
                                                     className="form-control" placeholder="Inserisci id tag" type="text"
                                                     required/>
                                        </div> : ""
                                    }
                                    <div className="form-group">
                                        <AvField name="name" label="Nome Tipologia" value={this.state.form_edit.name}
                                                 className="form-control"
                                                 placeholder="Inserisci il nome della tipologia" type="text"
                                                 required/>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="submit"
                                                color={(this.state.flag_edit) ? "warning" : "success"}>{(this.state.flag_edit) ? "Modifica" : "Crea"}</Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(
    connect(
        mapStatetoProps, {apiError}
    )
    (TicketResidenceTypePage));