import React, {Component} from "react";
import {DataGrid} from '@material-ui/data-grid';
import {Row, Col, Card, CardBody, Container, Alert, Button, ButtonGroup, CardTitle, CardText} from "reactstrap";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
// availity-reactstrap-validation
import {AvForm, AvField} from 'availity-reactstrap-validation';
// Redux
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
//i18n
import {withTranslation} from 'react-i18next';

// actions
import {getFish, setSpecs, editSpecs, deleteSpecs, apiError} from '../../store/actions';

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';

class Specs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Nome Pesce", field: "name", flex: 1},
                {headerName: "Descrizione", field: "description", flex: 1},
            ],
            columnsSpec: [
                {
                    headerName: 'Lunghezza min',
                    field: 'min_length',
                    width: 200
                },
                {
                    headerName: 'Lunghezza max',
                    field: 'max_length',
                    width: 200
                },
                {
                    headerName: 'Peso min',
                    field: 'min_weight',
                    width: 200
                },
                {
                    headerName: 'Peso max',
                    field: 'max_weight',
                    width: 200
                },
                {
                    headerName: " ",
                    width: 200,
                    field: 'id',
                    renderCell: (params) => (
                        <ButtonGroup>
                            <Button onClick={() => this.actionButton(params)} type="button"
                                    color="primary">Modifica</Button>
                            <Button onClick={() => deleteSpecs({id: params.id})} type="button"
                                    color="danger">Elimina</Button>
                        </ButtonGroup>
                    )
                }
            ],
            rows: [],
            rowsSpec: [],
            form_edit: {
                id: null,
                min_length: null,
                max_length: null,
                min_weight: null,
                max_weight: null,
            },
            showFish: true,
            paginator: {loading: true, countRows: 0, limit: 50},
            flag_edit: false,
            fish: null,
            specs: null
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async handleValidSubmit(event, values, edit = false) {
        if (this.state.fish === null) {
            global.notify("Devi selezionare un pesce.", "danger");
            return;
        }

        if (this.state.flag_edit) {
            values.id_spec = this.state.form_edit.id;
            let formResult = await editSpecs(values);

            if (typeof formResult.fish !== 'undefined') {
                global.notify("specifica modificata con successo", "success");
                this.form && this.form.reset();
            } else if (typeof formResult.error !== 'undefined') {
                global.notify("Errore con la modifica: " + formResult.error, "danger");

                this.setState({error: formResult.error});
            } else {
                this.setState({error: 'Errore generico'});
            }
            return;
        } else {
            values.id = this.state.fish.id;
        }
        let formResult = await setSpecs(values);

        if (typeof formResult.fish !== 'undefined') {
            global.notify("specifica aggiunta con successo", "success");
            this.form && this.form.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con l'aggiunta: " + formResult.error, "danger");

            this.setState({error: formResult.error});
        } else {
            this.setState({error: 'Errore generico'});
        }
    }

    actionButton = (params) => {
        this.setState({form_edit: params.row, flag_edit: true}, function () {
        });
    }

    loadListFish = () => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getFish().then(result => {
            paginator.loading = false;
            if (!result) {
                this.setState({rows: [], paginator: paginator});
            } else {
                this.setState({rows: result, paginator: paginator});
            }
        })
    }

    async componentDidMount() {
        this.loadListFish();
    }

    handleRowSelected = (rows) => {
        let row = [...rows.entries()][0][1];
        if (this.state.showFish)
            this.setState({
                fish: row,
                rowsSpec: typeof row.fish_specs !== "undefined" ? row.fish_specs : []
            });
    }

    showFish = (state) => {
        if (!state && this.state.fish === null) {
            global.notify("Devi selezionare almeno un pesce", "warning");
            return;
        }
        if (!state && (typeof this.state.fish.fish_specs === "undefined" || Object.keys(this.state.fish.fish_specs).length === 0)) {
            global.notify("Non ci sono specifiche presenti per questo pesce", "warning");
            return;
        }

        this.setState({showFish: state});
        if (state) {
            if (Object.keys(this.state.rows).length === 0)
                this.loadListFish(0);
        }
    }

    render() {
        const {rows, columns} = this.state
        const defaultColDef = {
            sortable: true,
            flex: 1,
            minWidth: 100,
            filter: true,
            resizable: true,
        }
        return (

            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumb title="Geoticket" breadcrumbItem="Category"/>

                        <Row>
                            <Col lg="12">
                                {this.state.error && this.state.error ?
                                    <Alert color="danger">{this.state.error}</Alert> : null}
                                {this.state.success && this.state.success ?
                                    <Alert color="success">{this.state.success}</Alert> : null}

                                <Card>
                                    <CardBody>
                                        <div className="text-center mb-4">
                                            <ButtonGroup>
                                                <Button
                                                    onClick={() => this.showFish(true)}
                                                    color={(this.state.showFish) ? "light" : "primary"}>Pesci</Button>
                                                <Button
                                                    onClick={() => this.showFish(false)}
                                                    color={(!this.state.showFish) ? "light" : "primary"}>Specifiche</Button>
                                            </ButtonGroup>
                                        </div>
                                        <div style={{height: '40vh', width: '100%'}} hidden={!this.state.showFish}>
                                            <DataGrid
                                                rows={this.state.rows}
                                                columns={this.state.columns}
                                                pageSize={Object.keys(this.state.rows).length}
                                                rowsPerPageOptions={[Object.keys(this.state.rows).length]}
                                                rowCount={Object.keys(this.state.rows).length}
                                                loading={this.state.paginator.loading}
                                                onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                            />
                                        </div>
                                        <div style={{height: '40vh', width: '100%'}} hidden={this.state.showFish}>
                                            <DataGrid
                                                rows={this.state.rowsSpec}
                                                columns={this.state.columnsSpec}
                                                pageSize={Object.keys(this.state.rowsSpec).length}
                                                rowsPerPageOptions={[Object.keys(this.state.rowsSpec).length]}
                                                rowCount={Object.keys(this.state.rowsSpec).length}
                                                onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Card>
                            <CardBody>
                                <div className={"row"}>
                                    {this.state.fish !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">{this.state.fish.name}</CardTitle>
                                                <CardText>{this.state.fish.description}
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                </div>
                                <br/>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    <div className="form-group">
                                        <AvField name="min_length" label="min lunghezza"
                                                 value={this.state.form_edit.min_length} className="form-control"
                                                 placeholder="Inserisci min lunghezza" type="text" required/>
                                    </div>
                                    <div className="form-group">
                                        <AvField name="max_length" label="max lunghezza"
                                                 value={this.state.form_edit.max_length} className="form-control"
                                                 placeholder="Inserisci max lunghezza" type="text" required/>
                                    </div>
                                    <div className="form-group">
                                        <AvField name="min_weight" label="min peso"
                                                 value={this.state.form_edit.min_weight} className="form-control"
                                                 placeholder="Inserisci min peso" type="text" required/>
                                    </div>
                                    <div className="form-group">
                                        <AvField name="max_weight" label="max peso"
                                                 value={this.state.form_edit.max_weight} className="form-control"
                                                 placeholder="Inserisci max peso" type="text" required/>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="submit"
                                                color="danger">{(this.state.flag_edit) ? "Modifica Specifica" : "Crea Specifica"}</Button>
                                    </div>
                                </AvForm>

                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(
    connect(
        mapStatetoProps, {getFish, setSpecs, editSpecs, deleteSpecs, apiError}
    )
    (Specs));