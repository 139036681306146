import React, {Component} from "react";
import {Row, Col, Card, CardBody, Container, Alert, Button, ButtonGroup, CardTitle, CardText, Badge} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    getSocCaptureStats,
    setFish,
    editFish,
    getSocietyListAreas,
    listSocTickets,
    apiError
} from '../../store/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';
import "./society.scss";
import {windowOpen} from "echarts/src/util/format";

var moment = require('moment');

class SocCaptureStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "Nome Pesce", field: "fish", width: 240,
                    renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Peso", field: "weight", width: 160},
                {headerName: "Lunghezza", field: "length", width: 160},
                {headerName: "Kill", field: "killed", width: 160, renderCell: (params) => params.value ? "SI" : "NO"},
                {headerName: "Data", field: "date_capture", width: 160},
            ],
            rows: [],
            columnsArea: [{headerName: "Nome area", field: "name", width: 160},
                {
                    headerName: "Categoria",
                    field: "category",
                    flex: 1,
                    renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 140},
                {headerName: "Province", field: "province", width: 140},
                {headerName: "Indirizzo", field: "address", width: 140},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 140},
                {headerName: "CAP", field: "cap", width: 120},
                {headerName: "Telefono", field: "phone", width: 140}],
            columnsMicroArea: [{headerName: "Nome micro area", field: "name", width: 260}],
            columnsTicket: [
                {
                    headerName: "Nome ticket", field: "name", width: 160, valueGetter: (params) =>
                        (params.getValue(params.id, "is_disabled") ? "[DISABILITATO] " : "") + params.value
                },
                {headerName: "Prezzo", field: "price", width: 140},
                {headerName: "Inizio validità", field: "date_start_validity", width: 160},
                {headerName: "Fine validità", field: "date_end_validity", width: 160},
                {
                    headerName: "Tipologia", field: "typeName", width: 160, valueGetter: (params) => {
                        switch (params.getValue(params.id, "type")) {
                            case "DAILY":
                                return "Giornaliero";
                            case "TWODAYS":
                                return "Bigiornaliero";
                            case "THREEDAYS":
                                return "Tre giorni";
                            case "FOURDAYS":
                                return "Quattro giorni";
                            case "WEEKDAYS":
                                return "Cinque giorni";
                            case "WEEKLY":
                                return "Settimanale";
                            case "TWOWEEKS":
                                return "Bisettimanale";
                            case "MONTHLY":
                                return "Mensile";
                            case "TRIMESTER":
                                return "Trimestrale";
                            case "SEASONAL":
                                return "Stagionale";
                            case "SEMIANNUAL":
                                return "Semestrale";
                            case "YEARLY":
                                return "Annuale";
                            case "BIANNUAL":
                                return "Biennale";
                            case "TRIENNIAL":
                                return "Triennale";
                        }
                    }
                },
                {
                    headerName: "Tipo residenti", field: "ticket_residence_type", width: 180, renderCell: (params) => {
                        if (params.value === null)
                            return (<Badge color="alert">Sconosciuto</Badge>)
                        return (<Badge color="info">{params.value.name}</Badge>)
                    }
                },
            ],
            rowsArea: [],
            rowsMicroArea: [],
            rowsTicket: [],
            form_filter: {
                id_area: null,
                id_ticket: null,
                date_start: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
                date_end: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
            },
            date_start: new Date(),
            date_end: new Date(),
            date_current: new Date(),
            area: null,
            micro_area: null,
            ticket: null,
            flag_edit: false,
            paginator: {loadingStats: true, page: 0, countRows: 0, limit: 50},
            paginatorArea: {loading: true},
            paginatorTicket: {loading: true, countRows: 0, limit: 50},
            paginatorFish: {loading: true, countRows: 0, limit: 50},

        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async handleValidSubmit(event, values, edit = false) {
        let formResult = null;
        if (edit) {
            formResult = await editFish(values);
        } else {
            formResult = await setFish(values);
        }

        if (typeof formResult.fish !== 'undefined') {
            this.setState({error: false, success: 'Azione eseguita con successo'});
            this.loadListStats();
        } else if (typeof formResult.error !== 'undefined') {
            this.setState({error: formResult.error});
        } else {
            this.setState({error: 'Errore generico'});
        }
        window.scrollTo(0, 0);
    }

    actionButton = (params) => {
        this.setState({form_filter: params.row, flag_edit: true});
    }

    async componentDidMount() {
        this.loadListStats(0);
        this.showTable('AREA');
    }

    loadListStats = (page) => {
        let paginator = this.state.paginator;
        paginator.loadingStats = true;
        this.setState({paginator: paginator});
        let id_area = (this.state.area) ? this.state.area.id : false;
        let id_ticket = (this.state.ticket) ? this.state.ticket.id : false;
        getSocCaptureStats(page + 1, this.state.form_filter.date_start, this.state.form_filter.date_end, id_area, id_ticket).then(result => {
            paginator.loadingStats = false;

            if (!result) {
                this.setState({rows: []});
            } else {
                this.setState({rows: result.list});
            }
        })
    }

    handlePageChange = (params) => {
        this.loadListStats(params.page);
    };
    handlePageAreaChange = (params) => {
        this.loadListAreas(params.page)
    };

    handlePageTicketChange = (params) => {
        this.loadListTicket(params.page)
    };

    loadListTicket = (page) => {
        let paginatorTicket = this.state.paginatorTicket;
        paginatorTicket.loading = true;
        this.setState({paginatorTicket: paginatorTicket});

        listSocTickets(page + 1, this.state.area.society.id).then(result => {
            paginatorTicket.loading = false;
            if (!result) {
                paginatorTicket.countRows = 0;
                this.setState({rowsTicket: [], paginatorTicket: paginatorTicket});
            } else {
                paginatorTicket.countRows = result.paginator.count_all_entities;
                this.setState({rowsTicket: result.list, paginatorTicket: paginatorTicket});
            }
        })
    }

    setDate = (date, type) => {
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_filter = this.state.form_filter;
        form_filter.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_filter.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_filter: form_filter});
    }

    showTable = (state) => {
        if (state === 'MICRO' && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        if (state === 'MICRO' && (typeof this.state.area.micro_areas === "undefined" || Object.keys(this.state.area.micro_areas).length === 0)) {
            global.notify("Non ci sono micro aree presenti per quest'area", "warning");
            return;
        }
        if (state === 'TICKET' && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        this.setState({showTable: state});
        if (state === 'AREA') {
            if (Object.keys(this.state.rows).length === 0)
                this.loadListAreas(0);
        }
    }

    loadListAreas = (page) => {
        let paginatorArea = this.state.paginatorArea;
        paginatorArea.loading = true;
        this.setState({paginatorArea: paginatorArea});

        getSocietyListAreas(page + 1).then(result => {
            paginatorArea.loading = false;
            if (!result) {
                this.setState({paginatorArea: paginatorArea});
            } else {
                this.setState({rowsArea: result.list, paginatorArea: paginatorArea});
            }
        })
    }

    handleRowSelected = (rows) => {
        let row = [...rows.entries()][0][1];
        switch (this.state.showTable) {
            case "AREA":
                this.setState({
                    area: row,
                    rowsMicroArea: typeof row.micro_areas !== "undefined" ? row.micro_areas : []
                }, () => {
                    this.loadListTicket(0);
                    this.loadListStats(0);
                });
                break;
            case "MICRO":
                this.setState({micro_area: row}, () => {
                    this.loadListStats(0);
                });
                break;
            case "TICKET":
                this.setState({ticket: row}, () => {
                    this.loadListStats(0);
                });
                break;
        }
    }

    updateDate = () => {
        this.loadListStats(0);
    }

    downloadCaptures = () => {
        if (this.state.area === null) {
            global.notify("Devi selezionare un'area per procedere", "danger");
            return;
        }
        let date_start = moment(this.state.date_start).unix();
        let date_end = moment(this.state.date_end).unix();

        global.notify("Dati scaricati", "success");
        windowOpen(global.baseUrl + 'stats/capture/download/' + date_start + "/" + date_end, "blank")
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Statistiche"/>
                        <Row>
                            <Col lg="12">
                                {this.state.error && this.state.error ?
                                    <Alert color="danger">{this.state.error}</Alert> : null}
                                {this.state.success && this.state.success ?
                                    <Alert color="success">{this.state.success}</Alert> : null}
                                <Card>
                                    <CardBody>
                                        <h5>Seleziona l'area, zona o permesso su cui visualizzare le statistiche</h5>
                                        <hr/>
                                        <div className="text-center mb-4">
                                            <ButtonGroup>
                                                <Button
                                                    onClick={() => this.showTable('AREA')}
                                                    color={(this.state.showTable === 'AREA') ? "light" : "primary"}>Area</Button>
                                                <Button
                                                    onClick={() => this.showTable('MICRO')}
                                                    color={(this.state.showTable === 'MICRO') ? "light" : "primary"}>MicroArea</Button>
                                                <Button
                                                    onClick={() => this.showTable('TICKET')}
                                                    color={(this.state.showTable === 'TICKET') ? "light" : "primary"}>Ticket</Button>
                                            </ButtonGroup>
                                        </div>
                                        <div style={{height: '40vh', width: '100%'}}
                                             hidden={this.state.showTable !== 'AREA'}>
                                            <DataGrid
                                                rows={this.state.rowsArea}
                                                columns={this.state.columnsArea}
                                                pagination
                                                onPageChange={this.handlePageAreaChange}
                                                loading={this.state.paginatorArea.loading}
                                                onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                            />
                                        </div>
                                        <div style={{height: '40vh', width: '100%'}}
                                             hidden={this.state.showTable !== 'MICRO'}>
                                            <DataGrid
                                                rows={this.state.rowsMicroArea}
                                                columns={this.state.columnsMicroArea}
                                                pageSize={Object.keys(this.state.rowsMicroArea).length}
                                                rowsPerPageOptions={[Object.keys(this.state.rowsMicroArea).length]}
                                                rowCount={Object.keys(this.state.rowsMicroArea).length}
                                                onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                            />
                                        </div>
                                        <div style={{height: '40vh', width: '100%'}}
                                             hidden={this.state.showTable !== 'TICKET'}>
                                            <DataGrid
                                                rows={this.state.rowsTicket}
                                                columns={this.state.columnsTicket}
                                                pagination
                                                pageSize={this.state.paginatorTicket.limit}
                                                rowsPerPageOptions={[this.state.paginatorTicket.limit]}
                                                rowCount={this.state.paginatorTicket.countRows}
                                                paginationMode="server"
                                                onPageChange={this.handlePageTicketChange}
                                                loading={this.state.paginatorTicket.loading}
                                                onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                            />
                                        </div>
                                        <div className={"row"}>
                                            {this.state.area !== null ? <div className={"col-4"}>
                                                    <Card body>
                                                        <CardTitle tag="h5">Area</CardTitle>
                                                        <CardText>Nome
                                                            area: {this.state.area.name}<br/> {this.state.area.address},
                                                            n°{this.state.area.number} {this.state.area.city} {this.state.area.region}
                                                        </CardText>
                                                    </Card>
                                                </div>
                                                : ""}
                                            {this.state.micro_area !== null ? <div className={"col-4"}>
                                                    <Card body>
                                                        <CardTitle tag="h5">Sotto zona</CardTitle>
                                                        <CardText>Nome sotto zona: {this.state.micro_area.name}</CardText>
                                                    </Card>
                                                </div>
                                                : ""}
                                            {this.state.ticket !== null ? <div className={"col-4"}>
                                                    <Card body>
                                                        <CardTitle tag="h5">Ticket</CardTitle>
                                                        <CardText>Nome
                                                            ticket: {this.state.ticket.name}<br/> {this.state.ticket.price},
                                                            {this.state.ticket.date_start_validity} {this.state.ticket.date_end_validity} {this.state.ticket.type}
                                                        </CardText>
                                                    </Card>
                                                </div>
                                                : ""}
                                        </div>
                                        <br/>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <AvForm className="form-horizontal" onValidSubmit={(e, v,) => {
                                            this.handleValidSubmit(e, v, this.state.flag_edit)
                                        }}>
                                            <h5>Scegli una data</h5>
                                            <hr/>
                                            <div className={"row"}>
                                                <div className="form-group col-4">
                                                    <AvField name="date_start" hidden
                                                             label="Data inizio"
                                                             value={this.state.form_filter.date_start}
                                                             className="form-control"
                                                             placeholder="Inserisci data" type="text"
                                                             required/>
                                                    <DatePicker selected={this.state.date_start}
                                                                startDate={this.state.date_current}
                                                                className="form-control"
                                                                onChange={(date) => this.setDate(date, "start")}
                                                                dateFormat="dd/MM/yyyy"
                                                    />
                                                </div>
                                                <div className="form-group col-4">
                                                    <AvField name="date_end" hidden label="Data fine"
                                                             value={this.state.form_filter.date_end}
                                                             className="form-control"
                                                             placeholder="Inserisci data" type="text"
                                                             required/>
                                                    <DatePicker selected={this.state.date_end}
                                                                minDate={this.state.date_start} className="form-control"
                                                                onChange={(date) => this.setDate(date, "end")}
                                                                dateFormat="dd/MM/yyyy"
                                                    />
                                                </div>
                                                <div className="form-group col-4" style={{display: "flex"}}>
                                                    <div style={{marginTop: "auto"}}>
                                                        <ButtonGroup>
                                                            <Button onClick={() => this.updateDate()}
                                                                    color={"success"}>Aggiorna</Button>
                                                            <Button onClick={() => this.downloadCaptures()}
                                                                    color={"primary"}>Scarica catture</Button>
                                                        </ButtonGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </AvForm>
                                        <div style={{height: 400, width: '100%'}}>
                                            <DataGrid
                                                rows={this.state.rows}
                                                columns={this.state.columns}
                                                pagination
                                                pageSize={this.state.paginator.limit}
                                                rowsPerPageOptions={[this.state.paginator.limit]}
                                                rowCount={this.state.paginator.countRows}
                                                paginationMode="server"
                                                onPageChange={this.handlePageChange}
                                                loading={this.state.paginator.loadingStats}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(
    connect(
        mapStatetoProps, {getSocCaptureStats, setFish, editFish, apiError}
    )
    (SocCaptureStats));