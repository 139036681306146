import React from "react";
import {Redirect} from "react-router-dom";

var tmpGeoCenter = {
    lat: 0,
    lng: 0,
}
if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    tmpGeoCenter = {
        lat: 42.45,
        lng: 12.96
    }
}

const geoCenter = tmpGeoCenter;

export {geoCenter};
