import {put} from 'redux-saga/effects';
import {apiCall} from '../../../helpers/backend_helper';

export const getUserSos = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/user/society/list'});
        if (typeof response.list !== 'undefined') {
            return response.list;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setUserSosAdd = async (societyUser) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/user/society/add', data: societyUser});
    } catch (error) {
        return false;
    }
}

export const setFishingguardSosAdd = async (societyUser) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/user/fishingguard/add', data: societyUser});
    } catch (error) {
        return false;
    }
}

export const editSociety = async (society) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/society/edit', data: society});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}