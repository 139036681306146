import React, {Component} from "react";
import {MDBDataTable} from "mdbreact";
import {Row, Col, Card, CardBody, Container, Alert, Button} from "reactstrap";
import {AgGridReact, AgGridColumn} from 'ag-grid-react';


import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

// availity-reactstrap-validation
import {AvForm, AvField} from 'availity-reactstrap-validation';
// Redux
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
//i18n
import {withTranslation} from 'react-i18next';

// actions
import {getLanguage, setLanguage, deleteLanguage, apiError} from "../../../store/actions";

//Import Breadcrumb
import Breadcrumb from '../../../components/Common/Breadcrumb';

import ButtonEdit from "../include/ButtonEdit";

class Language extends Component {
    constructor(props) {
        super(props);
        this.state = {

            rows: [],
            form_edit: {
                lang: null,
            },
            flag_edit: false
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async handleValidSubmit(event, values, edit = false) {

        console.log(values, edit);
        let formResult = null;
        if (edit) {
            formResult = await deleteLanguage(values);
        } else {
            formResult = await setLanguage(values);
        }

        console.log(formResult)
        if (typeof formResult.language !== 'undefined') {
            this.setState({error: false, success: 'Azione eseguita con successo'}, function () {
            });
            let rows = await getLanguage();
            this.setState({rows}, function () {
            });

        } else if (typeof formResult.error !== 'undefined') {
            this.setState({error: formResult.error}, function () {
            });
        } else {
            this.setState({error: 'Errore generico'}, function () {
            });
        }
        this.form && this.form.reset();
        window.scrollTo(0, 0);
    }


    async componentDidMount() {
        let rowsTmp = await getLanguage();
        let rows = [];
        rowsTmp.forEach(element => {
            rows.push({lang: element});
        });
        this.setState({rows}, function () {
        });
    }


    render() {
        const {rows} = this.state
        const getRowStyle = params => {
            if (params.node.rowIndex % 2 === 0) {
                return {background: '#eeeef7'};
            }
        };
        return (

            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumb title="Geoticket" breadcrumbItem="Language"/>
                        <Row>
                            <Col lg="12">
                                {this.state.error && this.state.error ?
                                    <Alert color="danger">{this.state.error}</Alert> : null}
                                {this.state.success && this.state.success ?
                                    <Alert color="success">{this.state.success}</Alert> : null}

                                <Card>
                                    <CardBody>
                                        <div id="myGrid" className="ag-theme-alpine"
                                             style={{
                                                 height: '300px',
                                                 width: '1200px'
                                             }}>
                                            <AgGridReact
                                                frameworkComponents={{
                                                    buttonEdit: ButtonEdit
                                                }}

                                                rowData={rows}
                                                rowSelection="multiple"
                                                getRowStyle={getRowStyle}
                                                defaultColDef={{

                                                    sortable: true,
                                                    flex: 1,
                                                    minWidth: 100,
                                                    filter: true,
                                                    resizable: true,
                                                }}

                                            >
                                                <AgGridColumn headerName="Lingua" field="lang" sortable={true}
                                                              filter={true} checkboxSelection={true}></AgGridColumn>
                                                <AgGridColumn field="Tasto" cellRenderer="buttonEdit"/>
                                            </AgGridReact>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    <div className="form-group">
                                        <AvField name="lang" label="Language" value={this.state.form_edit.lang}
                                                 className="form-control" placeholder="Enter Language" type="text"
                                                 required/>
                                    </div>

                                    <div className="text-center mt-4">
                                        <Button type="submit"
                                                color="danger">{(this.state.flag_edit) ? "Delete Language" : "Save Language"}</Button>
                                    </div>
                                </AvForm>

                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(
    connect(
        mapStatetoProps, {getLanguage, setLanguage, deleteLanguage, apiError}
    )
    (Language));