import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    Label,
    CardText,
    ListGroup,
    FormGroup,
    Form,
    Badge,
    Input,
    ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import GoogleMapReact from 'google-map-react';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    getInfoArea,
    addMicroArea,
    editMicroArea,
    deleteMicroArea,
    getCategories,
    getMapAreas,
    editZone, getListRegion, setAreaTag, setMicroAreaTag, ImgCategory, uploadImageArea, uploadLogoArea, deleteArea
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import ReactQuill from "react-quill";

class ZoneEditPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            form_edit: {
                name: null,
                region: null,
                province: null,
                address: null,
                number: null,
                city: null,
                cap: null,
                tickets_available_daily: null,
                description: null,
                full_description: null
            },
            microarea: [],
            area: null,
            categories: [],
            lat: null,
            lon: null,
            polygons: [],
            map: null,
            maps: null,
            polygonObj: null,
            id_society: null,
            category: 0,
            paginator: {loadingSociety: true, countRows: 0, limit: 50},
            markers: [],
            key_microarea: 0,
            input: "",
            regionList: [],
            tags: [],
            area_tags: [],
            microarea_tags: false,
            modalEdit: false,
            micro_area_edit: null,
            form_modal: {},
            borderSet: true
        };
        this.quill = null;
    }

    async handleValidSubmitEdit(event, values, micro_area) {
        if (micro_area.id !== null) {
            let data = {
                id: micro_area.id,
                id_area: this.state.area.id,
                name: values.name,
                tickets_available_daily: values.tickets_available_daily,
                is_score_capture_unlimited: values.is_score_capture_unlimited_microarea
            };
            if (typeof micro_area.area_cords !== "undefined" && micro_area.area_cords !== null && Object.keys(micro_area.area_cords).length > 0)
                data.area_cords = JSON.stringify(micro_area.area_cords.map((cords) => {
                    return {lat: cords.lat, lon: typeof cords.lng !== "undefined" ? cords.lng : cords.lon};
                }))

            let res = await editMicroArea(data);

            if (typeof res.error !== 'undefined')
                global.notify("La MicroArea \"" + micro_area.name + "\" non è stata modificata: " + res.error, "danger");
            else {
                global.notify("Microarea modificata con successo", "success");
                this.handleClose();
            }
        } else {
            this.handleClose();
        }

        let list_microareas = this.state.microarea;
        for (let i in list_microareas) {
            if (list_microareas[i].key_microarea === micro_area.key_microarea) {
                list_microareas[i].name = values.name;
                list_microareas[i].tickets_available_daily = values.tickets_available_daily;
                list_microareas[i].score_capture_time_unlimited = values.is_score_capture_unlimited_microarea;
            }
        }
        this.setState({microarea: list_microareas});
    }

    async handleValidSubmit(event, values) {
        values.id_society = this.state.id_society;
        values.id = this.state.area.id;
        values.id_society = this.state.area.society.id;
        values.lat = this.state.lat;
        values.lon = this.state.lon;
        values.area_cords = JSON.stringify(this.state.polygons.map((cords) => {
            return {lat: cords.lat, lon: cords.lng};
        }));

        if (this.state.form_edit.full_description !== null && this.state.form_edit.full_description.trim() !== "" && this.state.form_edit.full_description !== "<p><br></p>")
            values.full_description = this.state.form_edit.full_description;

        let formResult = await editZone(values);

        if (typeof formResult.error === 'undefined')
            global.notify("Modifica eseguita con successo", "success");
        else
            global.notify(formResult.error, "danger");
        let microarea_res = [];
        for (let i in this.state.microarea) {
            let microarea = this.state.microarea[i];
            let cords = [];
            if (microarea.area_cords !== null && Object.keys(microarea.area_cords).length >= 3)
                cords = JSON.stringify(microarea.area_cords.map((cords) => {
                    return {lat: cords.lat, lon: cords.lng};
                }));

            if (typeof microarea.id === "undefined" || microarea.id === null) {
                let data = {
                    id_area: this.state.area.id,
                    name: microarea.name,
                    area_cords: cords
                };
                if (microarea.tickets_available_daily !== null)
                    data.tickets_available_daily = microarea.tickets_available_daily;

                if (microarea.score_capture_time_unlimited !== null)
                    data.is_score_capture_unlimited = microarea.score_capture_time_unlimited;
                let res = await addMicroArea(data);
                if (typeof res.error !== 'undefined')
                    global.notify("La MicroArea \"" + microarea.name + "\" non aggiunta: " + res.error, "danger");
                else {
                    microarea.id = res.micro_area.id;
                    microarea.is_valid = res.micro_area.is_valid;
                }

                let tags = microarea.category_tags.map((item, index) => {
                    return item.id
                });
                let res_tags = await setMicroAreaTag(microarea.id, tags);
                microarea.category_tags = res_tags.micro_area.category_tags;
            }
            microarea_res.push(microarea);
        }

        this.setState({microarea: microarea_res});
        window.scrollTo(0, 0);
    }

    async componentDidMount() {
        this.loadRegions();
        let categories = await getCategories();
        let area = await getInfoArea(this.props.match.params.id);

        let tags = area.category.tags;
        let area_tags = [];
        area.category_tags.forEach(element => area_tags.push(element.id));
        let polygons = [];
        if (area.area_cords !== null && Object.keys(area.area_cords).length > 0) {
            for (let i in area.area_cords)
                polygons.push({lat: area.area_cords[i].lat, lng: area.area_cords[i].lon})
        }

        let form_edit = {
            name: area.name,
            region: area.region,
            province: area.province,
            address: area.address,
            number: area.number,
            city: area.city,
            cap: area.cap,
            description: area.description,
            full_description: area.full_description,
            tickets_available_daily: area.tickets_available_daily,
        }
       await this.setState({
            category: area.category,
            categories: categories,
            lat: area.lat,
            lon: area.lon,
            area: area,
            polygons: polygons,
            tags: tags,
            area_tags: area_tags,
            form_edit: form_edit,
        });
    }

    handleBoundChanged = () => {
        if (this.state.map !== null && this.state.maps !== null)
            this.loadMarkers(this.state.map, this.state.maps);
    }

    handleApiLoaded = (map, maps) => {
        let markerDragged = (e) => {
            this.setState({lat: e.latLng.lat(), lon: e.latLng.lng()})
        }

        let marker = new maps.Marker({
            map: map,
            animation: maps.Animation.DROP,
            icon: {
                url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                scaledSize: new maps.Size(42, 42)
            },
            position: new maps.LatLng({lat: this.state.lat, lng: this.state.lon}),
            draggable: true
        });
        maps.event.addListener(marker, 'dragend', markerDragged);
        this.loadMarkers(map, maps);
        this.addPolygon(map, maps);

        for (let i in this.state.area.micro_areas) {
            let microarea = this.state.area.micro_areas[i];
            let polygon = [];
            for (let k in microarea.area_cords)
                polygon.push({lat: microarea.area_cords[k].lat, lng: microarea.area_cords[k].lon});
            this.addMicroArea(microarea.id, microarea.name, polygon, microarea.category_tags, microarea.is_valid, microarea.tickets_available_daily, microarea.score_capture_time_unlimited)
        }
    }

    loadMarkers = (map, maps) => {
        let bounds = map.getBounds();
        getMapAreas(this.state.category.id, bounds.getNorthEast().lat(), bounds.getNorthEast().lng(), bounds.getSouthWest().lat(), bounds.getSouthWest().lng()).then((result) => {
            let arr_markers = [];
            for (let i in result.list) {
                let area = result.list[i];
                if (this.state.form_edit.id === area.id)
                    continue;

                let find = this.state.markers.find(m => {
                    return m.id === area.id
                });
                if (typeof find !== "undefined")
                    continue;
                if (area.id === this.state.area.id)
                    continue;

                arr_markers.push({id: area.id});
                new maps.Marker({
                    map: map,
                    position: new maps.LatLng(area.lat, area.lon),
                });

                if (typeof area.area_cords !== "undefined" && area.area_cords !== null && Object.keys(area.area_cords).length >= 3) {
                    let polygon = [];
                    for (let i in area.area_cords)
                        polygon.push({lat: area.area_cords[i].lat, lng: area.area_cords[i].lon});

                    let polygonObj = new maps.Polygon({
                        paths: polygon,
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.35
                    });
                    polygonObj.setMap(map);
                }
            }
            this.setState({markers: [...arr_markers, ...this.state.markers]})
        });
    }

    polygonEdited = (e) => {
        const nextPath = this.state.polygonObj.getPath().getArray().map(latLng => {
            return {lat: latLng.lat(), lng: latLng.lng()};
        });
        this.setState({polygons: nextPath})
    }

    addPolygon = (map, maps) => {
        let add_poly = this.state.polygons;
        if (this.state.lat === null || this.state.lon === null) {
            global.notify("Devi prima aggiungere il marker", "warning");
            return;
        }
        if (Object.keys(this.state.polygons).length === 0) {
            add_poly.push({lat: this.state.lat + 0.01, lng: this.state.lon + 0.02});
            add_poly.push({lat: this.state.lat - 0.02, lng: this.state.lon - 0.01});
            add_poly.push({lat: this.state.lat + 0.01, lng: this.state.lon - 0.02});
        }

        if (this.state.polygonObj !== null)
            this.state.polygonObj.setMap(null);

        let polygonObj = new maps.Polygon({
            paths: add_poly,
            strokeColor: "#0088ff",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#144356",
            fillOpacity: 0.35,
            editable: true,
            draggable: true
        });
        maps.event.addListener(polygonObj.getPath(), 'set_at', position => this.polygonEdited(position));
        maps.event.addListener(polygonObj.getPath(), 'insert_at', position => this.polygonEdited(position));
        polygonObj.setMap(map);
        this.setState({polygons: add_poly, polygonObj: polygonObj});
    }

    renderMap() {
        if (this.state.area === null)
            return;
        let defaultProps = {
            center: {
                lat: this.state.area.lat,
                lng: this.state.area.lon
            },
            zoom: 14
        };
        let mapObj = this.state.map;
        let mapsObj = this.state.maps;

        let removeBorders = () => {
            if (this.state.polygonObj !== null)
                this.state.polygonObj.setMap(null);
            this.setState({polygons: []})
        }
        let addBorders = () => {
            this.addPolygon(mapObj, mapsObj)
        }

        return <Card body inverse style={{backgroundColor: '#eeeeee', borderColor: '#eeeeee'}}>
            <div className="text-center mb-4">
                <div className="btn-group">
                    <Button type="button" color="warning" onClick={addBorders}
                            disabled={Object.keys(this.state.polygons).length > 0}>Add Borders</Button>
                    <Button type="button" color="danger" onClick={removeBorders}>Remove Borders</Button>
                </div>
            </div>

            <div style={{height: '50vh', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyCV53jtplvMZi17CqUW7w8Q5w9uat1lgqw"}}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({map, maps}) => {
                        this.setState({map: map, maps: maps})
                        this.handleApiLoaded(map, maps)
                    }}
                    onBoundsChange={this.handleBoundChanged}
                />
            </div>
        </Card>
    }

    listCategories() {
        if (Object.keys(this.state.categories).length === 0)
            return;
        return this.state.categories.map(function (category, i) {
            return <option value={category.id} key={i}>{category.name}</option>
        });
    }

    renderSociety() {
        if (this.state.area === null)
            return;
        let society = this.state.area.society;
        let text = society.name;
        if (society.address !== null)
            text += " " + society.address;
        if (society.number !== null)
            text += ", n°" + society.number;
        if (society.province !== null)
            text += ", " + society.province;
        if (society.region !== null)
            text += " (" + society.region + ")";
        return <CardText>{text}</CardText>
    }

    btnAddMicroArea = () => {
        this.addMicroArea(null, this.state.input);
        this.setState({input: ""});
    }

    polygonMicroAreaEdited = (key) => {
        let microareas = [];
        for (let i in this.state.microarea) {
            let microarea = this.state.microarea[i]

            if (microarea.key_microarea === key) {
                let cords = microarea.polygonObj.getPath().getArray().map(latLng => {
                    return {lat: latLng.lat(), lng: latLng.lng()};
                });
                microarea.area_cords = cords;
            }
            microareas.push(microarea);
        }
        this.setState({microarea: microareas});
    }

    addMicroArea = (id = null, name = null, poly = null, category_tags = [], is_valid = true, tickets_available_daily = null, score_capture_time_unlimited = null) => {
        let map = this.state.map;
        let maps = this.state.maps;
        let microareas = this.state.microarea;
        let polygonObj = null;
        let color = "#FFF";

        if (id === null && this.state.borderSet && (poly === null || Object.keys(poly).length < 3)) {
            poly = [];
            poly.push({lat: this.state.lat + 0.004, lng: this.state.lon + 0.006});
            poly.push({lat: this.state.lat - 0.006, lng: this.state.lon - 0.004});
            poly.push({lat: this.state.lat + 0.004, lng: this.state.lon - 0.006});
        }

        if (poly !== null && Object.keys(poly).length >= 3) {
            color = "#" + ((1 << 24) * Math.random() | 1).toString(16);
            polygonObj = new maps.Polygon({
                paths: poly,
                strokeColor: color,
                strokeOpacity: 0.9,
                strokeWeight: 2,
                fillColor: color,
                fillOpacity: 0.45,
                editable: true,
                draggable: true
            });
            let key = this.state.key_microarea;
            maps.event.addListener(polygonObj.getPath(), 'set_at', () => this.polygonMicroAreaEdited(key));
            maps.event.addListener(polygonObj.getPath(), 'insert_at', () => this.polygonMicroAreaEdited(key));
            polygonObj.setMap(map);
        }

        microareas.push({
            id: id,
            name: name,
            area_cords: poly,
            color: color,
            key_microarea: this.state.key_microarea,
            polygonObj: polygonObj,
            category_tags: category_tags,
            is_valid: is_valid,
            tickets_available_daily: tickets_available_daily,
            score_capture_time_unlimited: score_capture_time_unlimited
        });
        this.setState({microareas: microareas, key_microarea: this.state.key_microarea + 1});
    }

    deleteMicroArea = async (key = null) => {
        let microarea = this.state.microarea.find(m => {
            return m.key_microarea === key
        });
        if (typeof microarea !== "undefined") {
            microarea.polygonObj.setMap(null);
            let microareas = [];
            for (let i in this.state.microarea) {
                if (this.state.microarea[i].key_microarea !== key)
                    microareas.push(this.state.microarea[i]);
            }
            if (microarea.id !== null)
                await deleteMicroArea(microarea.id);
            this.setState({microarea: microareas});
        }
    }

    listMicroAreas = () => {
        let list;
        if (Object.keys(this.state.microarea).length === 0)
            list = <ListGroupItem>Non ci sono microaree impostate</ListGroupItem>
        else {
            list = this.state.microarea.map((item, key) => {
                return <ListGroupItem style={{background: item.color}}>
                    {!item.is_valid ?
                        <Badge color="warning" pill><i className="bx bx-error" color={"orange"}/></Badge> : ""}
                    <Badge color="light" pill>Name: {item.name}</Badge>
                    <div style={{float: "right"}}>
                        <Button color={"warning"} size="sm" onClick={() => this.setState({
                            micro_area_edit: item,
                            modalEdit: true
                        })}>Modifica</Button>
                    </div>
                </ListGroupItem>
            });
        }

        return <ListGroup>{list}</ListGroup>
    }

    listTags = () => {
        let list;
        if (Object.keys(this.state.tags).length === 0)
            list = <ListGroupItem>Non ci sono Tag impostati per la Categoria</ListGroupItem>
        else {
            list = this.state.tags.map((item, key) => {
                return <Chip className={"mb-2 mr-1"}
                             label={<strong>{item.name}</strong>}
                             onClick={() => this.state.area_tags.includes(item.id) ? this.deleteTag(item.id) : this.setTag(item.id)}
                             icon={this.state.area_tags.includes(item.id) ? <DoneIcon/> : null}
                             variant={this.state.area_tags.includes(item.id) ? "outlined" : null}
                />
            });
        }

        return <div>{list}</div>
    }

    deleteTag = async (id) => {
        let tags = this.state.area_tags;
        const index = tags.indexOf(id);
        if (index > -1) {
            tags.splice(index, 1);
            await setAreaTag(this.state.area.id, tags);
            this.setState({area_tags: tags});
            this.listTags();
        }
    }

    setTag = async (id) => {
        let tags = this.state.area_tags;
        tags.push(id);
        await setAreaTag(this.state.area.id, tags);
        this.setState({area_tags: tags});
        this.listTags();
    }

    deleteMicroAreaTag = async (id_tag, micro_area) => {
        let tags = micro_area.category_tags.filter((tag) => {
            if (tag.id !== id_tag)
                return tag.id;
        });
        if (micro_area.id !== null) {
            let res = await setMicroAreaTag(micro_area.id, tags);
            micro_area.category_tags = res.micro_area.category_tags;
        } else {
            micro_area.category_tags = micro_area.category_tags.filter((item, key) => {
                if (item.id !== id_tag)
                    return item;
            });
        }
        this.setState({micro_area_edit: micro_area});
    }

    setMicroAreaTag = async (id_tag, micro_area) => {
        let tags = micro_area.category_tags.map((tag) => {
            return tag.id;
        });
        tags.push(id_tag);
        if (micro_area.id !== null) {
            let res = await setMicroAreaTag(micro_area.id, tags);
            micro_area.category_tags = res.micro_area.category_tags;
        } else {
            let category_tag = this.state.tags.filter((item, key) => {
                if (item.id === id_tag)
                    return item;
            })[0];
            micro_area.category_tags.push(category_tag);
        }
        this.setState({micro_area_edit: micro_area});
    }

    handleChangeName = (e) => {
        this.setState({input: e.target.value});
    }

    loadRegions = () => {
        getListRegion().then(result => {
            if (!result) {
                this.setState({regionList: []});
            } else {
                let regions = [];
                result.list.map((value, i) => {
                    if (typeof regions[value.country] === "undefined")
                        regions[value.country] = [];
                    regions[value.country].push(value.region);
                })
                this.setState({regionList: regions});
            }
        })
    }

    renderListRegion = (regionSelected = null) => {
        let ret = [];
        for (let country in this.state.regionList) {
            ret.push(<option disabled>{country}</option>)
            for (let region in this.state.regionList[country]) {
                let selected = null;
                if (regionSelected !== null && this.state.regionList[country][region] === regionSelected)
                    selected = "selected";
                ret.push(<option
                    value={this.state.regionList[country][region]}
                    selected={selected}>{this.state.regionList[country][region]}</option>)
            }
        }
        return ret;
    }

    renderDetails = () => {
        if (this.state.category === null)
            return;
        switch (this.state.category.type) {
            case "TYPE_FISHING":
                return (<div className="form-check col-md-12 mb-4">
                    <AvField type="checkbox" className="form-check-input" value={this.state.area.check_fipsas_enabled}
                             id="is_check_fipsas_enabled" name="is_check_fipsas_enabled" label="Area controllo FIPSAS"/>
                    <AvField type="checkbox" className="form-check-input" value={this.state.area.check_coupon_enabled}
                             id="is_check_coupon_enabled" name="is_check_coupon_enabled"
                             label="Area controllo FIPSAS con codice non attivato"/>
                    <AvField type="checkbox" className="form-check-input"
                             value={this.state.area.score_capture_time_unlimited}
                             id="is_score_capture_unlimited" name="is_score_capture_unlimited"
                             label="Segna catture con tempo sessione illimitato"/>
                </div>);
            case "TYPE_MUSHROOM":
                let level = 0;
                if (this.state.area_level !== null)
                    level = this.state.area.area_level;
                return (<div className="form-group col-md-6">
                    <AvField type="select" name="area_level" label="Livello popolamento" value={level}
                             placeholder="Livello crescita">
                        <option disabled>Scegli livello popolamento</option>
                        <option value={0} key={0} selected={level === 0}>0 - Assente</option>
                        <option value={1} key={1} selected={level === 1}>1 - Insufficiente</option>
                        <option value={2} key={2} selected={level === 2}>2 - Scarsa</option>
                        <option value={3} key={3} selected={level === 3}>3 - Buona</option>
                        <option value={4} key={4} selected={level === 4}>4 - Abbondante</option>
                        <option value={5} key={5} selected={level === 5}>5 - Miracolosa</option>
                    </AvField>
                </div>);
        }
    }

    uploadImage = () => {
        var formData = new FormData();
        formData.append("image", this.state.imageUpload);
        formData.append("id", this.state.uploadId);

        ImgCategory(formData).then(result => {
            this.loadListCategories();
            this.setState({modal: false, imageUpload: null, uploadId: null, image: null});
        })
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            var formData = new FormData();
            formData.append("image", event.target.files[0]);
            formData.append("id", this.state.area.id);
            uploadImageArea(formData).then(result => {
                if (typeof result.error === "undefined") {
                    let area = this.state.area;
                    area.image = result.area.image;
                    this.setState({area: area});
                    global.notify("L'immagine è caricata", "success");
                } else {
                    global.notify("L'immagine non è stata caricata", "danger");
                }
            });
        }
    };

    onLogoChange = event => {
        if (event.target.files && event.target.files[0]) {
            var formData = new FormData();
            formData.append("image", event.target.files[0]);
            formData.append("id", this.state.area.id);
            uploadLogoArea(formData).then(result => {
                if (typeof result.error === "undefined") {
                    let area = this.state.area;
                    area.logo = result.area.logo;
                    this.setState({area: area});
                    global.notify("Il logo è caricato", "success");
                } else {
                    global.notify("Il logo non è stato caricato", "danger");
                }
            });
        }
    };

    renderModalEdit = () => {
        if (this.state.micro_area_edit === null)
            return;
        let micro_area = this.state.micro_area_edit;
        let microarea_tagsId = [];
        micro_area.category_tags.forEach(element => microarea_tagsId.push(element.id));

        let list = [];
        if (Object.keys(this.state.tags).length === 0)
            list.push(<ListGroupItem>Non ci sono Tag impostati per la Categoria</ListGroupItem>);
        else {
            list = this.state.tags.map((item, key) => {
                return <Chip className={"mb-2 mr-1"}
                             label={<strong>{item.name}</strong>}
                             onClick={() => microarea_tagsId.includes(item.id) ? this.deleteMicroAreaTag(item.id, micro_area) : this.setMicroAreaTag(item.id, micro_area)}
                             icon={microarea_tagsId.includes(item.id) ?
                                 <DoneIcon/> : false}
                             variant={microarea_tagsId.includes(item.id) ? "outlined" : null}
                />
            });
        }

        return <Modal isOpen={this.state.modalEdit} backdrop="static" keyboard={false}>
            <AvForm onValidSubmit={(e, v) => {
                this.handleValidSubmitEdit(e, v, micro_area)
            }} ref={c => (this.form_edit = c)}>
                <ModalHeader>Modifica micro area: {micro_area.name}</ModalHeader>
                <ModalBody>
                    <div className="form-horizontal">
                        <div className="row">
                            <div className="form-group col-md-12">
                                <AvField name="name" label="Nome" value={micro_area.name}
                                         className="form-control" placeholder="Inserisci Nome" type="text"
                                         required/>
                            </div>
                            <div className="form-group col-md-12">
                                <AvField name="tickets_available_daily"
                                         label="Numero di posti disponibili al giorno"
                                         value={micro_area.tickets_available_daily}
                                         className="form-control" placeholder="Inserisci numero"
                                         type="text"/>
                                <small>Attenzione! Le date d'utilizzo per i ticket di questa micro area saranno
                                    obbligatorie (lasciare il campo vuoto se non ci sono limiti)</small>
                            </div>
                            <div className="form-group col-md-12">
                                <AvField type="checkbox" className="form-check-input"
                                         value={micro_area.score_capture_time_unlimited}
                                         id="is_score_capture_unlimited_microarea"
                                         name="is_score_capture_unlimited_microarea"
                                         label="Segna catture con tempo sessione illimitato"/>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <Label>Lista tags</Label>
                        <br/>
                        {list}
                    </div>
                    <hr/>
                    <div align={"center"}>
                        <Button color={"danger"} onClick={() => {
                            this.deleteMicroArea(micro_area.key_microarea);
                            this.handleClose();
                        }}>Elimina micro area</Button>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.handleClose}>Chiudi</Button>
                    <Button type="submit" color="success">Salva</Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    }

    handleClose = () => this.setState({modalEdit: false, micro_area_edit: null});

    deleteArea = () => {
        deleteArea(this.state.area.id).then((result) => {
            if (typeof result.error === "undefined") {
                global.notify("L'area è stata eliminata", "success");
                window.history.go(-1);
            } else {
                global.notify("Errore durante l'eliminazione", "danger");
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Modifica Zona"/>
                        <Card>
                            <CardBody>
                                <div className="form-group">
                                    <Label for="society">Ente</Label>
                                    {this.renderSociety()}
                                </div>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v)
                                }} ref={c => (this.form = c)}>
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <AvField type="select" name="id_category" label="categoria"
                                                     value={this.state.area !== null ? this.state.area.category.id : null}
                                                     placeholder="Inserisci categoria" required>
                                                {this.listCategories()}
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <AvField name="name" label="Area nome" value={this.state.form_edit.name}
                                                     className="form-control" placeholder="Inserisci Area nome"
                                                     type="text"
                                                     required/>
                                        </div>

                                        <div className="form-group col-md-6">
                                            <AvField name="region" label="Area Regione"
                                                     value={this.state.form_edit.region}
                                                     className="form-control" placeholder="Inserisci Regione"
                                                     type="select" required>
                                                {this.renderListRegion(this.state.form_edit.region)}
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="province" label="Provincia"
                                                     value={this.state.form_edit.province}
                                                     className="form-control" placeholder="Inserisci Provincia"
                                                     type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="address" label="Area indirizzo"
                                                     value={this.state.form_edit.address} className="form-control"
                                                     placeholder="Inserisci indirizzo" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <AvField name="number" label="Area Numero"
                                                     value={this.state.form_edit.number}
                                                     className="form-control" placeholder="Inserisci Numero"
                                                     type="text" required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="city" label="Città" value={this.state.form_edit.city}
                                                     className="form-control" placeholder="Inserisci Città" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <AvField name="cap" label="CAP" value={this.state.form_edit.cap}
                                                     className="form-control" placeholder="Inserisci CAP" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="tickets_available_daily"
                                                     label="Numero di posti disponibili al giorno"
                                                     value={this.state.form_edit.tickets_available_daily}
                                                     className="form-control" placeholder="Inserisci numero"
                                                     type="text"/>
                                            <small>Attenzione! Le date d'utilizzo per i ticket di questa area saranno
                                                obbligatorie</small>
                                        </div>
                                        {this.renderDetails()}
                                    </div>
                                    <p><b>Descrizione zona</b></p>
                                    <ReactQuill
                                        theme="snow"
                                        name="full_description"
                                        value={this.state.form_edit.full_description}
                                        ref={(ref) => this.quill = ref}
                                        modules={{
                                            toolbar: {
                                                container: [
                                                    [
                                                        {header: [1, 2, 3, 4, 5, 6, false]},
                                                    ],
                                                    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'line'],
                                                    [
                                                        {list: 'ordered'},
                                                        {list: 'bullet'},
                                                        {align: []},
                                                    ],
                                                    [{color: []}],
                                                    ['link'],
                                                    ['clean'],
                                                ],
                                            },
                                            clipboard: {
                                                matchVisual: false,
                                            },
                                        }}
                                        formats={[
                                            'header',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent',
                                            'link',
                                            'color',
                                            'formats/style', 'style', 'line', 'align', 'clean', 'hr'
                                        ]}
                                        onChange={(value) => {
                                            let form_edit = this.state.form_edit;
                                            form_edit.full_description = value;
                                            this.setState({form_edit: form_edit});
                                        }}/>
                                    <hr/>
                                    <div align={"center"}>
                                        <Button
                                            onClick={() => (window.location.href = "/zone/image/" + this.state.area.id)}
                                            color={"info"}>Gestisci Immagini Area</Button>
                                    </div>
                                    <hr/>
                                    <Label>Lista Tags {this.state.form_edit.name}</Label>
                                    {this.listTags()}
                                    <hr/>
                                    <Label>Aggiungi Zona</Label>
                                    <Form inline>
                                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                            <Label for="nameMicroArea" className="mr-sm-2">Nome</Label>
                                            <Input type="text" name="nameMicroArea" id="nameMicroArea"
                                                   placeholder={"Nome zona"}
                                                   value={this.state.input} onChange={this.handleChangeName}/>
                                        </FormGroup>
                                        <Button type="button" color={"success"}
                                                onClick={this.btnAddMicroArea}>Aggiungi</Button>
                                    </Form>
                                    <FormGroup check onClick={() => this.setState({borderSet: !this.state.borderSet})}>
                                        <Input type="checkbox" checked={this.state.borderSet}/>
                                        <Label check>Impostazione confini</Label>
                                    </FormGroup>

                                    <hr/>
                                    <Label>Lista MicroArea</Label>
                                    {this.listMicroAreas()}
                                    {this.state.area !== null ?
                                        <div align={"center"} className={"mt-2"}>
                                            <a href={'/area/group/' + this.state.area.id}>Gestisci
                                                gruppi</a>
                                        </div>
                                        : <></>}
                                    <hr/>
                                    {this.renderMap()}
                                    <div className="text-center mt-4">
                                        <Button type="submit" color={"success"}>Modifica Area</Button>
                                    </div>
                                </AvForm>
                                {this.renderModalEdit()}
                            </CardBody>
                        </Card>

                        <div className="text-center m-4">
                            <Button onClick={() => this.deleteArea()} color={"danger"}>Elimina Area</Button>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {
    getInfoArea,
    addMicroArea,
    editMicroArea,
    deleteMicroArea,
    getCategories,
    getMapAreas,
    editZone
})(ZoneEditPage));