import React, {Component} from "react";
import {Card, CardBody, Container, Button, Modal, ModalHeader, ModalBody, Row, Col, ModalFooter} from "reactstrap";

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {apiError, listUsers, addFishingGuard, deleteFishingGuard} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {DataGrid} from "@material-ui/data-grid";
import moment from "moment";

class UserSociety extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Email", field: "email", width: 240},
                {headerName: "Nome", field: "firstname", width: 160},
                {headerName: "Cognome", field: "lastname", width: 160},
                {
                    headerName: "Tipologia", field: "role", width: 160,
                    renderCell: (params) => {
                        switch (params.value) {
                            case "ROLE_USER_SOCIETY":
                                return "Gestore";
                            case "ROLE_USER_FISHING_GUARD":
                                return "Guardiapesca";
                        }
                    }
                },
                {
                    headerName: "Ultimo accesso",
                    width: 180,
                    field: 'last_login',
                    renderCell: (params) => {
                        return params.getValue(params.id, "last_login") === null ?
                            <span>Nessun accesso</span> : moment(params.getValue(params.id, "last_login"), "DD/MM/YYYY hh:mm").format("DD/MM/YYYY hh:mm")
                    }
                },
                {
                    headerName: "-",
                    width: 140,
                    field: 'id',
                    renderCell: (params) => {
                        if (params.getValue(params.id, "role") === "ROLE_USER_SOCIETY")
                            return <></>
                        return <Button
                            onClick={() => this.deleteUser(params.row.id)}
                            type="button"
                            color="danger">Elimina</Button>
                    }
                }
            ],
            rows: [],
            paginator: {loadingSociety: true, countRows: 0, limit: 50},
            form_edit: {
                user: null,
                society: null,
                modal: false,
            },
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async handleValidSubmit(event, values) {
        let formResult = null;
        // if (values.role === "FISHING_GUARD") {
        delete values.role;
        formResult = await addFishingGuard(values);
        // } else {
        //     delete values.role;
        //     formResult = await setUserSosAdd(values);
        // }
        if (typeof formResult.error === 'undefined') {
            global.notify("Utente aggiunto con successo", "success");
            this.loadListUsers();
            this.form && this.form.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }

        window.scrollTo(0, 0);
    }

    deleteUser = async (id) => {
        let formResult = await deleteFishingGuard(id);
        if (typeof formResult.error === 'undefined') {
            global.notify("Utente cancellato con successo", "success");
            this.loadListUsers();
            this.form && this.form.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    async componentDidMount() {
        this.loadListUsers(0);
    }

    loadListUsers = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        listUsers(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.page = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.page += 1;
                this.setState({rows: result.list, paginator: paginator});
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Utenti ente"/>
                        <Card>
                            <CardBody>
                                <h5>Lista utenti ente</h5>
                                <div style={{height: '70vh', width: '100%'}}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        pagination
                                        pageSize={this.state.paginator.limit}
                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                        loading={this.state.paginator.loading}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h5>Aggiungi utente</h5>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v)
                                }} ref={c => (this.form = c)}>
                                    <div className={"row"}>
                                        <div className="form-group col-12">
                                            <AvField name="email" label="Email"
                                                     className="form-control" placeholder="Email" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-6 col-sm-6">
                                            <AvField name="firstname" label="Nome"
                                                     className="form-control" placeholder="Nome" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-6 col-sm-6">
                                            <AvField name="lastname" label="Cognome"
                                                     className="form-control" placeholder="Cognome" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-6 col-sm-6">
                                            <AvField type="select" name="role" label="Tipologia utente"
                                                     placeholder="Imposta il ruolo" required>
                                                <option value="USER_SOCIETY" disabled={true}>Gestore ente</option>
                                                <option value="FISHING_GUARD" selected={true}>Guardia pesca</option>
                                            </AvField>
                                        </div>
                                        <hr/>
                                        <div className="text-center col-12 mt-4">
                                            <Button type="submit" color="success">Aggiungi utente</Button>
                                        </div>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {apiError})(UserSociety));