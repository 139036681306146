import React, {Component} from "react";
import {Container, Card, CardBody, Button, CardTitle, CardText, Row, Badge, Input} from "reactstrap";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import {withTranslation} from 'react-i18next';

import {DataGrid} from "@material-ui/data-grid";
import {clientSubscriptionSuspend, infoClient} from "../../store/manager/client/actions";
import spid_logo from "../../assets/images/spid-level2-logo-lb.png";
import {editClientExtraDataTicket, editClientTicket, infoClientTicket} from "../../store/manager/products/actions";
import {AvField, AvForm} from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";

var moment = require('moment');
moment.locale('it');

class ClientInfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "ID", field: "id", width: 80},
                {headerName: "Nominativo", field: "nickname", width: 320},
                {headerName: "Ticket", field: "ticket", width: 160, renderCell: (params) => params.value.name},
                {headerName: "Data Acquisto", field: "date_purchase", width: 180},
                {
                    headerName: "Stato Pagamento", field: "status_payment", width: 200, renderCell: (params) => {
                        switch (params.value) {
                            case 'STATUS_PAYED':
                                return <Badge color="success">PAGATO</Badge>;
                            case 'STATUS_TO_PAY':
                                return <Badge color="info">DA PAGARE</Badge>;
                            case 'STATUS_CANCELLED':
                                return <Badge color="danger">CANCELLATO</Badge>;
                            case 'STATUS_REFUNDED':
                                return <Badge color="warning">RIMBORSATO</Badge>;
                        }
                    }
                },
                {
                    headerName: "Stato", field: "is_used", width: 120, renderCell: (params) =>
                        ((params.value) ? <Badge color="success">USATO</Badge> :
                                <Badge color="danger">NON USATO</Badge>
                        )
                },
                {
                    headerName: " ",
                    width: 80,
                    field: 'id1',
                    renderCell: (params) => (
                        <Button onClick={() => this.infoButton(params.id)} type="button" color="info">Info</Button>)
                }
            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            form_edit_ticket: {
                date_start: null,
                date_end: null,
            },
            ticket: null,
            sessions: [],
            society: null,
            client: null,
            date_start_use: null,
            date_end_use: null,
            tmp_input: null
        };
    }

    componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined" && this.props.match.params.id !== null) {
            infoClient(this.props.match.params.id).then((result) => {
                if (!result) {
                    global.notify("Errore", "danger");
                    return;
                }
                this.setState({
                    client: result.client,
                    rows: result.listTicketClient,
                    paginator: {loading: false, countRows: Object.keys(result.listTicketClient).length, limit: 50},
                });
            });
        }
    }

    suspendSubscribe = () => {
        clientSubscriptionSuspend(this.state.client.id).then((result) => {
            if (typeof result.user !== 'undefined') {
                global.notify("Abbonamento sospeso", "success");
                this.setState({client: result.user});
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    setDateTicket = (date, type) => {
        let date_start = this.state.date_start_use;
        let date_end = this.state.date_end_use;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit_ticket = this.state.form_edit_ticket;
        form_edit_ticket.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit_ticket.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start_use: date_start, date_end_use: date_end, form_edit_ticket: form_edit_ticket});
    }

    editTicket = async () => {
        let data = {
            id: this.state.ticket.id,
            nickname: this.state.tmp_input,
            date_start: this.state.form_edit_ticket.date_start,
            date_end: this.state.form_edit_ticket.date_end
        };

        console.log(data);
        let res = await editClientTicket(data);
        if (typeof res.error !== 'undefined')
            global.notify("Il ticket non è stato modificato: " + res.error, "danger");
        else {
            global.notify("Ticket modificato con successo", "success");
            this.infoButton(this.state.ticket.id);
        }
    }

    async infoButton(params) {
        let res = await infoClientTicket(params);

        if (Object.keys(res.sessions).length === 0) {
            this.setState({ticket: res.ticket, sessions: []});
        }
        let sessions = [];
        for (let i in res.sessions) {
            let session = res.sessions[i];
            sessions.push({
                id: session.id,
                client_spid: typeof session.client_spid !== "undefined" ? session.client_spid : null,
                id_ticket: res.ticket.id,
                name: "Sessione " + session.id,
                data: res.ticket.date_purchase,
                date_start: session.date_start,
                date_end: session.date_end,
                date_check: session.date_check,
                user_check: session.checked_by,
                area: res.ticket.ticket.area.name,
                micro_area: (res.ticket.ticket.micro_area != undefined) ? res.ticket.ticket.micro_area.name : '',
                count: res.captures.length,
                fishes: res.captures
            });
        }
        let ticket = res.ticket;
        let form_edit = {date_start: null, date_end: null};
        if (ticket.date_start_use !== null) {
            this.setState({date_start_use: moment(ticket.date_start_use, "DD/MM/YYYY h:mm").toDate()});
            form_edit.date_start = moment(ticket.date_start_use, "DD/MM/YYYY h:mm").format("DD/MM/YYYY");
        }
        if (ticket.date_end_use !== null) {
            this.setState({date_end_use: moment(ticket.date_end_use, "DD/MM/YYYY h:mm").toDate()});
            form_edit.date_end = moment(ticket.date_end_use, "DD/MM/YYYY h:mm").format("DD/MM/YYYY");
        }
        let nickname = null;
        if (ticket.nickname !== null)
            nickname = res.ticket.nickname;
        this.setState({ticket: ticket, sessions: sessions, form_edit_ticket: form_edit, tmp_input: nickname});
    }

    async handleEditExtraDataSubmit(event, values, edit = false) {
        editClientExtraDataTicket({id: this.state.ticket.id, extra_data: JSON.stringify(values)}).then((res) => {
            if (typeof res.error !== 'undefined')
                global.notify("I dati extra non sono stati modificati: " + res.error, "danger");
            else {
                global.notify("Ticket modificato con successo", "success");
                this.infoButton(this.state.ticket.id);
            }
        })
    }

    renderSessions = () => {
        return this.state.sessions.map((session, i) => {
            let spid = null;
            if (typeof session.client_spid !== "undefined" && session.client_spid !== null) {
                let data = session.client_spid.data;
                let content = [];
                if (typeof data.name !== "undefined") {
                    content.push("Nome: " + data.name[0]);
                    content.push(<br/>);
                }
                if (typeof data.familyName !== "undefined") {
                    content.push("Cognome: " + data.familyName[0]);
                    content.push(<br/>);
                }
                if (typeof data.email !== "undefined") {
                    content.push("Email: " + data.email[0]);
                    content.push(<br/>);
                }
                if (typeof data.address !== "undefined") {
                    content.push("Indirizzo: " + data.address[0]);
                    content.push(<br/>);
                }
                if (typeof data.fiscalNumber !== "undefined") {
                    content.push("Codice fiscale: " + data.fiscalNumber[0]);
                    content.push(<br/>);
                }
                if (typeof data.idCard !== "undefined") {
                    content.push("Documento: " + data.idCard[0]);
                    content.push(<br/>);
                }
                spid = <>
                    <div className={'col-md-12'}>
                        <hr/>
                    </div>
                    <div className={'col-md-6'}>
                        <CardText>
                            <h5 style={{color: "#01579b"}}>Sessionte autenticata con SPID</h5>
                            {content}
                        </CardText>
                    </div>
                    <div className={'col-md-6'} align={"center"}>
                        <img src={spid_logo} style={{width: "80%", maxWidth: 350, opacity: 0.5}}/>
                    </div>
                </>;
            }
            return (
                <div className={"col-md-12 col-sm-12"}>
                    <Card body>
                        <CardTitle tag="h5">{session.name} - {session.date_start}</CardTitle>
                        <div className={"row"}>
                            <div className={'col-md-6'}>
                                <CardText>
                                    Data avvio: {session.date_start}<br/>
                                    Data chiusura: {session.date_end}<br/>
                                    Area: {session.area}<br/>
                                    MicroArea: {session.micro_area}<br/>
                                    Catture: {session.count}<br/>
                                </CardText>
                            </div>
                            <div className={'col-md-6'}>
                                <CardText>Ultima verifica: {(session.date_check === null) ? "-" : session.date_check}
                                    <br/>
                                    Controllato
                                    da: {(session.user_check === null || typeof session.user_check === "undefined") ? "Sconosciuto" : (session.user_check.firstname + " " + session.user_check.lastname + " " + session.user_check.email + "(" + session.user_check.id + ")")}
                                </CardText>
                            </div>
                            {spid}
                        </div>
                        <Row>
                            {session.fishes.map((fish, iFish) => {
                                return (
                                    <div className={"col-md-2 col-sm-12"}>
                                        <Card body>
                                            <CardTitle tag="h5">{fish.fish.name}</CardTitle>
                                            <CardText>
                                                Data: <b>{fish.date_capture}</b><br/>
                                                {(fish.killed) ?
                                                    <Badge color="info">KILL</Badge> :
                                                    <Badge color="warning">NO KILL</Badge>}<br/>
                                                Lunghezza: {fish.length}<br/>
                                                Peso: {fish.weight}<br/>
                                            </CardText>
                                        </Card>
                                    </div>
                                )
                            })}
                        </Row>
                    </Card>
                </div>
            )
        })
    }

    getAddressClient = (client) => {
        let address = null;
        if (client.address !== null)
            address += "via " + client.address + " ";
        if (client.number !== null)
            address += "n° " + client.number + " ";
        if (client.city !== null)
            address += " " + client.city + " ";
        if (client.cap !== null)
            address += " (" + client.cap + ") ";
        return address;
    }

    renderPremium = () => {
        if (this.state.client.premium === null)
            return;
        let premium = this.state.client.premium;
        let status = null;
        switch (premium.status) {
            case 'STATUS_PAYED':
                status = <Badge color="success">PAGATO</Badge>;
                break;
            case 'STATUS_TO_PAY':
                status = <Badge color="info">DA PAGARE</Badge>;
                break;

            case 'STATUS_SUSPENDED':
                status = <Badge color="danger">SOSPESO</Badge>;
                break;
        }
        return <div className={"col-md-6"}>
            <Card>
                <CardBody>
                    <h5>Abbonamento {status}</h5>
                    <p>Abbonamento da €{premium.price.toFixed(2)} al {premium.type === "MONTHLY" ? "mese" : "anno"}</p>
                    <p>Inizio abbonamento {premium.date_start}</p>
                    { premium.date_expire !== null ?
                        <p>Termine abbonamento {premium.date_expire}</p> : <></>}
                    {premium.status === "STATUS_PAYED" ?
                        <Button onClick={() => this.suspendSubscribe()} type="button" color="danger" size={"sm"}>Sospendi
                            abbonamento</Button> : <></>}
                </CardBody>
            </Card>
        </div>
    }

    renderInfoTicket = () => {
        if (this.state.ticket === null)
            return;
        let ticket = this.state.ticket;
        let payment = null;
        if (ticket.paypal !== null)
            payment = <div>
                <hr/>
                <b>Pagato con PayPal</b><br/>Codice
                transazione: {ticket.paypal.sale_id}<br/>Token: {ticket.paypal.token}</div>
        if (ticket.stripe !== null)
            payment = <div>
                <hr/>
                <b>Pagato con Stripe</b><br/>Codice transazione: {ticket.stripe.stripe_id}<br/>Codice
                cliente: {ticket.stripe.client_secret}</div>

        let extra_data = [];
        if (Object.keys(ticket.extra_data).length > 0)
            extra_data.push(<hr/>);
        for (let i in ticket.extra_data) {
            let data = ticket.extra_data[i];
            extra_data.push(<b>{data.name}:</b>);
            extra_data.push(<span> {data.value}</span>);
            extra_data.push(<br/>);
        }
        let total = 0;
        let summary = [<hr/>];
        summary.push(<><b>Ticket:</b> €{ticket.price.toFixed(2)}<br/></>);
        total = ticket.price;
        if (ticket.service_fee !== null) {
            summary.push(<><span
                style={{textWrap: "nowrap"}}><b>Commissione:</b> €{ticket.service_fee.toFixed(2)}</span></>);
            total += ticket.service_fee;
        }
        if (ticket.discount_price !== null) {
            let code = "";
            if (ticket.coupon !== null)
                code = " (" + ticket.coupon.code + ")";
            summary.push(<><span style={{textWrap: "nowrap"}}><b>Sconto:</b> -€{ticket.discount_price.toFixed(2)} {code}</span><br/></>);
            total -= ticket.discount_price;
        }
        if (ticket.fees !== null) {
            summary.push(<><span
                style={{textWrap: "nowrap"}}><b>Transazione pagamento:</b> €{ticket.fees.toFixed(2)}</span><br/></>);
        }
        summary.push(<><b>Totale:</b> €{total.toFixed(2)}<br/></>);
        let form_extra_data = null;
        if (Object.keys(ticket.ticket.extra_data).length > 0) {
            let inputs = [];
            for (let i in ticket.ticket.extra_data) {
                let extra_data = ticket.ticket.extra_data[i];
                let value = "";
                for (let x in ticket.extra_data) {
                    if (ticket.extra_data[x].id === extra_data.id)
                        value = ticket.extra_data[x].value;
                }
                inputs.push(<div className="form-group col-md-12">
                    <AvField name={extra_data.id} label={extra_data.name} value={value}
                             className="form-control"
                             placeholder={extra_data.name}
                             type="text"/>
                </div>)
            }

            form_extra_data = <Card><CardBody><CardTitle tag="h5">Modifica dati extra del ticket</CardTitle>
                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                    this.handleEditExtraDataSubmit(e, v, this.state.flag_edit)
                }} ref={c => (this.form = c)}>
                    <div className={"row"}>
                        {inputs}
                        <div className="form-group col-md-12" align={"center"}>
                            <Button type="submit" color="success">Salva</Button>
                        </div>
                    </div>
                </AvForm>
            </CardBody></Card>
        }

        return <div className={"row"}>
            <div className={"col-md-6"}>
                <Card body>
                    <CardTitle tag="h5">Ticket #{ticket.id}</CardTitle>
                    <CardText>
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <b>Nome ticket:</b> {ticket.ticket.name}<br/>
                                Data acquisto: {ticket.date_purchase}<br/>
                                Data utilizzo: {ticket.date_start_use} - {ticket.date_end_use}<br/>
                                {ticket.client !== null ? "Utente: " + ticket.client.firstname + " " + ticket.client.lastname : ""}<br/>
                                {ticket.nickname !== null ? "Nominativo: " + ticket.nickname : <></>} <br/>
                                {summary}
                                {extra_data}
                                {payment}
                                <hr/>
                                <b>Ente:</b> {ticket.ticket.society.name}<br/>
                                <b>Area:</b> {ticket.ticket.area.name} <br/>
                                {ticket.ticket.micro_area !== null ? "Zona: " + ticket.ticket.micro_area.name : <></>}<br/>
                            </div>
                            <div className={"col-md-6"} align={"right"}>
                                <Button onClick={() => this.downloadTicket(ticket.id)}
                                        color={"danger"}>Scarica pdf</Button>
                                <br/>
                                <br/>
                                <Button onClick={() => this.refundTicket(ticket.id)}
                                        color={"warning"}>Rimborsa ticket</Button>
                            </div>
                        </div>
                    </CardText>
                </Card>
            </div>
            <div className={"col-md-6"}>
                <Card>
                    <CardBody>
                        <CardTitle tag="h5">Modifica dati del ticket</CardTitle>
                        <div className={"row"}>
                            <div className="form-group col-md-6">
                                <label>Data inizio utilizzo</label>
                                <DatePicker selected={this.state.date_start_use} startDate={this.state.date_current}
                                            className="form-control"
                                            onChange={(date) => this.setDateTicket(date, "start")}
                                            dateFormat="dd/MM/yyyy" placeholder={"Data inizio utilizzo"}/>
                            </div>
                            <div className="form-group col-md-6">
                                <label>Data fine utilizzo</label>
                                <DatePicker selected={this.state.date_end_use} minDate={this.state.date_start_use}
                                            className="form-control"
                                            onChange={(date) => this.setDateTicket(date, "end")}
                                            dateFormat="dd/MM/yyyy" placeholder={"Data fine utilizzo"}/>
                            </div>
                            <div className="form-group col-md-12">
                                <label>Nominativo</label>
                                <Input type="text" value={this.state.tmp_input} onChange={(e) => {
                                    this.setState({tmp_input: e.target.value});
                                }} placeholder={"Nominativo del ticket"}/>
                            </div>
                            <div align={"center"} class={"col-md-12"}>
                                <Button onClick={() => this.editTicket()} color={"success"}>Salva</Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                {form_extra_data}
            </div>
        </div>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={"Homepage"} breadcrumbItem={"Scheda utente"}/>
                        {this.state.client === null ? <div></div> : <div className={"row"}>
                            {this.renderPremium()}
                            <div className={"col-md-12"}>
                                <Card>
                                    <CardBody>
                                        <h5>Lista ticket acquistati</h5>
                                        <div className="form-group">
                                            <div style={{height: '50vh', width: '100%'}}>
                                                <DataGrid
                                                    rows={this.state.rows}
                                                    columns={this.state.columns}
                                                    pagination
                                                    pageSize={this.state.paginator.limit}
                                                    rowsPerPageOptions={[this.state.paginator.limit]}
                                                    rowCount={this.state.paginator.countRows}
                                                    paginationMode="server"
                                                    onPageChange={this.handlePageChange}
                                                    loading={this.state.paginator.loading}
                                                />
                                            </div>
                                        </div>
                                        {this.renderInfoTicket()}
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        }
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(ClientInfoPage);
