import {put} from 'redux-saga/effects';
import {apiCall} from '../../../helpers/backend_helper';

export const getLanguage = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'language/list'});
        if (typeof response.languages !== 'undefined') {
            return response.languages;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setLanguage = async (language) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/settings/language/add', data: language});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const deleteLanguage = async (language) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'DELETE', url: 'admin/settings/language/delete', data: language});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}
