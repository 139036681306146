import React, {Component} from "react";
import {Container, Card, CardBody} from "reactstrap";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import {withTranslation} from 'react-i18next';

import {getStatsDashboard} from "../../store/society/actions";
import {Bar, Doughnut, Line, Pie} from 'react-chartjs-2';
import {societyStats, societyStatsSummary} from "../../store/manager_society/actions";

var moment = require('moment');

class SocietyStatsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date_start: null,
            date_start_clone: null,
            date_end: null,
            stats_residence: [],
            stats_total: [],
            stats_types: [],
            stats_fishes: null,
        };
    }

    componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined" && this.props.match.params.id !== null) {
            societyStats(this.props.match.params.id).then((result) => {
                if (!result) {
                    global.notify("Errore", "danger");
                    return;
                }
                if (typeof result.error === "undefined") {
                    this.setState({
                        date_start: result.date_start,
                        date_start_clone: moment(result.date_start, "DD/MM/YYYY"),
                        date_end: moment(result.date_end, "DD/MM/YYYY"),
                        stats_residence: result.stats_residence,
                        stats_total: result.stats_total,
                        stats_types: result.stats_types,
                        stats_fishes: null
                    });
                    if (typeof result.stats_fishes !== "undefined")
                        this.setState({stats_fishes: result.stats_fishes});
                } else {
                    global.notify("Errore durante il caricamento delle statistiche", "error");
                }
            })
        }
    }

    renderTotal = () => {
        let date_start = moment(this.state.date_start, "DD/MM/YYYY");
        let diff = moment(this.state.date_end, "DD/MM/YYYY").diff(date_start, 'months', true)
        let labels = [];
        let data_total = [];
        let data_price = [];
        for (let i = 0; i < diff; i++) {
            date_start.add(1, "month");
            labels.push(date_start.format("MMMM"))
            if (typeof this.state.stats_total[date_start.format("YYYY/M")] !== "undefined") {
                data_total.push(this.state.stats_total[date_start.format("YYYY/M")].total);
                data_price.push(parseFloat(this.state.stats_total[date_start.format("YYYY/M")].total_price).toFixed(2));
            } else {
                data_total.push(0);
                data_price.push(0);
            }
        }

        const data = {
            labels: labels,
            datasets: [
                {
                    label: "Analisi delle vendite",
                    fill: true,
                    lineTension: 0.5,
                    backgroundColor: "rgba(85, 110, 230, 0.2)",
                    borderColor: "#556ee6",
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: "#556ee6",
                    pointBackgroundColor: "#fff",
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "#556ee6",
                    pointHoverBorderColor: "#fff",
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: data_total
                },
                {
                    label: "Incassi Mensili",
                    fill: true,
                    lineTension: 0.5,
                    backgroundColor: "rgba(235, 239, 242, 0.2)",
                    borderColor: "#ebeff2",
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: "#ebeff2",
                    pointBackgroundColor: "#fff",
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "#ebeff2",
                    pointHoverBorderColor: "#eef0f2",
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: data_price
                }
            ]
        };
        return (<Line data={data}/>);
    }

    renderStatsTypes = () => {
        let labels = [];
        let colors = [];
        let list = [];
        for (let i in this.state.stats_types) {
            labels.push(i);
            colors.push("#" + ((1 << 24) * Math.random() | 0).toString(16));
            list.push(this.state.stats_types[i].total);
        }
        const data = {
            labels: labels,
            datasets: [
                {
                    data: list,
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                    hoverBorderColor: "#fff"
                }]
        };
        return <Doughnut data={data}/>
    }

    renderStatsResidence = () => {
        let labels = [];
        let colors = [];
        let list = [];
        for (let i in this.state.stats_residence) {
            let item = this.state.stats_residence[i];
            labels.push(item.type.name);
            colors.push("#" + ((1 << 24) * Math.random() | 0).toString(16));
            list.push(item.total);
        }

        const data = {
            labels: labels,
            datasets: [
                {
                    data: list,
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                    hoverBorderColor: "#fff"
                }]
        };
        return <Pie data={data}/>
    }

    renderStatsFishes = () => {
        let labels = [];
        let list = [];
        for (let i in this.state.stats_fishes) {
            labels.push(this.state.stats_fishes[i].fish.name);
            list.push(this.state.stats_fishes[i].total);
        }

        const data = {
            labels: labels,
            datasets: [
                {
                    label: "Catture",
                    backgroundColor: "rgba(52, 195, 143, 0.8)",
                    borderColor: "rgba(52, 195, 143, 0.8)",
                    borderWidth: 1,
                    hoverBackgroundColor: "rgba(52, 195, 143, 0.9)",
                    hoverBorderColor: "rgba(52, 195, 143, 0.9)",
                    data: list
                }
            ]
        };

        return (<Bar data={data}/>);
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={"Homepage"} breadcrumbItem={"Statistiche ente"}/>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <Card>
                                    <CardBody>
                                        <h6>Statistiche di vendita</h6>
                                        {this.renderTotal()}
                                    </CardBody>
                                </Card>
                            </div>

                            <div className={"col-md-6"}>
                                <Card>
                                    <CardBody>
                                        <h6>Statistiche per
                                            tipologia <small>{this.state.date_start_clone !== null ? "dal " + this.state.date_start_clone.format("MM/YYYY") : ""}</small>
                                            <small>{this.state.date_end !== null ? " al " + this.state.date_end.format("MM/YYYY") : ""}</small>
                                        </h6>
                                        {this.renderStatsTypes()}
                                    </CardBody>
                                </Card>
                            </div>
                            <div className={"col-md-6"}>
                                <Card>
                                    <CardBody>
                                        <h6>Statistiche per
                                            residenza <small>{this.state.date_start_clone !== null ? "dal " + this.state.date_start_clone.format("MM/YYYY") : ""}</small>
                                            <small>{this.state.date_end !== null ? " al " + this.state.date_end.format("MM/YYYY") : ""}</small>
                                        </h6>
                                        {this.renderStatsResidence()}
                                    </CardBody>
                                </Card>
                            </div>
                            {this.state.stats_fishes !== null ? <div className={"col-md-12"}>
                                    <Card>
                                        <CardBody>
                                            <h6>Statistiche
                                                pesci <small>{this.state.date_start_clone !== null ? "dal " + this.state.date_start_clone.format("MM/YYYY") : ""}</small>
                                                <small>{this.state.date_end !== null ? " al " + this.state.date_end.format("MM/YYYY") : ""}</small>
                                            </h6>
                                            {this.renderStatsFishes()}
                                        </CardBody>
                                    </Card>
                                </div>
                                : <></>}
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(SocietyStatsPage);
