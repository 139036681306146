import React, {Component} from "react";
import {Row, Col, Card, CardBody, Container, Button, ButtonGroup, CardTitle, CardText} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {getListAreas, getCategories, getFishRegulation, addCapture} from '../../store/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';

var moment = require('moment');

class CaptureAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            columnsArea: [{headerName: "Nome area", field: "name", width: 160},
                {
                    headerName: "Categoria",
                    field: "category",
                    flex: 1,
                    renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 140},
                {headerName: "Province", field: "province", width: 140},
                {headerName: "Indirizzo", field: "address", width: 140},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 140},
                {headerName: "CAP", field: "cap", width: 120},
                {headerName: "Telefono", field: "phone", width: 140}],
            columnsMicroArea: [{headerName: "Nome micro area", field: "name", width: 260}],
            columnsFish: [
                {headerName: "Nome Pesce", field: "name", flex: 1},
                {headerName: "Descrizione", field: "description", flex: 1},
            ],
            rowsArea: [],
            rowsMicroArea: [],
            rowsFish: [],
            form_filter: {
                id_area: null,
                id_fish: null,
                date_capture: moment.parseZone(new Date()).local().format("DD/MM/YYYY HH:mm"),
            },
            date_capture: new Date(),
            area: null,
            fish: null,
            micro_area: null,
            paginator: {loadingStats: true, page: 0, countRows: 0, limit: 50},
            paginatorArea: {loading: true, countRows: 0, limit: 50},
            paginatorFish: {loading: true, countRows: 0, limit: 50},
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async handleValidSubmit(event, values) {
        if (this.state.area === null) {
            global.notify("Devi selezionare un 'area", "warning");
            return;
        }
        values.id_area = this.state.area.id;
        if (this.state.micro_area !== null)
            values.id_micro_area = this.state.micro_area.id;

        if (this.state.fish === null) {
            global.notify("Devi selezionare un pesce", "warning");
            return;
        }
        values.id_fish = this.state.fish.id;

        let formResult = await addCapture(values);
        if (typeof formResult.capture !== 'undefined') {
            global.notify('Cattura aggiunta con successo', "success");
            this.form && this.form.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con l'aggiunta: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
    }

    actionButton = (params) => {
        this.setState({form_filter: params.row});
    }

    async componentDidMount() {
        this.loadListFish();
        this.showTable('AREA');
    }

    handlePageAreaChange = (params) => {
        this.loadListAreas(params.page)
    };

    setDate = (date) => {
        let form_filter = this.state.form_filter;
        form_filter.date_capture = moment.parseZone(date).local().format("DD/MM/YYYY HH:mm");
        this.setState({date_capture: date, form_filter: form_filter});
    }

    showTable = (state) => {
        if (state === 'MICRO' && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        if (state === 'MICRO' && (typeof this.state.area.micro_areas === "undefined" || Object.keys(this.state.area.micro_areas).length === 0)) {
            global.notify("Non ci sono micro aree presenti per quest'area", "warning");
            return;
        }
        this.setState({showTable: state});
        if (state === 'AREA') {
            if (Object.keys(this.state.rows).length === 0)
                this.loadListAreas(0);
        }
    }

    loadListAreas = async (page) => {
        let paginatorArea = this.state.paginatorArea;
        paginatorArea.loading = true;
        this.setState({paginatorArea: paginatorArea});
        let categories = await getCategories();
        let id_category = null;
        if (categories !== null) {
            for (let i in categories) {
                if (categories[i].type === "TYPE_FISHING") {
                    id_category = categories[i].id;
                    break;
                }
            }
        }
        getListAreas(page + 1, id_category).then(result => {
            paginatorArea.loading = false;
            if (!result) {
                paginatorArea.countRows = 0;
                this.setState({paginatorArea: paginatorArea});
            } else {
                paginatorArea.countRows = result.paginator.count_all_entities;
                this.setState({rowsArea: result.list, paginatorArea: paginatorArea});
            }
        })
    }

    handleRowSelected = (rows) => {
        let row = [...rows.entries()][0][1];
        switch (this.state.showTable) {
            case "AREA":
                this.setState({
                    area: row,
                    rowsMicroArea: typeof row.micro_areas !== "undefined" ? row.micro_areas : []
                });
                break;
            case "MICRO":
                this.setState({micro_area: row});
                break;
        }
    }

    loadListFish = () => {
        let paginatorFish = this.state.paginatorFish;
        paginatorFish.loading = true;
        this.setState({paginatorFish: paginatorFish});

        getFishRegulation().then(result => {
            paginatorFish.loading = false;
            if (!result) {
                this.setState({rowsFish: [], paginatorFish: paginatorFish});
            } else {
                this.setState({rowsFish: result, paginatorFish: paginatorFish});
            }
        })
    }

    handleRowFishSelected = (rows) => {
        let row = [...rows.entries()][0][1];
        this.setState({fish: row});
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Aggiunta catture"/>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <h5>Seleziona l'area e/o zona per aggiungere la cattura</h5>
                                        <hr/>
                                        <div className="text-center mb-4">
                                            <ButtonGroup>
                                                <Button
                                                    onClick={() => this.showTable('AREA')}
                                                    color={(this.state.showTable === 'AREA') ? "light" : "primary"}>Area</Button>
                                                <Button
                                                    onClick={() => this.showTable('MICRO')}
                                                    color={(this.state.showTable === 'MICRO') ? "light" : "primary"}>MicroArea</Button>
                                            </ButtonGroup>
                                        </div>
                                        <div style={{height: '40vh', width: '100%'}}
                                             hidden={this.state.showTable !== 'AREA'}>
                                            <DataGrid
                                                rows={this.state.rowsArea}
                                                columns={this.state.columnsArea}
                                                pagination
                                                pageSize={this.state.paginatorArea.limit}
                                                rowsPerPageOptions={[this.state.paginatorArea.limit]}
                                                rowCount={this.state.paginatorArea.countRows}
                                                paginationMode="server"
                                                onPageChange={this.handlePageAreaChange}
                                                loading={this.state.paginatorArea.loading}
                                                onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                            />
                                        </div>
                                        <div style={{height: '40vh', width: '100%'}}
                                             hidden={this.state.showTable !== 'MICRO'}>
                                            <DataGrid
                                                rows={this.state.rowsMicroArea}
                                                columns={this.state.columnsMicroArea}
                                                pageSize={Object.keys(this.state.rowsMicroArea).length}
                                                rowsPerPageOptions={[Object.keys(this.state.rowsMicroArea).length]}
                                                rowCount={Object.keys(this.state.rowsMicroArea).length}
                                                onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                            />
                                        </div>
                                        <div className={"row"}>
                                            {this.state.area !== null ? <div className={"col-md-4"}>
                                                    <Card body>
                                                        <CardTitle tag="h5">Area</CardTitle>
                                                        <CardText>Nome
                                                            area: {this.state.area.name}<br/> {this.state.area.address},
                                                            n°{this.state.area.number} {this.state.area.city} {this.state.area.region}
                                                        </CardText>
                                                    </Card>
                                                </div>
                                                : ""}
                                            {this.state.micro_area !== null ? <div className={"col-md-4"}>
                                                    <Card body>
                                                        <CardTitle tag="h5">Micro Area</CardTitle>
                                                        <CardText>Nome micro area: {this.state.micro_area.name}</CardText>
                                                    </Card>
                                                </div>
                                                : ""}
                                        </div>
                                        <br/>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <h5>Seleziona il pesce da catturare</h5>
                                        <hr/>
                                        <div style={{height: '40vh', width: '100%'}}>
                                            <DataGrid
                                                rows={this.state.rowsFish}
                                                columns={this.state.columnsFish}
                                                pageSize={Object.keys(this.state.rowsFish).length}
                                                rowsPerPageOptions={[Object.keys(this.state.rowsFish).length]}
                                                rowCount={Object.keys(this.state.rowsFish).length}
                                                loading={this.state.paginatorFish.loading}
                                                onRowSelected={(x) => this.handleRowFishSelected(x.api.current.getSelectedRows())}
                                            />
                                        </div>
                                        <br/>

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                            this.handleValidSubmit(e, v)
                                        }} ref={c => (this.form = c)}>
                                            <div className={"row"}>
                                                <div className="form-group col-md-4">
                                                    <AvField name="date_capture" hidden
                                                             label="Data della cattura"
                                                             value={this.state.form_filter.date_capture}
                                                             className="form-control"
                                                             placeholder="Inserisci data" type="text"
                                                             required/>
                                                    <DatePicker selected={this.state.date_capture}
                                                                className="form-control"
                                                                showTimeSelect
                                                                onChange={(date) => this.setDate(date)}
                                                                dateFormat="dd/MM/yyyy HH:mm"
                                                                timeFormat="HH:mm"
                                                    />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <AvField name="quantity" label="Quantità" className="form-control"
                                                             placeholder="Inserisci la quantità" type="number" value={1}
                                                             required/>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <AvField name="weight" label="Peso" className="form-control"
                                                             placeholder="Inserisci il peso" type="number"/>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <AvField name="length" label="Lunghezza" className="form-control"
                                                             placeholder="Inserisci la lunghezza" type="number"/>
                                                </div>
                                                <div className="form-check col-md-6">

                                                    <AvField type="checkbox" className="form-check-input" name="killed"
                                                             label="Catturato?"/>
                                                </div>
                                                <div className="form-group col-md-12" align={"center"}>
                                                    <Button type="submit" color="success">Aggiungi cattura</Button>
                                                </div>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(CaptureAdd));