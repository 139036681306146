import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import GoogleMapReact from 'google-map-react';
import {DataGrid} from '@material-ui/data-grid';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {getListAreas, getMapAreas, fileRules} from '../../../store/actions';
import Breadcrumb from '../../../components/Common/Breadcrumb';

class ZoneListPage extends Component {
    static defaultProps = {
        center: {
            lat: 42.45,
            lng: 12.96
        },
        zoom: 5.5
    };

    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Nome area", field: "name", width: 180},
                {
                    headerName: "Categoria",
                    field: "category",
                    width: 140,
                    renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Provincia", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "Numero", field: "number", width: 140},
                {headerName: "Città", field: "city", width: 160},
                {headerName: "CAP", field: "cap", width: 140},
                {headerName: "Telefono", field: "phone", width: 140},
                {
                    headerName: "Regolamento",
                    width: 200,
                    field: 'fishing_regulation',
                    renderCell: (params) => {
                        return <Row>
                            <Col>
                                <Button onClick={() => this.fileButton(params.getValue(params.id, "id"))} type="button"
                                        color="secondary">File</Button>
                            </Col>
                            <Col>
                                {(params.formattedValue !== null) ? ((params.formattedValue.document !== null) ?
                                    <a href={global.baseUrlImg + params.formattedValue.document} alt="" target="_blank"
                                       max-height="100" width="100">Regolamento</a> : 'Nessun file') : 'Nessun file'}
                            </Col>
                        </Row>
                    }
                },
                {
                    headerName: " ",
                    width: 120,
                    field: 'id',
                    renderCell: (params) => (
                        <Button onClick={() => this.actionButton(params.id)} type="button"
                                color="primary">Modifica</Button>
                    )
                }
            ],
            rows: [],
            map: null,
            maps: null,
            paginator: {loading: true, countRows: 0, limit: 50},
            markers: [],
            image: null,
            modal: false,
            imageUpload: null,
            uploadId: null
        };
        this.onImageChange = this.onImageChange.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose = () => this.setState({modal: false});

    uploadImage = async () => {
        var formData = new FormData();
        formData.append("file", this.state.imageUpload);
        formData.append("id_area", this.state.uploadId);

        let result = await fileRules(formData);
        if (typeof result.error === "undefined") {
            this.setState({modal: false, imageUpload: null, uploadId: null, image: null}, () => {
                global.notify("Immagine caricata con successo", "success");
                this.loadListAreas(0);
            });
        } else {
            global.notify("Errore durante il caricamento: " + result.error, "error");
        }
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                image: URL.createObjectURL(img),
                imageUpload: event.target.files[0]
            });
        }
    };

    async componentDidMount() {
        this.loadListAreas(0);
    }

    handleBoundChanged = () => {
        if (this.state.map !== null && this.state.maps !== null)
            this.handleApiLoaded(this.state.map, this.state.maps);
    }

    handleApiLoaded = (map, maps) => {
        let bounds = map.getBounds();
        getMapAreas('', bounds.getNorthEast().lat(), bounds.getNorthEast().lng(), bounds.getSouthWest().lat(), bounds.getSouthWest().lng()).then((result) => {
            let arr_markers = [];
            for (let i in result.list) {
                let area = result.list[i];
                if (typeof this.state.markers.find(m => {
                    return m.id === area.id
                }) !== "undefined")
                    continue;

                arr_markers.push({id: area.id});
                let marker = new maps.Marker({
                    map: map,
                    position: new maps.LatLng(area.lat, area.lon),
                });
                maps.event.addListener(marker, 'click', () => {
                    window.location.href = '/zone/edit/' + area.id
                });


                if (typeof area.area_cords !== "undefined" && area.area_cords !== null && Object.keys(area.area_cords).length >= 3) {
                    let polygon = [];
                    for (let i in area.area_cords)
                        polygon.push({lat: area.area_cords[i].lat, lng: area.area_cords[i].lon});

                    let polygonObj = new maps.Polygon({
                        paths: polygon,
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.35
                    });
                    polygonObj.setMap(map);
                }
            }
            this.setState({markers: [...arr_markers, ...this.state.markers]})
        });
    }

    renderMap() {
        return <Card body>
            <div style={{height: '50vh', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyCV53jtplvMZi17CqUW7w8Q5w9uat1lgqw"}}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({map, maps}) => {
                        this.setState({map: map, maps: maps})
                        this.handleApiLoaded(map, maps)
                    }}
                    onBoundsChange={this.handleBoundChanged}
                />
            </div>
        </Card>
    }

    loadListAreas = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getListAreas(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    handlePageChange = (params) => {
        this.loadListAreas(params.page)
    };

    actionButton = (value) => {
        window.location.href = '/zone/edit/' + value;
    }

    fileButton = (id) => {
        this.setState({modal: true, uploadId: id});
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista Zone"/>
                        <Card>
                            <CardBody>
                                <div className="form-group">
                                    <div style={{height: '70vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChange}
                                            loading={this.state.paginator.loading}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Modal
                            isOpen={this.state.modal}
                            backdrop="static"
                            keyboard={false}>
                            <ModalHeader>Carica file</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg="12">
                                        <img src={this.state.image}/>
                                        <h1>Scegli file</h1>
                                        <input type="file" name="file" onChange={this.onImageChange}/>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.handleClose}>Annulla</Button>{' '}
                                <Button color="primary" onClick={this.uploadImage}>Carica</Button>
                            </ModalFooter>
                        </Modal>
                        {this.renderMap()}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {getListAreas, getMapAreas})(ZoneListPage));
