import React, {Component} from "react";
import {Container, Card, CardBody} from "reactstrap";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import {withTranslation} from 'react-i18next';

import {listUsers} from "../../store/manager_society/actions";
import {DataGrid} from "@material-ui/data-grid";

var moment = require('moment');
moment.locale('it');

class ListUserPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Email", field: "email", width: 240},
                {
                    headerName: "Ente", field: "society", width: 160,
                    renderCell: (params) => {
                        return params.getValue(params.id, "society").name;
                    }
                },
                {headerName: "Nome", field: "firstname", width: 160},
                {headerName: "Cognome", field: "lastname", width: 160},
                {
                    headerName: "Tipologia", field: "role", width: 160,
                    renderCell: (params) => {
                        switch (params.value) {
                            case "ROLE_USER_SOCIETY":
                                return "Gestore";
                            case "ROLE_USER_FISHING_GUARD":
                                return "Guardiapesca";
                        }
                    }
                },
                {
                    headerName: "Ultimo accesso",
                    width: 180,
                    field: 'last_login',
                    renderCell: (params) => {
                        return params.getValue(params.id, "last_login") === null ?
                            <span>Nessun accesso</span> : moment(params.getValue(params.id, "last_login"), "DD/MM/YYYY hh:mm").format("DD/MM/YYYY hh:mm")
                    }
                },
            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            society: null
        };
    }

    componentDidMount() {
        this.loadList(0);
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});
        listUsers(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    handlePageChange = (params) => {
        this.loadList(params.page)
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={"Homepage"} breadcrumbItem={"Lista utenti"}/>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <Card>
                                    <CardBody>
                                        <div style={{height: '80vh', width: '100%'}}>
                                            <DataGrid
                                                rows={this.state.rows}
                                                columns={this.state.columns}
                                                pagination
                                                pageSize={this.state.paginator.limit}
                                                rowsPerPageOptions={[this.state.paginator.limit]}
                                                rowCount={this.state.paginator.countRows}
                                                paginationMode="server"
                                                onPageChange={this.handlePageChange}
                                                loading={this.state.paginator.loading}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(ListUserPage);
