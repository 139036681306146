import React, {Component} from "react";
import {Switch, BrowserRouter as Router} from "react-router-dom";
import {connect} from "react-redux";
import NotificationAlert from 'react-notification-alert';

// Import Routes
import {authProtectedRoutes, publicRoutes} from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import Cookies from 'universal-cookie';
// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Import fackbackend Configuration file
import fakeBackend from './helpers/AuthType/fakeBackend';
import {isUserAuthenticated} from './helpers/backend_helper';
import {checkUser} from './store/actions';

// Activating fake backend
fakeBackend();

// init firebase backend
// initFirebaseBackend(firebaseConfig);
global.cookies = new Cookies();

global.getFormData = function (object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
};
var moment = require('moment');
moment.locale('it');

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        if (isUserAuthenticated()) {
            checkUser().then(result => {
                if (!result) {
                    localStorage.removeItem("authUser");
                    window.location.href = '/login';
                }
            });
        }
        this.getLayout = this.getLayout.bind(this);
    }

    /**
     * Returns the layout
     */
    getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (this.props.layout.layoutType) {
            // case "horizontal":
            // 	layoutCls = HorizontalLayout;
            // 	break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    render() {
        const Layout = this.getLayout();

        return (
            <React.Fragment>
                <NotificationAlert ref={(notify) => global.notifyRef = notify} zIndex={9999}/>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                            />
                        ))}

                        {authProtectedRoutes.map((route, idx) => (
                            <AppRoute
                                path={route.path}
                                layout={Layout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={true}
                            />
                        ))}
                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}

global.notify = function (message, type) {
    global.notifyRef.notificationAlert({
        place: 'tr',
        message: message,
        type: type,
        autoDismiss: 5
    });
}

const mapStateToProps = state => {
    return {
        layout: state.Layout
    };
};

export default connect(mapStateToProps, null)(App);
