import React, {Component} from "react";
import {Row, Col, Card, CardTitle, CardText, CardBody, Container, Button, Badge, ButtonGroup} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Redux
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
//i18n
import {withTranslation} from 'react-i18next';
import {AvForm, AvField} from 'availity-reactstrap-validation';

import Breadcrumb from '../../components/Common/Breadcrumb';
import {windowOpen} from "echarts/src/util/format";
import {listAllClientTickets, ticketInfo} from "../../store/manager_society/actions";
import spid_logo from "../../assets/images/spid-level2-logo-lb.png";

var moment = require('moment');

class TicketClientListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "[Ticket] Utente", field: "client", width: 320, renderCell: (params) => (
                        <Row>
                            <Col>[{params.id}] {params.value !== null ? params.value.email : "Utente anonimo"}</Col>
                        </Row>
                    )
                },
                {
                    headerName: "Nome e cognome",
                    field: "client1",
                    width: 320,
                    renderCell: (params) => params.getValue(params.id, "client") !== null ? (
                        <Row>
                            <Col>{params.getValue(params.id, "client").firstname} {params.getValue(params.id, "client").lastname}</Col>
                        </Row>
                    ) : <></>
                },
                {
                    headerName: "Nominativo",
                    field: "nickname",
                    width: 240,
                },
                {headerName: "Ticket", field: "ticket", width: 160, renderCell: (params) => (params.value.name)},
                {
                    headerName: "Ente",
                    field: "society",
                    width: 140,
                    renderCell: (params) => (params.getValue(params.id, "ticket").society.name)
                },
                {headerName: "Data Acquisto", field: "date_purchase", width: 180},
                {
                    headerName: "Stato Pagamento", field: "status_payment", width: 200, renderCell: (params) => {
                        switch (params.value) {
                            case 'STATUS_PAYED':
                                return <Badge color="success">PAGATO</Badge>;
                            case 'STATUS_TO_PAY':
                                return <Badge color="info">DA PAGARE</Badge>;
                            case 'STATUS_CANCELLED':
                                return <Badge color="danger">CANCELLATO</Badge>;
                            case 'STATUS_REFUNDED':
                                return <Badge color="warning">RIMBORSATO</Badge>;
                        }
                    }
                },
                {
                    headerName: "Stato", field: "is_used", width: 180, renderCell: (params) =>
                        ((params.value) ? <Badge color="success">USATO</Badge> :
                                <Badge color="danger">NON USATO</Badge>
                        )
                },
                {
                    headerName: " ",
                    width: 100,
                    field: 'id',
                    renderCell: (params) => (
                        <Button onClick={() => this.infoButton(params.id)} type="button"
                                color="info">Info</Button>
                    )
                }
            ],
            rows: [],
            map: null,
            maps: null,
            paginator: {loading: true, countRows: 0, limit: 50},
            markers: [],
            form_edit: {
                date_start: moment().subtract('1', "month").format("DD/MM/YYYY"),
                date_end: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
            },
            search: null,
            date_start: (moment().subtract('1', "month").toDate()),
            date_end: new Date(),
            date_current: new Date(),
            ticket: null,
            sessions: []
        };
    }

    setDate = (date, type) => {
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    async componentDidMount() {
        if (typeof this.props.match.params.type !== "undefined" && this.props.match.params.type !== null) {
            let date_start = this.state.date_start;
            let date_end = this.state.date_end;
            switch (this.props.match.params.type) {
                case 'DAILY':
                    date_start = moment.parseZone(new Date());
                    date_end = moment.parseZone(new Date());
                    break;
                case 'TWODAYS':
                    date_start = moment.parseZone(new Date()).subtract(1, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'THREEDAYS':
                    date_start = moment.parseZone(new Date()).subtract(2, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'FOURDAYS':
                    date_start = moment.parseZone(new Date()).subtract(3, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'WEEKDAYS':
                    date_start = moment.parseZone(new Date()).subtract(4, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'WEEKLY':
                    date_start = moment.parseZone(new Date()).subtract(6, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'TWOWEEKS':
                    date_start = moment.parseZone(new Date()).subtract(13, 'days');
                    date_end = moment.parseZone(new Date()).add(1, 'days');
                    break;
                case 'MONTHLY':
                    date_start = moment.parseZone(new Date()).subtract(26, 'days');
                    date_end = moment.parseZone(new Date()).add(4, 'days');
                    break;
                case 'TRIMESTER':
                    date_start = moment.parseZone(new Date()).subtract(26, 'days');
                    date_end = moment.parseZone(new Date()).add(45, 'days');
                    break;
                case "SEASONAL":
                case 'SEMIANNUAL':
                    date_start = moment.parseZone(new Date()).subtract(100, 'days');
                    date_end = moment.parseZone(new Date()).add(100, 'days');
                    break;
                case 'YEARLY':
                    date_start = moment.parseZone(new Date()).subtract(176, 'days');
                    date_end = moment.parseZone(new Date()).add(189, 'days');
                    break;
            }
            let form_edit = {
                date_start: date_start.local().format("DD/MM/YYYY"),
                date_end: date_end.local().format("DD/MM/YYYY"),
            }
            this.setState({
                date_start: new Date(date_start),
                date_end: new Date(date_end),
                form_edit: form_edit
            }, () => this.loadList(0));
        } else {
            this.loadList(0);
        }
    }

    loadList = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});
        let data = {
            page: page + 1,
            date_start: this.state.form_edit.date_start,
            date_end: this.state.form_edit.date_end
        }
        if (this.state.search !== null)
            data.search = this.state.search;
        listAllClientTickets(data).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    async infoButton(params) {
        let res = await ticketInfo(params);

        if (Object.keys(res.sessions).length === 0) {
            this.setState({ticket: res.ticket, sessions: []});
        }
        let sessions = [];
        for (let i in res.sessions) {
            let session = res.sessions[i];

            let captures = [];
            for (let c in res.captures) {
                let capture = res.captures[c];
                if (typeof capture.score_capture_session !== "undefined" && capture.score_capture_session !== null) {
                    if (capture.score_capture_session.id === session.id)
                        captures.push(capture);
                }
            }
            sessions.push({
                id: session.id,
                id_ticket: res.ticket.id,
                name: "Sessione " + session.id,
                data: res.ticket.date_purchase,
                date_start: session.date_start,
                date_end: session.date_end,
                date_check: session.date_check,
                user_check: session.checked_by,
                area: res.ticket.ticket.area.name,
                micro_area: (res.ticket.ticket.micro_area != undefined) ? res.ticket.ticket.micro_area.name : '',
                count: captures.length,
                fishes: captures
            });
        }
        this.setState({ticket: res.ticket, sessions: sessions});
    }

    async handleValidSubmit(event, values, edit = false) {
        let search = null;
        if (values.search !== "" && values.search !== null)
            search = values.search;
        this.setState({search: search}, () => this.loadList(0));
    }

    renderSessions = () => {
        return this.state.sessions.map((session, i) => {
            let spid = null;
            if (typeof session.client_spid !== "undefined" && session.client_spid !== null) {
                let data = session.client_spid.data;
                let content = [];
                if (typeof data.name !== "undefined") {
                    content.push("Nome: " + data.name[0]);
                    content.push(<br/>);
                }
                if (typeof data.familyName !== "undefined") {
                    content.push("Cognome: " + data.familyName[0]);
                    content.push(<br/>);
                }
                if (typeof data.email !== "undefined") {
                    content.push("Email: " + data.email[0]);
                    content.push(<br/>);
                }
                if (typeof data.address !== "undefined") {
                    content.push("Indirizzo: " + data.address[0]);
                    content.push(<br/>);
                }
                if (typeof data.fiscalNumber !== "undefined") {
                    content.push("Codice fiscale: " + data.fiscalNumber[0]);
                    content.push(<br/>);
                }
                if (typeof data.idCard !== "undefined") {
                    content.push("Documento: " + data.idCard[0]);
                    content.push(<br/>);
                }
                spid = <>
                    <div className={'col-md-12'}>
                        <hr/>
                    </div>
                    <div className={'col-md-6'}>
                        <CardText>
                            <h5 style={{color: "#01579b"}}>Sessionte autenticata con SPID</h5>
                            {content}
                        </CardText>
                    </div>
                    <div className={'col-md-6'} align={"center"}>
                        <img src={spid_logo} style={{width: "80%", maxWidth: 350, opacity: 0.5}}/>
                    </div>
                </>;
            }
            return (
                <Card className={"col-md-12 col-sm-12"} body>
                    <CardTitle tag="h5">{session.name} - {session.date_start}</CardTitle>
                    <div className={"row"}>
                        <div className={'col-md-6'}>
                            <CardText>
                                Data avvio: {session.date_start}<br/>
                                Data chiusura: {session.date_end}<br/>
                                Area: {session.area}<br/>
                                MicroArea: {session.micro_area}<br/>
                                Catture: {session.count}<br/>
                            </CardText>
                        </div>
                        <div className={'col-md-6'}>
                            <CardText>Ultima verifica: {(session.date_check === null) ? "-" : session.date_check}
                                <br/>
                                Controllato
                                da: {(session.user_check === null || typeof session.user_check === "undefined") ? "Sconosciuto" : (session.user_check.firstname + " " + session.user_check.lastname + " " + session.user_check.email + "(" + session.user_check.id + ")")}
                            </CardText>
                        </div>
                        {spid}
                    </div>
                    <Row>
                        {session.fishes.map((fish, iFish) => {
                            return (
                                <div className={"col-md-2 col-sm-12"}>
                                    <Card body>
                                        <CardTitle
                                            tag="h5">{fish.fish.name}</CardTitle>
                                        <CardText>
                                            Data: <b>{fish.date_capture}</b><br/>
                                            {(fish.killed) ?
                                                <Badge color="info">KILL</Badge> :
                                                <Badge color="warning">NO KILL</Badge>}<br/>
                                            Lunghezza: {fish.length}<br/>
                                            Peso: {fish.weight}<br/>
                                        </CardText>
                                    </Card>
                                </div>
                            )
                        })}
                    </Row>
                </Card>
            )
        })
    }

    downloadTickets = () => {
        let date_start = moment(this.state.date_start).unix();
        let date_end = moment(this.state.date_end).unix();

        global.notify("Dati scaricati", "success");
        windowOpen(global.baseUrl + 'stats/ticket/download/' + date_start + "/" + date_end, "blank")
    }

    downloadTicket = (id) => {
        global.notify("Ticket scaricato", "success");
        windowOpen(global.baseUrl + 'admin/ticket/download/' + id, "blank")
    }

    handlePageChange = (params) => {
        this.loadList(params.page)
    };

    renderInfoTicket = () => {
        if (this.state.ticket === null)
            return;
        let ticket = this.state.ticket;
        let extra_data = [];
        if(Object.keys(ticket.extra_data).length >0)
            extra_data.push(<hr/>);
        for(let i in ticket.extra_data){
            let data = ticket.extra_data[i];
            extra_data.push(<b>{data.name}:</b>);
            extra_data.push(<span> {data.value}</span>);
            extra_data.push(<br/>);
        }
        return <div className={"row"}>
            <div className={"col-md-12"}>
                <Card body>
                    <CardTitle tag="h5">Ticket #{ticket.id}</CardTitle>
                    <CardText>
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                Nome ticket: {ticket.ticket.name}<br/>
                                Data acquisto: {ticket.date_purchase}<br/>
                                Data Utilizzo: {ticket.date_start_use} - {ticket.date_end_use}<br/>
                                {this.state.ticket.client !== null ? "Utente: " + this.state.ticket.client.firstname + " " + this.state.ticket.client.lastname : ""}<br/>
                                {ticket.nickname !== null ? "Nominativo: " + ticket.nickname : <></>} <br/>
                                {extra_data}
                                <hr/>
                                Area: {ticket.ticket.area.name} <br/>
                                {ticket.ticket.micro_area !== null ? "Zona: " + ticket.ticket.micro_area.name : <></>}<br/>
                            </div>
                            <div className={"col-md-6"} align={"right"}>
                                <Button onClick={() => this.downloadTicket(this.state.ticket.id)}
                                        color={"danger"}>Scarica pdf</Button>
                            </div>
                        </div>
                    </CardText>
                </Card>
            </div>
        </div>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista Tickets"/>
                        <Card>
                            <CardBody>
                                <h5>Scegli le date in cui vuoi visualizzare i ticket</h5>
                                <div className={"row"}>
                                    <div className="form-group col-md-4">
                                        <DatePicker selected={this.state.date_start}
                                                    startDate={this.state.date_current} className="form-control"
                                                    onChange={(date) => this.setDate(date, "start")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <DatePicker selected={this.state.date_end}
                                                    minDate={this.state.date_start} className="form-control"
                                                    onChange={(date) => this.setDate(date, "end")}
                                                    dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group col-md-4" style={{display: "flex"}}>
                                        <div style={{marginTop: "auto"}}>
                                            <ButtonGroup>
                                                <Button onClick={() => this.loadList(0)}
                                                        color={"success"}>Aggiorna</Button>
                                                <Button onClick={() => this.downloadTickets()}
                                                        color={"primary"}>Scarica dati</Button>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    <div className={"row"}>
                                        <div className="form-group col-md-8">
                                            <AvField name="search" label="Cerca ticket"
                                                     className="form-control"
                                                     placeholder="Cerca ticket per nome, cognome o email dei clienti"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-4" style={{display: "flex"}}>
                                            <div style={{marginTop: "auto"}}>
                                                <Button type="submit" color="primary">Cerca</Button>
                                            </div>
                                        </div>
                                    </div>
                                </AvForm>

                                <div className="form-group">
                                    <div style={{height: '50vh', width: '100%'}}>
                                        <DataGrid
                                            rows={this.state.rows}
                                            columns={this.state.columns}
                                            pagination
                                            pageSize={this.state.paginator.limit}
                                            rowsPerPageOptions={[this.state.paginator.limit]}
                                            rowCount={this.state.paginator.countRows}
                                            paginationMode="server"
                                            onPageChange={this.handlePageChange}
                                            loading={this.state.paginator.loading}
                                        />
                                    </div>
                                </div>
                                {this.renderInfoTicket()}
                                {this.renderSessions()}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(TicketClientListPage));
