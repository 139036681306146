import React, {Component} from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import {sidebarRoutes} from "../../routes/";

//i18n
import {withTranslation} from 'react-i18next';

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li key="title-menu" className="menu-title">{this.props.t('Menu')}</li>
                        {sidebarRoutes.map((sideRout, i) => {
                            return (
                                <li key={i}>
                                    <Link onClick={() => {
                                        if (!sideRout.sub)
                                            this.props.toggleMenu()
                                    }} to={sideRout.path} className="waves-effect">
                                        {(sideRout.icon) ? <i className={sideRout.icon}></i> : ""}
                                        <span>{this.props.t(sideRout.name)}</span>
                                    </Link>
                                    {(sideRout.sub) ?
                                        <ul className="sub-menu" aria-expanded="false">
                                            {sideRout.sub.map((sideRoutSub, iSub) => {
                                                return (
                                                    <li key={iSub}>
                                                        <Link onClick={() => this.props.toggleMenu()}
                                                              to={sideRoutSub.path}>
                                                            {(sideRoutSub.icon) ?
                                                                <i className={sideRoutSub.icon}></i> : ""}
                                                            <span>{this.props.t(sideRoutSub.name)}</span>
                                                        </Link>
                                                    </li>)
                                            })}

                                        </ul>
                                        : ""}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(SidebarContent));
