import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ButtonGroup
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {editRegion, getListRegion, ImgRegion, setRegion} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {AvField, AvForm} from "availity-reactstrap-validation";

class RegionListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    headerName: "Immagine",
                    width: 140,
                    field: 'image',
                    renderCell: (params) => (
                        <img src={global.baseUrlImg + params.value} alt="" max-height="100" width="100"/>
                    )
                },
                {headerName: "Regione", field: "region", flex: 1},
                {headerName: "Nazione", field: "country", flex: 1},
                {
                    headerName: " ",
                    width: 200,
                    field: 'id',
                    renderCell: (params) => (
                        <ButtonGroup>
                            <Button onClick={() => this.imgButton(params)} type="button"
                                    color="secondary">Immagine</Button>
                            <Button onClick={() => this.actionButton(params)} type="button"
                                    color="primary">Modifica</Button>
                        </ButtonGroup>
                    )
                }
            ],
            rows: [],
            paginator: {loading: true},
            form_edit: {
                id: null,
                country: null,
                region: null,
            },
            flag_edit: false,
            image: null,
            modal: false,
            imageUpload: null,
            uploadId: null
        };
        this.onImageChange = this.onImageChange.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose = () => this.setState({modal: false});

    uploadImage = () => {
        var formData = new FormData();
        formData.append("image", this.state.imageUpload);
        formData.append("id", this.state.uploadId);

        ImgRegion(formData).then(result => {
            if (typeof result.error !== "undefined")
                global.notify("Errore durante il caricamento: " + result.error, "error");
            else
                global.notify("Immagine caricata con successo", "success");
            this.loadListRegion();
            this.setState({modal: false, imageUpload: null, uploadId: null, image: null});
        })
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                image: URL.createObjectURL(img),
                imageUpload: event.target.files[0]
            });
        }
    };

    async componentDidMount() {
        this.loadListRegion();
    }

    loadListRegion = () => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getListRegion().then(result => {
            paginator.loading = false;
            if (!result) {
                this.setState({rows: [], paginator: paginator});
            } else {
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    actionButton = (params) => {
        this.setState({form_edit: params.row, flag_edit: true});
    }

    imgButton = (params) => {
        this.setState({modal: true, uploadId: params.value});
    }

    async handleValidSubmit(event, values, edit = false) {
        let formResult = edit ? await editRegion(values) : await setRegion(values);

        if (typeof formResult.error === 'undefined') {
            global.notify("Azione eseguita con successo", "success");
            this.loadListRegion();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore: " + formResult.error, "error");
        } else {
            global.notify("Errore generico, riprova più tardi ", "error");
        }
        this.form && this.form.reset();
        this.setState({flag_edit: false});
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Gestione regioni"/>
                        <Card>
                            <CardBody>
                                <h5>Lista regioni</h5>
                                <div style={{height: '70vh', width: '100%'}}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        loading={this.state.paginator.loading}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h5>{this.state.flag_edit ? "Modifica regione" : "Aggiungi regione"}</h5>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    <div className="row">
                                        {(this.state.flag_edit) ?
                                            <div className="form-group" hidden={true}>
                                                <AvField name="id" label="Id regione" value={this.state.form_edit.id}
                                                         className="form-control" placeholder="Id regione" type="text"
                                                         required/>
                                            </div> : ""
                                        }
                                        <div className="form-group col-md-6 col-sm-12 ">
                                            <AvField name="country" label="Nazione" value={this.state.form_edit.country}
                                                     className="form-control" placeholder="Inserisci la nazione"
                                                     type="text" required/>
                                        </div>
                                        <div className="form-group col-md-6 col-sm-12 ">
                                            <AvField name="region" label="Regione" value={this.state.form_edit.region}
                                                     className="form-control" placeholder="Inserisci la regione"
                                                     type="text" required/>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="submit" color={(this.state.flag_edit ? "warning" : "success")}>
                                            {(this.state.flag_edit ? "Modifica regione" : "Aggiungi regione")}
                                        </Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                        <Modal isOpen={this.state.modal} backdrop="static" keyboard={false}>
                            <ModalHeader>Immagine Zona</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col lg="12">
                                        <img src={this.state.image}/>
                                        <h1>Scegli immagine</h1>
                                        <input type="file" name="myImage" onChange={this.onImageChange}/>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={this.handleClose}>Annulla</Button>{' '}
                                <Button color="primary" onClick={this.uploadImage}>Carica</Button>
                            </ModalFooter>
                        </Modal>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {getListRegion})(RegionListPage));
