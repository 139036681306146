import {apiCall, UploadFile} from '../../../helpers/backend_helper';

export const uploadImage = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await UploadFile({method: 'POST', url: 'admin/blog/image/upload', data: data});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const infoBlog = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/blog/info', data: {id: id}});
    } catch (error) {
        return false;
    }
}

export const addBlog = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/blog/add', data: data});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editBlog = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/blog/edit', data: data});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const deleteBlog = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/blog/delete', data: {id: id}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listBlog = async (page) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/blog/list', data: {page: page}});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}


export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}