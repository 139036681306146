import React, {Component} from "react";
import {Row, Col, Card, CardBody, Container, Alert, Button} from "reactstrap";
import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {setSociety, getSocietyInfo} from '../../store/actions';
import {editSocSociety} from '../../store/society/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';
import "./society.scss";

class SocSocietyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form_edit: {
                id: null,
                name: null,
                description: null,
                piva: null,
                phone: null,
                region: null,
                province: null,
                address: null,
                number: null,
                city: null,
                cap: null,
            },
            flag_edit: false,
            paginator: {loadingSociety: true, page: 0, countRows: 0, limit: 50},
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async handleValidSubmit(event, values, edit = false) {
        let formResult = await editSocSociety(values);

        if (typeof formResult.society !== 'undefined') {
            this.setState({error: false, success: 'Azione eseguita con successo'});
            this.loadListSocieties(this.state.paginator.page - 1);
        } else if (typeof formResult.error !== 'undefined') {
            this.setState({error: formResult.error});
        } else {
            this.setState({error: 'Errore generico'});
        }
        window.scrollTo(0, 0);
    }

    actionButton = (params) => {
        this.setState({form_edit: params.row, flag_edit: true});
    }

    async componentDidMount() {
        let res = await getSocietyInfo();
        if (typeof res.error !== "undefined") {
            global.notify("Errore: " + res.error, "danger");
            return;
        }
        let society = res.society;
        let form_edit = {
            id: society.id,
            name: society.name,
            description: society.description,
            piva: society.piva,
            phone: society.phone,
            region: society.region,
            province: society.province,
            address: society.address,
            number: society.number,
            city: society.city,
            cap: society.cap,
        }
        this.setState({
            flag_edit: true,
            form_edit: form_edit,
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumb title="Geoticket" breadcrumbItem="Dati Ente"/>
                        <Row>
                            <Col lg="12">
                                {this.state.error && this.state.error ?
                                    <Alert color="danger">{this.state.error}</Alert> : null}
                                {this.state.success && this.state.success ?
                                    <Alert color="success">{this.state.success}</Alert> : null}
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    <div className="row">
                                        <div className="form-group col-md-4">
                                            <AvField name="name" label="Nome Ente" value={this.state.form_edit.name}
                                                     className="form-control" placeholder="Inserisci Nome Ente"
                                                     type="text" required/>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <AvField name="description" label="Descrizione Ente"
                                                     value={this.state.form_edit.description} className="form-control"
                                                     placeholder="Inserisci Descrizione Ente" type="textarea"/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="piva" label="P.IVA Ente" value={this.state.form_edit.piva}
                                                     className="form-control" placeholder="Inserisci P.IVA Ente"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="phone" label="Phone Ente"
                                                     value={this.state.form_edit.phone} className="form-control"
                                                     placeholder="Inserisci Phone Ente" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="region" label="Regione dell'Ente"
                                                     value={this.state.form_edit.region} className="form-control"
                                                     placeholder="Inserisci Regione dell'Ente" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="province" label="Provincia Ente"
                                                     value={this.state.form_edit.province}
                                                     className="form-control" placeholder="Inserisci provincia Ente"
                                                     type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="address" label="Indirizzo Ente"
                                                     value={this.state.form_edit.address} className="form-control"
                                                     placeholder="Inserisci Indirizzo Ente" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <AvField name="number" label="Numero civico"
                                                     value={this.state.form_edit.number} className="form-control"
                                                     placeholder="Inserisci Numero civico" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="city" label="Città" value={this.state.form_edit.city}
                                                     className="form-control" placeholder="Inserisci Città" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <AvField name="cap" label="CAP" value={this.state.form_edit.cap}
                                                     className="form-control" placeholder="Inserisci CAP" type="text"
                                                     required/>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="submit"
                                                color="danger">{(this.state.flag_edit) ? "Modifica Ente" : "Crea Ente"}</Button>
                                    </div>
                                </AvForm>

                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(SocSocietyPage));