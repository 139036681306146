import React, {Component} from "react";
import {Button, Card, CardBody, CardText, Container, Label} from "reactstrap";
import GoogleMapReact from 'google-map-react';
import {DataGrid} from '@material-ui/data-grid';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {getSocietyListAreas, editArea, getMapAreas} from '../../store/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';
import {listAreaSociety, listClientSociety, societyInfo} from "../../store/manager_society/actions";

class SocietyClientListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Email", field: "email", width: 180},
                {headerName: "Nome", field: "firstname", width: 160},
                {headerName: "Cognome", field: "lastname", width: 160},
                {headerName: "C.F.", field: "fiscal_code", width: 180},
                {headerName: "Telefono", field: "phone", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 140},
                {headerName: "CAP", field: "cap", width: 140},
            ],
            rows: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            society: null
        };
    }

    async componentDidMount() {
        if (typeof this.props.match.params.id !== "undefined" && this.props.match.params.id !== null) {
            societyInfo(this.props.match.params.id).then((result) => {
                if (!result) {
                    global.notify("Errore", "danger");
                    return;
                }
                this.setState({society: result.society}, () => this.loadListClient(0))
            });
        }
    }

    loadListClient = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});
        listClientSociety(this.state.society.id, page + 1).then(result => {
            paginator.loading = false;

            if (!result) {
                paginator.page = 0;
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.page += 1;
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    handlePageChange = (params) => {
        this.loadListClient(params.page);
    };

    renderSociety() {
        if (this.state.society === null)
            return;
        let society = this.state.society;
        let text = society.name;
        if (society.address !== null)
            text += " " + society.address;
        if (society.number !== null)
            text += ", n°" + society.number;
        if (society.province !== null)
            text += ", " + society.province;
        if (society.region !== null)
            text += " (" + society.region + ")";
        return <CardText>{text}</CardText>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Lista clienti dell'ente"/>
                        {this.state.society === null ? <div></div> :
                            <div className={"row"}>
                                <div className={"col-md-12"}>
                                    <Card>
                                        <CardBody>
                                            <div className="form-group">
                                                <Label for="society"><b>{this.state.society.name}</b></Label>
                                                {this.renderSociety()}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className={"col-md-12"}>
                                    <Card>
                                        <CardBody>
                                            <div style={{height: '80vh', width: '100%'}}>
                                                <DataGrid
                                                    rows={this.state.rows}
                                                    columns={this.state.columns}
                                                    pagination
                                                    pageSize={this.state.paginator.limit}
                                                    rowsPerPageOptions={[this.state.paginator.limit]}
                                                    rowCount={this.state.paginator.countRows}
                                                    paginationMode="server"
                                                    onPageChange={this.handlePageChange}
                                                    loading={this.state.paginator.loadingFishes}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        }
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {getSocietyListAreas, getMapAreas})(SocietyClientListPage));
