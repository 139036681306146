import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    ButtonGroup,
    CardTitle,
    CardText,
    Badge,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    getSocietyListAreas,
    listSocTickets,
    infoSocClientTicket,

    socListClientTicketsCalendar,
    socRefundClientTicket, socTicketClientChangeDate,
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {DataGrid} from "@material-ui/data-grid";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import DatePicker from "react-datepicker";
import {windowOpen} from "echarts/src/util/format";

var moment = require('moment');

class CalendarPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [{headerName: "Nome area", field: "name", width: 180},
                {
                    headerName: "Categoria",
                    field: "category",
                    width: 160, renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Province", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 140},
                {headerName: "CAP", field: "cap", width: 140},
                {headerName: "Telefono", field: "phone", width: 160}],
            columnsMicroArea: [{headerName: "Nome micro area", field: "name", width: 240}],
            columnsTicket: [
                {
                    headerName: "Nome ticket", field: "name", width: 180, valueGetter: (params) =>
                        (params.getValue(params.id, "is_disabled") ? "[DISABILITATO] " : "") + params.value
                },
                {headerName: "Prezzo", field: "price", width: 160},
                {headerName: "Inizio validità", field: "date_start_validity", width: 160},
                {headerName: "Fine validità", field: "date_end_validity", width: 160},
                {
                    headerName: "Tipologia", field: "typeName", width: 160, valueGetter: (params) => {
                        switch (params.getValue(params.id, "type")) {
                            case "DAILY":
                                return "Giornaliero";
                            case "TWODAYS":
                                return "Bigiornaliero";
                            case "THREEDAYS":
                                return "Tre giorni";
                            case "FOURDAYS":
                                return "Quattro giorni";
                            case "WEEKDAYS":
                                return "Cinque giorni";
                            case "WEEKLY":
                                return "Settimanale";
                            case "TWOWEEKS":
                                return "Bisettimanale";
                            case "MONTHLY":
                                return "Mensile";
                            case "TRIMESTER":
                                return "Trimestrale";
                            case "SEASONAL":
                                return "Stagionale";
                            case "SEMIANNUAL":
                                return "Semestrale";
                            case "YEARLY":
                                return "Annuale";
                            case "BIANNUAL":
                                return "Biennale";
                            case "TRIENNIAL":
                                return "Triennale";
                        }
                    }
                },
                {
                    headerName: "Tipo residenti", field: "ticket_residence_type", width: 180, renderCell: (params) => {
                        if (params.value === null)
                            return (<Badge color="alert">Sconosciuto</Badge>)
                        return (<Badge color="info">{params.value.name}</Badge>)
                    }
                },
            ],
            rows: [],
            rowsMicroArea: [],
            rowsTicket: [],

            dateStartView: moment(),
            dateEndView: moment().add(1, "month"),

            form_edit: {
                id_area: null,
                id_micro_area: null,
                id_ticket: null,
                fishable_specs: null,
                max_quantity: null,
                date_capture: "DAILY",
                operating_dates: null,
                kill: true,
            },
            dates_week: {},
            operating_dates: null,
            showTable: 'AREA',
            paginator: {loading: true, countRows: 0, limit: 50},
            paginatorTicket: {loading: true, countRows: 0, limit: 50},
            area: null,
            micro_area: null,
            ticket: null,
            eventsCalendar: [],

            modalTicket: null,
            date_start_use: null,
            date_end_use: null,
            date_current: new Date(),
            form_edit_ticket: {
                date_start: null,
                date_end: null,
            },
        };
    }

    async componentDidMount() {
        this.showTable('AREA');
    }

    showTable = (state) => {
        if (state === 'MICRO' && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        if (state === 'MICRO' && (typeof this.state.area.micro_areas === "undefined" || Object.keys(this.state.area.micro_areas).length === 0)) {
            global.notify("Non ci sono micro aree presenti per quest'area", "warning");
            return;
        }
        if (state === 'TICKET' && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        this.setState({showTable: state});
        if (state === 'AREA') {
            if (Object.keys(this.state.rows).length === 0)
                this.loadListAreas(0);
        }
    }

    loadListAreas = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getSocietyListAreas(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                this.setState({rows: result.list, rowsArea: result.list, paginator: paginator});
            }
        })
    }

    loadListTicket = (page) => {
        let paginatorTicket = this.state.paginatorTicket;
        paginatorTicket.loading = true;
        this.setState({paginatorTicket: paginatorTicket});

        listSocTickets(this.state.area.id, page + 1).then(result => {
            paginatorTicket.loading = false;
            if (!result) {
                paginatorTicket.countRows = 0;
                this.setState({rowsTicket: [], paginatorTicket: paginatorTicket});
            } else {
                this.setState({rowsTicket: result.list, paginatorTicket: paginatorTicket});
            }
        })
    }

    loadListTicketCalendar = () => {
        let data = {};
        if (this.state.area !== null)
            data.id_area = this.state.area.id;
        if (this.state.micro_area !== null)
            data.id_micro_area = this.state.micro_area.id;
        if (this.state.ticket !== null)
            data.id_ticket = this.state.ticket.id;
        data.date_start = this.state.dateStartView.format("DD/MM/YYYY");
        data.date_end = this.state.dateEndView.format("DD/MM/YYYY");

        socListClientTicketsCalendar(data).then(result => {
            if (typeof result.list !== "undefined") {
                let events = [];
                for (let i in result.list) {
                    let ticket = result.list[i];
                    if (ticket.date_start_use === null || ticket.date_end_use === null)
                        continue;
                    let start = moment(ticket.date_start_use, "DD/MM/YYYY hh:mm");
                    let end = moment(ticket.date_end_use, "DD/MM/YYYY hh:mm");


                    let name = start.format("hh:mm") + "-" + end.format("hh:mm") + " " + "[" + ticket.id + "]";
                    if (ticket.nickname !== null && ticket.nickname !== "")
                        name += " " + ticket.nickname;
                    if (ticket.client !== null && ticket.client.email !== null)
                        name += " - " + ticket.client.email;
                    let ticket_change_date = null;
                    if (typeof ticket.ticket_change_date !== "undefined")
                        ticket_change_date = ticket.ticket_change_date;
                    events.push({
                        id: ticket.id,
                        title: name,
                        start: start.format("YYYY-MM-DD hh:mm"),
                        end: end.format("YYYY-MM-DD hh:mm"),
                        backgroundColor: ticket_change_date !== null ? "#FFA726" : "#2196F3",
                        allDay: false,
                        textColor: "#fafafa",
                        eventTextColor: "#fafafa",
                    })
                }
                this.setState({eventsCalendar: events});
            } else {
                console.log("result")
                console.log(result)
                this.setState({eventsCalendar: []});

            }
        })
    }

    handlePageChange = (params) => {
        this.loadListAreas(params.page)
    };

    handlePageTicketChange = (params) => {
        this.loadListTicket(params.page)
    };

    handleRowSelected = async (rows) => {
        let row = [...rows.entries()][0][1];
        switch (this.state.showTable) {
            case "AREA":
                await this.setState({
                    area: row,
                    rowsMicroArea: typeof row.micro_areas !== "undefined" ? row.micro_areas : []
                }, () => this.loadListTicket(0));
                break;
            case "MICRO":
                await this.setState({micro_area: row});
                break;
            case "TICKET":
                await this.setState({ticket: row});
                break;
        }
        this.loadListTicketCalendar();
    }

    renderCalendar = () => {
        return <div>
            <FullCalendar
                datesSet={(arg) => {
                    this.setState({dateStartView: moment(arg.start), dateEndView: moment(arg.end)});
                }}
                plugins={[dayGridPlugin, interactionPlugin]}
                aspectRatio={2}
                eventLimit={true}
                eventDisplay="block"
                dayMaxEventRows={5}
                headerToolbar={{
                    left: 'prev,next',
                    center: 'title',
                    right: 'dayGridMonth,dayGridWeek,dayGridDay' // user can switch between the two
                }}
                locale={itLocale}
                initialView="dayGridMonth"
                events={this.state.eventsCalendar}
                eventClick={(info) => {
                    this.openEditTicket(info.event.id);
                }}
            /></div>;
    }

    openEditTicket = async (id) => {
        let res = await infoSocClientTicket(id);
        if (typeof res.error !== "undefined") {
            global.notify("Errore: " + res.error, "danger");
            return;
        }
        this.setState({modalTicket: res.ticket});
        this.setDateTicket(moment(res.ticket.date_start_use, "DD/MM/YYYY HH:mm").toDate(), "start");
        this.setDateTicket(moment(res.ticket.date_end_use, "DD/MM/YYYY HH:mm").toDate(), "end");
    }

    downloadTicket = (id) => {
        global.notify("Ticket scaricato", "success");
        windowOpen(global.baseUrl + 'admin/ticket/download/' + id, "blank")
    }

    changeDate = async () => {
        let ticket = this.state.modalTicket;
        let form = this.state.form_edit_ticket;
        let result = await socTicketClientChangeDate(ticket.id, form.date_start, form.date_end);
        if (typeof result.ticket_client !== "undefined") {
            global.notify("Proposta inviata con successo", "success");
            this.setState({modalTicket: result.ticket_client}, () => this.loadListTicketCalendar());
        } else if (typeof result.error !== 'undefined') {
            global.notify("Errore con la proposta: " + result.error, "danger");
        } else {
            global.notify("Errore generico ", "danger");
        }
    }

    async refundTicket(id) {
        let res = await socRefundClientTicket(id);
        if (res) {
            global.notify("Rimborso effettuato con successo", "success");
            this.setState({ticket: null, modalTicket: null}, () => this.loadListTicketCalendar());
        } else {
            global.notify("Errore con il rimborso", "danger");
            this.loadList(0)
        }
    }

    setDateTicket = (date, type) => {
        let date_start = this.state.date_start_use;
        let date_end = this.state.date_end_use;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit_ticket = this.state.form_edit_ticket;
        form_edit_ticket.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY HH:mm");
        form_edit_ticket.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY HH:mm");

        this.setState({date_start_use: date_start, date_end_use: date_end, form_edit_ticket: form_edit_ticket});
    }

    renderModalEdit = () => {
        if (this.state.modalTicket !== null) {
            let ticket = this.state.modalTicket;
            let extra_data = [];
            if (Object.keys(ticket.extra_data).length > 0)
                extra_data.push(<hr/>);
            for (let i in ticket.extra_data) {
                let data = ticket.extra_data[i];
                extra_data.push(<b>{data.name}:</b>);
                extra_data.push(<span> {data.value}</span>);
                extra_data.push(<br/>);
            }
            let ticket_change_date = null;
            if (typeof ticket.ticket_change_date !== "undefined")
                ticket_change_date = ticket.ticket_change_date;
            return <Modal isOpen={this.state.modalTicket} backdrop="static" size={"lg"}>
                <ModalHeader>Informazioni ticket</ModalHeader>
                <ModalBody>
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <h5>Ticket #{ticket.id}</h5>
                            <b>Nome ticket:</b> {ticket.ticket.name}<br/>
                            Data acquisto: {ticket.date_purchase}<br/>
                            Data utilizzo: {ticket.date_start_use} - {ticket.date_end_use}<br/>
                            {ticket.client !== null ? "Utente: " + ticket.client.firstname + " " + ticket.client.lastname + "(" + ticket.client.email + ")" : ""}<br/>
                            {ticket.nickname !== null ? "Nominativo: " + ticket.nickname : <></>} <br/>
                            {extra_data}
                            <hr/>
                            <b>Ente:</b> {ticket.ticket.society.name}<br/>
                            Area: {ticket.ticket.area.name} <br/>
                            {ticket.ticket.micro_area !== null ? "Zona: " + ticket.ticket.micro_area.name : <></>}<br/>
                            <div align={"center"}>
                                <Button onClick={() => this.refundTicket(ticket.id)} color={"warning"}>Rimborsa
                                    ticket</Button>
                            </div>
                            <hr/>
                        </div>
                        <div className={"col-md-12"}>
                            <h5>Modifica prenotazione</h5>
                            <div className={"row"}>
                                <div className="form-group col-md-6">
                                    <label>Data inizio utilizzo</label>
                                    <DatePicker
                                        selected={this.state.date_start_use}
                                        minDate={this.state.date_current}
                                        disabled={ticket_change_date !== null}
                                        className="form-control"
                                        onChange={(date) => this.setDateTicket(date, "start")}
                                        dateFormat="dd/MM/yyyy HH:mm" placeholder={"Data inizio utilizzo"}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Data fine utilizzo</label>
                                    <DatePicker
                                        selected={this.state.date_end_use}
                                        minDate={this.state.date_start_use}
                                        disabled={ticket_change_date !== null}
                                        className="form-control"
                                        onChange={(date) => this.setDateTicket(date, "end")}
                                        dateFormat="dd/MM/yyyy HH:mm" placeholder={"Data fine utilizzo"} showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}/>
                                </div>
                                <div align={"center"} class={"col-md-12"}>
                                    <Button onClick={() => {
                                        this.changeDate()
                                    }} color={"success"} disabled={ticket_change_date !== null}
                                    >Proponi cambio data</Button>
                                </div>
                            </div>
                            {ticket_change_date !== null ? <><p>Nuova proposta della
                                data: <b>{ticket_change_date.date_start}</b>-<b>{ticket_change_date.date_end}</b>
                            </p></> : <></>}
                            <hr/>
                        </div>

                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => this.downloadTicket(ticket.id)}
                            color={"danger"}>Scarica pdf</Button>{" "}
                    <Button color="secondary" onClick={() => this.setState({modalTicket: null})}>Chiudi</Button>
                </ModalFooter>
            </Modal>
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Calendario"/>
                        <Card>
                            <CardBody>
                                <div className="text-center mb-4">
                                    <ButtonGroup>
                                        <Button
                                            onClick={() => this.showTable('AREA')}
                                            color={(this.state.showTable === 'AREA') ? "light" : "primary"}>Area</Button>
                                        <Button
                                            onClick={() => this.showTable('MICRO')}
                                            color={(this.state.showTable === 'MICRO') ? "light" : "primary"}>MicroArea</Button>
                                        <Button
                                            onClick={() => this.showTable('TICKET')}
                                            color={(this.state.showTable === 'TICKET') ? "light" : "primary"}>Ticket</Button>
                                    </ButtonGroup>
                                </div>
                                <div style={{height: '40vh', width: '100%'}} hidden={this.state.showTable !== 'AREA'}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        pagination
                                        pageSize={this.state.paginator.limit}
                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                        rowCount={this.state.paginator.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageChange}
                                        loading={this.state.paginator.loading}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div style={{height: '40vh', width: '100%'}} hidden={this.state.showTable !== 'MICRO'}>
                                    <DataGrid
                                        rows={this.state.rowsMicroArea}
                                        columns={this.state.columnsMicroArea}
                                        pageSize={Object.keys(this.state.rowsMicroArea).length}
                                        rowsPerPageOptions={[Object.keys(this.state.rowsMicroArea).length]}
                                        rowCount={Object.keys(this.state.rowsMicroArea).length}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div style={{height: '40vh', width: '100%'}} hidden={this.state.showTable !== 'TICKET'}>
                                    <DataGrid
                                        rows={this.state.rowsTicket}
                                        columns={this.state.columnsTicket}
                                        pagination
                                        pageSize={this.state.paginatorTicket.limit}
                                        rowsPerPageOptions={[this.state.paginatorTicket.limit]}
                                        rowCount={this.state.paginatorTicket.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageTicketChange}
                                        loading={this.state.paginatorTicket.loading}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div className={"row"}>
                                    {this.state.area !== null ? <div className={"col-md-4"}>
                                            <Card body>
                                                <CardTitle tag="h5">Area</CardTitle>
                                                <CardText>Nome area: {this.state.area.name}<br/> {this.state.area.address},
                                                    n°{this.state.area.number} {this.state.area.city} {this.state.area.region}
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.micro_area !== null ? <div className={"col-md-4"}>
                                            <Card body>
                                                <CardTitle tag="h5">Micro Area</CardTitle>
                                                <CardText>Nome micro area: {this.state.micro_area.name}</CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.ticket !== null ? <div className={"col-md-4"}>
                                            <Card body>
                                                <CardTitle tag="h5">Ticket</CardTitle>
                                                <CardText>Nome
                                                    ticket: {this.state.ticket.name}<br/> {this.state.ticket.price},
                                                    {this.state.ticket.date_start_validity} {this.state.ticket.date_end_validity} {this.state.ticket.type}
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <h4>Calendario</h4>
                                {this.renderCalendar()}
                            </CardBody>
                        </Card>
                    </Container>
                    {this.renderModalEdit()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(CalendarPage));
