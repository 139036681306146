import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    Modal,
    ModalHeader, ModalBody, ModalFooter, ButtonGroup
} from "reactstrap";
import {DataGrid} from '@material-ui/data-grid';

// availity-reactstrap-validation
import {AvForm, AvField} from 'availity-reactstrap-validation';
// Redux
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
//i18n
import {withTranslation} from 'react-i18next';

// actions
import {
    getSociety,
    setSociety,
    editSociety,
    apiError,
    getSocieties,
    getListRegion,
    disableSociety
} from '../../store/actions';

import Breadcrumb from '../../components/Common/Breadcrumb';

class Society extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {headerName: "Nome ente", field: "name", width: 180},
                {headerName: "Numero tel", field: "phone", width: 160},
                {headerName: "Partita Iva", field: "piva", width: 160},
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Provincia", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 160},
                {headerName: "CAP", field: "cap", width: 120},
                {
                    headerName: " ",
                    width: 220,
                    field: 'id',
                    renderCell: (params) => {
                        return <ButtonGroup>
                            <Button onClick={() => this.summarySociety(params)} type="button"
                                    color="success">Scheda</Button>
                            <Button onClick={() => this.actionButton(params)} type="button"
                                    color="primary">Modifica</Button>
                            {(params.getValue(params.id, "is_disabled") ?
                                <Button onClick={() => this.disableSociety(params.row)} type="button"
                                        color="warning">Riabilita</Button> :
                                <Button onClick={() => this.disableSociety(params.row)} type="button"
                                        color="danger">Sospendi</Button>)
                            }
                        </ButtonGroup>
                    }
                }
            ],
            rows: [],
            form_edit: {
                id: null,
                name: null,
                description: null,
                piva: null,
                phone: null,
                region: null,
                province: null,
                address: null,
                number: null,
                city: null,
                cap: null,
                email_send_order: null
            },
            flag_edit: false,
            modal: false,
            society: null,
            is_disabled: false,
            regionList: [],
            paginator: {loadingSociety: true, page: 0, countRows: 0, limit: 50},
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    async handleValidSubmit(event, values, edit = false) {
        let formResult = null;
        if (edit) {
            formResult = await editSociety(values);
        } else {
            formResult = await setSociety(values);
        }

        if (typeof formResult.society !== 'undefined') {
            global.notify('Azione eseguita con successo', "success");
            this.loadListSocieties(this.state.paginator.page - 1);
            this.form && this.form.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore: " + formResult.error, "danger");
        } else {
            global.notify("Errore generico", "danger");
        }
        window.scrollTo(0, 0);
    }

    disableSociety = (society) => {
        this.setState({modal: true, society: society, is_disabled: society.is_disabled});
    }

    actionButton = (params) => {
        this.setState({form_edit: params.row, flag_edit: true});
    }

    summarySociety = (params) => {
        window.location.href = '/summary/' + params.id
    }

    async componentDidMount() {
        this.loadRegions();
        this.loadListSocieties(0);
    }

    loadListSocieties = (page) => {
        let paginator = this.state.paginator;
        paginator.loadingSociety = true;
        this.setState({paginator: paginator});

        getSocieties(page + 1).then(result => {
            paginator.loadingSociety = false;

            if (!result) {
                paginator.page = 0;
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.page += 1;
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, paginator: paginator});
            }
        })
    }

    handlePageChange = (params) => {
        this.loadListSocieties(params.page);
    };

    loadRegions = () => {
        getListRegion().then(result => {
            if (!result) {
                this.setState({regionList: []});
            } else {
                let regions = [];
                result.list.map((value, i) => {
                    if (typeof regions[value.country] === "undefined")
                        regions[value.country] = [];
                    regions[value.country].push(value.region);
                })
                this.setState({regionList: regions});
            }
        })
    }

    renderListRegion = (regionSelected = null) => {
        let ret = [];
        for (let country in this.state.regionList) {
            ret.push(<option disabled>{country}</option>)
            for (let region in this.state.regionList[country]) {
                let selected = null;
                if (regionSelected !== null && this.state.regionList[country][region] === regionSelected)
                    selected = "selected";
                ret.push(<option
                    value={this.state.regionList[country][region]}
                    selected={selected}>{this.state.regionList[country][region]}</option>)
            }
        }
        return ret;
    }

    saveDisableSociety = () => {
        let data = {id: this.state.society.id};
        if (this.state.is_disabled)
            data.status = true;
        disableSociety(data).then((result) => {
            if (typeof result.error === 'undefined') {
                global.notify('Azione eseguita con successo', "success");
                this.setState({society: null, status: null, modal: false});
                this.loadListSocieties(0);
            } else if (typeof result.error !== 'undefined') {
                global.notify("Errore: " + result.error, "danger");
            } else {
                global.notify("Errore generico", "danger");
            }
        });
    }

    handleClose = () => this.setState({modal: false});

    renderModal = () => {
        let society = this.state.society;
        if (society === null)
            return;
        return <Modal isOpen={this.state.modal} backdrop="static" keyboard={false}>
            <ModalHeader>Sospendi ente {society.name}</ModalHeader>
            <ModalBody>
                <div className="form-check col-md-12 mb-4">
                    <input type="checkbox" className="form-check-input" checked={this.state.is_disabled}
                           id="is_disabled" name="is_disabled"
                           onChange={(value) => (this.setState({is_disabled: value.target.checked}))}/>
                    <label className="form-check-label" for={"is_disabled"}>Ente disabilitato</label>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.handleClose}>Chiudi</Button>
                <Button color="success" onClick={this.saveDisableSociety}>Salva</Button>
            </ModalFooter>
        </Modal>
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Aggiunta-modifica ente"/>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div style={{height: 400, width: '100%'}}>
                                            <DataGrid
                                                rows={this.state.rows}
                                                columns={this.state.columns}
                                                pagination
                                                pageSize={this.state.paginator.limit}
                                                rowsPerPageOptions={[this.state.paginator.limit]}
                                                rowCount={this.state.paginator.countRows}
                                                paginationMode="server"
                                                onPageChange={this.handlePageChange}
                                                loading={this.state.paginator.loadingSociety}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v, this.state.flag_edit)
                                }} ref={c => (this.form = c)}>
                                    <div className="row">
                                        {(this.state.flag_edit) ?
                                            <div className="form-group" hidden={true}>
                                                <AvField name="id" label="id" value={this.state.form_edit.id}
                                                         className="form-control" placeholder="Inserisci id"
                                                         type="text" required/>
                                            </div> : ""
                                        }
                                        <div className="form-group col-md-4 ">
                                            <AvField name="name" label="Nome ente" value={this.state.form_edit.name}
                                                     className="form-control" placeholder="Inserisci nome ente"
                                                     type="text" required/>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <AvField name="description" label="Descrizione ente"
                                                     value={this.state.form_edit.description} className="form-control"
                                                     placeholder="Inserisci descrizione ente" type="textarea"/>
                                        </div>
                                        <div className="form-group col-md-6 ">
                                            <AvField name="piva" label="P.IVA ente" value={this.state.form_edit.piva}
                                                     className="form-control" placeholder="Inserisci P.IVA ente"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="phone" label="Telefono ente"
                                                     value={this.state.form_edit.phone} className="form-control"
                                                     placeholder="Inserisci telefono ente" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="region" label="Regione" value={this.state.form_edit.region}
                                                     className="form-control" placeholder="Inserisci regione"
                                                     type="select" required>
                                                {this.renderListRegion(this.state.form_edit.region)}
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="province" label="Provincia ente"
                                                     value={this.state.form_edit.province}
                                                     className="form-control" placeholder="Inserisci provincia ente"
                                                     type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="address" label="Indirizzo ente"
                                                     value={this.state.form_edit.address} className="form-control"
                                                     placeholder="Inserisci Indirizzo ente" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <AvField name="number" label="Numero civico"
                                                     value={this.state.form_edit.number} className="form-control"
                                                     placeholder="Inserisci Numero civico" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="city" label="Città" value={this.state.form_edit.city}
                                                     className="form-control" placeholder="Inserisci Città" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <AvField name="cap" label="CAP" value={this.state.form_edit.cap}
                                                     className="form-control" placeholder="Inserisci CAP" type="text"
                                                     required/>
                                        </div>

                                        <div className="form-group col-md-6">
                                            <AvField name="email_send_order" label="Email di ricevuta degli ordini effettuati" value={this.state.form_edit.email_send_order}
                                                     className="form-control" placeholder="Inserisci email" type="text"/>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <Button type="submit"
                                                color={(this.state.flag_edit) ? "warning" : "success"}>{(this.state.flag_edit) ? "Modifica ente" : "Crea ente"}</Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                        {this.renderModal()}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(
    connect(
        mapStatetoProps, {getSociety, setSociety, editSociety, apiError}
    )
    (Society));