import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    Label,
} from "reactstrap";
import GoogleMapReact from 'google-map-react';

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    getInfoArea,
    getCategories,
    getMapAreas,
    getListRegion, ImgCategory, uploadImageArea, uploadLogoArea, deleteArea, editZoneShowcase
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';

class ZoneShowcaseEditPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            form_edit: {
                name: null,
                region: null,
                province: null,
                address: null,
                number: null,
                city: null,
                cap: null,
                email: null,
                email_cc: null
            },
            microarea: [],
            area: null,
            categories: [],
            lat: null,
            lon: null,
            map: null,
            maps: null,
            category: 0,
            paginator: {loadingSociety: true, countRows: 0, limit: 50},
            markers: [],
            key_microarea: 0,
            regionList: [],
        };
    }

    async handleValidSubmit(event, values) {
        values.id = this.state.area.id;
        values.lat = this.state.lat;
        values.lon = this.state.lon;

        let formResult = await editZoneShowcase(values);

        if (typeof formResult.error === 'undefined')
            global.notify("Modifica eseguita con successo", "success");
        else
            global.notify(formResult.error, "danger");

        window.scrollTo(0, 0);
    }

    async componentDidMount() {
        this.loadRegions();
        let categories = await getCategories();
        let area = await getInfoArea(this.props.match.params.id);


        let form_edit = {
            name: area.name,
            region: area.region,
            province: area.province,
            address: area.address,
            number: area.number,
            city: area.city,
            cap: area.cap,
            description: area.description,
            email: area.email_showcase,
            email_cc: area.email_cc_showcase,
        }
        this.setState({
            category: area.category,
            categories: categories,
            lat: area.lat,
            lon: area.lon,
            area: area,
            form_edit: form_edit,
        });
    }

    handleBoundChanged = () => {
        if (this.state.map !== null && this.state.maps !== null)
            this.loadMarkers(this.state.map, this.state.maps);
    }

    handleApiLoaded = (map, maps) => {
        let markerDragged = (e) => {
            this.setState({lat: e.latLng.lat(), lon: e.latLng.lng()})
        }

        let marker = new maps.Marker({
            map: map,
            animation: maps.Animation.DROP,
            icon: {
                url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                scaledSize: new maps.Size(42, 42)
            },
            position: new maps.LatLng({lat: this.state.lat, lng: this.state.lon}),
            draggable: true
        });
        maps.event.addListener(marker, 'dragend', markerDragged);
        this.loadMarkers(map, maps);
    }

    loadMarkers = (map, maps) => {
        let bounds = map.getBounds();
        getMapAreas(this.state.category.id, bounds.getNorthEast().lat(), bounds.getNorthEast().lng(), bounds.getSouthWest().lat(), bounds.getSouthWest().lng()).then((result) => {
            let arr_markers = [];
            for (let i in result.list) {
                let area = result.list[i];
                if (this.state.form_edit.id === area.id)
                    continue;

                let find = this.state.markers.find(m => {
                    return m.id === area.id
                });
                if (typeof find !== "undefined")
                    continue;
                if (area.id === this.state.area.id)
                    continue;

                arr_markers.push({id: area.id});
                new maps.Marker({
                    map: map,
                    position: new maps.LatLng(area.lat, area.lon),
                });

                if (typeof area.area_cords !== "undefined" && area.area_cords !== null && Object.keys(area.area_cords).length >= 3) {
                    let polygon = [];
                    for (let i in area.area_cords)
                        polygon.push({lat: area.area_cords[i].lat, lng: area.area_cords[i].lon});

                    let polygonObj = new maps.Polygon({
                        paths: polygon,
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.35
                    });
                    polygonObj.setMap(map);
                }
            }
            this.setState({markers: [...arr_markers, ...this.state.markers]})
        });
    }

    renderMap() {
        if (this.state.area === null)
            return;
        let defaultProps = {
            center: {
                lat: this.state.area.lat,
                lng: this.state.area.lon
            },
            zoom: 14
        };

        return <Card body inverse style={{backgroundColor: '#eeeeee', borderColor: '#eeeeee'}}>
            <div style={{height: '50vh', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyCV53jtplvMZi17CqUW7w8Q5w9uat1lgqw"}}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({map, maps}) => {
                        this.setState({map: map, maps: maps})
                        this.handleApiLoaded(map, maps)
                    }}
                    onBoundsChange={this.handleBoundChanged}
                />
            </div>
        </Card>
    }

    listCategories() {
        if (Object.keys(this.state.categories).length === 0)
            return;
        return this.state.categories.map(function (category, i) {
            return <option value={category.id} key={i}>{category.name}</option>
        });
    }

    loadRegions = () => {
        getListRegion().then(result => {
            if (!result) {
                this.setState({regionList: []});
            } else {
                let regions = [];
                result.list.map((value, i) => {
                    if (typeof regions[value.country] === "undefined")
                        regions[value.country] = [];
                    regions[value.country].push(value.region);
                })
                this.setState({regionList: regions});
            }
        })
    }

    renderListRegion = (regionSelected = null) => {
        let ret = [];
        for (let country in this.state.regionList) {
            ret.push(<option disabled>{country}</option>)
            for (let region in this.state.regionList[country]) {
                let selected = null;
                if (regionSelected !== null && this.state.regionList[country][region] === regionSelected)
                    selected = "selected";
                ret.push(<option
                    value={this.state.regionList[country][region]}
                    selected={selected}>{this.state.regionList[country][region]}</option>)
            }
        }
        return ret;
    }

    uploadImage = () => {
        var formData = new FormData();
        formData.append("image", this.state.imageUpload);
        formData.append("id", this.state.uploadId);

        ImgCategory(formData).then(result => {
            this.loadListCategories();
            this.setState({modal: false, imageUpload: null, uploadId: null, image: null});
        })
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            var formData = new FormData();
            formData.append("image", event.target.files[0]);
            formData.append("id", this.state.area.id);
            uploadImageArea(formData).then(result => {
                if (typeof result.error === "undefined") {
                    let area = this.state.area;
                    area.image = result.area.image;
                    this.setState({area: area});
                    global.notify("L'immagine è caricata", "success");
                } else {
                    global.notify("L'immagine non è stata caricata", "danger");
                }
            });
        }
    };

    onLogoChange = event => {
        if (event.target.files && event.target.files[0]) {
            var formData = new FormData();
            formData.append("image", event.target.files[0]);
            formData.append("id", this.state.area.id);
            uploadLogoArea(formData).then(result => {
                if (typeof result.error === "undefined") {
                    let area = this.state.area;
                    area.logo = result.area.logo;
                    this.setState({area: area});
                    global.notify("Il logo è caricato", "success");
                } else {
                    global.notify("Il logo non è stato caricato", "danger");
                }
            });
        }
    };


    deleteArea = () => {
        deleteArea(this.state.area.id).then((result) => {
            if (typeof result.error === "undefined") {
                global.notify("L'area è stata eliminata", "success");
                window.history.go(-1);
            } else {
                global.notify("Errore durante l'eliminazione", "danger");
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Modifica Zona Vetrina"/>
                        <Card>
                            <CardBody>
                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                    this.handleValidSubmit(e, v)
                                }} ref={c => (this.form = c)}>
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <AvField type="select" name="id_category" label="categoria"
                                                     value={this.state.area !== null ? this.state.area.category.id : null}
                                                     placeholder="Inserisci categoria" required>
                                                {this.listCategories()}
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <AvField name="name" label="Area nome" value={this.state.form_edit.name}
                                                     className="form-control" placeholder="Inserisci Area nome"
                                                     type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <AvField name="description" label="Descrizione zone"
                                                     value={this.state.form_edit.description} className="form-control"
                                                     placeholder="Inserisci descrizione della zona" type="textarea"/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="region" label="Area Regione"
                                                     value={this.state.form_edit.region}
                                                     className="form-control" placeholder="Inserisci Regione"
                                                     type="select" required>
                                                {this.renderListRegion(this.state.form_edit.region)}
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="province" label="Provincia"
                                                     value={this.state.form_edit.province}
                                                     className="form-control" placeholder="Inserisci Provincia"
                                                     type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="address" label="Area indirizzo"
                                                     value={this.state.form_edit.address} className="form-control"
                                                     placeholder="Inserisci indirizzo" type="text" required/>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <AvField name="number" label="Area Numero"
                                                     value={this.state.form_edit.number}
                                                     className="form-control" placeholder="Inserisci Numero"
                                                     type="text" required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="city" label="Città" value={this.state.form_edit.city}
                                                     className="form-control" placeholder="Inserisci Città" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <AvField name="cap" label="CAP" value={this.state.form_edit.cap}
                                                     className="form-control" placeholder="Inserisci CAP" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="email" label="Email per le segnalazioni"
                                                     value={this.state.form_edit.email}
                                                     className="form-control"
                                                     placeholder="Inserisci email per le segnalazioni" type="text"
                                                     required/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <AvField name="email_cc" label="Email per altri destinatari (CC:)"
                                                     value={this.state.form_edit.email_cc}
                                                     className="form-control"
                                                     placeholder="Inserisci email per altri destinatari" type="text"/>
                                            <small>(separare le email multiple con la virgola)</small>
                                        </div>
                                    </div>
                                    <hr/>

                                    {this.renderMap()}
                                    <div className="text-center mt-4">
                                        <Button type="submit" color={"success"}>Modifica Area</Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <h5>Caricamento immagini</h5>
                                <hr/>
                                <div className="row">
                                    <Card className={'col-md-6'}>
                                        <CardBody>
                                            <h6>Scegli sfondo</h6>
                                            <div align={"center"}>
                                                <img
                                                    src={(this.state.area !== null && this.state.area.image !== null) ? global.baseUrlImg + '/' + this.state.area.image : ""}
                                                    style={{width: "100%"}}/>
                                            </div>
                                            <hr/>
                                            <div align={"center"}>
                                                <input type="file" name="image" onChange={this.onImageChange}/>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className={'col-md-6'}>
                                        <CardBody>
                                            <h6>Scegli logo</h6>
                                            <div align={"center"}>
                                                <img
                                                    src={(this.state.area !== null && this.state.area.logo !== null) ? global.baseUrlImg + '/' + this.state.area.logo : ""}
                                                    style={{maxHeight: "35vh"}}/>
                                            </div>
                                            <hr/>
                                            <div align={"center"}>
                                                <input type="file" name="logo" onChange={this.onLogoChange}/>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </CardBody>
                        </Card>

                        <div className="text-center m-4">
                            <Button onClick={() => this.deleteArea()} color={"danger"}>Elimina Area</Button>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(ZoneShowcaseEditPage));