import {apiCall, UploadFile} from '../../helpers/backend_helper';

export const listMushrooms = async () => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/mushroom/list'});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listPickings = async (page = 1,date_start = null, date_end = null) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let data = {page: page};
        if (date_start !== null)
            data.date_start = date_start;
        if (date_end !== null)
            data.date_end = date_end;

        let response = await apiCall({method: 'POST', url: 'admin/picking/list', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const listCourses = async (page = 1, date_start = null, date_end = null) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let data = {page: page};
        if (date_start !== null)
            data.date_start = date_start;
        if (date_end !== null)
            data.date_end = date_end;

        let response = await apiCall({method: 'POST', url: 'admin/course/list', data: data});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setCourseStatus = async (id, status) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/course/validate', data: {id: id, status: status}});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setPickingStatus = async (id, status) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/picking/validate', data: {id: id, status: status}});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getMapPickings = async (lat_a, lon_a, lat_b, lon_b) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({
            method: 'POST',
            url: 'admin/picking/map',
            data: {lat_a: lat_a, lon_a: lon_a, lat_b: lat_b, lon_b: lon_b}
        });
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const getMapCourses = async (lat_a, lon_a, lat_b, lon_b) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({
            method: 'POST',
            url: 'admin/course/map',
            data: {lat_a: lat_a, lon_a: lon_a, lat_b: lat_b, lon_b: lon_b}
        });
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const addMushroom = async (fish) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/mushroom/add', data: fish});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editMushroom = async (fish) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/mushroom/edit', data: fish});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const uploadImageMushroom = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await UploadFile({method: 'POST', url: 'admin/mushroom/upload', data: data});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}